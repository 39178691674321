<template>
       <v-btn
          class="ma-2 btn-back"
          fab
          small
          color="rgba(255, 255, 255, 0.90)"
          @click="pushBack()"
        >
      <v-icon color="primary">
        keyboard_backspace
      </v-icon>
    </v-btn>
</template>


<script>

export default {
  name: "BtnBack",
 components: { },


  methods: { 
    pushBack(){
        this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-back{
  position: absolute;
  z-index: 2;
  outline: none;
  color:rgba(255, 255, 255, 0.219)
}

@media (min-width: 960px) {
  .btn-back {
    display: none;
  }
}

</style>
