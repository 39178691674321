<template>
  <div >
    <v-form v-model="valid" ref="formSteps">
  <v-expansion-panels 
    accordion
     v-model="panel"
    multiple
    class="py-1" 
    >
    <v-expansion-panel >
      <v-expansion-panel-header>
        <div>
           <v-icon v-if="!isValidStep" color="error">mdi-alert-circle</v-icon>
          <v-icon v-if="isValidStep" color="teal">mdi-check</v-icon>
          {{stepDescription | truncString}}
        </div>
        
        <template v-slot:actions>
          <v-icon  color="primary">$expand</v-icon>
          <!-- <v-icon v-if="i==1" color="error">mdi-alert-circle</v-icon>
          <v-icon v-if="i==2" color="teal">mdi-check</v-icon> -->
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>

      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-textarea
              v-model="instruction.description"
              name="description"
               :rules="[(v) => !!v || $t(`suggest_page.instruction.description_error`)]"
              :label="$t(`suggest_page.instruction.description`)"
              row-height="4"
              auto-grow
              required 
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12">
            <p>{{$t(`suggest_page.instruction.assets`)}}</p>
            <MediaContent :index="index" :type="'step'"></MediaContent>
          </v-col>

  
          <v-col cols="12" md="12">
 
            <div class="d-flex justify-space-between align-center pr-6">
              <v-switch
              v-model="steps[index].hint"
              :label="$t(`suggest_page.instruction.hint`)"
            ></v-switch>

              <v-btn icon color="primary" dark @click="removeStepNewRecipe()">
                <v-icon>delete</v-icon> {{$t(`suggest_page.instruction.delete`)}}
              </v-btn>
            </div>
            
          </v-col>

        </v-row>
      </v-container>
    </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </v-form>
  </div>
</template>
<script>
import MediaContent from "../media/MediaContent";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Step",
  components: {
    MediaContent,
  },

  props: {
    index: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    valid: false,
    panel: [0],

  }),

  mounted(){
    this.setRefFormInstructions(this.$refs.formSteps)
  },

 computed: {
    ...mapGetters({
      steps: "recipes/getStepsNewRecipe",
    }),

    instruction(){
       return this.steps[this.index]
    },
    stepDescription(){
      const description = this.instruction.description
        return (description || description !="") ? description  : this.$t(`suggest_page.create_an_entry`);
    },

    isValidStep(){
      return !!this.instruction?.description && this.instruction?.description.trim().length!==0;
    }
  },

  methods: {
 
    ...mapActions("recipes", ["removeStepNewRecipe", "addIngredient", "setRefFormInstructions"]),
  },
 
};
</script>

<style scoped lang="scss" >

</style>