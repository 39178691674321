import axios from 'axios'
import i18n from "@/i18n";

const axiosInstance = axios.create({
  // baseURL: 'https://vast-stream-38899.herokuapp.com/',
  baseURL: process.env.VUE_APP_PUBLIC_API_URL,
})

const NON_LOCALIZED = ["restaurant-menus", "restaurant-reviews", "reservations", "comments", "contacts"]

// const getURL = (string)  => {
//   const matches = string.match(/\w|-+/);
//   console.log("getURL")
//   console.log(matches.input)
//   // console.log(matches[1])
//   // return matches[1];
//   return matches.input;
// }

const isNomLocalized = (value)  => {
      let isLocalized= false;

        for(let i = 0; i<NON_LOCALIZED.length; i++){

          if(value.includes(NON_LOCALIZED[i])){
            isLocalized = true;
            break;
          }
      }
      return isLocalized;
}

axiosInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem('saborescv-jwt') || ''

  if (token) {
      config.headers.Authorization = `Bearer ${token}`
  }
 
  config.params = config.params || {};
  
  if (!isNomLocalized(config.url)){
    config.params['_locale'] = i18n.locale
  }
  // config.params['_locale'] = 'en'

  return config
}, function (err) {
  return Promise.reject(err)
})

export default axiosInstance
