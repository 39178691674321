<template>
  <div class="d-flex flex-wrap px-2 grid-recipe">
    <div
     
      v-for="(item, index) in recipes"
      :key="item.id + 'card-recipe'"
      :class="index < limit && !loading ?'card-feed mb-4':'mb-0'" 
    >
      <Card3
        v-if="index < limit && !loading"
        type="ratingShort"
        :recipe="item"
      ></Card3>
    </div>
    <div :class="!loading?'mb-0':'card-feed mb-4'" v-for="item in 6" :key="item">
      <Card3 v-if="loading" :loading="loading" type="ratingShort"></Card3>
    </div>
  </div>
</template>

<script>
import Card3 from "../../components/card/Card3";

export default {
  title: "Grid Restaurant Home",
  name: "GridRecipeHome",
  components: {
    Card3,
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    limit_sm: 4,
    limit_md: 6,
    limit_lg: 8,
  }),

  computed: {
    limit() {
      if (this.width >= 900 && this.width < 1300) return this.limit_md;
      else if (this.width < 900) return this.limit_sm;

      return this.limit_lg;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped lang="scss">
.grid-recipe::after {
  content: "";
  flex: auto;
}
.card-feed {
  width: 25%;
  padding-right: 2%;
}

@media (max-width: 1300px) {
  .card-feed {
    width: 33%;
    padding-right: 2%;
  }
}

@media (max-width: 900px) {
  .card-feed {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .card-feed {
    width: 100%;
  }
}
</style>