<template>
  <v-card  flat :class="isActive?'card-unavailable mx-auto my-card':'mx-auto my-card'" max-width="100%" >
   
   <div class="d-flex align-center">
      
    <v-img
      :src="getImageUrl"
      :lazy-src="getLazyImageUrl"
      height="100%"
      width="100%"
      max-height="100px"
      max-width="100px"
      aspect-ratio="1"
      class="card-img"
    ></v-img>
      
    <div
      class="d-flex flex-column align-left ml-4 pr-1  px-sm-1 px-md-1 card-info"
    >
    <div class="d-flex justify-space-between">
      <div :class="'h6 font-weight-normal  mb-0  my-card-title'">
        {{product.name}}
      </div>      

      <div class="px-1  ">
        <span>{{ product.price }}$00</span>
        <span class="caption ml-2 error--text" v-if="isActive">({{$t('unavailable')}})</span>
      </div>
      </div>

      <div class="caption">
        {{product.description}}
      </div>
     
    </div>

    </div>
  </v-card>
</template>


<script>
export default {
  name: "CardProduct",

  props: {
   
    product: {
      type: Object,
      require: true,
    },
  },
  data: () => ({
    show: false,
  }),

  created() {},

  computed: {
   

    lang() {
      return this.$i18n.locale;
    },

    getImageUrl(){
        return (this.product.image ) ? this.product.image.url : require('@/assets/placeholder.jpg');
    },

    getLazyImageUrl(){
        return (this.product.image ) ? this.product.image.formats.thumbnail.url: require('@/assets/placeholder.jpg');
      },

      isActive(){
        return !this.product.active;
        
      }
  },


};
</script>
<style lang="scss" scoped>

.v-image {
  border-radius: 50%;
}
.card-img{
 border-radius: 50%;
}
.my-card {
  cursor: pointer;
}
.card-unavailable{
  opacity: 0.7;
}

.value {
  color: gray;
}

.info-description{
  position: absolute;
  top: 5px;
  right: 10px;
}

.my-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1;
}

.text-unavailable{
  color: #FF5252;
}


</style>