<template>
  <div>
    <div class="grid-cat">
      <div v-if="isMenu">
        <GridCategory
          :categories="categories"
          :categorySelected="categorySelected"
          @changeDisplay="changeDisplay"
          @selectCategory="selectCategory"
          v-if="!isOneCategory"
         
        ></GridCategory>
      </div>
      <div v-if="!isMenu || isOneCategory">
        <v-btn
          class="my-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeDisplay()"
           v-if="!isOneCategory"
        >
          <v-icon dark>{{ "arrow_back" }}</v-icon>
        </v-btn>
        <GridItemBig :item="recipesSelected" :showStatus="showStatus"></GridItemBig>
      </div>
    </div>
  </div>
</template>

<script>
import GridItemBig from "./GridItemBig";
import GridCategory from "./GridCategory";

import { mapActions, mapGetters } from "vuex";
// import { findRecipeByCategory } from '@/helpers/index'

export default {
  //  name: 'swiper-example-multiple-slides-per-biew',
  title: "Grid Recipe Content",
  name: "GridRecipeContent",
  components: {
    GridItemBig,
    GridCategory,
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
      require: true,
    },
     showStatus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    categories: [],
    recipesSelected: [],
    categorySelected: null,
    isMenu: true,
  }),

  created() {
    this.fetchCategories()
      .then((res) => {
        this.setCategories(res);
      })
      .catch((error) => console.log(error));

      if(this.isOneCategory)
        this.isMenu = false;
  },

  methods: {
    ...mapActions("restaurants", ["setCategories", "changeDisplay"]),
    ...mapActions("categories", ["fetchCategories"]),

    setCategories(categories) {
      let list = [];

      if (this.recipes.length > 0) {
        categories.forEach((cat) => {
          const res = this.recipes.filter((rec) => {
            return (
              rec.categories.filter((el) => {
                const categoryId = typeof el === "string" ? el : el.id;
                return categoryId == cat.id;
              }).length > 0
            );
          });

          if (res.length > 0) {
            cat.numberRecipes = res.length;
            list.push(cat);
          }
        });

        this.categories = list;
        if (this.recipes.length > 0 && list.length > 0) {
          this.selectCategory(list[0].id);
        }
      }
    },

    selectCategory(categoryId) {
      if (this.recipes.length > 0) {
        this.recipesSelected = this.recipes.filter((rec) => {
          return (
            rec.categories.filter((el) => {
              const _categoryId = typeof el === "string" ? el : el.id;
              return _categoryId == categoryId;
            }).length > 0
          );
        });
        this.categorySelected = categoryId;
      }
    },

    changeDisplay() {
      this.isMenu = !this.isMenu;
    },
  },

  computed: {
    ...mapGetters({
      // categories: "restaurants/getCategories",
      // isMenu: "restaurants/getIsMenu",
      // recipesSelected: "restaurants/getRecipes",
    }),
    isOneCategory(){
      return this.categories.length === 1;
    }
  },
};
</script>

<style scoped lang="scss">

</style>