import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    sending: false,
    loading: false,
    commentCount: 0,
    comment:"",
    deleting: null,
  
  },

  getters: {
    getComments(state) {
      return state.items
    },
    getTotalComments(state) {
      return state.items.length
    },
    getCommentCount(state) {
      return state.commentCount
    },
    getSending(state) {
      return state.sending
    },
    getDeleting(state) {
      return state.deleting
    },
    getLoading(state) {
      return state.loading
    },

    getComment(state) {
      return state.comment;
    },

  },

  actions: {

    setComment({ commit }, comment) {
      commit('set', { resource: 'comment', value: comment })
    },
    setCommentCount({ commit }, value) {
      commit('set', { resource: 'commentCount', value: value })
    },


    fetchComments({ state, commit }, {postId, limit}) {
      commit('set', { resource: 'loading', value: true })
      const _limit = limit?'&_limit='+limit:'';
      return axiosInstance.get(`comments?_sort=created_at:DESC&post=${postId}${_limit}`)
        .then(res => {
          const comments = res.data
          commit('set', { resource: 'items', value: comments })
          commit('set', { resource: 'loading', value: false })
          return state.items
        })
        .catch(err => {
          commit('set', { resource: 'loading', value: false })
          console.log(err);
          // commit('set', { resource: 'deleting', value: null })
        })
    },


    fetchSendComment({ state, commit }, postId) {
      commit('set', { resource: 'sending', value: true })
      let editing=false;
      return axiosInstance({
        method: editing ? 'put' : 'post',
        url: editing ? `comments/${postId}` : 'comments',
        data: {
          "post": postId ,
          "text": state.comment
        },
      })
        .then(res => {
          const comment = res.data
          commit('addComment', { comment: comment})
          commit('set', { resource: 'sending', value: false })
          commit('set', { resource: 'comment', value: '' })
          commit('set', { resource: 'commentCount', value: state.commentCount+1 })
          return state.items;
        })
        .catch(
          err => {
            console.log(err)
            commit('set', { resource: 'sending', value: false })
          }
        )

        // return axiosInstance.post(`comments`,
        // {
        //   "post": state.comment,
        //   "text": postId
        // },)
        // .then(res => {
        //   const comment = res.data
        //   commit('addComment', { comment: comment})
        //   commit('set', { resource: 'sending', value: false })
        //   commit('set', { resource: 'comment', value: '' })
        //   return state.items
        // })
        // .catch(err => {
        //   console.log(err)
        //   commit('set', { resource: 'sending', value: false })
        // })
    },


   
    fetchRemoveComment({ state, commit }, commentId ) {

      commit('set', { resource: 'deleting', value: commentId })
      
      return axiosInstance.delete(`comments/${commentId}`)
        .then(res => {
          const comment = res.data
          console.log("comment deleted")
          console.log(comment)
          commit('remove', { comment: comment})
          commit('set', { resource: 'deleting', value: null })
          commit('set', { resource: 'commentCount', value: state.commentCount-1 })
          return state.items
        })
        .catch(err => {
          console.log(err);
          commit('set', { resource: 'deleting', value: null })
        })
    },
    clearComments({  commit }) {
      commit('set', { resource: 'comment', value: '' })
      commit('set', { resource: 'items', value: []})
      commit('set', { resource: 'commentCount', value: 0})
    }
  },




  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },

    addComment(state, { comment }) {
      state.items = [comment, ...state.items]
    },

  remove(state, { comment }) {

      const comments = state.items.filter(el => el.id!=comment.id);
      state.items = [...comments];
    },

  }
}