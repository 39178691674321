<template>
  <div class="main-wrapper mt-4">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"  :rules="[() => true]"
          >{{$t(`suggest_page.recipe_information`)}}</v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">{{$t(`suggest_page.ingredient.ingredients`)}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">{{$t(`suggest_page.instruction.instructions`)}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">{{$t(`suggest_page.assets`)}}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items >
        <v-stepper-content step="1" class="pa-0 ma-0" color="primary">
          <div class="container-wrapper">
            <RecipeInfo></RecipeInfo>
            <div class="my-4 mx-8">
               <v-btn @click="e1 = 1"  disabled>{{$t(`previous`)}}</v-btn>
              <v-btn color="primary" class="ml-6" @click="moveToIgredients()" >{{$t(`next`)}}</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="container-wrapper">
             <RecipeIngredients></RecipeIngredients>
            <div class="my-4 mx-5">
              <v-btn @click="e1 = 1" >{{$t(`previous`)}}</v-btn>
              <v-btn color="primary" class="ml-6" @click="moveToInstructions()" >{{$t(`next`)}}</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="container-wrapper">
            <RecipeSteps></RecipeSteps>
            <div class="my-4 mx-5">
              <v-btn @click="e1 = 2" >{{$t(`previous`)}}</v-btn>
              <v-btn color="primary" class="ml-4" @click="moveToMedia()" >{{$t(`next`)}}</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="container-wrapper">
            <MediaContent :type="'recipe'"></MediaContent>

            <div class="my-3 ">
              <v-btn @click="e1 = 2" >{{$t(`previous`)}}</v-btn>
              <v-btn color="primary" class="ml-4"  @click="sendNewRecipe()" :loading="sending" >{{$t(`send`)}}</v-btn
              >
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import RecipeInfo from "../components/form/RecipeInfo";
import RecipeSteps from "../components/form/RecipeSteps";
import RecipeIngredients from "../components/form/RecipeIngredients";
import MediaContent from "../components/media/MediaContent";
import { mapActions, mapGetters } from "vuex";
import toastMixins from "@/mixins/toast";

// import Vue from 'vue'
export default {
  name: "CreateRecipe",
  components: {
    RecipeInfo,
    RecipeSteps,
    MediaContent,
    RecipeIngredients,
  },

  data: () => ({
    e1: 1,
  }),

  computed: {
    posts() {
      return this.$store.state.posts;
    },

    ...mapGetters({
      sending: "recipes/getSendingRecipe",
      assets: "recipes/getAssetsNewRecipe",
      refFormInfos: "recipes/getRefFormInfos",
      refFormIngredients: "recipes/getRefFormIngredients",
      refFormInstructions: "recipes/getRefFormInstructions",
      isValidIngredients: "recipes/isValidIngredients",
      isValidSteps: "recipes/isValidSteps",
    }),
  },

  methods: {

    sendNewRecipe(){

      if(this.assets.length>0){
        this.sendRecipe()
        .then(() =>{
            this.e1 = 1;
            this.toast_show(this.$t(`suggest_page.submit_message.success`))
          }
        )
        .catch((err) => {
          console.error(err);
          this.toast_show(this.$t(`suggest_page.submit_message.error`))
        });
      }
      else{
        this.toast_show(this.$t(`suggest_page.submit_message.missing_image`))
      }

    },
    validateFormInfo(){
      return this.$refs.formInfo.validate();
    },
    moveToIgredients(){
      if(this.refFormInfos.validate()){
        this.e1 = 2;
      }
    },
    moveToInstructions(){
     
      if(this.refFormIngredients.validate() && this.isValidIngredients){
        this.e1 = 3;
      }
    },
    moveToMedia(){
      if(this.refFormInstructions.validate() && this.isValidSteps){
        this.e1 = 4;
      }
    },
    ...mapActions("recipes", ["sendRecipe"]),
  },

   mixins: [toastMixins],

};
</script>

<style scoped>
.container-wrapper {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-wrapper {
  width: 100%;
  max-width: 960px;
  background-color: var(--v-background-darken1);
  border-radius: 4px;
  margin: auto;
}

</style>