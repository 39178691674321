<template>
<v-btn class="" fab dark x-small color="primary" @click="goToAuthorPage()" :disabled="disabled">
  <v-avatar color="warning" :size="size">
    <span v-if="!author_isAuthorPhotoExist" class="white--text body-2">{{
      author_LettersName
    }}</span>
    <img
      v-if="author_isAuthorPhotoExist"
      :src="author_authorPhoto"
      :alt="author_fullName"
    />
  </v-avatar>
</v-btn>
</template>

<script>
import authorMixins from "../../mixins/author";

export default {
  name: "AuthorAvatar",
  components: {},

  props: {
    user: {
      type: Object,
      require: true,
    },
    size: {
      type: Number,
      default: 32,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted(){
    this.author = this.user;
  },

  mixins: [authorMixins],
};
</script>

<style scoped lang="scss">

</style>