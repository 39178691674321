// components/DashboardLayout.vue
// use this layout for dashboard pages after user authenticated

<template>
  <div id="page">
   
    <NavBar v-if="isAuthenticated"></NavBar>
     <SearchPage v-show="!loading && isSearchActive "></SearchPage>
      <div v-show="!loading && !isSearchActive" class="scv-main-wrapper">
        <div class="main-container">
        <router-view :key="$route.fullPath" />
        </div>
        <Footer v-if="$route.name!='login'"></Footer>
      </div>
    <BottomNav ></BottomNav>
  </div>
</template>

<script>
import NavBar from '@/components/navbar/Navbar';
import BottomNav from '@/components/navbar/BottomNav';
import Footer from '@/components/footer/Footer';
import SearchPage from '@/pages/Search';
import {mapGetters} from 'vuex';

export default {
    components: {
    NavBar,
    SearchPage,
    BottomNav,
    Footer
  },

  data: () => ({
    loading:true,
    layout: null,
  }),

    created () {
      this.$store.dispatch('auth/getAuthUser')
      .then(() => {
          this.loading=false;
          })
          .catch(() => {
          this.loading=false;
        })
    
  },

  computed: {
      ...mapGetters({
        'isAuthenticated': 'users/getAccount',
        'isSearchActive': 'search/getActive'
      })
    },
    
}
</script>

<style lang="scss">
@import "~@/scss/_common.scss";

.bar-bottom{
  padding-top: 80px;
}

.loading{
  width: 100vw;
  height: 100vh;
}
.scv-main-wrapper{
  height: calc(100vh);
  /* overflow-x: hidden; */
  /* background-color: aqua; */
  @media (max-width: 960px) {
    height: calc(100vh - 112px);
  }
}

</style>
