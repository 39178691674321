<template>
  <v-container>
    <schedule-plan
        v-if="step === 1"
        @next="handleNext"
        @back="handleBack"
    ></schedule-plan>
    <dietary-needs
        v-if="step === 2"
        :hasNext="$route.params.modeId !== '3'"
        @next="handleNext"
        @back="handleBack"
        @finish="handleFinish"
    ></dietary-needs>
    <location-preferences
        v-if="step === 3"
        @finish="handleFinish"
        @back="handleBack"
    ></location-preferences>
  </v-container>
</template>

<script>
import DietaryNeeds from "@/components/meal-plan/dietary-needs/DietaryNeeds";
import SchedulePlan from "@/components/meal-plan/schedule-plan/SchedulePlan";
import LocationPreferences from "@/components/meal-plan/location-preferences/LocationPreferences";

export default {
  name: 'MealPlan',
  components: {
    LocationPreferences,
    SchedulePlan,
    DietaryNeeds
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    handleNext() {
      this.step++;
    },
    handleBack() {
      this.step--;
    },
    handleFinish() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>
