<template>
  <v-app>
    
    <v-main class="main">
       <component :is="layout" />
       <v-container v-if="loading" class="loading d-flex justify-center align-center">
         <GifLoading></GifLoading>
       </v-container>
    </v-main>
  </v-app>
</template>

<script>
import GifLoading from './components/spinner/GifLoading';
import {mapGetters} from 'vuex';

import AuthLayout from "@/layouts/AuthLayout";
import PageLayout from "@/layouts/PageLayout";
import IntroLayout from "@/layouts/IntroLayout";
import LoadingLayout from "@/layouts/LoadingLayout";
import themeMixins from "@/mixins/theme";

export default {
  name: 'App',

  components: {
    GifLoading,
    AuthLayout,
    PageLayout,
    IntroLayout,
    LoadingLayout
  },

  data: () => ({
    loading:true,
    layout: null, 
  }),

  created () {
      this.$store.dispatch('auth/getAuthUser')
      .then(() => {
          this.loading=false;
          })
          .catch(err => {
          this.loading=false;
          console.log(err);
          })
    
  },
  mounted() {
    this.checkTheme();
},
  watch: {
    $route(to) {
      // set layout by route meta
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else if(to.meta.layout === 'AuthLayout'){
        this.layout = "AuthLayout" // this is default layout if route meta is not set
      }
      else {
        this.layout = "PageLayout" // this is default layout if route meta is not set
      }
    },
  },

  computed: {
      ...mapGetters({
        'isAuthenticated': 'users/getAccount',
        'isSearchActive': 'search/getActive'
      })
    },

 mixins: [themeMixins],
};
</script>

<style lang="scss">
@import "~@/scss/_common.scss";


.loading{
  width: 100vw;
  height: 100vh;
}


</style>
