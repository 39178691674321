import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    item:{
      date:new Date().toISOString().substr(0, 10),
      time:null,
      seats:null,
    },
    sending: false,
    deleting: null,
    review: null,
    imagesToUpload: [],
    showBookModal: null,

  },

  getters: {
    getShowBookModal(state) {
      return state.showBookModal
    },

    getReviews(state) {
      return state.items
    },
    getReservation(state) {
      return state.item
    },
    getReservations(state) {
      return state.items
    },
    getSending(state) {
      return state.sending
    },
    getDeleting(state) {
      return state.deleting
    },

    getReview(state) {
      return state.review;
    },

  
  

  },

  actions: {

    setShowBookModal({ commit }, value) {
      commit('set', { resource: 'showBookModal', value });
    },

    setReservation({ commit }, value) {
      commit('set', { resource: 'item', value });
    },

    setReviews({ commit }, reviews) {
      commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
    },

   

    fetchReviews({ state, commit }, recipeId) {
      return axiosInstance.get(`reviews?_sort=created_at:DESC&recipe=${recipeId}`)
        .then(res => {
          const reviews = res.data
          commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
          return state.items
        })
    },

    fetchMyReservations({ state, commit, rootGetters }) {
      // alert(restaurantId)
      // return axiosInstance.get(`restaurant-reviews?_sort=created_at:DESC&reservation.restaurant=${restaurantId}`)
      // return axiosInstance.get(`reservations?_sort=created_at:DESC&author.id=${authorId}`)
      // return axiosInstance.get(`reservations?_sort=created_at:DESC&author.id=2`)
      const authUser = rootGetters['users/getAccount']

      return axiosInstance.get(`reservations?author=${authUser.id}&_sort=created_at:DESC`)
        .then(res => {
          const reservations = res.data
          commit('setItems', { resource: 'reservations', items: reservations }, { root: true })
       
          return state.items
        })
 
    },


    resetReview({ commit }) {
      commit('set', { resource: 'review', value: null })
      commit('set', { resource: 'imagesToUpload', value: [] })
    },


    fetchSendReservation({ state, commit }, {reservation}) {

      const {restaurantId} = state.showBookModal;

      commit('set', { resource: 'sending', value: true })
      const formData = new FormData();
      const formJson = {
        ...reservation,
        restaurant : restaurantId
      }

      formData.append("data", JSON.stringify(formJson));

      return axiosInstance({
        method: reservation.id ? 'put' : 'post',
        url: reservation.id ? `reservations/${reservation.id}` : 'reservations',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(() => {
          commit('set', { resource: 'sending', value: false })
          return state.items
        })
        .catch(
          err => {
            console.log(err)
            commit('set', { resource: 'sending', value: false })
          }
        )
    },

  },




  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },
    deleteImageSelected(state, { index }) {

      const files = state.imagesToUpload;
      let list = [];
      for (let i = 0; i < files.length; i++) {
        if (i != index) {
          list.push(files[i]);
        }
      }

      state.imagesToUpload = [...list];
    },

  }
}