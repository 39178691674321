<template>
  <v-container class="pa-0 main-wrapper-main" fluid>
    <div class="container-img"></div>
    <v-row no-gutters class="main-wrapper">
      <v-col cols="12" sm="12" md="12" class="block-left" v-if="!loading">

        <BtnBack></BtnBack>

        <GalleryRecipe2 :assets="menu.assets" />
      </v-col>

        <v-col cols="12" xs="12" md="12">
          <v-container fluid class="d-flex pa-4 flex-column container-wrapper">
            <div class="d-flex justify-space-between name-container ">
              <h2 class="font-weight-bold">{{ menu.name }}</h2>

              <div class="font-weight-bold">{{ menu.price }}$00</div>

            </div>

            <div fluid class="py-4 flex-wrap container-wrapper">
              <div>
                {{ menu.description }}
              </div>
            </div>
          </v-container>
        </v-col>


        <v-col cols="12" xs="12" md="12">
           <v-container fluid>
            <h2 fluidclass="mt-4 mb-6">Entries</h2>
            </v-container>
        
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-container fluid class="d-flex pa-4 flex-wrap container-wrapper" v-if="!loading">

            
            
            <div v-for="entry in menu.entries" :key="entry.id" class="d-flex pb-8 align-center">


              <v-img
                :src="getImageUrl(entry.recipe)"
                :lazy-src="getLazyImageUrl(entry.recipe)"
                class="image"
                height="100%"
                width="100%"
                max-height="150px"
                max-width="150px"
                 aspect-ratio="1"
                v-if="!loading"
              ></v-img>
              <div class="ml-4">
                <h3>{{entry.recipe.name}}</h3>
                
                <div class="d-flex">
                  <div><span class="font-weight-bold">Price: </span> <span>{{entry.price}}$00 CVE</span></div>
                  <div><span class="font-weight-bold ml-4">Servings: </span> <span>{{entry.servings}}</span></div>
                </div>
                <div  class="description-wrapper">{{entry.description}}</div>
              </div>

            </div>

            <div class="py-8"></div>

             <!-- <h2 class="mt-4 mb-6">Entries</h2> -->
          </v-container>
        </v-col>

    </v-row>
  </v-container>
</template>

<script>

import GalleryRecipe2 from "../components/gallery/GalleryRecipe2";

import BtnBack from "../components/btn/BtnBack";
import authorMixins from "../mixins/author";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "Menu Page",
  name: "Menu",
  components: {

    GalleryRecipe2,
  
    BtnBack,
  },

  data: () => ({
    panel: [0, 1, 2, 3],
    tabToShow: 1,
    loading:true,
 
  }),
  mixins: [authorMixins],
  created() {

     let menuId = this.$route.params.id;
     console.log("_menu.id", menuId)

      this.fetchMenu(menuId)
      .finally(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
      });
    // let _recipe = this.$route.params.recipe;
    // if (_recipe)
    //   localStorage.setItem("current_recipe", JSON.stringify(_recipe));
    // else {
    //   const recipeLocalStore = localStorage.getItem("current_recipe");
    //   _recipe = JSON.parse(recipeLocalStore);
    // }

    // this.setRecipe(_recipe);
    // this.author = _recipe.author;
    
    // this.fetchRestaurants()
    //  .then()
    //   .catch((err) => {
    //     console.error(err);
    //   });
    
    // this.fetchReviewsCount(_recipe.id)
    // this.fetchRecipeRating(_recipe.id)

  },
  computed: {
    ...mapGetters({
      menu: "menus/getMenu",
     
    }),

  },

  methods: {
    ...mapActions("menus", ["fetchMenu"]),

    getImageUrl(recipe) {
      return recipe.assets && recipe.assets.length > 0
        ? recipe.assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl(recipe) {
      return recipe.assets && recipe.assets.length > 0
        ? recipe.assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
   
  },
};
</script>

<style scoped lang="scss">

.v-image {
  border-radius: 50%;
  /* width: 120px;
  height: 120px; */
  margin: auto;
}


.swiper-slide {
  width: 18%;
}

.filter-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}

.card-info {
  width: 33%;
  padding-right: 1%;
  padding-bottom: 1%;
}

.main-wrapper-main {
  background-color: #fff;
  min-height: 100%;
}

.restaurants-wrapper{
  background-color: white;
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  .block-left {
    margin-right: 16px;
  }
}

.container-wrapper {
  background-color: white;
  border-radius: 8px;
  .container-body {
    width: 100%;
    border-top: 1px solid gray;
  }
}

.description-wrapper{
  max-width: 600px;
}
.main-wrapper {
  width: 100%;
  height: 100%;
}

.name-container {
  width: 100%;
}

.info-wrapper {
  width: 100%;
  /* display: flex;
justify-content: space-between; */
  flex-wrap: wrap;
  .v-card {
    border-radius: 8px;
  }
  .value-top {
    font-weight: bold;
    color: #f7b538;
    line-height: 1.3;
  }

  .value-bottom {
    color: gray;
    line-height: 1.3;
    opacity: 0.7;
  }

  .caption {
    color: grey;
  }
}
@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
  .main-wrapper {
    margin-top: 0;
  }

  .v-card {
    margin-bottom: 10px;
  }

}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }

  .intro-subtitle {
    display: none;
  }
}
</style>