<template>
  <div class="d-flex flex-wrap justify-space-between px-2 grid-recipe">
    <div class="card-feed mb-4" v-for="item in recipes" :key="item.id">
      <Card3 type="ratingShort" :recipe="item"></Card3>
    </div>
  </div>
</template>

<script>
import Card3 from "../../components/card/Card3";

export default {
  title: "Grid Recipe ViewAll",
  name: "GridRecipeViewAll",
  components: {
    Card3,
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({}),
  
};
</script>

<style scoped lang="scss">
.grid-recipe::after {
  content: "";
  flex: auto;
}
.card-feed {
  width: 25%;
  padding-right: 2%;
}

@media (max-width: 1300px) {
  .card-feed {
    width: 33%;
    padding-right: 2%;
  }
}

@media (max-width: 900px) {
  .card-feed {
    width: 48%;
  }
}


@media (max-width: 420px) {
  .card-feed {
    width: 98%;
  }
}
</style>