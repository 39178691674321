<template>
  <v-card :class="isActive?'card-unavailable mx-auto my-card':'mx-auto my-card'" max-width="100%" @click="goToDetail()">
    <v-img
      :src="getImageUrl"
      :lazy-src="getLazyImageUrl"
      aspect-ratio="1.8"
    ></v-img>

    <div
      class="d-flex flex-column align-left py-2 px-1 px-sm-1 px-md-1 card-info"
    >
      <div :class="'h6 font-weight-normal p-1 mb-0  my-card-title'">
        {{ menu.name }}
      </div>      

      <div class="px-1  ">
        <span>{{ menu.price }}$00</span>
        <span class="caption ml-2 error--text" v-if="isActive">({{$t('unavailable')}})</span>
      </div>

     <button :content="menu.description" v-tippy class="info-description">
       <v-icon dark color="primary" size="25">info</v-icon>
     </button>

     <div class="content-dish" v-if="isMenuDishOfTheDay(menu.startDate, menu.endDate )">{{$t('dish_of_the_day')}}</div>
     
    </div>
  </v-card>
</template>


<script>
import { mapActions } from "vuex";
// import slotMixins from "@/mixins/slot";

export default {
  name: "CardMenu",

  props: {
    type: {
      type: String,
      require: "menu",
    },

    menu: {
      type: Object,
      require: true,
    },
  },
  data: () => ({
    show: false,
  }),

  created() {},

  computed: {
    rating() {
      return this.menu.rating || 0;
    },

    lang() {
      return this.$i18n.locale;
    },

    getImageUrl(){
        return (this.menu.entries[0].recipe.assets && this.menu.entries[0].recipe.assets.length>0) ? this.menu.entries[0].recipe.assets[0].url : require('@/assets/placeholder.jpg');
    },

    getLazyImageUrl(){
        return (this.menu.entries[0].recipe.assets && this.menu.entries[0].recipe.assets.length>0) ? this.menu.entries[0].recipe.assets[0].formats.thumbnail.url: require('@/assets/placeholder.jpg');
      },

      isActive(){
        return !this.menu.entries[0].active;
      }
  },

  methods: {
    ...mapActions("search", ["setSearchActive"]),
    goToDetail() {

      this.$router.push(
        // { name: "menuItem", params: { id: this.menu.id } },
        { name: "recipeItem", params: { recipeId: this.menu.entries[0].recipe.id } },
        () => {}
      );
      // this.setSearchActive(false);
    },
     isMenuDishOfTheDay(startDate, endDate){
      if(!startDate || !endDate )
          return false
      
       if(!this.isMainMenu() )
          return false

      const _startDate = new Date(startDate);
      const _endDate = new Date(endDate);
      const currentDate = new Date()

      if (currentDate >= _startDate && currentDate <= _endDate)   
        return true;
 
      
      return false;
    },
    isMainMenu(){
      let categories = []
      let result = [];
     
     if(this.menu && this.menu.entries && this.menu.entries[0] && this.menu.entries[0].recipe && this.menu.entries[0].recipe.categories)
        categories = this.menu.entries[0].recipe.categories

      result = categories.filter(category =>( category.id == 9 || category.id == 12 || category.id == 11 || category.id == 18  ))
             
      return result.length>0;
    }
  },

  // mixins: [slotMixins],
};
</script>
<style lang="scss" scoped>
.my-card {
  cursor: pointer;
  border-radius: 24px !important;
}
.card-unavailable{
  opacity: 0.7;
}
.card-info {
  min-height: 50px;
}
.value {
  color: gray;
}

.info-description{
  position: absolute;
  top: 5px;
  right: 10px;
}

.my-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1;
}

.content-dish{
  position: absolute;
  bottom: 66px;
  left: 0;
  color:white;
  background-color: $primary;
  padding: 0 5px;
}


</style>