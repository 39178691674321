import { render, staticRenderFns } from "./Ingredient.vue?vue&type=template&id=7bd9f7f9&scoped=true&"
import script from "./Ingredient.vue?vue&type=script&lang=js&"
export * from "./Ingredient.vue?vue&type=script&lang=js&"
import style0 from "./Ingredient.vue?vue&type=style&index=0&id=7bd9f7f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd9f7f9",
  null
  
)

export default component.exports