<template>
  <div class="view-all-wrapper">
    <BtnBack></BtnBack>
    <div class="pt-16">
      <GridRecipeViewAll :recipes="recipes"></GridRecipeViewAll>
    </div>
  </div>
</template>

<script>
import GridRecipeViewAll from "../components/grid/GridRecipeViewAll";
import BtnBack from "../components/btn/BtnBack";

export default {
  title: "View All",
  name: "ViewAll",
  components: {
    GridRecipeViewAll,
    BtnBack,
  },

  data: () => ({
    recipes: {},
  }),

  created() {
    let _recipes = this.$route.params.recipes;
    if (_recipes)
      localStorage.setItem("recipes_viewAll", JSON.stringify(_recipes));
    else {
      const recipesLocalStore = localStorage.getItem("recipes_viewAll");
      _recipes = JSON.parse(recipesLocalStore);
    }
    this.recipes = _recipes;
  },
};
</script>

<style scoped lang="scss">
.view-all-wrapper{
  min-height: calc(100vh - 280px);
}
</style>