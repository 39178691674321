import { debounce, getOrderBy } from '../../helpers';
export default {
  namespaced: true,

  state: {
    items: [],
    active: false,
    showFilter: false,
    onFocus: false,
    searchText: '',
    filters: {
      orderBy: 'name',
      categoriesSelected: [],
      costsSelected: [],
      difficultiesSelected: [],
      tagsSelected: [],
      prepTime: 100,
      cookTime: [1, 100],
      classification: [
        { id: "1", value: [1], selected: false },
        { id: "2", value: [1, 2], selected: false },
        { id: "3", value: [1, 2, 3], selected: false },
        { id: "4", value: [1, 2, 3, 4], selected: false },
        { id: "5", value: [1, 2, 3, 4, 5], selected: false },
      ],
    }

  },

  getters: {
    getItems(state) {
      return state.items
    },
    getActive(state) {
      return state.active;
    },
    getShowFilter(state) {
      return state.showFilter;
    },
    getSearchText(state) {
      return state.searchText;
    },
    getFilters(state) {
      return state.filters;
    },
    getOnFocus(state) {

      return  state.searchText.trim() === '';
      // return state.onFocus && state.searchText.trim() === '';
      // return false;
    },

  },

  actions: {

    setSearchActive({ commit }, _active) {
      commit('set', { resource: 'active', item: _active })
    },

    setOnFocus({ commit }, value) {
      commit('set', { resource: 'onFocus', item: value })
    },

    setShowFilter({ state, commit },) {
      commit('set', { resource: 'showFilter', item: !state.showFilter });
    },

    removeCategory({ commit }, item) {
      commit('deleteCategory', { item: item });
    },

    setClassification({ commit }, id) {
      
      commit('changeClassification', { id: id });
    },


    setSearchText: debounce(function ({ commit, state, dispatch }, newVal) {
      const text = newVal ? newVal.target.value.trim() : state.searchText;

      if(newVal){
        commit('set', { resource: 'searchText', item: text });
      }
        
      let url = `_q=${text}`;
      if (text !== "" && text !== "_q=") {

        url += getOrderBy(state,'&');

        state.filters.categoriesSelected.forEach((element) => {
          url += `&categories=${element}`;
        });

        state.filters.costsSelected.forEach((element) => {
          url += `&cost=${element.id}`;
        });

        state.filters.difficultiesSelected.forEach((element) => {
          url += `&difficulty=${element.id}`;
        });

        state.filters.tagsSelected.forEach((element) => {
          url += `&tags=${element.id}`;
        });

      
        const _prepTime = 300 * (state.filters.prepTime / 100);
        url += `&prepTime_lte=${_prepTime}`;

        const _cookTime = 300 * (state.filters.cookTime[1] / 100);
        url += `&cookTime_lte=${_cookTime}`;


        state.filters.classification.forEach((element) => {
          if(element.selected){
            url += `&rating=${element.id}`;
          }
        });

        
      }

      dispatch('recipes/fetchListRecipesSearch', url, { root: true });
    }, 300)

  },

  mutations: {
    set(state, { resource, item }) {
      state[resource] = item;
    },

    deleteCategory(state, { item }) {
      const index = state.filters.categoriesSelected.indexOf(item.id);

      if (index >= 0)
        state.filters.categoriesSelected.splice(index, 1);
    },

    changeClassification(state, { id }) {
      const items = state.filters.classification;
      items.forEach(el => {
        if (el.id == id)
          el.selected = !el.selected
      });
      state.filters.classification = [...items]
    },
  }
}