var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Banner',{attrs:{"title":_vm.$t('contact_us_page.title')}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"m-auto",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"py-3 px-6"},[_c('p',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('contact_us_page.intro_title')))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('contact_us_page.intro_text')))])]),_c('div',{staticClass:"pt-4"},[_c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"type":"email","dense":"","rules":[
                      (v) => !!v || _vm.$t(`login_page.signUp_email_error`),
                      (v) =>
                        (!!v &&
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            v
                          )) ||
                        _vm.$t(`login_page.invalid_email`),
                    ],"label":_vm.$t(`contact_us_page.email`)},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"dense":"","rules":[ (v) => (!!v && v.trim()!=='') || _vm.$t(`contact_us_page.missing_subject`)],"label":_vm.$t(`contact_us_page.subject`)},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('v-textarea',{attrs:{"name":"input-7-1","filled":"","rows":"3","auto-grow":"","rules":[ (v) => (!!v && v.trim()!=='') || _vm.$t(`contact_us_page.missing_message`)],"label":_vm.$t(`contact_us_page.message`)},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('div',{staticClass:"mb-6 d-flex justify-end"},[_c('v-btn',{attrs:{"loading":_vm.loading,"depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(_vm._s(_vm.$t(`contact_us_page.send`)))])],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }