<template>
  <form>
    <v-row justify="center">
      <v-dialog v-model="review" width="500px">
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
        <v-card>
          <!-- <v-card-title>
          <span class="headline">Use Google's location service?</span>
        </v-card-title> -->

          <div class="card-header d-flex align-center justify-space-between">
            <div class="block-left d-none d-sm-block"></div>
            <h4 class="">{{$t('recipe_page.new_reviews_title')}}</h4>
            <v-btn color="white" text @click="emit()"><v-icon>close</v-icon></v-btn>
          </div>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
           
         
        </v-card-actions> -->

          <Spinner v-if="loadind"></Spinner>
          <div class="pa-3">
            <div>
              <div class="text-center">{{$t('recipe_page.new_reviews_subtitle')}}</div>
              <div class="text-center mb-2">
                <v-rating
                  v-model="review.rating"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  hover
                  large
                ></v-rating>
              </div>

              <!-- <div class="d-flex"> -->
                <!-- <v-btn fab small dark class="mr-4" @click="goToPage('profile')">
        <v-avatar size="40">
          <img src="../../assets/profile.jpg" alt="Helder" />
        </v-avatar>
      </v-btn> -->

                <v-textarea
                  name="input-7-1"
                  filled
                  auto-grow
                  dense
                  rows="4"
                  :placeholder="$t('recipe_page.new_reviews_label_text')"
                  v-model="review.comment"
                >
                </v-textarea>
              <!-- </div> -->

              <div>

                  <div class="image-selected-wrapper d-flex flex-wrap mb-2">
                    <div
                        color="orange"
                        :size="32"
                        v-for="(item, index) in selectedImagesToUpload"
                        :key="index+'icon-img'"
                        class="image-selected "
                        :style="'background-image: url('+item+')'"
                      >
                      <!-- <v-btn class="" fab dark x-small color="primary"  >
                        <v-icon></v-icon>
                      </v-btn> -->
                      <v-btn class="btn-close" icon x-small color="primary" @click="removeImageSelected(index)">
                        <v-icon>close</v-icon>
                      </v-btn>
                      <!-- <img
                        :src="item"
                        :alt="item" 
                      />  -->
                    </div>
                </div>


                <input
                  type="file"
                  @change="onFileChanged"
                  class="input-file"
                  ref="fileInputselectImg"
                  multiple="multiple"
                />
                <v-btn
                  class=""
                  dark
                  small
                  color="primary"
                  @click="$refs.fileInputselectImg.click()"
                >
                  <v-icon size="20">filter</v-icon>
                </v-btn>

              
              
              </div>

              <div class="mb-6 d-flex justify-end">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="submit()"
                  :loading="sending"
                  >{{$t('send')}}</v-btn
                >
              </div>
            </div>
          </div>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Spinner from "../spinner/AppSpinner";

export default {
  name: "ReviewModalAddEdit",

  components: {
    Spinner,
  },

  props: {
    recipeId: {
      type: String,
      require: true,
    },

    dialog: {
      type: Boolean,
      require: true,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loadind: false,
    //  rating: 0,
    form: {
      recipeId: "",
      rating: 0,
      comment: null,
    },
  }),

  created() {
    this.loadind = false;
    if (this.recipeId == 2)
      this.fetchReviews(this.recipeId)
        .then(() => (this.loadind = false))
        .catch(() => (this.loadind = false));
  },

  methods: {
    ...mapActions("reviews", [
      "fetchReviews",
      "fetchSendReview",
      "createReview",
      "resetReview",
    ]),
    emit() {
      // this.$emit('closeEvent')
      this.resetReview();
    },

    submit() {
      this.review.recipeId = this.recipeId;
      if (this.review.rating && this.review.comment) {
        this.fetchSendReview()
          .then(() => {
            this.fetchReviews(this.recipeId)
            this.fetchReviewsCount(this.recipeId)
            this.fetchRecipeRating(this.recipeId)
            this.resetForm();
            this.emit();
          })
          .catch((error) => console.log(error));
      }
    },

    resetForm() {
      this.form.rating = 0;
      this.form.comment = null;
       this.setImagesToUpload([]);
    },

    onFileChanged(event) {
      this.setImagesToUpload(event.target.files);
    },

    ...mapActions("reviews", ["setImagesToUpload", "removeImageSelected", "fetchReviews", "fetchReviewsCount" ]),
    ...mapActions("recipes", ["fetchRecipeRating", ]),
    // emit(item) {
    //   this.$emit('closeEvent', item)
    // }
  },

  computed: {
    ...mapGetters({
      reviews: "reviews/getReviews",
      sending: "reviews/getSending",
      review: "reviews/getReview",
      selectedImagesToUpload: "reviews/getImagesToUploadUrl",
    }),
  },
};
</script>

<style scoped lang="scss">
.card-header {
  background-color: #f7b538;
  width: 100%;
  height: 60px;
  .block-left {
    width: 64px;
    /* @media (max-width: 500px) {
       display: none;
      } */
  }
}
.input-file {
  display: none;
}
.image-selected-wrapper{

  .image-selected{
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;

    .btn-close {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: white;
      z-index: 999;
    }
  }
}

</style>