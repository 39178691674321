<template>
  <div class="review-item">
    <div class="d-flex">
      <div class="avatar-wrapper pr-4">
        <AuthorAvatar :user="author" :size="48"></AuthorAvatar>
      </div>
 
      <div class="d-flex flex-column user-info-wrapper">
        <div class="d-flex justify-space-between user-info-review">
          <span>{{ review.author | authorFullName }}</span>
          <v-rating
            dense
            half-increments
            v-model="review.rating"
            background-color="gray"
            readonly
          ></v-rating>
        </div>

        <div class="caption">{{ review.created_at | fromNow("pt") }}</div>
      </div>
    </div>

<div v-if="review.assets && review.assets.length>0" class="pt-2">
  <GalleryRow :assets="review.assets"></GalleryRow>
</div>
    <div class="pt-2 pb-4">
      <div>
        {{ review.comment }}
      </div>

      <div v-if="isAuthor">
        <v-btn
          class="mx-2"
          x-small
          icon
          color="primary"
          @click="removeReview()"
          :loading="deleting == review.id"
        >
          <v-icon dark> delete </v-icon>
        </v-btn>

        <v-btn class="mx-2" x-small icon color="primary" @click="editReview()">
          <v-icon dark> edit </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GalleryRow from '../gallery/GalleryRow'
import AuthorAvatar from "../shared/AuthorAvatar";

export default {
  name: "ReviewItem",
  components:{
    GalleryRow,
    AuthorAvatar
  },

  props: {
    review: {
      type: Object,
      require: true,
    },
  },


  created(){
     this.author = this.review.author;
  },

  methods: {
    ...mapActions("reviews", ["fetchRemoveReview", "createReview", "fetchReviews", "fetchReviewsCount"]),
    ...mapActions("recipes", ["fetchRecipeRating"]),

    removeReview() {
      const recipeId =
        typeof this.review.recipe === "string"
          ? this.review.recipe
          : this.review.recipe.id;
      this.fetchRemoveReview({ recipeId: recipeId, reviewId: this.review.id })
        .then((res) => {
          console.log(res);
           this.fetchReviews(recipeId)
           this.fetchReviewsCount(recipeId)
           this.fetchRecipeRating(recipeId)
           
        })
        .catch((error) => console.log(error));
    },

    editReview() {
      this.createReview({
        rating: this.review.rating,
        comment: this.review.comment,
        recipe:
          typeof this.review.recipe === "string"
            ? this.review.recipe
            : this.review.recipe.id,
        id: this.review.id,
      });
    },
  },

  computed: {
    ...mapGetters({
      deleting: "reviews/getDeleting",
    }),
    
    ...mapGetters({
      authUser: "users/getAccount",
    }),

    isAuthor(){
      return this.review.author.id == this.authUser.id
    },

     authorPhoto() {
      return this.review && this.review.author && this.review.author.avatar
        ? this.review.author.avatar.formats.thumbnail.url
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.user-info-review,
.user-info-wrapper {
  width: 100%;
}
</style>