<template>
  <v-container fluid class="container-wrapper content-wrapper">
    <div class="container-body pt-5">
    
      <div class="ingredients-wrap d-flex flex-wrap">
        <div
          class="ingredient-item d-flex align-center"
          v-for="(item) in patries"
          :key="item.id"
        >
        <v-checkbox
            v-model="item.selected"
            @click="selectIngredient(item.id)"
            ></v-checkbox>

          <div class="ingredient-img pr-4">
            <v-img class="element-img" src="../../assets/ingredient.jpeg"></v-img>
          </div>
          <div class="ingredient-text">
            <span>{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IngredientItem",
  components: {},

  data: () => ({
    checkbox: false,
    ingredients: [
      {
        id: "1",
        text: "cooking spray",
      },

      {
        id: "2",
        text: "1/2 teaspoon vanilla extract",
      },
      {
        id: "3",
        text: "1/2 cup whole milk",
      },
      {
        id: "4",
        text: "1 pinch salt",
      },
      {
        id: "5",
        text: "2 large eggs1 tablespoon maple syrup",
      },
      {
        id: "6",
        text: "4 pieces 1/2-inch thick pieces brioche",
      },
    ],
  }),


  methods:{
           ...mapActions("pantries", ["selectIngredient"]),

  },

  computed: {
    ...mapGetters({
        patries: "pantries/getListIngredients",
      }),
  },
};
</script>

<style scoped lang="scss">


.container-wrapper {
  background-color: white;
  border-radius: 8px;

  .container-body {
    width: 100%;
    .ingredients-wrap {
      .ingredient-item {
        width: 50%;
        padding-bottom: 16px;

        .ingredient-img {
          .element-img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .container-wrapper {
    .container-body {
      .ingredients-wrap {
        .ingredient-item {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }
}
</style>