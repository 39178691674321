<template>
  <form>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px">
        <v-card>
          <div class="card-header d-flex align-center justify-space-between">
            <div class="block-left"></div>
            <h3> {{ $t('restaurant_review.title') }} </h3>
            <v-btn color="white" icon text @click="setShowReview(null)">
              <v-icon>close</v-icon>
            </v-btn>
          </div>

          <Spinner v-if="loadind"></Spinner>
          <div class="pa-3">
            <div>

                <div class="mb-4">
                   <p class="mb-0">{{ $t('restaurant_review.subtitle') }}</p>

                      <div
                        class="d-flex align-center  rating-wrap"
                        v-for="(item, index) in review.ratingOptions"
                        :key="index + item.label"
                      >
                        <!-- <p class="mt-2 mb-0 rating-title">{{ item.label }}</p> -->
                        <p class="mt-2 mb-0 rating-title">{{ $t(`restaurant_review.${item.label}`)  }}</p>
                        <div class="rating-wrapper ">
                        <v-rating
                          v-model="item.rating"
                          color="yellow darken-3"
                          background-color="grey darken-1"
                          empty-icon="$ratingFull"
                          hover
                        ></v-rating>
                        </div>
                      </div>
                     </div> 

              <!-- <v-text-field
                label="Title"
                dense
                v-model="review.title"
                :rules="[rules.required]"
              ></v-text-field> -->


              <v-textarea
                name="input-7-1"
                filled
                :label= "$t('restaurant_review.your_review')"
                auto-grow
                dense
                rows="3"
                :placeholder="$t('restaurant_review.add_comment')"
                v-model="review.comment"
                :rules="[rules.required]"
              >
              </v-textarea>
              <div>
                <div class="image-selected-wrapper d-flex flex-wrap">
                  <div
                    color="orange"
                    :size="32"
                    v-for="(item, index) in selectedImagesToUpload"
                    :key="index + 'icon-img'"
                    class="image-selected"
                    :style="'background-image: url(' + item + ')'"
                  >
                    <v-btn
                      class="btn-close"
                      icon
                      x-small
                      color="primary"
                      @click="removeImageSelected(index)"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </div>
                </div>


                <v-menu
                  v-model="showMenuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
              
                </v-menu>

                <p class="label mb-1">{{ $t('restaurant_review.gallery_title') }}</p>
                <input
                  type="file"
                  @change="onFileChanged"
                  class="input-file"
                  ref="fileInputselectImg"
                  multiple="multiple"
                />
                <v-btn
                  class=""
                  dark
                  :small="isSmall"
                  color="primary"
                  @click="$refs.fileInputselectImg.click()"
                >
                  <v-icon size="20">filter</v-icon>
                </v-btn>
              </div>

            
              <div class="mb-6 d-flex justify-end">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="submit()"
                  :loading="sending"
                  >{{$t('send')}}</v-btn
                >
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Spinner from "../spinner/AppSpinner";

export default {
  name: "ReviewModalAddEditRestaurant",
  components: {
    Spinner,
  },

  props: {
    restaurantId: {
      type: Number,
      require: true,
    },
    reservationId: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    loadind: false,
    //  rating: 0,
    form: {
      recipeId: "",
      rating: 0,
      comment: null,
    },

    showMenuDate: false,
    review: {
      comment: "",
      ratingOptions: [
        { label: "service", rating: 5 },
        { label: "food", rating: 5 },
        { label: "cost", rating: 5 },
        { label: "environment", rating: 5 },
        { label: "cleanliness", rating: 5 },
        { label: "accessibility", rating: 5 },
      ],
  
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    items: [
      "Breakfast",
      "Brunch",
      "Lunch",
      "Dinner",
      "Dessert",
      "Coffee or tea",
      "Snacks",
      "Drinks",
      "Late night food",
      "Other",
    ],
  }),

  created() {
    this.loadind = false;
    // if (this.recipeId == 2)
    //   this.fetchReviews(this.recipeId)
    //     .then(() => (this.loadind = false))
    //     .catch(() => (this.loadind = false));
  },

  methods: {
    ...mapActions("reviews", [
      "fetchReviews",
      "fetchSendReview",
      "createReview",
      "resetReview",
    ]),
      ...mapActions("restaurantReviews", [
      "fetchSendReview",
      
    ]),
    emit() {
      this.$emit('closeEvent')
      // this.resetReview();
    },

    submit() {
      
      if (this.review.comment) {
        
        const review = {
          comment: this.review.comment
        }
        this.review.ratingOptions.map( ({label, rating }) =>{
          review[label] = rating;
        })
        
        this.fetchSendReview({review: review})
          .then(() => {
            this.resetForm();
            this.setShowReview(null);
          })
          .catch((error) => console.log(error));
      }
    },

    resetForm() {
      
      this.review.comment = '';
      this.review.ratingOptions = [
        { label: "service", rating: 5 },
        { label: "food", rating: 5 },
        { label: "cost", rating: 5 },
        { label: "environment", rating: 5 },
        { label: "cleanliness", rating: 5 },
        { label: "accessibility", rating: 5 },
      ],
      this.setImagesToUpload([]);
    },

    onFileChanged(event) {
      this.setImagesToUpload(event.target.files);
    },

    ...mapActions("restaurantReviews", ["setShowReview", "setImagesToUpload", "removeImageSelected"]),
    
  },

  computed: {
    ...mapGetters({
      reviews: "reviews/getReviews",
      sending: "restaurantReviews/getSending",
      showReview: "restaurantReviews/getShowReview",
      // review: "reviews/getReview",
      // selectedImagesToUpload: [],
      selectedImagesToUpload: "restaurantReviews/getImagesToUploadUrl",
    }),

    show: {
      // getter
      get: function () {
        return this.showReview;
      },
      // setter
      set: function (newValue) {
        console.log(newValue)
        // this.setShowReview(newValue);
      },
    },

    size() {
      return this.$vuetify.breakpoint.name;
    },

    isSmall() {
      // console.log(this.$vuetify.breakpoint)
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style scoped lang="scss">
/* .v-expansion-panel-header{
      padding: 16px 6px;
} */

.rating-wrapper{
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.card-header {
  background-color: #f7b538;
  width: 100%;
  height: 60px;
  .block-left {
    width: 83px;
  }
}
.input-file {
  display: none;
}
.rating-title {
  min-width: 130px;
}

.title-question {
  width: 100%;
  max-width: 260px;
}
.image-selected-wrapper {
  .image-selected {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;

    .btn-close {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: white;
      z-index: 999;
    }
  }
}

@media (max-width: 600px) {
  .card-header {
    .block-left {
      width: 0%;
    }
  }
}
@media (max-width: 650px) {
  .title-question {
    max-width: 100%;
  }

  .rating-title {
    min-width: 0;
  }

  .rating-wrap {
    justify-content: space-between;
    width: 100%;
  }
}
</style>