<template>
  <div class="main-container d-flex align-center"  @click="setPositionOfRestaurant()">
    <div class="block-left">
      <v-img
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.8"
      ></v-img>
    </div>
    <div class="block-right">
      <p class="bold accent--text">{{ restaurant.name }}</p>
      <div class>
        <div class>
          <div class="d-flex align-center">
            <div>
              <div class="d-flex align-center mr-2">
                <v-icon size="16" color="secondary">location_on</v-icon>
              </div>
            </div>
            <div>
              <div class="value mr-1 body-2 accent--text ">{{
                address
              }}</div>
              <!-- <span class="value body-2 secondary--text">Portugal</span> -->
            </div>
          </div>

          <div class="d-flex align-center" v-if="showShedule">
            <div>
              <div class="d-flex align-center mr-2">
                <v-icon size="16" color="secondary">schedule</v-icon>
              </div>
            </div>

            <div
              class="
                d-flex
                align-center
                flex-wrap
                justify-space-between
                view-button-time-wrapper
              "
            >
              <span class="value body-2 mr-1 accent--text">{{
                slot_isOpen(slots)
                  ? $t(`restaurant_page.open`)
                  : $t(`restaurant_page.close`)
              }}</span>
              <v-btn color="primary" small class="view-button" v-t="'show'" @click="goToDetail()"></v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import slotMixins from "@/mixins/slot";

export default {
  name: "CardListRestaurant",
  components: {},
  props: {
    restaurant: {
      type: Object,
      require: true,
    },

    showShedule: {
      type: Boolean,
      require: false,
      default: () => true,
    },

     loading: {
      type: Boolean,
      default: () =>  false,
    },
  },

  computed: {
    lang() {
      return this.$i18n.locale;
    },

    getImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    address() {
      return this.restaurant?.address.address;
    },
    slots() {
      return this.restaurant && this.restaurant.slots
        ? this.restaurant.slots
        : [];
    },
  },

  methods: {
    setPositionOfRestaurant() {
      this.setCenterMapSearch({
        lat: this.restaurant.address.lat,
        lng: this.restaurant.address.lng,
      });
    },

    goToDetail() {
      console.log("this.restaurant", this.restaurant )

      // this.$router.push( { name: "restaurant", params: { restaurant: this.restaurant } }, () => {});
        this.$router.push(
        // { name: "restaurant", params: { restaurant: this.restaurant } },
        { name: "restaurantItem", params: { restaurantId: this.restaurant.id } },
        () => {}
      );
   },

    ...mapActions("restaurantSearch", ["setCenterMapSearch"]),
  },

  mixins: [slotMixins],
};
</script>

<style lang="scss" scoped>
.view-button-time-wrapper {
  width: 100%;
}
.view-button {

  margin-right: 10px;
  text-transform: none;
  
}
.main-container {
  /* display: flex; */
  cursor: pointer;

  .block-left {
    width: 35%;
    padding: 8px 8px 8px 0;
    .v-image {
      border-radius: 8px;
    }
  }

  .block-right {
    width: 65%;
    padding: 8px 0;
  }
}

.bold {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  &:hover {
    /* text-decoration: underline; */
    color: #f7b538;
  }
}

.value{
  line-height: 1;
}

.wrap-value {
  span {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
}
</style>