import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    item: null,
    user: null,
    account: null, //current user logged
    loading: null,
    imageToUpload: null,
    passwordsData: {
      "oldPassword": '',
      "password": '',
      "passwordConfirmation": '',
    }
  },

  getters: {
    getUsers(state) {
      return state.items
    },
    getUser(state) {
      return (state.user && state.account && state.user.id == state.account.id)
        ? state.account : state.user
    },
    getAccount(state) {
      return state.account
    },
    getAddress(state) {
      return state.account.address;
    },
    getLoading(state) {
      return state.loading;
    },
    getPasswordsData(state) {
      return state.passwordsData;
    },
    getImageToUpload(state) {
      return state.imageToUpload;
    },

    getImageToUploadUrl(state) {
      if (state.imageToUpload)
        return URL.createObjectURL(state.imageToUpload);
      return null;
    },


  },

  actions: {
    setImageToUpload({ commit }, file) {
      if (file)
        commit('set', { resource: 'imageToUpload', item: file })
    },

    fetchUser({ state, commit }, id) {
      if (state.items > 0)
        return state.items;

      return axiosInstance.get(`users/${id}`)
        .then(res => {
          const user = res.data
          commit('setItem', { resource: 'users', item: user }, { root: true })
          return state.items
        })
    },

    initUser({ commit }, _author) {
  
      let author = _author;
      if (_author) {
        localStorage.setItem("current_author", JSON.stringify(_author));
      }
      else {
        const authorLocalStore = localStorage.getItem("current_author");
        author = JSON.parse(authorLocalStore);
   
      }
      commit('set', { resource: 'user', item: author })
      return author;
    },


    updateAccount({ commit, state }, type) {
      let formData = new FormData();
      let formJson = {
        "firstName": state.account.firstName,
        "lastName": state.account.lastName,
        "username": state.account.username,
      }



      if (type == 'address')
        formJson = {
          "address": state.account.address,
        }
      else if (type == 'password')
        formJson = [...state.passwordsData];


      formData.append("data", JSON.stringify(formJson));
      if (state.imageToUpload)
        formData.append('files.avatar', state.imageToUpload, state.imageToUpload.name)


      commit('set', { resource: 'loading', item: 'updatedUser' })
      return axiosInstance({
        method: 'put',
        url: 'users/me',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          const updatedUser = res.data
          commit('set', { resource: 'account', item: updatedUser })
          commit('set', { resource: 'loading', item: null })
          commit('set', { resource: 'imageToUpload', item: null })
          localStorage.setItem("current_author", JSON.stringify(updatedUser));
          return updatedUser;
        })
        .catch(
          err => {
            commit('set', { resource: 'loading', item: null })
            console.log(err);
          }
        )
    }
  },





  mutations: {
    setUser(state, { user }) {
      state.user = user;
    },

    setAccount(state, { user }) {
      if (user && !user.address) {
        user.address = {
          country: '',
          city: '',
          address: '',
        }
      }
      state.account = user;
      localStorage.setItem("current_author", JSON.stringify(user));
    },

    set(state, { resource, item }) {
      state[resource] = item;
    },

  }
}