<template>
      <div class="d-flex justify-space-between py-4">
              <h4 class="font-weight-bold accent--text">{{ $t('home_page.popular_title') }}</h4>
              <v-btn
                text
                color="accent"
                class="text-none"
                @click="goViewAllRecipe()"
                >{{ $t('view_all') }}</v-btn
              >
            </div>
</template>
 
<script>

export default {
  title: "Header Popular",
  name: "HeaderPopular",
  components: {
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({}),

  methods: {

goViewAllRecipe() {
      this.$router.push(
        { name: "viewAll", params: { recipes: this.recipes } },
        () => {}
      );
    },
  },

  computed:{
    getSuggestionText() {
    const dateNow = new Date();
  
    if (dateNow.hour >= 3 && dateNow.hour < 10)
      return "meal_suggestion_by_time.1";
    else if (dateNow.hour >= 10 && dateNow.hour < 11)
      return "meal_suggestion_by_time.2";
    else if (dateNow.hour >= 11 && dateNow.hour < 15)
      return "meal_suggestion_by_time.3";
    else if (dateNow.hour >= 15 && dateNow.hour < 18)
      return "meal_suggestion_by_time.4";
    else if (dateNow.hour >= 18 && dateNow.hour < 22)
      return "meal_suggestion_by_time.4";
    return "meal_suggestion_by_time.6";

  }
  }


};
</script>

<style scoped lang="scss">
.card-category {
  width: 20%;
  position: relative;
  .v-image {
    border-radius: 8px;
  }
}

@media (max-width: 900px) {
  .card-category {
   width: 25%;
  }
}
@media (max-width: 600px) {
  .card-category {
    width: 50%;
  }
}

.title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  color: white;
}
</style>