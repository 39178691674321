<template>
  <v-form v-model="valid" class="form-wrapper">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            
            v-model="account.address.address"
            :rules="[ (v) => !!v || $t(`profile_page.form_rules.required`)]"
            :label="$t(`profile_page.edit_address_form.address`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            v-model="account.address.city"
            :rules="[ (v) => !!v || $t(`profile_page.form_rules.required`)]"
            :label="$t(`profile_page.edit_address_form.city`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            v-model="account.address.country"
            :rules="[ (v) => !!v || $t(`profile_page.form_rules.required`)]"
            :label="$t(`profile_page.edit_address_form.country`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="row-center">
            <v-btn
              color="primary"
              :loading="loading == 'updatedUser'"
              @click="updateAccount('address')"
              >{{$t(`profile_page.btn_update_label`)}}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditAddress",
  components: {},

  data: () => ({
    valid: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
  }),

  methods: {
    ...mapActions("users", ["updateAccount"]),
  },

  computed: {
    ...mapGetters({
      account: "users/getAccount",
      loading: "users/getLoading",
    }),
  },
};
</script>

<style scoped lang="scss" >
.form-wrapper {
 background-color: var(--v-background-base);
}

.main-wrapper {
  width: 100%;
  min-height: 50vh;
  max-width: 960px;
  background-color: var(--v-background-base);
  border-radius: 4px;
}

.row-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 400px) {
}
</style>