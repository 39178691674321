<template>
  <div>
    <v-app-bar color="primary accent-1" flat dark style="z-index: 99999;">
      <!-- <v-app-bar-nav-icon @click.stop="setShowDrawer(true)" class=""></v-app-bar-nav-icon> -->
      <!-- <v-toolbar-title></v-toolbar-title> -->
 
      <a class="icon-container" @click.prevent="goToPage('home')">
        <v-img class="logo" src="../../assets/logo-no-text.svg"></v-img>
      </a>

      <!-- <div class="d-none d-md-block">
        <a
          class="link-item ml-md-6 ml-sm-1"
          v-for="(item) in items"
          :key="item.id"
          @click.prevent="goToPage(item.href)"
        >{{item.text}}</a>
      </div> -->

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>-->

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>-->
      <Search></Search>
      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>-->

      <!-- <v-btn fab x-small dark class="ml-4" @click="goToPage('profile')" elevation="0"> -->
        <div class="ml-4">
        <AuthorAvatarMenu :size="38" :user="userAuthenticated"  >
        </AuthorAvatarMenu>
        </div>
        <!-- <div class="ml-4">
              <v-switch
                v-model="$vuetify.theme.dark"
                inset
                label="Dark"
                persistent-hint
              ></v-switch>
        </div> -->
      <!-- </v-btn> -->
      <!-- <div class="ml-4"> 
        <LocaleChanger></LocaleChanger>
      </div> -->
      
<!-- 
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="n in 5" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <!-- <Drawer :drawer="drawer"></Drawer> -->
  </div> 
</template>
<script>
import Search from "../form/Search";
// import Drawer from "./Drawer";
import AuthorAvatarMenu from "../shared/AuthorAvatarMenu";
// import LocaleChanger from "@/components/form/LocaleChanger";

import { mapActions, mapGetters} from "vuex";

export default {
  name: "NavBar",
  components: {
    Search,
    // Drawer,
    AuthorAvatarMenu,
    // LocaleChanger,
  },

  data: () => ({
    show1: false,

    itemsList: [
      { title: "Home", icon: "dashboard" },
      { title: "About", icon: "question_answer" },
    ],

    items: [
       {
        id: "4",
        text: "Restaurants",
        disabled: false,
        href: "restaurants",
      },
      {
        id: "2",
        text: "Blog",
        disabled: false,
        href: "blog",
      },
      
      {
        id: "5",
        text: "My Ingredients",
        disabled: false,
        href: "listIngredients",
      },
    
    ],
  }),

  methods: {
      ...mapActions("search", ["setSearchActive"]),
      ...mapActions("settings", ["setShowDrawer"]),
       goToPage(path) { 
      
      (path=='profile')?
      this.$router.push({ name: path, params: { "author": this.userAuthenticated }, },() => {}):
      this.$router.push({ name: path },() => {});
      this.setSearchActive(false)
    },
  },

  computed:{
       ...mapGetters({
        userAuthenticated: "users/getAccount",
        drawer: "settings/getShowDrawer",
      }),
      
  }
};
</script>

<style scoped lang="scss" >
.link-item {
  color: white;
  padding: 0 10px;
  font-weight: bold;
}

.icon-container {
  width: 50px;
}
*:focus {outline:none}

@media (max-width: 400px) {
}
</style>
