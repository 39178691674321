<template>
  <div class="asset-list">
    <div
      class="asset-item"
      v-for="(item, index) in assets"
      :key="index + 'asset-img'"
    >
      <v-img
        :src="getURL(item)"
        aspect-ratio="1"
      ></v-img>

      <v-btn class="btn-close" icon x-small @click="deleteAssetsNewRecipe(index)">
        <v-icon>close</v-icon>
      </v-btn>
    </div>

    <v-file-input
      class="asset-item add-more "
      hide-input
      accept="image/*"
      label="File input"
      prepend-icon="add"
      multiple
      @change="onFileChanged"
      show-size
      counter
    >
  
    </v-file-input>

    <!-- <div class="asset-item add-more">
              <v-icon size='40' center>add</v-icon>
    </div>-->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MediaContent",
  components: {},

  data: () => ({}),
        // src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"

  props: {
    index: {
      type: Number,
      require: true,
    },
    type: {
      type: String,
      default: "step",
    },
  },

  methods: {
    onFileChanged(files) {
      this.addAssets({ files: files, type: this.type, index: this.index });
    },

    getURL(file){
          return URL.createObjectURL(file);
    },

    deleteAssetsNewRecipe(index){
      this.removeAssetsNewRecipe({type: this.type, stepIndex:this.index, index:index})
    },

    ...mapActions("recipes", ["removeAssetsNewRecipe", "addAssets"]),
  },

  computed: {
    ...mapGetters({
      steps: "recipes/getStepsNewRecipe",
      recipe: "recipes/getNewRecipe",
    }),

    assets() {
      if(this.type=='step')
        return this.steps[this.index].assets;
      return this.recipe.assets;
    },
  },
};
</script>

<style scoped lang="scss" >
.asset-list {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;

  .asset-item {
    width: 9%;
    margin: 0.5% 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .btn-close {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: white;
    }
  }

  .add-more {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    max-width: 50px;
    height: 50px;
    padding-top: 0;
    padding-left: 9px;
  }
}

@media (max-width: 400px) {
}
</style>