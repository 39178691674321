import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    listPosts: [],
    item:null,
    categorySelected:'all'
  },

  getters: {
    getPosts (state) {
      return state.items 
    },

    getListPosts (state) {
      return state.listPosts 
    },

    getCategorySelected (state) {
      return state.categorySelected 
    },

    listPostsByAuthor (state) { //change later
      return state.items 
    },

    getPost (state) {
      return state.item 
    },
  },

  actions: {
    setCategorySelected ({commit},category) {
      commit('set', {resource: 'categorySelected', value: category})
    },

    fetchPost ({commit}, id) {
      return axiosInstance.get('posts/'+id)
        .then(res => {
          const post = res.data
          commit('setItem', {resource: 'posts', item: post},  {root: true})
          return post;
        })
        .catch( err => {console.log(err)})
    },

    fetchPosts ({state, commit}) {
      return axiosInstance.get('posts?_sort=created_at:ASC&_limit=10')
        .then(res => {
          const posts = res.data
          commit('setItems', {resource: 'posts', items: posts}, {root: true})
          // console.log("posts module")
          // console.log(posts)
          return state.items
        })
        .catch( err => {console.log(err)})
    },

    fetchListPosts ({state, commit}) {
  
      const catFilter = state.categorySelected==('all')?'':`&category=${state.categorySelected}`;
      return axiosInstance.get('posts?_sort=created_at:DESC&_limit=10'+catFilter)
        .then(res => {
          
          const posts = res.data;
          commit('set', {resource: 'listPosts', value: posts})
          return state.items
        })
        .catch( err => {console.log(err)})
    },

    fetchPostsAuthor ({state, commit},id) {
      return axiosInstance.get(`posts?_sort=created_at:ASC&author=${id}`)
        .then(res => {
          const posts = res.data
          commit('setItems', {resource: 'posts', items: posts}, {root: true});
          return state.items
        })
        .catch( err => {console.log(err)})
    },

    setPost ({commit}, post) {
      commit('setItem', {resource: 'posts', item: post},  {root: true})
    }
  },

  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },
  }
}