export default {
   
  props:{
    assets:{
      type: Array,
      default: () => []
    },

    thumbnail:{
      type: Boolean,
      default:false
    }

  },

  data: () => ({
    items: [
      {
        title: "Image 1",
        description: "Description 1",
        src: "https://res.cloudinary.com/kriolmidia/image/upload/v1629144087/coffee_machine_b4c3be9363.jpg",
      },
  
      // {
      //   title: "Youtube Video",
      //   description: "Youtube Video",
      //   thumb: "http://i3.ytimg.com/vi/d0tU18Ybcvk/maxresdefault.jpg",
      //   src: "https://youtu.be/6CVYPOuxL98",
      //   // thumb: "http://i3.ytimg.com/vi/6CVYPOuxL98/maxresdefault.jpg",
      // },
    ],
    index: null,
  }),

    created() {
      let list =[];
      if(this.assets && this.assets.length>0){
       
        this.assets.forEach(asset => {
          
          list.push(
             {
              title: asset.alternativeText,
              description: asset.caption,
              src: asset.url,
              thumbnail: asset.formats.thumbnail.url,
            },
          )
        });

        this.items = list;
      }

    },

    methods:{
      bg(i) {
        return i && i.length > 0 ? `background-image: url('${i}')` : "";
      },
    }

};
  