<template>



  <div class="pa-2 container">
   
    

    <div class="d-flex justify-space-between container-title">
      <h3>{{ $t(`filter_page.title`) }}</h3>
      <v-btn
        class="mx-2 filter-btn"
        fab
        x-small
        color="primary"
        outlined
        @click="setShowFilter()"
      >
        <v-icon dark>close</v-icon>
      </v-btn>
    </div>

    <div class="filter-item wrapper-order-by">
      <v-subheader class="pl-0">{{$t(`filter_page.order_by_text`)}}</v-subheader>
      <v-select
        :items="items"
        v-model="filters.orderBy"
        :item-text="sort_text"
        item-value="value"
        label=""
        class="select-input"
        dense
         @change="updateFilter()"
      ></v-select>
    </div>

    <v-divider class :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <div class="filter-list-category">
        <Autocomplete :isUpdating="isFetchingCategories"></Autocomplete>
      </div>
    </div>

    <v-divider class="mt-4" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <v-combobox
        v-model="filters.costsSelected"
        :items="recipe_costs"
        :label="$t(`filter_page.cost_text`)"
        :item-text="recipe_costs_text"
        multiple
        chips
        return-object
        dense
         @change="updateFilter()"
      ></v-combobox>
    </div>

    <v-divider class="mt-3" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <v-combobox
        v-model="filters.difficultiesSelected"
        :items="recipe_difficulties"
        :label="$t(`filter_page.difficulty_text`)"
        :item-text="recipe_difficulties_text"
        multiple
        chips
        return-object
        dense
         @change="updateFilter()"
      ></v-combobox>
    </div>

    <v-divider class="mt-4" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <div class="filter-row">
        <v-subheader class="pl-0">{{ $t(`filter_page.preparation_time_text`)}}</v-subheader>
        <span class="caption grey--text"
          >{{$t(`filter_page.less_than`)}} {{ filters.prepTime | formatMinutes }}</span
        >
      </div>
      <div class="filter-preparation-time mt-1">
        <v-slider
          dense
          hide-details
          v-model="filters.prepTime"
          @change="updateFilter()"
        ></v-slider>
      </div>
    </div>

    <v-divider class="mt-4" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <div class="filter-row">
        <v-subheader class="pl-0">{{$t(`filter_page.cook_time_text`)}}</v-subheader>
        <span class="caption grey--text"
          >{{ filters.cookTime[0] | formatMinutes }} :
          {{ filters.cookTime[1] | formatMinutes }}</span
        >
      </div>
      <div class="filter-preparation-time mt-1">
        <v-range-slider
          dense
          v-model="filters.cookTime"
            @change="updateFilter()"
          hide-details
        ></v-range-slider>
      </div>
    </div>

    <v-divider class="mt-3" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <v-subheader class="pl-0">Classification</v-subheader>

      <div class="filter-list-tag mt-1">
        <v-chip
          v-for="item in filters.classification"
          :key="item.id"
          :class="item.selected ? 'tag-chip active' : 'tag-chip'"
          link
          small
          @click="updateClassification(item.id)"
        >
          <v-icon
            size="12"
            :color="item.selected ? 'white' : 'gray'"
            v-for="star in item.value"
            :key="star"
            >star</v-icon
          >
        </v-chip>
      </div>
    </div>

    <v-divider class="mt-3" :inset="inset"></v-divider>

    <div class="filter-item wrapper-category mt-1">
      <v-combobox
        v-model="filters.tagsSelected"
        :items="tags"
        label="Tags"
        item-text="tag"
        multiple
        chips
        return-object
        dense
        @click="updateFilter()"
      ></v-combobox>
    </div>
  </div>
</template>

<script>
import Autocomplete from "../form/AutocompleteFilter";
import recipeMixins from "../../mixins/recipe";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "Grid Recipe",
  name: "Filter2",
  components: {
    Autocomplete,
  },

  data: () => ({
    items: [
      { id: 1, value: "name" },
      { id: 2, value: "time" },
      { id: 3, value: "cookTime" },
    ],
    inset:false,
    isFetchingCategories: true,
  }),

  created() {
    this.fetchCategories()
      .then(() => {
        this.isFetchingCategories=false;
      })
      .catch(() => {
      });

    this.fetchTags()
      .then(() => {})
      .catch(() => {});
  },

  mixins: [recipeMixins],

  methods: {
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("tags", ["fetchTags"]),
    ...mapActions("search", ["setClassification", "setShowFilter", "setSearchText"]),
    sort_text(item) {
      return this.$t(`filter_page.order_${item.value}`);
    },
    updateFilter(){
      this.setSearchText()
    },

    updateClassification(id){
      this.setClassification(id)
      this.setSearchText()
    }
  },

  computed: {
    ...mapGetters({
      filters: "search/getFilters",
      categories: "categories/getCategories",
      tags: "tags/getTags",
    }),
  },
};
</script>

<style scoped lang="scss">
.container {
  /* background-color: grey; */
  background-color: var(--v-background-darken1);
  border-radius: 4px;
  //rgb(247,181,56)
  display: flex;
  flex-direction: column;
  z-index: 999;
 
}


.container-title {
  width: 100%;
}
.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper-order-by {
  .select-input {
    padding-top: 0;
    margin-top: 0;
  }
}

.filter-list-category {
  display: flex;
  flex-wrap: wrap;
}

.filter-list-tag {
  .tag-chip {
    margin: 1px 1.5px;
    padding: 2px 3.5px;
    &.active {
      background-color: #f7b538;
      color: white;
    }
  }
}

.v-subheader {
  height: 30px;
}

.v-messages {
  height: 0;
}
@media (max-width: 520px) {
 .close-wrapper{
    margin-left: 0;
    display: none;
 }
}

</style>