import { render, staticRenderFns } from "./CreateRecipe.vue?vue&type=template&id=77fe8d86&scoped=true&"
import script from "./CreateRecipe.vue?vue&type=script&lang=js&"
export * from "./CreateRecipe.vue?vue&type=script&lang=js&"
import style0 from "./CreateRecipe.vue?vue&type=style&index=0&id=77fe8d86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fe8d86",
  null
  
)

export default component.exports