<template>
  <v-container
    fluid
    class="
      d-flex
      pa-0
      pt-0
      flex-column
      container-wrapper-additional-info
      content-wrapper
    "
  >
    <div class="container-body pt-0">
      <!-- <h3 class="pb-5">Additional Information</h3> -->

      <div class="d-flex content">
        <div class="block-left">
          <div class="d-flex pb-2 recipe-info-wrap">
            <div
              class="grey--text name-info"
              v-t="'recipe_page.difficulty'"
            ></div>
            <div class="pl-5">{{ $t(`difficulty.${recipe.difficulty}`) }}</div>
          </div>

          <div class="d-flex pb-2 recipe-info-wrap">
            <div
              class="grey--text name-info"
              v-t="'recipe_page.duration'"
            ></div>
            <div class="pl-5">
              {{
                ((recipe.prepTime || 0) + (recipe.cookTime || 0))
                  | formatDuration
              }}
            </div>
          </div>

          <div class="d-flex pb-2 recipe-info-wrap">
            <div class="grey--text name-info" v-t="'recipe_page.cost'"></div>
            <div class="pl-5">{{ $t(`costs.${recipe.cost}`) }}</div>
          </div>

          <div class="d-flex pb-2 recipe-info-wrap">
            <div class="grey--text name-info">
              {{ $t(`recipe_page.serving`) }}(±)
            </div>
            <div class="pl-5">{{ recipe.servings || 0 }}</div>
          </div>

          <div class="d-flex pb-2 recipe-info-wrap">
            <div class="grey--text name-info" v-t="'recipe_page.views'"></div>
            <div class="pl-5">
              {{ recipe.viewers | length | formatNumberViews }}
            </div>
          </div>
        </div>

        <div class="block-right">
          <div class="d-flex pb-2 recipe-info-wrap">
            <div
              class="grey--text name-info"
              v-t="'recipe_page.category'"
            ></div>
            <div class="pl-5">{{ categories | removeLastCharacter }}</div>
          </div>

          <div class="d-flex pb-2 recipe-info-wrap">
            <div class="grey--text name-info pt-1" v-t="'recipe_page.tags'"></div>
            <div class="pl-5">
              <v-btn text exact class="pa-1 btn-tag" v-for="(item) in tags" :key="item.id"  :to="{ name: 'tagItem', params: { id: item.id, tag:item }}"  > 
                #{{item.tag}}
              </v-btn>
            </div>
          </div>

            <div class="d-flex pb-2 recipe-info-wrap" v-if="recipe.source">
              <div class="grey--text name-info pt-1" v-t="'recipe_page.source'"></div>
              <div class="pl-5">
                <v-btn text exact class="pa-1 btn-tag" target="_blank"   :href="recipe.source"    > 
                  {{$t('open')}}
                  <v-icon right >open_in_new</v-icon>
                </v-btn>
              </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TabAdditionalInfo",
  components: {},

  data: () => ({}),

  computed: {
    ...mapGetters({
      recipe: "recipes/getRecipe",
    }),
    categories() {
      if (!this.recipe || !this.recipe.categories) return "";
      const reducer = (accumulator, category) =>
        accumulator + " " + category.name + ",";
      return this.recipe.categories.reduce(reducer, "");
    },

    tags() {
      return this.recipe.tags || []
      
      // if (!this.recipe || !this.recipe.tags) return "";
      // const reducer = (accumulator, item) => accumulator + " " + item.tag + ",";

      // return this.recipe.tags.reduce(reducer, "");
    },
  },
};
</script>

<style scoped lang="scss">
.container-wrapper-additional-info {
  width: 100%;
  min-height: 200px;
  border-radius: 8px;

  .container-body {
    width: 100%;

    .block-left {
      width: 50%;

      .recipe-info-wrap {
        .name-info {
          min-width: 120px;
        }
      }
    }

    .block-right {
      width: 50%;
      .recipe-info-wrap {
        .name-info {
          min-width: 80px;
        }
      }
    }

    @media (max-width: 500px) {
      .content {
        flex-direction: column;
        .block-left {
          width: 100%;
        }

        .block-right {
          width: 100%;
        }
      }
    }
  }
}

.btn-tag{
  color: $primary;
  text-transform: lowercase;
}
@media (max-width: 600px) {
  .container-wrapper-additional-info {
    .block-left,
    .block-right {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }
}
</style>