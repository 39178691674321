var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{ref:"mapRef",style:('width: 100%; height:' + _vm.getHeight),attrs:{"zoom":12,"center":_vm.centerPoint},on:{"dragend":_vm.dragEnd,"center_changed":_vm.updateRouteLatLng}},[_vm._l((_vm.markers),function(m,index){return _c('GmapInfoWindow',{key:index + 'T',attrs:{"position":_vm.google && new _vm.google.maps.LatLng(m.lat, m.lng),"options":_vm.getInfoOptions(m.label),"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}})}),_c('GmapCluster',{attrs:{"position":_vm.positionMarkerOnCenter,"clickable":true,"animation":2}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":_vm.google && new _vm.google.maps.LatLng(m.lat, m.lng),"clickable":true,"draggable":true,"icon":"https://res.cloudinary.com/kriolmidia/image/upload/v1677103991/red_circle_536672d443.png"},on:{"click":function($event){_vm.center = _vm.google && new _vm.google.maps.LatLng(m.lat, m.lng);
         _vm.infoWinOpen = true;}}})}),1),_c('GmapMarker',{key:'my_position',attrs:{"position":_vm.positionMarkerOnCenter,"clickable":true,"draggable":true},on:{"dragend":_vm.centerMarkerdragEnd,"click":function($event){_vm.center =
           _vm.google &&
           new _vm.google.maps.LatLng(
             _vm.positionMarkerOnCenter.lat,
             _vm.positionMarkerOnCenter.lng
           );
         _vm.infoWinOpen = true;}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }