import Vue from 'vue'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import axiosInstance from '@/services/axios'

function checkTokenValidity(token) {
  if (token) {
    const decodedToken = jwt.decode(token)

    return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }

  return false
}

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false,
    isLoading: false,
    dialogForgotPassword: false,
  },
  getters: {

    isAuthenticated(state) {
      return !!state.user
    },

    getIsLoading(state) {
      return state.isLoading
    },

    getDialogForgotPassword(state) {
      return state.dialogForgotPassword
    },
  
  },
  actions: {
    loginWithEmailAndPassword({ commit, dispatch }, userData) {
    
      commit('setIsLoading', true);

      return axiosInstance.post('auth/local',
        {
          "identifier": userData.username,
          "password": userData.password
        })
        .then(res => {
          const user = res.data;
          localStorage.setItem('saborescv-jwt', user.jwt)
          commit('users/setAccount', { user: user.user }, { root: true })
          commit('setIsLoading', false)
          dispatch('recipes/fetchFavoriteRecipes',  null ,{ root:true })


          // resolve(true);
          return Promise.resolve(res.data);

        })
        .catch(
          err => {
            commit('setIsLoading', false)
            return Promise.reject(err);
            // reject(false)
          }
          // err => rejectError(err)
        )
    },

    loginWithProvider({ commit, dispatch }, data) {    
      
      commit('setIsLoading', true);
      return axiosInstance.get(`auth/${data.provider}/callback?access_token=${data.token}`)
        .then(res => {
          
          const data = res.data;
          localStorage.setItem('saborescv-jwt', data.jwt)
          commit('users/setAccount', { user: data.user }, { root: true })
          commit('setIsLoading', false)
          dispatch('recipes/fetchFavoriteRecipes',  null ,{ root:true })

          return Promise.resolve(data);
        })
        .catch(
          err => {
            commit('setIsLoading', false)
            return Promise.reject(err);
          }
        )
    },



    registerUser({ commit }, userData) {
      
      commit('setIsLoading', true)

      return axiosInstance.post('auth/local/register', userData)
        .then((result) => {
         
          commit('setIsLoading', false);
        
          if(result.response)
            return result.response.data;
          return result
        })
        .catch(
          err => {
            commit('setIsLoading', false);

            return err.response.data;
          }
          // err => rejectError(err)
        )
    },


    sendForgotPassword({ commit }, userData) {
     
      commit('setIsLoading', true);

      return axiosInstance.post('auth/forgot-password',
        {
          "email": userData.email,
        })
        .then(res => {
          commit('setIsLoading', false)
          return Promise.resolve(res.data);
        })
        .catch(
          err => {
           
            commit('setIsLoading', false)
            return Promise.reject(err);
            // reject(false)
          }
          // err => rejectError(err)
        )
    },


    activateUser(_, hash) {
      return axios.patch(`/api/v1/users/${hash}/activate`)
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem('saborescv-jwt')
        localStorage.removeItem('current_author')
        localStorage.removeItem('recipes_viewAll')
        localStorage.removeItem('current_recipe')
        localStorage.removeItem('current_restaurant')
        localStorage.removeItem('current_post')
        commit('users/setAccount', { user: {} }, { root: true })
        commit('recipes/resetState', { }, { root: true })
        commit('settings/resetState', {  }, { root: true })
        resolve(true)
      })
    },
    getAuthUser({ commit, rootGetters, dispatch }) {
      // const authUser = getters['authUser']
      const authUser = rootGetters['users/getAccount']
      // const authUser = getters.users.getAccount;
      const token = localStorage.getItem('saborescv-jwt')
      const isTokenValid = checkTokenValidity(token)

      if (authUser && isTokenValid) { 
          return Promise.resolve(authUser) 
      }
     

      if (token){
        return axiosInstance.get('users/me')
          .then((res) => {
            const user = res.data
            commit('users/setAccount', { user: user }, { root: true })
            commit('setAuthState', true)

            // commit('recipes/addListRecipe', { resource: '_listFavorites', list: user.favouriteRecipes }, { root: true })
            // commit('recipes/addRecipes', user.favouriteRecipes, { root: true })

            dispatch('recipes/fetchFavoriteRecipes',  null ,{ root:true })

            commit('setItems', { resource: 'pantries', items: user.pantry }, { root: true })
            commit('users/setAccount', { user: user }, { root: true })

            return user 
          })
          .catch(err => {
            commit('users/setAccount', { user: null }, { root: true })
            commit('setAuthState', true)
            localStorage.removeItem("saborescv-jwt")
            return err
          })
        }
        else{
            commit('users/setAccount', { user: null }, { root: true })
            commit('setAuthState', true)
            localStorage.removeItem("saborescv-jwt")
        }
    },

    changeDialogForgotPassword({ commit }, value) {
      commit('setDialogForgotPassword', value)
    },

    addMeetupToAuthUser({ commit, state }, meetupId) {
      const userMeetups = [...state.user['joinedMeetups'], meetupId]
      commit('setMeetupsToAuthUser', userMeetups)
    },
    removeMeetupFromAuthUser({ commit, state }, meetupId) {
      const userMeetupsIds = [...state.user['joinedMeetups']]
      const index = userMeetupsIds.findIndex(userMeetupId => userMeetupId === meetupId)

      userMeetupsIds.splice(index, 1)
      commit('setMeetupsToAuthUser', userMeetupsIds)
    },

  },
  mutations: {

    setDialogForgotPassword(state, value) {
      return state.dialogForgotPassword = value
    },

    setAuthState(state, authState) {
      return state.isAuthResolved = authState
    },

    setIsLoading(state, isLoading) {
      return state.isLoading = isLoading
    },
    setMeetupsToAuthUser(state, meetups) {
      return Vue.set(state.user, 'joinedMeetups', meetups)
    }
  }
}