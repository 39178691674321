export default {
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
    width: 0,
    height: 0
  }),
  mounted() {
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },  

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.width = this.$refs.galleryRef? this.$refs.galleryRef.clientWidth :0;
      this.height = this.$refs.galleryRef? this.$refs.galleryRef.clientHeight :0;
    },

  },

}