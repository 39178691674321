<template>
  <div class="main-container" @click="goToDetail()">
    <div class="block-left">
      <v-skeleton-loader
        v-bind="attrs"
        type="image"
        min-width="100%"
        min-height="60px"
        height="100%"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-img
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.8"
        v-if="!loading"
      ></v-img>
    </div>
    <div class="block-right">
      <p class="bold accent--text" v-if="!loading">{{ recipe.name }}</p>
      <v-skeleton-loader
        v-bind="attrs"
        type="sentences"
        min-width="100%"
        min-height="60px"
        height="100%"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <div>
        <v-rating
          class="rating"
          small
          dense
          half-increments
          v-model="rating"
          background-color="gray"
          readonly
          v-if="!loading"
        ></v-rating>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardListRecipe",
  components: {},

  props: {
    recipe: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    attrs: {
      class: "mb-0",
      boilerplate: false,
      elevation: 0,
    },
  }),

  computed: {
    rating() {
      return this.recipe.rating || 0;
    },

    lang() {
      return this.$i18n.locale;
    },

    getImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
  },

  methods: {
    goToDetail() {
      this.$router.push({ name: "recipeItem", params: {recipeId: this.recipe.id} }, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  cursor: pointer;

  .block-left {
    width: 35%;
    padding: 8px 8px 8px 0;
    .v-image {
      border-radius: 4px;
    }
  }

  .block-right {
    width: 65%;
    padding: 8px 0;
  }
}

.bold {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  &:hover {
    /* text-decoration: underline; */
    color: #f7b538;
  }
}

.wrap-value {
  span {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
}
</style>