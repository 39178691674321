<template>
  <div class="find-restaurant-wrapper">
    <v-row class="main-wrapper">
      <v-col cols="12" md="6" class="p-0">
        <div class="header-wrapper">
          <div class="header-container mx-auto px-6 pt-16 pt-sm-8 pb-2"> 
            <div style="max-width: 100px" class="mx-auto d-none d-md-flex">
              <v-img
                class="icon-restaurant mb-2"
                alt="menu-logo"
                color="white"
                src="../assets/icons/restaurant-circle-white.svg"
              ></v-img>
            </div>

            <div class="input-search-wrapper d-flex">
              <GmapAutocomplete
                ref="gmapAutocomplete"
                :placeholder="$t('find_restaurant_page.input_search_placeholder')"
                @place_changed="setPlace"
              ></GmapAutocomplete>
              <v-icon color="gray" class="mr-4 icon">location_on</v-icon>
              <!-- <button @click="usePlace">Add</button> -->
            </div>
          </div>

          <div class="px-8 slider-container">
            <RestaurantSearchSlider />
          </div>
        </div>

        <div class="pl-6 pt-4 search-result-1">
          <h5 class="accent--text">
            {{ $t("find_restaurant_page.search_result") }}
          </h5>
          <div
            v-for="item in restaurants"
            :key="item.id + 'cardListRestaurantId'"
          >
            <div class="card-restaurant-wrapper">
              <CardListRestaurant :restaurant="item"></CardListRestaurant>
            </div>
          </div>
          <div class="py-1 " v-if="isListRestaurantEmpty">{{$t('empty_list')}}</div>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="p-0">
        <MapSectionRestaurant :height="getMapHeight" :markers="listMarkers" />

        <div class="pl-6 my-4 search-result-2">
          <h5 class="accent--text">
            {{ $t("find_restaurant_page.search_result") }}
          </h5>
          <div
            v-for="item in restaurants"
            :key="item.id + 'cardListRestaurantId'"
          >
            <div class="card-restaurant-wrapper">
              <CardListRestaurant :restaurant="item"></CardListRestaurant>
            </div>
          </div>
          <div class="py-1 " v-if="isListRestaurantEmpty">{{$t('empty_list')}}</div>

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardListRestaurant from "../components/card/CardListRestaurant";
import MapSectionRestaurant from "@/components/maps/MapSectionRestaurant";
import RestaurantSearchSlider from "@/components/form/RestaurantSearchSlider";
import resizeMixins from "@/mixins/resize";
import { gmapApi } from "vue2-google-maps";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "Find My Restaurant",
  name: "FindMyRestaurant",
  components: {
    CardListRestaurant,
    MapSectionRestaurant,
    RestaurantSearchSlider,
  },

  data: () => ({
    recipes: {},
    ex3: { label: "", val: 50 },
    showMap: false,
  }),

  created() {
    const latitude = 14.919576;
    const longitude = -23.508067;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          this.mapSearch(latitude, longitude);
          this.setAddressSearchInput();
          this.showMap = true;
        },
        (error) => {
          if (error.code == error.PERMISSION_DENIED) {
            this.mapSearch(latitude, longitude);
            // this.setAddressSearchInput()
          }
        }
      );
    }

    //  const searchQuery = this.searchQuery;
    //  const query =  {searchQuery};
    //  this.$router.replace({ query });
    //  this.fetchMapSearch().catch((error) => console.log(error));
  },

  mounted() {
    this.setGmapAutocompleteRef(this.$refs.gmapAutocomplete);
  },
  computed: {
    ...mapGetters({
      showFilter: "search/getShowFilter",
      restaurants: "restaurantSearch/getRestaurants",
      searchQuery: "restaurantSearch/getSearchQuery",
      mapCenter: "restaurantSearch/getCenterMapSearch",
    }),

    google: gmapApi,

    getMapHeight() {
      const toolbarSize = 70;

      if (this.windowSize.x > 960)
        return this.windowSize.y - toolbarSize + "px";
      return this.windowSize.y * 0.4 + "px";
    },

    listMarkers() {
      let markers = [];

      if (this.restaurants) {
        this.restaurants.forEach((item) => {
          if (item.address)
            markers.push({
              label: item.name,
              lat: item.address.lat,
              lng: item.address.lng,
            });
        });
      }
      return markers;
    },

    isListRestaurantEmpty(){
     return this.restaurants && this.restaurants.length == 0;
    }
  },

  methods: {
    ...mapActions("restaurantSearch", [
      "fetchMapSearch",
      "setCenterMapSearch",
      "setGmapAutocompleteRef",
    ]),

    mapSearch(lat, lng) {
      this.setCenterMapSearch({ lat, lng });
      this.fetchMapSearch()
        .then(() => this.setCenterMapSearch({ lat: lat, lng: lng }))
        .catch((error) => {
          console.log(error);
        });

      this.showMap = true;
    },

    setPlace(place) {
      this.mapSearch(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    },
    setAddressSearchInput() {
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ latLng: this.mapCenter }, (result, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          this.$refs.gmapAutocomplete.$refs.input.value =
            result[0].formatted_address;
        }
      });
    },
  },

  mixins: [resizeMixins],
};
</script>

<style scoped lang="scss">
.main-wrapper {
  height: 100%;
}
.input-search-wrapper {
  background-color: var(--v-background-base);
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  input {
    width: 100%;
    padding: 12px 8px;
    padding-right: 50px;
    outline: none;
    color: var(--v-inputText-base);;
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 0;
  }
}
.find-restaurant-wrapper {
  /* height: calc(100% - 70px); */

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: linear-gradient(to right, #6d191fd2, #6d191fa8),
      url("../assets/coffee_machine.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50%;

    .header-container {
      width: 100%;
      max-width: 400px;
    }
  }

  .slider-container {
    width: 100%;
    max-width: 400px;
    label {
      color: white;
    }
  }

  .card-restaurant-wrapper {
    max-width: 500px;
  }
  .search-result-2 {
    display: none;
  }
}

.search-result-1 {
  height: 280px;
  max-height: 50%;
  overflow: scroll;
}

@media (max-width: 960px) {
  .find-restaurant-wrapper {
    .header-wrapper {
      height: 100%;
    }
    .search-result-1 {
      display: none;
    }
    .search-result-2 {
      display: block;
    }
  }
}
</style>