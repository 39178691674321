import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    getPartners(state) {
      return state.items
    },
    
    getPartner(state) {
      if(state.items || state.items.length>0)
        return state.items[0]
      return {}  
    },
  },

  actions: {

    fetchPartners({ state, commit }) {
      if (state.items > 0)
        return state.items;

      return axiosInstance.get('partners')
        .then(res => {
          const partners = res.data
         
          commit('setItems', { resource: 'partners', items: partners }, { root: true })
          return state.items
        })
        .catch(err => { console.log(err) })
    },


  }
}