<template>
  <v-card class="mx-auto my-card" max-width="100%" @click="goToDetail()">
    <v-img
      v-if="!loading"
      :src="getImageUrl"
      :lazy-src="getLazyImageUrl"
      aspect-ratio="2"
    ></v-img>

    <v-skeleton-loader
      type="card"
      min-width="100%"
      v-if="loading"
      transition="fade-transition"
    ></v-skeleton-loader>
 
    <div class="pt-1  px-sm-2 px-md-3 card-info" v-if="!loading">
      <div class="body-2 my-card-title px-1 pt-1 ">
        {{ restaurant.name }}
      </div>

      <div class="">
        <v-icon color="secondary" size="18">location_on</v-icon>
        <span class=" caption"
          >{{ restaurant.address.city }}
          {{ restaurant.address.city ? "," : "" }}
          {{ restaurant.address.country }}</span
        >
      </div>
    </div>
  </v-card>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "Card-3",

  props: {
    type: {
      type: String,
      default: () => "rating",
    },

    restaurant: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    show: false,
  }),

  created() {},

  computed: {
    rating() {
      return 4;
    },

    lang() {
      return this.$i18n.locale;
    },
    getImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
  },

  methods: {
    ...mapActions("search", ["setSearchActive"]),
    goToDetail() {
      this.$router.push(
        // { name: "restaurant", params: { restaurant: this.restaurant } },
        { name: "restaurantItem", params: { restaurantId: this.restaurant.id,  restaurant: this.restaurant } },
        () => {}
      );
      this.setSearchActive(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-card {
  width: 100%;
  cursor: pointer;
  border-radius: 24px !important;
  background-color: var(--v-background-darken2);
}
.card-info {
  min-height: 50px;
  @media (max-width: 400px) {
    padding-right: 4px ;
    padding-left: 4px; 
}

}
.value {
  color: gray;
}

.my-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1;
  /* min-height: 40px; */
}
.rating {
  button {
    padding: 0 !important;
    font-size: 10px;
  }
}
</style>