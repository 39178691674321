import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    sending: false,
    deleting: null,
    review: null,
    imagesToUpload: [],
    showReview: null,
  },

  getters: {
    getReviews(state) {
      return state.items
    },
    getNumberReviews(state) {
      if(!state.items)
        return 0;
      return state.items.length;
    },
    getReviewsValue(state) {
      if(!state.items || state.items.length === 0)
        return 0;
        let numOr0 = n => isNaN(n) ? 0 : n
        const sum = state.items.reduce(function (acc, obj) { return acc + numOr0(obj.rating); }, 0); 
        const total = (sum / state.items.length) ;
        return (total).toFixed(1);
    },
    getSending(state) {
      return state.sending
    },
    getDeleting(state) {
      return state.deleting
    },

    getReview(state) {
      return state.review;
    },

    getImagesToUploadUrl(state) {
      let list = [];
        state.imagesToUpload.forEach(file => {
          list.push(URL.createObjectURL(file))
        });
      
      return list;
    },

    getShowReview(state) {
      return state.showReview;
    },

  },

  actions: {
    setReviews({ commit }, reviews) {
      commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
    },

    setImagesToUpload({ commit }, files) {
      commit('set', { resource: 'imagesToUpload', value: files })
    },

    removeImageSelected({ commit }, index) {
      commit('deleteImageSelected', { index: index })
    },

    setShowReview({ commit }, value) {
      commit('set', { resource: 'showReview', value },)
    },

    fetchReviews({ state, commit }, recipeId) {
      return axiosInstance.get(`reviews?_sort=created_at:DESC&recipe=${recipeId}`)
        .then(res => {
          const reviews = res.data
          commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
          return state.items
        })
    },

    fetchRestaurantReviews({ state, commit }, {restaurantId}) {
    
      return axiosInstance.get(`restaurant-reviews?_sort=created_at:DESC&reservation.restaurant=${restaurantId}`)
    
        .then(res => {
          const reviews = res.data
          commit('setItems', { resource: 'restaurantReviews', items: reviews }, { root: true })
          return state.items;
        })
     
    },


    resetReview({ commit }) {
      commit('set', { resource: 'review', value: null })
      commit('set', { resource: 'imagesToUpload', value: [] })

    },

    createReview({  commit }, review) {

      const _review = {
        // "rating": review.rating,
        "accessibility": review.accessibility,
        "cleanliness": review.cleanliness,
        "cost": review.cost,
        "environment": review.environment,
        "food": review.food,
        "service": review.service,
        "comment": review.comment,
        "recipeId": review.recipeId,
        "id": review.id,
      }
      commit('set', { resource: 'review', value: _review })

    },


    fetchSendReview({state, commit}, {review}) {

      // const review = state.review;
      commit('set', { resource: 'sending', value: true })

      review.restaurant = state.showReview.restaurantId;
      review.reservation = state.showReview.reservationId;
 
      commit('set', { resource: 'sending', value: true })

      // const formData = new FormData(); 
      
      // formData.append("data", JSON.stringify(review));

      // if(state.imagesToUpload && state.imagesToUpload.length>0){
      //   state.imagesToUpload.forEach(img => {
      //     formData.append('files.assets', img, img.name)
      //   });
      // }

      // return axiosInstance({
      //   method: review.id ? 'put' : 'post',
      //   url: review.id ? `restaurant-reviews/${review.id}` : 'restaurant-reviews',
      //   data: formData,
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // })
      //   .then(res => {
      //     const _review = res.data;
      //     console.log("_review")
      //     console.log(_review)
      //     // commit('recipes/updateRecipeReview', { type: review.id ? "edit" : "add", recipeId: review.recipeId, review: _review }, { root: true })
      //     commit('set', { resource: 'sending', value: false })
      //     // commit('set', { resource: 'review', value: null })

      //     return state.items
      //   })
      //   .catch(
      //     err => {
      //       console.log(err)
      //       commit('set', { resource: 'sending', value: false })
      //       // commit('set', { resource: 'review', value: null })
      //     }
      //   )
    },


    fetchRemoveReview({ state, commit }, { recipeId, reviewId }) {

      commit('set', { resource: 'deleting', value: reviewId })

      return axiosInstance.delete(`reviews/${reviewId}`)
        .then(res => {
          const review = res.data
          commit('recipes/updateRecipeReview', { type: "delete", recipeId: recipeId, review: review }, { root: true })
          commit('set', { resource: 'deleting', value: null })

          return state.items
        })
        .catch(err => {
          console.log(err);
          commit('set', { resource: 'deleting', value: null })
        })
    }
  },




  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },
    deleteImageSelected(state, { index }) {

      const files = state.imagesToUpload;
      let list = [];
      for (let i = 0; i < files.length; i++) {
        if (i != index) {
          list.push(files[i]);
        }
      }

      state.imagesToUpload = [...list];
    },

  }
}