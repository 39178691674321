export default {
    data () {
      return {
        pageLoader_isDataLoaded: false,
        recipe_costs: [
          { id: 1, value: "Cheap" },
          { id: 2, value: "Medium" },
          { id: 3, value: "Expensive" },
        ],
        recipe_difficulties: [
          { id: 1, value: "Beginner" },
          { id: 2, value: "Basic" },
          { id: 3, value: "Intermediate" },
          { id: 4, value: "Advanced" },
          { id: 5, value: "Advanced" },
        ],
      }
    },
    methods: {
    
      recipe_difficulties_text(item) {
          return  this.$t(`difficulty.${item.id}`)
       },

      recipe_costs_text(item) {
          return  this.$t(`costs.${item.id}`)
       },
   
    }
  }