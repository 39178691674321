var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',[_c('h4',{directives:[{name:"t",rawName:"v-t",value:('login_page.signUp_title'),expression:"'login_page.signUp_title'"}],staticClass:"text--primary text-h6"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('login_page.signUp_subtitle'),expression:"'login_page.signUp_subtitle'"}],staticClass:"body-2 subtitle-continue"}),_c('v-form',{ref:"registerForm"},[_c('v-text-field',{attrs:{"prepend-icon":"badge","label":_vm.$t('login_page.signUp_firs_name_label_text'),"rules":[(v) => !!v || _vm.$t(`login_page.signUp_firs_name_error`)]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('v-text-field',{attrs:{"prepend-icon":"badge","label":_vm.$t('login_page.signUp_last_name_label_text'),"rules":[(v) => !!v || _vm.$t(`login_page.signUp_last_name_error`)]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('v-text-field',{attrs:{"prepend-icon":"account_circle","name":"username","type":"text","rules":[(v) => !!v || _vm.$t(`login_page.signUp_username_error`)],"label":_vm.$t('login_page.signUp_username_label_text')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('v-text-field',{attrs:{"prepend-icon":"email","name":"email","type":"email","rules":[
          (v) => !!v || _vm.$t(`login_page.signUp_email_error`),
          (v) =>
            (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
            _vm.$t(`login_page.invalid_email`),
        ],"label":_vm.$t('login_page.signUp_email_label_text')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{attrs:{"id":"password","name":"password","prepend-icon":"lock","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
          (v) => !!v || _vm.$t(`login_page.signUp_password_error`),
          (v) =>
            (!!v && v.length >= 6) ||
            _vm.$t(`login_page.signUp_password_length_error`),
        ],"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('login_page.signUp_password_label_text'),"hint":_vm.$t('login_page.signUp_password_length_error'),"autocomplete":"on"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('v-card-actions',[_c('v-flex',[_c('v-spacer'),_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.authenticate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("login_page.signUp_btn"))+" ")]),_c('div',{staticClass:"d-flex align-center justify-center flex-wrap my-4"},[_c('span',[_vm._v(_vm._s(_vm.$t("login_page.signUp_subtitle_bottom")))]),_c('a',{staticClass:"mx-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/login')}}},[_vm._v(_vm._s(_vm.$t("login_page.signUp_here")))])]),_c('div',{staticClass:"d-flex align-center my-4"},[_c('v-divider'),_c('span',{directives:[{name:"t",rawName:"v-t",value:('login_page.login_sign_with'),expression:"'login_page.login_sign_with'"}],staticClass:"mx-2"}),_c('v-divider')],1),_c('div',{staticClass:"d-flex align-center justify-center mb-4"},[_c('BtnFacebookLogin',{staticClass:"mr-2"}),_c('BtnGoogleLogin',{staticClass:"ml-2"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }