<template>
  <div class="main-wrapper">
    <h4>{{ $t("comments") }} ({{ commentCount }})</h4>
    <v-divider class="my-2" color="#f7b538" ></v-divider>

    <div>
      <div class="d-flex">
        <v-btn fab small dark class="mr-4" @click="goToPage('profile')">
          <AuthorAvatarImg
            :size="40"
            :user="userAuthenticaded"
          ></AuthorAvatarImg>
        </v-btn>

        <v-textarea
          v-model="commentText"
          name="input-7-1"
          filled
          auto-grow
          dense
          rows="4"
          placeholder="Add comment"
        >
        </v-textarea>
      </div>

      <div class="mb-6 d-flex justify-end">
        <v-btn
          depressed
          small
          color="primary"
          :loading="sending"
          @click="fetchSendComment(postId)"
          v-t="'send'"
        ></v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import AuthorAvatarImg from "../shared/AuthorAvatarImg";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CommentForm",
  components: {
    AuthorAvatarImg,
  },
  props: {
    postId: {
      type: Number,
      require: true,
    },
  },

  data: () => ({}),

  methods: {
    goToPage(path) {
      this.$router.push({ name: path }, () => {});
    },
    ...mapActions("comments", ["fetchSendComment", "setComment"]),
  },

  computed: {
    ...mapGetters({
      userAuthenticaded: "users/getAccount",
      sending: "comments/getSending",
      comment: "comments/getComment",
      commentCount: "comments/getCommentCount",
    }),

    commentText: {
      get: function () {
        return this.comment;
      },

      set: function (newValue) {
        this.setComment(newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss" >
.main-wrapper {
  width: 100%;
}
</style>
