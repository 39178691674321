<template>
  <div class="container-wrapper">
 
      
      <div v-if="loading" >
           <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="item in 5" :key="item" >
                <CardSpotlight  :loading="true"></CardSpotlight>
              </swiper-slide>
          </swiper>
      </div>
      
       <div v-if="!loading" >
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="item in recipes" :key="item.id"  >
                <CardSpotlight :recipe="item" :loading="false"></CardSpotlight>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
      </div>
     
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

import CardSpotlight from "../card/CardSpotlight";

export default {
  title: "Multiple slides per view",
  name: "CarouselSpotlight",
  components: {
    CardSpotlight,
    Swiper,
    SwiperSlide,
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
     loading: {
      type: Boolean,
      default: () => false,
    },
    
  },

  data: () => ({
    ecosystem: [],

    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
};
</script>

<style scoped lang="scss">
.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    font-size: 12;
    /* background-color: white; */
  }
}

.swiper-slide {
  width: 32%;
}

@media (max-width: 1100px) {
    .swiper-slide {
  width: 40%;
}
}
@media (max-width: 700px) {
  .swiper-slide {
    width: 80%;
    padding-left: 1%;
  }
}

@media (max-width: 500px) {
  .swiper-slide {
    width: 95%;
    padding-left: 0%;
  }
}
</style>