<template>
  <div class="d-flex flex-wrap justify-space-between px-1 grid-recipe">
    <a
      class="card-category pa-1"
      v-for="item in categories"
      :key="item.id"
      @click.prevent="select(item)"
    >
      <v-img
        :src="getImageUrl(item)"
        :lazy-src="getLazyImageUrl(item)"
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        aspect-ratio="1.5"
      ></v-img>
      <div
        class="title d-flex flex-wrap align-center justify-center flex-column"
      >
        <h3 class="text-center">{{ item.name }}</h3>
        <div class="body-2">{{ item.numberRecipes }}</div>
      </div>
    </a>
  </div>
</template>
 
<script>
import { mapActions } from "vuex";

export default {
  title: "Grid Category",
  name: "GridCategorySearch",
  components: {
    // Card3,
  },

  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({}),

  methods: {
    ...mapActions("search", ["setSearchActive"]),
    select(category) {
      this.$router.push(
        {
          name: "categoryItem",
          params: { category: category, id: category.id },
          path: "id",
        },
        () => {}
      );
      this.setSearchActive(false);
    },

    getImageUrl(item) {
      return item.image && item.image.url
        ? item.image.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl(item) {
      return item.image && item.image.url
        ? item.image.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
  },
};
</script>

<style scoped lang="scss">
.card-category {
  width: 33.33%;
  position: relative;
  .v-image {
    border-radius: 8px;
  }
}

@media (max-width: 600px) {
  .card-category {
    width: 50%;
  }
}

.title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  color: white;
}
</style>