var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"form-wrapper",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"row-center py-5"},[_c('AuthorAvatarEditBtn',{attrs:{"user":_vm.userAuthenticated,"size":100}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":[ (v) => !!v || _vm.$t(`edit_user_form.first_name_error`)],"label":_vm.$t(`edit_user_form.first_name_text`),"required":""},model:{value:(_vm.userAuthenticated.firstName),callback:function ($$v) {_vm.$set(_vm.userAuthenticated, "firstName", $$v)},expression:"userAuthenticated.firstName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":[ (v) => !!v || _vm.$t(`edit_user_form.last_name_error`)],"label":_vm.$t(`edit_user_form.last_name_text`),"required":""},model:{value:(_vm.userAuthenticated.lastName),callback:function ($$v) {_vm.$set(_vm.userAuthenticated, "lastName", $$v)},expression:"userAuthenticated.lastName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":[ (v) => !!v || _vm.$t(`edit_user_form.username_error`)],"label":_vm.$t(`edit_user_form.username_text`),"required":""},model:{value:(_vm.userAuthenticated.username),callback:function ($$v) {_vm.$set(_vm.userAuthenticated, "username", $$v)},expression:"userAuthenticated.username"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":[
                      (v) => !!v || _vm.$t(`login_page.signUp_email_error`),
                      (v) =>
                        (!!v &&
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            v
                          )) ||
                        _vm.$t(`login_page.invalid_email`),
                    ],"label":_vm.$t(`edit_user_form.email_text`),"required":"","readonly":""},model:{value:(_vm.userAuthenticated.email),callback:function ($$v) {_vm.$set(_vm.userAuthenticated, "email", $$v)},expression:"userAuthenticated.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"row-center"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading=='updatedUser'},on:{"click":function($event){return _vm.updateAccount('contact')}}},[_vm._v(_vm._s(_vm.$t(`profile_page.btn_update_label`)))])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }