<template>
  <div>
    <v-card-text>
      <h4 class="text--primary text-h6" v-t="'login_page.forgot_title'"></h4>
      <p
        class="body-2 subtitle-continue"
        v-t="'login_page.forgot_subtitle'"
      ></p>
      <v-form ref="forgotPasswordForm">
        <v-text-field
          prepend-icon="email"
          name="email"
          type="email"
          :rules="[
            (v) => !!v || $t(`login_page.forgot_email_error`),
            (v) =>
              (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
              $t(`login_page.invalid_email`),
          ]"
          v-model="form.email"
          :label="$t('login_page.forgot_email_label_text')"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-flex>
        <v-spacer></v-spacer>
        <v-btn
          block
          depressed
          color="primary"
          @click.prevent="authenticate"
          :loading="isLoading"
        >
          {{ $t("login_page.forgot_send_text") }}
        </v-btn>
        <div class="d-flex align-center justify-center flex-wrap my-4">
          <span>{{ $t("login_page.forgot_subtitle_bottom") }}</span>
          <a class="mx-2" @click.prevent="$router.push('/login')">{{
            $t("login_page.forgot_back_login")
          }}</a>
        </div>
      </v-flex>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  components: {},
  data: () => ({
    form: {
      email: null,
    },
    rules: {
      required: (value) => !!value || "Required.",
      emailMatch: () => "The email and password you entered don't match",
      emailValid: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Invalid e-mail",
    },
  }),

  methods: {
    ...mapActions("auth", ["sendForgotPassword"]),

    authenticate() {
      if (this.$refs.forgotPasswordForm.validate()) {
        this.sendForgotPassword(this.form)
          .then(() => {
            this.$toasted.show(this.$t("login_page.forgot_success_message"), {
              theme: "toasted-primary",
              position: "top-center",
              duration: 5000,
            });
            this.$refs.forgotPasswordForm.reset();
          })
          .catch((errorMessage) => {
            this.$toasted.show(
              errorMessage.message === "Network Error"
                ? this.$t("network_error")
                : this.$t("login_page.forgot_error_message"),
              {
                theme: "toasted-primary",
                position: "top-center",
                duration: 5000,
              }
            );
          });
      }
    },
  },

  computed: {
    ...mapGetters({
      isLoading: "auth/getIsLoading",
    }),
  },
};
</script>

<style scoped lang="scss">
button {
  /* text-transform: none !important; */
  outline: none;
}
</style>
