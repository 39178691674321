<template>

<div class="btn-wrapper">
    <v-icon
     class="edit-icon"
      color="white"
      dark 
      size="20"
      >edit</v-icon>

  <input type="file" 
    @change="onFileChanged" 
    class="input-file"
    ref="fileInputAvatar"
    >
  <v-btn class="" fab dark x-small color="primary" @click="$refs.fileInputAvatar.click()" >
  <v-avatar color="orange" :size="size">

    <img
      v-if="getUserImage"
      :src="getUserImage"
      :alt="author_fullName" 
    /> 
     <v-icon
      v-if="!getUserImage"
      dark size="50">mdi-account-circle</v-icon>
  </v-avatar>
</v-btn>
</div>

</template>

<script>
import authorMixins from "../../mixins/author";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AuthorAvatarEditBtn",
  components: {},

    data: () => ({
    }),

  props: {
    user: {
      type: Object,
      require: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },

    created(){
      this.author = this.user;
    },

  mixins: [authorMixins],

  methods: {
  onFileChanged (event) {
    this.setImageToUpload(event.target.files[0]);
  },
  
  onUpload() {
    // upload file
  },

  goToAuthorPage(){
    console.log("goToAuthorPage")
  },

   ...mapActions("users", ["setImageToUpload"]),

  },
  computed:{
    getUserImage(){
      if(this.selectedFile)
        return this.selectedFile;
      if(this.author_isAuthorPhotoExist)
        return this.author_authorPhotoThumbnail
      return  null;
    },
     ...mapGetters({
        selectedFile: "users/getImageToUploadUrl",
      }),

  }
};
</script>

<style scoped lang="scss">
*:focus {outline:none}

.btn-wrapper{
position: relative;
}

.input-file{
  display: none;
}
.edit-icon{
  position: absolute;
  z-index: 99999;
  top:-20px;
  left: 45px;
  background-color: #ff9800;
  border-radius: 50%;
  padding: 5px;
}
</style>