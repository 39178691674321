<template>
  <v-container fluid class="d-flex pa-0 flex-wrap">
    <div class="card-info" v-for="(item) in item" :key="item.id">
      <div class="card-wrapper">
      <Card3  :showStatus="showStatus" v-if="type=='recipe'" :type="'time'" :recipe="item"></Card3>
      <CardPost  v-if="type=='post'" :post="item"></CardPost>
      </div>
    </div>
  </v-container>
</template>

<script>
import Card3 from "../card/Card3";
import CardPost from "../card/CardPost";

export default {
  title: "Multiple slides per view",
  name: "GridItemBig",
  components: {
    Card3,
    CardPost,
  },

  props:{
    item:{
        type: Array,
      default: () => []
    },

    type:{
        type: String,
        default: 'recipe'
    },
     showStatus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  created(){
  },

  computed: {},
};
</script>

<style scoped lang="scss">

.card-info {
  width: 25%;
  padding-right: 1%;
  padding-bottom: 1%;
}

@media (max-width: 1200px) {
  .card-info {
    width: 33.3%;
 
  }
}

@media (max-width: 760px) {
  .card-info {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .card-info {
    width: 100%;
 
  }
}
</style>