<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :class="`diet-card rounded-lg mx-auto text-center ${hover && 'diet-card--active'}`"
        :to="link"
    >
      <v-img
          :src="image"
          height="150px"
          aspect-ratio="1"
          class="grey lighten-2"
      ></v-img>

      <v-card-title class="justify-center">
        <span class="headline font-weight-bold">{{ title }}</span>
      </v-card-title>

      <v-card-subtitle>
        {{ subtitle }}
      </v-card-subtitle>

      <v-card-text class="text--secondary">
        {{ description.slice(0, 80) + '...' }}
      </v-card-text>

      <v-card-actions class="justify-center align-center">
        <v-btn
            color="primary"
            class="ma-12 pa-6 font-weight-bold"
            rounded
            depressed
        >
          {{ $t('dietCard.select') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'DietCard',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    description: {
      type: String
    },
    image: {
      type: String,
      default: '../../../assets/logo_transparent.png'
    },
    link: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.diet-card {
  border: 4px solid transparent;
  &--active {
    border: 4px solid $primary !important;
  }
}
</style>
