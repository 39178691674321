<template>
  <div
    :class="boxShadow ? 'main-container box-shadow' : 'main-container'"
    @click="goToDetail()"
  >
       <v-skeleton-loader 
        v-bind="attrs" 
        type="image" 
         width="100%"
         height="200"
         transition = "fade-transition"
         v-if="loading"
        ></v-skeleton-loader>
    <div v-else class="block-img">
      <v-img :src="post.featured.url" aspect-ratio="1.8"></v-img>
      <div class="caption category">{{ post.readingTime }} {{$t("min_read")}}</div>
    </div>
    
    <div class="block-info"  v-if="!loading" >
      <p class="title-text ">{{ post.title }}</p>
      <div class="d-flex align-center justify-start">
      
        <AuthorAvatarImg :user="post.author" :size="23"></AuthorAvatarImg>

        <p class="pl-1 ma-0 caption pr-1 ">
          {{ post.author.firstName + " " + post.author.lastName }}
        </p>
        <v-icon class="ml-md-6" size="20" color="accent">timer</v-icon>
        <p class="pl-1 ma-0 caption ">{{ post.created_at | fromNow(lang) }}</p>
      </div>
    </div>
    <div class="pt-2" v-else >
      <v-skeleton-loader 
        v-bind="attrs" 
        type="text" 
        width="100%"
        transition = "fade-transition"
      ></v-skeleton-loader>
    </div>


  </div>
</template>
<script>
import AuthorAvatarImg from "../../components/shared/AuthorAvatarImg";

export default {
  name: "CardPost",
  components: {
    AuthorAvatarImg
  },
  props: {
    boxShadow: {
      type: Boolean,
      default: false,
    },

    post: {
      type: Object,
      default: ()=>{},
    
    },

    loading: {
      type: Boolean,
       default: false,
    },
  },

    data: () => ({
      attrs: {
          class: '',
          boilerplate: false,
          elevation: 0,
        },
  }),

  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    goToDetail() {
      this.$router.push(
        // { name: "post", params: { post: this.post } },
        { name: "postItem", params: {postId: this.post.id, post: this.post } },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  &.box-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;



  .block-img {
    width: 100%;
    position: relative;
    .v-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .category {
      position: absolute;
      bottom: 0;
      padding: 2px 5px;
      background-color: var(--v-primary-base);;
      color: white;
      /* border-radius: 4px; */
    }
  }

  .block-info {
    width: 100%;
    padding: 8px;
    background-color: var(--v-background-darken2);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    p {
      line-height: 1;
    }
  }
}

.title-text {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 6px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
  &:hover {
    /* text-decoration: underline; */
    color: var(--v-primary-base);;
  }
}
@media (max-width: 500px) {
  .title-text {
       -webkit-line-clamp: 2;
  }
}
</style>