<template>
  <div class="locale-changer">
    <select v-model="language">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        <!-- {{ lang | capitalize }} -->
        <span v-if="lang==='en'" >🇬🇧</span>
        <span v-if="lang==='pt'" >🇵🇹</span>
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LocaleChanger",
  data() {
    return { langs: ["pt", "en"] };
  },
  computed: {
    ...mapGetters({
      getLanguage: "language/getLanguage",
    }),
    language: {
      // getter
      get: function () {
        return this.getLanguage;
      },
      // setter
      set: function (newValue) {
        this.setLanguage(newValue);
      },
    },
  },

  methods: {
    ...mapActions("language", ["setLanguage"]),
  },
};
</script>

<style scoped lang="scss" >
select {
  color: white;
  outline: none;
}
</style>