import axiosInstance from '@/services/axios'
import { debounce } from '@/helpers';

export default {
  namespaced: true,

  state: {
    items: [],
    item: {},
    centerMapSearch:{},
    mapSearchDistance: 10,
    gmapAutocompleteRef: null,

    positionMarkerOnCenter: { lat: 14.919576, lng: -23.508067 },
  },

  getters: {
    getRestaurants(state) {
      return state.items
    },

    getRestaurant(state) {
      return state.item
    },
    
    getCenterMapSearch(state) {
      // console.log("state.centerMapSearch")
      // console.log(state.centerMapSearch)

      return state.centerMapSearch;
    },

    getMapSearchDistance(state) {
      return state.mapSearchDistance;
    },

    getPositionMarkerOnCenter(state) {
      return state.positionMarkerOnCenter;
    },

    getGmapAutocompleteRef(state) {
      return state.gmapAutocompleteRef;
    },

    getSearchQuery(state) {
      return `lat=14.919576&lng=-23.508067&distance=${state.mapSearchDistance}`;
    },
    
  },

  actions: {

    
    fetchMapSearch: debounce(function  ({ state, commit }) {
 
      const {lat, lng} = state.centerMapSearch;   
      return axiosInstance.get(`restaurants/search?lat=${lat}&lng=${lng}&distance=${state.mapSearchDistance}`)
      // return axiosInstance.get(`restaurants/search?lat=14.919576&lng=-23.508067&distance=${state.mapSearchDistance}`)
        .then(res => {
          const restaurants = res.data
          // console.log("restaurants  LAS")
          // console.log(restaurants)
          commit('setItems', { resource: 'restaurantSearch', items: restaurants }, { root: true })
          return state.items;
        })
        .catch(err => { console.log(err) })
    }, 300),
    // https://saborescv-backend.herokuapp.com/restaurants/search?lat=15.329711&lng=-23.739176&distance=1000000


    setPositionMarkerOnCenter({ commit }, point) {
      commit('set', { resource: 'positionMarkerOnCenter', item: point })
    },

    setCenterMapSearch({ commit }, point) {
      commit('set', { resource: 'centerMapSearch', item: point })
      commit('set', { resource: 'positionMarkerOnCenter', item: point })
    },

    setMapSearchDistance({ commit }, distance) {
      commit('set', { resource: 'mapSearchDistance', item: distance })
    },

    setGmapAutocompleteRef({ commit }, ref) {
      commit('set', { resource: 'gmapAutocompleteRef', item: ref })
    },
    

  },


  mutations: {

    set(state, { resource, item }) {
      state[resource] = item;
    },
    

  }
}