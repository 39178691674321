<template>
  <v-avatar color="warning" :size="size" >
    <span v-if="!author_isAuthorPhotoExist" class="white--text body-2">{{
      author_LettersName
    }}</span>
    <img
      v-if="author_isAuthorPhotoExist"
      :src="author_authorPhotoThumbnail"
      :alt="author_fullName" 
    />
  </v-avatar>
</template>

<script>
import authorMixins from "../../mixins/author";
import { mapGetters } from "vuex";

export default {
  name: "AuthorAvatarImg",
  components: {},

  props: {
    user: {
      type: Object,
      require: true,
    },

    size: {
      type: Number,
      default: 32,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authorMixins],

  mounted() {
    this.author = this.user;
  },

  computed: {
    ...mapGetters({
      userAuthenticated: "users/getAccount",
    }),
  },
};
</script>

<style scoped lang="scss">

</style>