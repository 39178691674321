<template>
 <div class="loading-wrapper" v-if="loading">
      <div class="loading-text">
        <GifLoading></GifLoading>
      </div>
    </div>
</template>

<script>
import GifLoading from '@/components/spinner/GifLoading';

export default {
  name: "LoadingContainer",
  components: {
        GifLoading
  },

  props: {
    loading: {
      type: Boolean,
      require: true,
    },
  },

};
</script>

<style scoped lang="scss">
.loading-wrapper{
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#ffffff;
  z-index: 99;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;

  .loading-text{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

  }
}
</style>