<template>
  <div class="form-wrapper">
    <v-container width="100%" class="main-wrapper">
      <v-row>
        <v-col cols="6" class="col-scv">
          <div
            :class="language === 'pt' ? 'selected pt-btn' : 'pt-btn'"
            @click="setLanguage('pt')"
          ></div>
        </v-col>

        <v-col cols="6" class="col-scv">
          <div
            :class="language === 'en' ? 'selected en-btn' : 'en-btn'"
            @click="setLanguage('en')"
          ></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditLanguage",
  components: {},

  data() {
    return { pt: "pt", en: "en" };
  },
  computed: {
    ...mapGetters({
      getLanguage: "language/getLanguage",
    }),
    language: {
      // getter
      get: function () {
        return this.getLanguage;
      },
      // setter
      set: function (newValue) {
        this.setLanguage(newValue);
      },
    },
  },

  methods: {
    ...mapActions("language", ["setLanguage"]),
  },
};
</script>

<style scoped lang="scss" >
.form-wrapper {
  background-color: var(--v-background-base);


  h4 {
    color: #000000de;
  }
  .col-scv {
    width: 100%;
    height: 100%;
  }

  .pt-btn,
  .en-btn {
    background-size: cover;
    width: 250px;
    height: 150px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.5s ease;
    background-color: white;

    &.selected {
      border: 2px solid $primary;
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }

  .pt-btn {
    background-image: url("../../assets/flags/portugal_flag.svg");
    
  }
  .en-btn {
    background-image: url("../../assets/flags/uk_flag.svg");
  }

  @media (max-width: 500px) {
    .pt-btn,
    .en-btn {
      width: 150px;
      height: 80px;
    }
  }
}
</style>