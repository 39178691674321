<template>
<div>
    <div v-for="menuGroup in listMenusBycategories" :key="menuGroup.id"  >
     <div v-if="menuGroup.menus.length>0" class="pb-8">
      <div class="h5 accent--text">{{$t(`menu_category.${menuGroup.name}`)}}</div>

      <div>
        <CarouselMenu :menus="sort(menuGroup.menus)" />
      </div>
      <!-- <v-row>
        <v-col v-for="item in menuGroup.menus" :key="item.id" md="4" sm="6" xs="12">
          <div class="card-menu">
            <CardMenu :menu="item" /> 
          </div>
        </v-col>
      </v-row> -->
    </div>
    </div>
  </div>
</template>

<script>
import CarouselMenu from "../../components/carousel/CarouselMenu";

export default {
  title: "Grid Restaurant Menu",
  name: "GridRestaurantMenu",
  components: {
    CarouselMenu,
  },

  props: {
    listMenusBycategories: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
  }),

  created() {
  
  },

  methods: {
 
    getImageUrl(assets) {
      return assets && assets.length > 0
        ? assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl(assets) {
      return assets && assets.length > 0
        ? assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    getRecipeCategories(menu) {
      if (!menu) return "-1";

      return menu.entries[0].recipe;
    },

    sort(_menus){
        // console.log("_menus", _menus)
      return !!_menus && _menus.slice().sort(function(a,b){
        if(a.entries[0].active) return -1;
        if(b.entries[0].active) return 1;
        if(a.name< b.name) return -1;
        if(a.name >b.name) return 1;
        return 0;
    });
    },

  },

};
</script>

<style scoped lang="scss">
@media (max-width: 900px) {
}
@media (max-width: 600px) {
}
</style>
