<template>
  <v-container class="py-0 px-0" fluid>
    <v-row class="main-wrapper">
      <v-col cols="12" class="pt-0">
        
        <CarouselCategoryCircle :categories="categories" :loading="isCatageriesLoading" ></CarouselCategoryCircle>
        <div class="">
          <div class="my-4 ml-3 section-home" v-if="showListRecipeByTime">
            <HeaderByTime :recipes="listRecipeByTime"></HeaderByTime>
            <CarouselSpotlight
              :recipes="listRecipeByTime"
              :loading="loading"
            ></CarouselSpotlight>
          </div>

          <div class="mb-4 ml-3 section-home pt-2">
            <HeaderPopular :recipes="listRecipeMostViews"></HeaderPopular>
            <CarouselSpotlight
              :recipes="listRecipeMostViews"
              :loading="loading"
            ></CarouselSpotlight>
          </div>

          <div class="container-feed-recipes pl-3 py-4 section-home">
            <h4 class="pt-2 accent--text font-weight-bold accent--text">
              {{ $t("home_page.feed_of_recipes") }}
            </h4>
            <div class="my-8">
              <GridRecipeHome
                :recipes="listRecipeFeed"
                :loading="loading"
              ></GridRecipeHome>
            </div>
          </div>

          <div class="container-restaurant-of-week-wrapper section-home">
            <div class="container-restaurant-of-week pl-3 pt-4 pb-14">
              <div class="">
                <div class="d-flex align-center">
                  <div style="width: 25px; height: 25px" class="img-logo mr-1">
                    <v-img
                      src="../assets/icons/restaurant-square-white.svg"
                    ></v-img>
                  </div>

                  <h4 class="pt-2 text-white font-weight-bold">
                    {{ $t("home_page.restaurant_of_week") }}
                  </h4>
                </div>

                <div class="my-8">
                  <CardSpotlightRestaurant
                    :restaurant="restaurantOfTheWeek"
                    :loading="loading"
                  ></CardSpotlightRestaurant>
                </div>
              </div>
            </div>
          </div>

          <div class="container-restaurants-wrapper section-home">
            <div class="container-restaurants pl-3 py-4">
              <div class="d-flex align-center">
                <div style="width: 25px; height: 25px" class="img-logo mr-1">
                  <v-img
                    src="../assets/icons/restaurant-square-red.svg"
                  ></v-img>
                </div>

                <h4 class="pt-2 accent--text font-weight-bold accent--text">
                  {{ $t("home_page.restaurants") }}
                </h4>
              </div>

              <div class="my-8">
                <GridRestaurantHome
                  :restaurants="restaurants"
                  :loading="loading"
                ></GridRestaurantHome>
              </div>
            </div>
          </div>

          <!-- Todo in second phase of project -->
          <!-- <div class="container-streaming-wrapper">
            <div class="container-streaming pl-3 py-4">
              <div class="d-flex align-center">
                <div style="width: 25px; height: 25px" class="img-logo mr-1">
                  <v-img src="../assets/icons/iconsscv-17.svg"></v-img>
                </div>

                <h4 class="pt-2 text-white font-weight-bold">
                  {{ $t('home_page.solidary_live_streaming') }}
                </h4>
              </div>
              <CardLiveStreaming></CardLiveStreaming>
            </div>
          </div> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "swiper/swiper-bundle.css";
import CarouselSpotlight from "../components/carousel/CarouselSpotlight";
import CarouselCategoryCircle from "../components/carousel/CarouselCategoryCircle";
import GridRecipeHome from "../components/grid/GridRecipeHome";
import GridRestaurantHome from "../components/grid/GridRestaurantHome";
import CardSpotlightRestaurant from "../components/card/CardSpotlightRestaurant";
import HeaderByTime from "../components/home/HeaderByTime";
import HeaderPopular from "../components/home/HeaderPopular";
import { mapActions, mapGetters } from "vuex";
export default {
  title: "Home",
  name: "Home",
  components: {
    CarouselSpotlight,
    CarouselCategoryCircle,
    GridRecipeHome,
    GridRestaurantHome,
    CardSpotlightRestaurant,
    HeaderByTime,
    HeaderPopular,
  },

  data: () => ({
    recipes: [{ id: "1" }, { id: "2" }, { id: "3" }],
    loading: false,

    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),

  computed: {
    ...mapGetters({
      categories: "categories/getCategories",
      listRecipeFeed: "recipes/listRecipeFeed",
      listRecipeMostViews: "recipes/listRecipeMostViews",
      listRecipeByTime: "recipes/listRecipeByTime",
      dailyRecipe: "recipes/getDailyRecipe",
      listFavorites: "recipes/listFavorites",
      posts: "posts/getPosts",
      restaurants: "restaurants/getRestaurants",
      restaurantOfTheWeek: "restaurants/getRestaurantOfTheWeek",
      isHomeInfoLoaded: "settings/getIsHomeInfoLoaded",
    }),

    showListRecipeByTime() {
      return !(!this.loading && this.listRecipeByTime.length === 0);
    },
    isCatageriesLoading() {
      return this.loading;
      // return this.loading && this.categories && this.categories.length < 1;
    },
  },

  created() {
    if (!this.isHomeInfoLoaded) {
       this.loading = true;
      // in test
      Promise.all([
        this.fetchCategories(),
        this.fetchListRecipeFeed(),
        this.fetchListRecipeByTime(),
        this.fetchListRecipeByMostViews(),
        this.fetchRestaurants(),
        this.fetchRestaurantOfTheWeek(),
      ])
        .then(() =>
          // this.pageLoader_resolveData()
          {
            this.loading = false;
            this.setIsHomeInfoLoaded(true)
          }
        )
        .catch((err) => {
          console.error(err);
          this.loading = false;
          // this.pageLoader_resolveData()
        });
    } 
  },

  methods: {
    //...mapActions('meetups', ['fetchMeetups', 'initializePagesFromQuery']),
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("settings", ["setIsHomeInfoLoaded"]),
    ...mapActions("restaurants", [
      "fetchRestaurants",
      "fetchRestaurantOfTheWeek",
    ]),
    ...mapActions("recipes", [
      "fetchListRecipeFeed",
      "fetchListRecipeByTime",
      "fetchListRecipeByMostViews",
    ]),
  },
};
</script>

<style scoped lang="scss">


.images-wrapper {
  .image {
    width: 100px;
    height: 100px;
  }
}

.section-home {
  min-height: 200px;
}
.swiper-slide {
  width: 15%;
}

.card-info {
  width: 33%;
  padding-right: 1%;
  padding-bottom: 1%;
}

.container-feed-recipes {
  height: 100%;
  background-color: var(--v-background-darken1);
  /* background-color: $grey-light; */
  border-top-right-radius: 64px;
}

.container-restaurants-wrapper {
  background-color:  var(--v-secondary-base); 
  .container-restaurants {
    height: 100%;
    /* background-color: var(--v-mainBackground-base); */
    background-color: var(--v-background-base);
    border-top-right-radius: 64px;
  }
}
.container-streaming-wrapper {
  background-color: white;
  .container-streaming {
    height: 100%;
    background-color: $primary;
    border-top-right-radius: 64px;
  }
}

.container-restaurant-of-week-wrapper {
  background-color: var(--v-background-darken1);
  .container-restaurant-of-week {
    height: 100%;
    background-color: var(--v-secondary-base);
    border-top-right-radius: 64px;
  }
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wrapper-1 {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 16px;
  margin: 16px;

  background-color: #f7f8fa;
  border-radius: 8px;
  position: relative;
  max-width: 304px;
}

.user-info-wrapper {
  .container-1 {
    display: flex;
    .block-right {
      padding-left: 16px;
      p {
        padding: 0;
        margin: 0;
      }
      .username {
        color: #707070;
      }
      /* width: 50%; */
    }
  }

  .container-2 {
    border-top: 1px solid gray;
    margin-top: 16px;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;

    .wrap-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      .value {
        color: #030f09;
        font-size: 20px;
        font-weight: bold;
      }
      .text {
        color: #707070;
      }
    }
  }
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

.btn-container {
  display: flex;
  flex-direction: row;
}

.text-info {
  margin-top: 25%;
}

.logo-wrap {
  position: absolute;
}

.user-img {
  border-radius: 50%;
  max-width: 70px;
  max-height: 70px;
}

@media (max-width: 960px) {
  .text-info {
    margin-top: 15%;
    text-align: center;
  }

  .btn-container {
    justify-content: center;
  }

  .swiper-slide {
    width: 18%;
  }

  .wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.1)
      ),
      url("../assets/intro/img-intro.jpeg");
  }
}
@media (max-width: 700px) {
  .swiper-slide {
    width: 22%;
  }
}
@media (max-width: 600px) {
  .text-info {
    margin-top: 25%;
  }

  .block-left {
    height: 5%;
  }

  .swiper-slide {
    width: 24%;
  }
}
@media (max-width: 500px) {
   .swiper-slide {
    width: 26%;
  }
}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }

  .intro-subtitle {
    display: none;
  }

  .swiper-slide {
    width: 33%;
  }
}

@media (max-width: 350px) {

  .swiper-slide {
    width: 38%;
  }
}
</style>
