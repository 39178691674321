<template>
  <div class="container-wrapper">
   
    <swiper class="swiper pb-6" :options="swiperOption" v-if="!loading">
      <swiper-slide v-for="(item) in menus" :key="item.id" >
        <CardMenu :menu="item"  > </CardMenu>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <swiper class="swiper pb-6" :options="swiperOption" v-if="loading">
      <swiper-slide v-for="(item) in 5" :key="item" loading="true">
        <CardMenu :menu="item"  > </CardMenu>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>


  </div>
</template>
 
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

import CardMenu from "../card/CardMenu";

export default {
  title: "Multiple slides per view",
  name: "CarouselMenu",
  props: {
    type: {
      type: String,
      default: "rating",
    },

    menus:{
      type: Array,
      default: () => []
    },
    
    loading: {
      type: Boolean,
       default: false,
    },
  },

  components: {
    CardMenu,
    Swiper,
    SwiperSlide,
  },

  data: () => ({
    ecosystem: [],

    swiperOption: {
      // slidesPerView: 2,
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
};
</script>

<style scoped lang="scss">
.swiper {
  /* height: 100%;
  width: 100%; */

  .swiper-slide {
    /* display: flex; */
    /* font-size: 12;
    background-color: white; */
  }
}

.swiper-slide {
  width: 22%;
}

.container-wrapper {
  padding: 16px 0;
}
 @media (max-width: 1124px) {
  .swiper-slide {
    width: 29%;
  }
}


@media (max-width: 960px) {
  .swiper-slide {
    width: 36%
  }
}


@media (max-width: 800px) {
  .swiper-slide {
    width: 41%
  }
}

@media (max-width: 650px) {
    .swiper-slide {
     width: 60%;
    }
  }

@media (max-width: 500px) {
  .swiper-slide {
    width: 65%;
  }
}

@media (max-width: 400px) {
  .swiper-slide {
    width: 85%;
  }
}


@media (max-width: 300px) {
  .swiper-slide {
    width: 90%;
  }
} 
</style>