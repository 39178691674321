<template>
  <v-expansion-panels accordion flat class="py-1" v-model="panel" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon v-if="!isValid" color="error">mdi-alert-circle</v-icon>
          <v-icon v-if="isValid" color="teal">mdi-check</v-icon>
          {{ productName }}
        </div>
        
        <template v-slot:actions>
          <v-icon color="primary">$expand</v-icon>
          
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="ingredients[index].product"
                :items="products"
                :label="$t(`suggest_page.ingredient.product`)"
                item-text="name"
                item-value="id"
                return-object
                :rules="[
                  (v) => !!v || $t(`suggest_page.ingredient.product_error`),
                ]"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="4">
              <v-combobox
                v-model="ingredients[index].unit"
                :items="units"
                :label="$t(`suggest_page.ingredient.unit`)"
                item-text="name"
                :rules="[
                  (v) => !!v || $t(`suggest_page.ingredient.unit_error`),
                ]"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="ingredients[index].quantity"
                :rules="[
                  (v) => !!v || $t(`suggest_page.ingredient.quantity_error`),
                  (v) => !!v && v>0 || $t(`suggest_page.ingredient.quantity_error_length`),
                ]"
                :label="$t(`suggest_page.ingredient.quantity`)"
                type="number"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn
                icon
                color="primary"
                dark
                @click="
                  removeIngredientNewRecipe({
                    ingredientIndex: index,
                  })
                "
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Ingredient",

  props: {
    index: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    valid: false,
    lastname: "",
    panel: [0],
  }),

  created() {
    Promise.all([this.fetchProducts(), this.fetchUnits()]).catch((err) => {
      console.error(err);
    });
  },

  methods: {
    ...mapActions("recipes", [
      "removeIngredientNewRecipe",
      "setRefFormIngredients",
    ]),
    ...mapActions("products", ["fetchProducts"]),
    ...mapActions("units", ["fetchUnits"]),
  },

  computed: {
    ...mapGetters({
      ingredients: "recipes/getIngredientsNewRecipe",
      products: "products/getProducts",
      units: "units/getUnits",
    }),
      
    product() {
      return this.ingredients[this.index].product;
    },

    unit() {
      return this.ingredients[this.index].unit;
    },

    quantity() {
      return this.ingredients[this.index].quantity;
    },

    productName() {
      return this.product || this.product != ""
        ? this.product.name
        : this.$t(`suggest_page.create_an_entry`);
    },

    isValid(){
      return !!this.product && !!this.unit && !!this.quantity && this.quantity > 0;
    }


      },
};
</script>

<style scoped>
.main-wrapper {
  width: 100%;
  height: 100%;
  max-width: 960px;
  /* background-color: rgb(240, 240, 240); */
  border-radius: 4px;
}

</style>