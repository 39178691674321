<template>
  <div
    :class="selected ? 'mx-auto my-card selected' : 'mx-auto my-card'"
    max-width="100%"
    @click="goTodetail()"
  >
    <v-skeleton-loader
      type="image"
      class="v-image"
      v-if="loading"
    ></v-skeleton-loader>

    <v-img
      class="category-img"
      :src="getImageURL"
      :lazy-src="getLazyImageUrl"
      v-if="!loading"
    ></v-img>

    <div class="">
      <div v-if="!loading" class="custom-title">{{ category.name }} <span class="text-caption">({{category.numberRecipes}})</span></div>

      <div class="mt-4">
        <v-skeleton-loader
          type="text"
          width="120"
          class="mx-auto"
          v-if="loading"
        ></v-skeleton-loader>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "CardCircle",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    category: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    show: false,
    rating: 3,
    attrs: {
      class: "mb-0",
      boilerplate: true,
      elevation: 0,
    },
  }),
  computed: {
    getImageURL() {
      return this.category?.image
        ? this.category.image.url
        : require(process.env.VUE_APP_IMAGE_PLACEHOLDER);
    },
    getLazyImageUrl() {
      return this.category?.image
        ? this.category.image.formats.small.url
        : require("@/assets/placeholder.jpg");
    },
  },

  methods: {
    goTodetail() {
      this.$router.push(
        {
          name: "categoryItem",
          params: { category: this.category, id: this.category.id },
          path: "id",
        },
        () => {}
      );
      this.setSearchActive(false);
    },
    ...mapActions("search", ["setSearchActive"]),
  },
};
</script>


<style scoped lang="scss">
.v-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: auto;
}

.my-card {
  border-radius: 8px;
  /* opacity: 0.6; */
  .custom-title {
    padding: 8px 5px;
    text-align: center;
    color: white;
    font-weight: bold;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &.selected {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .v-image {
    border-radius: 50%;
    width: 105px;
    height: 105px;
    margin: auto;
  }
}
</style>
