<template>
   <v-container fluid class="d-flex pa-0 flex-column container-wrapper-reviews">
          <!-- <h3 class="pb-5">Reviews</h3> -->
          
          <p v-if="isEmpty">
            {{$t(`reviews_component.add_the_first`)}} <a @click.prevent="addReview()">{{$t(`reviews_component.here`)}}</a>.
          </p>

          <ReviewItem v-for="(item) in reviews" :key="item.id" :review="item"></ReviewItem>

          <div class="flex text-center pb-5" v-if="!isEmpty && showViewAll">
            <a class="text-center" @click.prevent="showAllReview=true">{{$t(`reviews_component.view_all_`)}} ({{recipe.reviewsCount | formatNumberViews}}) </a>
            <br/>
            <a class="text-center" @click.prevent="addReview()">{{$t(`reviews_component.add_review`)}}</a>
          </div>

          <div v-if="showAllReview">
              <ReviewModal :recipeId="recipe.id" :dialog="showAllReview"  @closeEvent="hideShowAllReview()"></ReviewModal>
          </div>
          <div v-if="review!=null">
              <!-- <ReviewModalAddEdit :recipeId="recipe.id"  :dialog="showAddEditReview" @closeEvent="hideShowAddEditReview()"></ReviewModalAddEdit> -->
              <ReviewModalAddEdit :recipeId="recipe.id"  ></ReviewModalAddEdit>
          </div>
        </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReviewItem from "./ReviewItem";
import ReviewModal from "./ReviewModal";
import ReviewModalAddEdit from "./ReviewModalAddEdit";

export default {
  name: "RecipeReviewCard", 
  components: {
    ReviewItem,
    ReviewModal,
    ReviewModalAddEdit,
  },

  props:{
    showLoadMore:{
      type:Boolean,
      default:false,
    },

    showViewAll:{
      type:Boolean,
      default:true,
    },

    recipeId:{
      type: Number,
      require :true,
    },
  
  },

  data: () => ({
     showAllReview: false,
     showAddEditReview: false,
     loadind: false,
  }),


  created(){
    // alert(this.recipeId)
    this.loadind = true;
    this.fetchReviews(this.recipeId)
        .then(() => (this.loadind = false))
        .catch(() => (this.loadind = false));
  },


  computed: {
        ...mapGetters({
        recipe: "recipes/getRecipe",
        reviews: "reviews/getReviews",
        review: "reviews/getReview",
      }),
    // reviews() {
    //   return this.recipe.reviews
    // },
    isEmpty(){
      return this.recipe.reviews.length == 0;
    }
  },

  methods:{
      ...mapActions("reviews", [ "createReview", "fetchReviews"]),

    hideShowAllReview(){
      this.showAllReview=false;
    },

      hideShowAddEditReview(){
      this.showAddEditReview=false;
    },

    addReview(){
      this.createReview({
        "rating": 0,
        "comment": null,
        "recipeId": this.recipe.id,
      })
    }
  }
};
</script>

<style scoped lang="scss">

.container-wrapper-reviews {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
}


</style>