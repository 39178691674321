<template>
  <v-footer
    padless
    tile
    elevation="0"
    class="footer"
   
  >
    <v-card
      flat
      tile
      class="primary white--text footer-card text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="btn in icons"
          :key="btn.icon"
          class="mx-2 white--text"
          icon
          :href="btn.href" 
          target='_blank'
        >
          <v-icon size="24px">
            {{ btn.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text py-0 text-center">
        <!-- Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. -->
           <v-btn
                v-for="value in links"
                :key="value.title"
                class="mx-2 pr-8 pl-8 py-0 white--text btn-links "
                text
                tile
                small
                plain
                :to="{ name: value.href }"
            >
            {{ $t(value.title)}}
            
            </v-btn>
      </v-card-text>
      
      <v-divider></v-divider>

      <v-card-text class="white--text text-center py-1">
       KriolMidia {{ new Date().getFullYear() }} — <strong> © {{ $t('terms_and_conditions_page.all_rights_reserved' )}}</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},

    data: () => ({
      icons: [
         {icon: 'mdi-facebook', href: 'https://www.facebook.com/'},
         {icon: 'mdi-twitter', href: 'https://twitter.com/'},
         {icon: 'mdi-linkedin', href: 'https://www.linkedin.com/'},
         {icon: 'mdi-instagram', href: 'https://www.instagram.com/'},
         {icon: 'mdi-whatsapp', href: 'https://www.whatsapp.com/'},
      ],
      links: [
        {title: 'about_us_page.title', href: 'about-us'},
        {title: 'contact_us_page.title', href: 'contact-us'},
        {title: 'terms_and_conditions_page.title' , href: 'terms-and-conditions'},
       
      ],
    }),

  

  methods: {
    goToPage(path) {
      this.$router.push({ name: path }, () => {});
    },
  },
};
</script>

<style scoped lang="scss" >

.footer{
  padding-bottom: 56px;
}

.custom-bottom-nav {
    position: fixed;
    z-index: 99999;
  }

  .footer-card{
      padding-bottom: 10px;
      background-color:  #F5F5F5;
  }
  .btn-links{
      border-right: 1px solid white;
      text-transform: unset !important;
      &:last-child{
        border-right: none;
      }
      @media (max-width: 618px) {
        padding-left: 0px !important;
        padding-right: 8px !important;
      }
  }


</style>
