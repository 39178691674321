<template>
   <div class="container-wrapper-category pl-2 pt-10 pb-6">
          <swiper class="swiper" :options="swiperOption">
           
            <swiper-slide
              v-for="(item, index) in categories"
              :key="item.id"
              :loading="!loading"
            >
              <CardCircle :category="item" :selected="index === 0"></CardCircle>
            </swiper-slide>

            <swiper-slide
              v-for="(item, index) in 8"
              :key="item"
              :loading="loading"
              :class="!loading ? 'hide' : ''"
            >
              <CardCircle :selected="index === 0" :loading="true"></CardCircle>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
</template>
 
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import CardCircle from "../card/CardCircle";

export default {
  title: "Multiple slides per view",
  name: "CarouselCategoryCircle",
  components: {
    CardCircle,
    Swiper,
    SwiperSlide,
  },

  props: {
    categories: {
      type: Array,
      default: () => [],
    },


    loading: {
      type: Boolean,
      default: () => true,
    },

  },

  data: () => ({
    ecosystem: [],

    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),

  methods: {
    selectCat(categoryId) {
      this.$emit("selectCategory", categoryId);
    },
  },
};
</script>

<style scoped lang="scss">

.hide {
  display: none;
}

.swiper-slide {
  width: 150px;
}

.container-wrapper-category {
  background-image: linear-gradient(to right, #6d191fd2, #6d191fa8),
    url("../../assets/coffee_machine.jpg");
  background-size: cover;
  background-position: center;
}

/* @media (max-width: 1080px) {
     .swiper-slide {
    width: 15%;
  }
} */
@media (max-width: 960px) {

  .swiper-slide {
    width: 18%;
  }

}
@media (max-width: 700px) {
  .swiper-slide {
    width: 22%;
  }
}
@media (max-width: 600px) {


  .swiper-slide {
    width: 24%;
  }
}
@media (max-width: 500px) {
   .swiper-slide {
    width: 26%;
  }
}

@media (max-width: 400px) {
 

  .swiper-slide {
    width: 33%;
  }
}

@media (max-width: 350px) {

  .swiper-slide {
    width: 38%;
  }
}
</style>