import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    getTags (state) {
      return state.items 
    },
    },

  actions: {
    fetchTags ({state, commit}) {
      if(state.items>0)
        return state.items;
        
      return axiosInstance.get('tags')
      // return axiosInstance.get('recipes/tags')
        .then(res => {
          const tags = res.data
          commit('setItems', {resource: 'tags', items: tags}, {root: true})
          return state.items
        })
    },

    fetchTag ({state,commit }, id) {


      if(state.items>0){
          const _tags = state.items.filter(tag => tag.id === id)
       
          if(_tags.length>0){
            return _tags[0]
          }
         
      }
        
      return axiosInstance.get('tags')
      // return axiosInstance.get('recipes/tags')
        .then(res => {
          const tags = res.data
          commit('setItems', {resource: 'tags', items: tags}, {root: true})
          const _tags = tags.filter(tag => tag.id == id)
          
          if(_tags.length>0){
            return _tags[0]
          }

          return {}
        })

       
    }
  }
}