import i18n from "@/i18n";

export default {
  namespaced: true,

  state: {
    item: null
  },

  getters: {
    getLanguage(state) {
      return state.item || i18n.locale 
    },
  },

  actions: {

    setLanguage({ commit }, lng) {
      i18n.locale = lng;
      commit('set', {resource: 'item', value: lng}) 
      commit('settings/set', { resource: 'isHomeInfoLoaded', item: false }, { root: true })    
    }
  },

  mutations: {

    set(state, { resource, value }) {
      localStorage.setItem("scv_language", value)
      state[resource] = value;
    },
  }
}