<template>
  <div>
    <GmapMap
      ref="mapRef"
      :zoom="12"
      :style="'width: 100%; height:' + getHeight"
      :center="centerPoint"
      @dragend="dragEnd"
      @center_changed="updateRouteLatLng"
    >
      <GmapInfoWindow
        :key="index + 'T'"
        v-for="(m, index) in markers"
        :position="google && new google.maps.LatLng(m.lat, m.lng)"
        :options="getInfoOptions(m.label)"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
      </GmapInfoWindow>

 <GmapCluster :position="positionMarkerOnCenter" :clickable="true" :animation="2">
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="google && new google.maps.LatLng(m.lat, m.lng)"
        :clickable="true"
        :draggable="true"
        @click="
          center = google && new google.maps.LatLng(m.lat, m.lng);
          infoWinOpen = true;
        "
        icon="https://res.cloudinary.com/kriolmidia/image/upload/v1677103991/red_circle_536672d443.png"
      />
 </GmapCluster>

      <GmapMarker
        :key="'my_position'"
        :position="positionMarkerOnCenter"
        :clickable="true"
        :draggable="true"
        @dragend="centerMarkerdragEnd"
        @click="
          center =
            google &&
            new google.maps.LatLng(
              positionMarkerOnCenter.lat,
              positionMarkerOnCenter.lng
            );
          infoWinOpen = true;
        "
      />
      <!-- icon="https://res.cloudinary.com/kriolmidia/image/upload/v1631847869/restaurant_marker_be830c0b1a.png" -->
    </GmapMap>
    <!-- @click="center=google && new google.maps.LatLng(m.lat, m.lng)" -->
  </div>
</template>
<script>
// https://www.npmjs.com/package/vue2-google-maps
// http://xkjyeah.github.io/vue-google-maps/
// http://xkjyeah.github.io/vue-google-maps/autoapi.html
import { gmapApi } from "vue2-google-maps";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MapSectionRestaurant",
  components: {},

  props: {
    height: {
      type: String,
    },
    markers: {
      type: Array,
      require: true,
    },
  },

  data() {
    const lat = 14.919576;
    const lng = -23.508067;
    return {
      center: { lat: lng },
      centerOnEventMove: { lat, lng },
      place: null,
      infoWindowPos: { lat, lng },
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
    };
  },

  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   map.panTo( this.mapCenter? this.mapCenter : {lat:14.919576, lng: -23.508067 } );
    // });
  },

  computed: {
    getHeight() {
      return this.height || "300px";
    },

    centerPoint() {
      return this.centerMapSearch &&
        this.centerMapSearch.lat &&
        this.centerMapSearch.lng
        ? this.centerMapSearch
        : this.center;
    },

    ...mapGetters({
      centerMapSearch: "restaurantSearch/getCenterMapSearch",
      positionMarkerOnCenter: "restaurantSearch/getPositionMarkerOnCenter",
      gmapAutocompleteRef: "restaurantSearch/getGmapAutocompleteRef",
    }),

    google: gmapApi,
  },

  methods: {
    ...mapActions("restaurantSearch", [
      "setCenterMapSearch",
      "fetchMapSearch",
      "setPositionMarkerOnCenter",
    ]),

    btnClick(link) {
      window.location.href = link;
    },

    usePlace(place) {
      console.log(place);
    },

    getInfoOptions(text) {
      return {
        content: text,
        pixelOffset: {
          width: 0,
          height: -35,
        },
      };
    },

    updateMapCenter(position) {
      this.setCenterMapSearch(position);
      this.fetchMapSearch()
        .then(() => this.setCenterMapSearch(position))
        .catch((error) => {
          console.log(error);
        });
    },

    dragEnd() {
      this.updateMapCenter(this.centerOnEventMove);
      this.gmapAutocompleteRef.$refs.input.value = null;
    },
    centerMarkerdragEnd(event) {
      this.updateMapCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    updateRouteLatLng(e) {
      this.centerOnEventMove = { lat: e.lat(), lng: e.lng() };
      this.setPositionMarkerOnCenter({ lat: e.lat(), lng: e.lng() });
    },
  },
};
</script>

<style lang="scss" scoped></style>
