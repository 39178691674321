<template>
  <div class="main-container" @click="goToDetail()" role="button">
    <div class="block-left">
      <v-img
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.5"
      ></v-img>
    </div>
    <div class="block-right">
      <p class="bold">{{ post.title }}</p>

      <div class="d-flex">
        <v-icon size="20" color="primary">timer</v-icon>
        <p class="pl-1 ma-0 caption">
          {{ post.created_at | fromNow(this.$i18n.locale) }}
        </p>
      </div>
      <div class="d-flex">
        <v-icon size="20" color="primary">local_library</v-icon>
        <p class="pl-1 ma-0 caption">
          {{ post.readingTime }} {{ $t("min_read") }}
        </p>
      </div>
      <!-- <div class="d-flex align-center my-1">
        <v-avatar size="22">
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
        </v-avatar>
        <p class="pl-1 ma-0 caption">John Gomes</p>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "CardListPost",
  components: {},

  props: {
    post: {
      type: Object,
      require: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getImageUrl() {
      return this.post.featured && this.post.featured.url
        ? this.post.featured.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.post.featured && this.post.featured.url
        ? this.post.featured.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
  },
  methods: {
    goToDetail() {
      console.log(this.post)
      // alert(34)
      // this.$router.push({ name: "post" }, () => {});
      this.$router.push({ name: "postItem", params: {postId: this.post.id, post: this.post } }, () => {});
      // this.$router.go()
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  cursor: pointer;

  .block-left {
    width: 35%;
    padding: 8px 8px 8px 0;
  }

  .block-right {
    width: 65%;
    padding: 8px 0;
  }
}

.bold {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  &:hover {
    /* text-decoration: underline; */
    color: #f7b538;
  }
}
@media (max-width: 500px) {
}
</style>