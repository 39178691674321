<template>
  <div class="main-wrapper mb-4">
    <div>
      <div class="d-flex">
        <AuthorAvatar :size="40" :user="comment.author"></AuthorAvatar>

        <div class="comment-info ml-4">
          <div class="d-flex align-center">
            <h4 class="name">{{ comment.author | authorFullName }}</h4>
            <p class="body-2 ml-1 mb-0 date">
              {{ comment.created_at | fromNow(this.$i18n.locale) }}
            </p>
          </div>
          <div class="comment-text">
            {{ comment.text }}
          </div>
        </div>
        <div>
          <v-btn
            icon
            color="primary"
            small
            :loading="deleting == comment.id"
            v-if="isUserAuthor"
            @click="removeComment(comment.id)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthorAvatar from "../shared/AuthorAvatar";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CommentItem",
  components: {
    AuthorAvatar,
  },

  props: {
    comment: {
      type: Object,
      require: true,
    },
  },

  data: () => ({}),


  methods: {
     ...mapActions("comments", ["fetchRemoveComment"]),

    goToPage(path) {
      this.$router.push({ name: path }, () => {});
    },
    removeComment(value){
  
      this.fetchRemoveComment(value)
    }
   
  },

  computed: {
    ...mapGetters({
      userAuthenticaded: "users/getAccount",
      deleting: "comments/getDeleting",
    }),

    isUserAuthor() {
      return this.userAuthenticaded.id == this.comment.author.id;
    },
  },
};
</script>

<style scoped lang="scss" >
.main-wrapper {
  width: 100%;

  .name,
  .date {
    line-height: 1;
  }

  .date {
    color: gray;
  }
}
</style>
