<template>
  <v-form  class="form-wrapper">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="passwordsData.oldPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[ 
                  (v) => !!v || $t(`profile_page.form_rules.required`),
                  (v) => !!v && v.length <6 || $t(`profile_page.form_rules.min_8_characters`),
                  ]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            :label="$t(`profile_page.edit_password_form.current_password`)"
            :hint="$t(`profile_page.form_rules.at_least_min_8_characters`)"
            counter
            @click:append="show1 = !show1"
             
            autocomplete="on"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="passwordsData.password"
            autocomplete="on"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[ 
                  (v) => !!v || $t(`profile_page.form_rules.required`),
                  (v) => !!v && v.length <6|| $t(`profile_page.form_rules.min_8_characters`),
                  ]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            :label="$t(`profile_page.edit_password_form.new_password`)"
            :hint="$t(`profile_page.form_rules.at_least_min_8_characters`)"
            counter
            @click:append="show2 = !show2"
            required
            
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="passwordsData.passwordConfirmation"
            autocomplete="on"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[ 
                  (v) => !!v || $t(`profile_page.form_rules.required`),
                  (v) => !!v && v.length <6|| $t(`profile_page.form_rules.min_8_characters`),
                  ]"
            :type="show3 ? 'text' : 'password'"
            name="input-10-1"
            :label="$t(`profile_page.edit_password_form.confirm_password`)"
            :hint="$t(`profile_page.form_rules.at_least_min_8_characters`)"
            counter
            @click:append="show3 = !show3"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="row-center">
            <v-btn
              color="primary"
              :loading="loading == 'updatedUser'"
              @click="updateAccount('password')"
              >{{$t(`profile_page.btn_update_label`)}}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditPassword",
  components: {},

  props: {
    showModal: {
      type: Boolean,
      require: true,
      default: false,
    },
  },

  data: () => ({
    show1: false,
    show2: false,
    show3: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),

  methods: {
    ...mapActions("users", ["updateAccount"]),
  },

  computed: {
    ...mapGetters({
      passwordsData: "users/getPasswordsData",
      loading: "users/getLoading",
    }),
  },
};
</script>

<style scoped lang="scss" >
.form-wrapper {
  background-color: var(--v-background-base);
}

.main-wrapper {
  width: 100%;
  min-height: 50vh;
  max-width: 960px;
  background-color: var(--v-background-base);
  border-radius: 4px;
}

.row-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 400px) {
}
</style>