<template>
  <div class="main-wrapper">
    <v-form v-model="valid" ref="formIngredients">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="mb-2">
              {{ $t(`suggest_page.ingredient.ingredients`) }} ({{
                numberIngredients
              }})
            </p>
            <Ingredient
              v-for="(item, i) in ingredients"
              :key="i"
              :index="i"
            ></Ingredient>

            <div class="mt-2">
              <v-btn block color="primary" dark @click="addIngredient(index)">
                <v-icon>add</v-icon> {{ $t(`suggest_page.ingredient.add_new`) }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import Ingredient from "./Ingredient";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RecipeIngredients",
  components: {
    Ingredient,
  },

  props: {
    index: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    valid: false,
  }),

  mounted() {
    this.setRefFormIngredients(this.$refs.formIngredients);
  },

  methods: {
    ...mapActions("recipes", [
      "removeStepNewRecipe",
      "addIngredient",
      "setRefFormIngredients",
    ]),
  },
  computed: {
    ...mapGetters({
      ingredients: "recipes/getIngredientsNewRecipe",
    }),

    numberIngredients() {
      return this.ingredients.length;
    },
  },
};
</script>

<style scoped lang="scss" >
.main-wrapper {
  width: 100%;
  min-height: 50vh;
  max-width: 960px;
  /* background-color: white !important; // fix when add dark theme   */
  border-radius: 4px;
}

.asset-list {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;

  .asset-item {
    width: 9%;
    margin: 0.5% 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .btn-close {
      position: absolute;
      top: -8px;
      right: -8px;
      /* background-color: white; */
    }
  }

  .add-more {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    max-width: 50px;
    height: 50px;
    padding-top: 0;
    padding-left: 9px;
  }
}

@media (max-width: 400px) {
}
</style>