<template>
  <v-form v-model="valid" class="form-wrapper">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="row-center py-5">
            <AuthorAvatarEditBtn :user="userAuthenticated" :size="100"></AuthorAvatarEditBtn>
          </div>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            dense
            v-model="userAuthenticated.firstName"
            :rules="[ (v) => !!v || $t(`edit_user_form.first_name_error`)]"
            :label="$t(`edit_user_form.first_name_text`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            dense
            v-model="userAuthenticated.lastName"
            :rules="[ (v) => !!v || $t(`edit_user_form.last_name_error`)]"
            :label="$t(`edit_user_form.last_name_text`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            dense
            v-model="userAuthenticated.username"
            :rules="[ (v) => !!v || $t(`edit_user_form.username_error`)]"
            :label="$t(`edit_user_form.username_text`)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            
            dense
            v-model="userAuthenticated.email"
            :rules="[
                        (v) => !!v || $t(`login_page.signUp_email_error`),
                        (v) =>
                          (!!v &&
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            )) ||
                          $t(`login_page.invalid_email`),
                      ]"
            :label="$t(`edit_user_form.email_text`)"
            required
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="row-center">
            <v-btn color="primary" :loading="loading=='updatedUser'" @click="updateAccount('contact')">{{$t(`profile_page.btn_update_label`)}}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AuthorAvatarEditBtn from "../btn/AuthorAvatarEditBtn";
export default {
  name: "EditUser",
  components: {
    AuthorAvatarEditBtn
  },

  data: () => ({
    valid: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),

     methods: {
       ...mapActions("users", ["updateAccount"]),
     },

    computed:{
       ...mapGetters({
        userAuthenticated: "users/getAccount",
        loading: "users/getLoading",
      }),
    }
};
</script>

<style scoped lang="scss" >
.form-wrapper {
  background-color: var(--v-background-base);
}
.main-wrapper {
  width: 100%;

  max-width: 960px;
  background-color: var(--v-background-base);
  border-radius: 4px;
}

.row-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 400px) {
}
</style>
