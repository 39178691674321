<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="500px"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <!-- <v-card-title>
          <span class="headline">Use Google's location service?</span>
        </v-card-title> -->

<div class="card-header d-flex align-center justify-space-between">
  <div class="block-left"></div>
  <h3>Reviews</h3>
   <v-btn     color="white"
              text
              @click="emit()"
            >

              close
            </v-btn>
</div>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
           
         
        </v-card-actions> -->

        <Spinner v-if="loadind"></Spinner>
        <div class="pa-3">  
            <ReviewItem v-for="(item) in reviews" :key="item.id" :review="item" ></ReviewItem>

        </div>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ReviewItem from "./ReviewItem";
import Spinner from "../spinner/AppSpinner";

export default {
  name: "ReviewModal",

  components: {
      ReviewItem,
      Spinner
  },

  props:{
    recipeId:{
      type: String,
      require: true,
    },
    dialog:{
      type: Boolean,
      require: true,
    },
  },


  data: () => ({
     loadind: false,
  }),

   created(){
     this.loadind =true;
    if(this.recipeId)
      this.fetchReviews(this.recipeId)
        .then(() => this.loadind=false)
        .catch(() => this.loadind=false)
   },

    methods: {
        ...mapActions("reviews", ["fetchReviews"]),
        emit() {
          this.$emit('closeEvent')
        },
        
        // emit(item) {
        //   this.$emit('closeEvent', item)
        // }
    },

    computed: {
      ...mapGetters({
        reviews: "reviews/getReviews",
      }),
  },

};
</script>

<style scoped lang="scss">
  .user-info-review,
  .user-info-wrapper {
    width: 100%;
  }
  .card-header{
    background-color: #f7b538;
    width: 100%;
    height: 60px;
    .block-left{
      width: 83px;
    }
  }

</style>