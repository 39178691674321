import Vue from 'vue'
import Router from 'vue-router'
import Login from '../pages/Login.vue'
// import Intro from '../pages/Intro.vue'
import Profile from '../pages/Profile.vue'
import Recipe from '../pages/Recipe.vue'
import Home from '../pages/Home.vue'
import Search from '../pages/Search.vue'
import CreateRecipe from '../pages/CreateRecipe.vue'
import Restaurant from '../pages/Restaurant.vue'
import Post from '../pages/Post.vue'
import Blog from '../pages/Blog.vue'
import ListIngredients from '../pages/ListIngredients.vue'
import Category from '../pages/Category.vue'
import RestaurantIndex from '../pages/RestaurantIndex.vue'
import ViewAll from '../pages/ViewAll.vue'
import FindMyRestaurant from '../pages/FindMyRestaurant.vue'
import Reservations from '../pages/Reservations.vue'
import Settings from '../pages/Settings.vue'
import ContactUs from '../pages/Contact.vue'
import AboutUs from '../pages/About.vue'
import TermsAndConditions from '../pages/TermsAndConditions.vue'
import MealPlanner from "@/pages/MealPlanner";
import MealPlan from "@/components/meal-plan/MealPlan";
import DietPicker from "@/components/meal-plan/diet-picker/DietPicker";
import store from '@/store/store'
import Register from "@/pages/Register";
import ForgotPassword from "@/pages/ForgotPassword";
import Tag from "@/pages/Tag";
import Menu from "@/pages/Menu";
import GoogleCallback from "@/pages/GoogleCallback";
import FacebookCallback from "@/pages/FacebookCallback";
Vue.use(Router)


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // other options could be: 'reload', 'throw' and default to `throw` to avoid breaking changes
  duplicateNavigationPolicy: 'ignore',
  routes: [
    // {
    //   path: '/',
    //   name: 'intro',
    //   component: Intro,
    //   meta: { onlyGuestUser: true, layout: 'IntroLayout', }
    // },
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: { onlyGuestUser: true, layout: 'AuthLayout', }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: { onlyGuestUser: true, layout: 'AuthLayout', }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: { onlyGuestUser: true, layout: 'AuthLayout', }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/recipe',
      name: 'recipe',
      component: Recipe,
      meta: {onlyAuthUser: true},
      children: [
       
        {
          path: ':recipeId',
          name: 'recipeItem',
          component: Recipe,
        },
      ]
    },

    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {onlyAuthUser: true},
    },

    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {onlyAuthUser: true}
    },

    {
      path: '/createRecipe',
      name: 'createRecipe',
      component: CreateRecipe,
      meta: {onlyAuthUser: true}
    },

    {
      path: '/restaurant',
      name: 'restaurant',
      component: Restaurant,
      children: [
        {
          path: ':restaurantId',
          name: 'restaurantItem',
          component: Restaurant,
        },
      ],
      meta: {onlyAuthUser: true}
    },

    {
      path: '/restaurant-menu',
      name: 'restaurantMenu',
      component: Menu,
      children: [
      
        {
          path: ':id',
          name: 'menuItem',
          component: Menu,
        },
      ]
    },

    {
      path: '/restaurants',
      name: 'restaurants',
      component: RestaurantIndex,
      meta: {onlyAuthUser: true}
    },

    {
      path: '/blog',
      name: 'blog',
      component: Blog
    },

    {
      path: '/post',
      name: 'post',
      component: Post,
      children: [
       
        {
          path: ':postId',
          name: 'postItem',
          component: Post,
          meta: {
            reload: true,
          },
        },
      ],
      meta: {
        reload: true,
      },
     
    },
   
    {
      path: '/listIngredients',
      name: 'listIngredients',
      component: ListIngredients,
      meta: {onlyAuthUser: true}
    },

    // {
    //   path: '/category',
    //   name: 'category',
    //   component: Category,
    //   meta: {onlyAuthUser: true}
    // },
    {
      path: '/category',
      name: 'category',
      component: Category,
      meta: {onlyAuthUser: true},
      children: [
       
        {
          path: ':id',
          name: 'categoryItem',
          component: Category,
        },
      ]
    },
    {
      path: '/tag',
      name: 'tag',
      component: Tag,
      meta: {onlyAuthUser: true},
      children: [
       
        {
          path: ':id',
          name: 'tagItem',
          component: Tag,
        },
      ]
    },

    {
      path: '/viewAll',
      name: 'viewAll',
      component: ViewAll,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/findMyRestaurant',
      name: 'findMyRestaurant',
      component: FindMyRestaurant,
      meta: {onlyAuthUser: true}
    },

    {
      path: '/reservations',
      name: 'reservations',
      component: Reservations,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: ContactUs,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: AboutUs,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsAndConditions,
      meta: {onlyAuthUser: true}
    },

    {
      path: '/meal-planner',
      name: 'mealPlanner',
      component: MealPlanner,
      meta: {onlyAuthUser: true},
      children: [
        {
          path: '',
          redirect: { name: 'mealPlanner.mode',  }
        },
        {
          path: 'mode',
          name: 'mealPlanner.mode',
          component: DietPicker
        },
        {
          path: 'mode/:modeId',
          name: 'mealPlanner.plan',
          component: MealPlan
        }
      ]
    },

    {
      path: '/auth/google/callback',
      name: '/auth/google/callback',
      component: GoogleCallback,
      meta: { onlyGuestUser: true,  layout: 'LoadingLayout', }
    },
    {
      path: '/auth/facebook/callback',
      name: '/auth/facebook/callback',
      component: FacebookCallback,
      meta: { onlyGuestUser: true,  layout: 'LoadingLayout', }
    },

    {
      path: '*',
      beforeEnter: (to, from, next) => { next('/') }, 
      // name: '/home',
      // component: Home,
      // meta: {onlyAuthUser: true}
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
     
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
  
})

router.beforeEach((to, from, next) => {

  store.dispatch('auth/getAuthUser')
    .then(() => {
      const isAuthenticated = store.getters['users/getAccount']

      if (to.meta.onlyAuthUser) {
        if (isAuthenticated) {
          next()
        } else {
          const redirectPath = window.location.pathname;
          localStorage.setItem("redirectPath", redirectPath)
          next({ name: 'login', query: { from: redirectPath } });
        }
      } else if (to.meta.onlyGuestUser) {
        if (isAuthenticated) {
          next({name: 'home'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
})

export default router
