import axiosInstance from '@/services/axios'
import { getCategoriesFromMenu, containsArray } from '@/helpers';

export default {
  namespaced: true,

  state: {
    items: [],
    item:{},
    menusByCategories:[],
    menusByCategoriesDefault:[
      { name: 'appetisers', categoryIDs: [14, 39], menus:[] }, //Appetisers
      { name: 'desserts',  categoryIDs: [9], menus:[] }, //Desserts
      { name: 'drinks',  categoryIDs: [40], menus:[] }, //Drinks
      { name: 'salads',  categoryIDs: [16], menus:[] }, //Salads
      { name: 'soups',  categoryIDs: [15], menus:[] }, //Soups
      { name: 'sides',  categoryIDs: [17], menus:[] }, //Sides
      { name: 'vegetarian',  categoryIDs: [37], menus:[] }, //Vegetarian
      { name: 'main_course',  categoryIDs: [9, 12, 11, 18], menus:[] }, // Main menu (Breakfast, Dinner, Lunch, Pizza)
      { name: 'dish_of_the_day',  categoryIDs: [], menus:[] }, // 
    ]
},

  getters: {

    getMenus (state) {
      return state.items 
    },

    getMenu (state) {
      return state.item 
    },

    getMenuByCategories (state) {
      return state.menusByCategories 
    },
  
  },

  actions: {

    fetchMenus ({state, commit}, restaurantId) {

      commit('setItems', {resource: 'menus', items: []}, {root: true})
      commit('setMenusByCategories',  [])
      // return axiosInstance.get(`/restaurant-menus?_sort=created_at:ASC&author=${id}&_limit=10`)
      // return axiosInstance.get('/restaurant-menus?_sort=created_at:ASC&restaurant=4&_limit=10')
      return axiosInstance.get(`/restaurant-menus?restaurant=${restaurantId}`)
      // return axiosInstance.get('/restaurant-menus?_sort=created_at:ASC&_limit=10')
      

        .then(res => {
          const menus = res.data
           
           commit('setItems', {resource: 'menus', items: menus}, {root: true})
           commit('setMenusByCategories',  menus)
 
          return state.items
        })
        .catch( err => {console.log(err)})
    },

    fetchMenu ({state, commit}, restaurantMenuId) {

      return axiosInstance.get(`/restaurant-menus/${restaurantMenuId}`)
     
        .then(res => {
          const menu = res.data
           
           commit('setItem', {resource: 'menus', item: menu}, {root: true})
 
          return state.items
        })
        .catch( err => {console.log(err)})
    },

  
  },

  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },

    setMenusByCategories(state, list){
      
      const items = [ ...state.menusByCategoriesDefault];
      // const items = [];
     

      list.forEach((item)=>{
        
        const menuCategories = getCategoriesFromMenu(item);

        for(let i = 0; i < items.length; i++){
           const result = containsArray (items[i].categoryIDs, menuCategories);
           if(result){
             let arrayMenuFiltered = items[i].menus.filter( menu => menu.id === item.id)
             if(arrayMenuFiltered.length === 0){
              items[i].menus.push(item);
             }
            
            break;
         }
        }

        
      })

      state.menusByCategories = [...items];

    }
  }
}