 
export default {

  data: () => ({
    scvDarkTheme : "sabores-cv-dark-theme"
  }),

  computed: {
    
    isLightTheme(){
      return !this.$vuetify.theme.dark;
    },

    isDarkTheme(){
      return this.$vuetify.theme.dark;
    },
  },


  methods: {
    
    setTheme(value){
      if(this.$vuetify.theme.dark !== value){
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem(this.scvDarkTheme, this.$vuetify.theme.dark.toString());

      }
    },

    checkTheme(){
      const theme = localStorage.getItem(this.scvDarkTheme);
      if (theme) {
          if (theme == "true") {
              this.$vuetify.theme.dark = true;
          } else {
              this.$vuetify.theme.dark = false;
          }
      }
    }
  },

}