<template>
  <v-card class="mx-auto my-card" width="100%" @click="goTodetail()">
    <v-skeleton-loader
      v-bind="attrs"
      type="card"
      min-width="100%"
      transition="fade-transition"
      v-if="loading"
    ></v-skeleton-loader>

    <v-img
      :src="getImageUrl"
      :lazy-src="getLazyImageUrl"
      class="image"
      height="170"
      width="100%"
      v-if="!loading"
    ></v-img>

    <div
      v-if="!loading"
      class="
        d-flex
        justify-space-between
        align-center
        py-2
        px-1 px-sm-2 px-md-3
        card-info
      "
    >
      <div :class="'body-2 my-card-title px-1 pt-1'">
        {{ recipe.name}}
      </div>

      <div>
        <v-rating
          class="rating"
          x-small
          dense
          half-increments
          v-model="rating"
          background-color="gray"
          readonly
        ></v-rating>
      </div>
      

         <button :content="statusValue" v-tippy class="btn-status" v-if="showStatus">
            <v-icon v-if="recipeStatus==='awaiting_approval'" dark color="primary" size="23">hourglass_empty</v-icon>
            <v-icon v-else-if="recipeStatus==='rejected'" dark color="error" size="25">highlight_off</v-icon>
            <v-icon v-else   dark color="success" size="25">check_circle_outline</v-icon>

        </button>
    </div>
  </v-card>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "Card-3",

  props: {
    type: {
      type: String,
      require: "rating",
    },

    recipe: {
      type: Object,
      require: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
    attrs: {
      class: "mb-0",
      boilerplate: false,
      elevation: 0,
    },
  }),

  created() {
  },

  computed: {
    rating() {
      return this.recipe.rating || 0;
    },

    lang() {
      return this.$i18n.locale;
    },

    getImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    recipeStatus(){
      return this.recipe.status;
    },

    statusValue(){
      return this.$t(`recipe_status.${this.recipe.status}`);
    }
  },

  methods: {
    ...mapActions("search", ["setSearchActive"]),
    goTodetail() {
      this.$router.push(
        { name: "recipeItem", params: { recipeId: this.recipe.id } },
        () => {}
      );
      this.setSearchActive(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-card {
  cursor: pointer;
  border-radius: 24px !important;
  background-color: var(--v-background-darken2);
}

.card-info {
  min-height: 50px;
}
.value {
  color: gray;
}

.my-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1;
  /* min-height: 40px; */
}
.rating {
  button {
    padding: 0 !important;
    font-size: 10px;
  }
}

.btn-status{
  position: absolute;
  top: 5px;
  right: 10px;
}
</style>