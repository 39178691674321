import { mapGetters } from "vuex";

export default {
   
  data: () => ({
    author:null,
  }),

    methods:{
      goToAuthorPage() { 
        this.$router.push({ name: 'profile', params: { "author": this.author }},() => {});
        this.setSearchActive(false)
      },
    },

    computed:{

      author_authorPhoto() {
        return  this.author &&  this.author.avatar
          ? this.author.avatar.url
          : null;
      },

      author_authorPhotoThumbnail() {
        return  this.author &&  this.author.avatar
          ? this.author.avatar.formats.thumbnail.url
          : null;
      },

      author_isAuthorPhotoExist() {
        return  this.author &&  this.author.avatar;
      },

      author_LettersName() {
        if(!this.author) return '';
        return (this.author?.firstName?.substring(0, 1) || "") +" "+ (this.author?.lastName?.substring(0, 1) || "")
      },

      author_fullName() {
        if(!this.author) return '';
        return   (this.author.firstName || "") +" "+ (this.author.lastName || "")
      },

      author_username() {
        if(!this.author) return '';
          return   (this.author.username || "")
      },

      author_isCurrentUserAuthenticated() {
        if(!this.author || !this.userAuthenticated) return false;
        return   (this.author.id == this.userAuthenticated.id)
      },

      ...mapGetters({
        userAuthenticated: "users/getAccount",
      }),

    }

};
  