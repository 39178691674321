<template>
  <div class="form-wrapper">
    <v-container width="100%" class="main-wrapper">
      <v-row>
        <v-col cols="6" class="col-scv">
          <div
            :class="isLightTheme ? 'selected pt-btn' : 'pt-btn'"
            @click="setTheme(false)"
          >
         <v-icon :size="$vuetify.breakpoint.mobile?60:100" >
            light_mode 
          </v-icon>
          </div>
        </v-col>

        <v-col cols="6" class="col-scv">
          <div
            :class="isDarkTheme ? 'selected en-btn' : 'en-btn'"
            @click="setTheme(true)"
          >
          <v-icon  :size="$vuetify.breakpoint.mobile?60:100" >
            dark_mode
          </v-icon>
</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import themeMixins from "@/mixins/theme";

export default {
  name: "EditTheme",
  components: {},

  mixins: [themeMixins],
 
};
</script>

<style scoped lang="scss" >
.form-wrapper {
  background-color: var(--v-background-base);


  h4 {
    color: #000000de;
  }
  .col-scv {
    width: 100%;
    height: 100%;
  }

  .pt-btn,
  .en-btn {
    background-size: cover;
    width: 250px;
    height: 150px;
    opacity: 0.5;
    /* border-radius: 50%; */
    cursor: pointer;
    transition: all 0.5s ease;
    background-color: var(--v-primary-base);
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected {
      border: 2px solid $primary;
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }

  /* .pt-btn {
    background-image: url("../../assets/flags/portugal_flag.svg");
  }
  .en-btn {
    background-image: url("../../assets/flags/uk_flag.svg");
  } */

  @media (max-width: 500px) {
    .pt-btn,
    .en-btn {
      max-width: 150px;
      height: 80px;
    }
  }
}
</style>