<template>
  <v-container class="pa-0 ml-md-10" fluid>
    <v-row no-gutters class="main-wrapper">
  
      <v-col cols="12"   >
   
        
     
      <div class="mb-4">
          <v-icon size="18" color="primary">filter_alt</v-icon>
          <span>Filters</span>     
      </div>
      

         
        <div class="pl-1"> 
   
          <div class="mb-4">
            <h3>Delivery Available</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

          <div class="mb-4">
            <h3>Near me</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

          <div class="mb-4">
            <h3>Outdoor Seating Available</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

          <div class="mb-4">
            <h3>Moderately Priced</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

          <div class="mb-4">
            <h3>Cheap Eats</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

          <div class="mb-4">
            <h3>Breakfast</h3>
            <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>
          </div>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import CarouselRestaurant from "../components/carousel/CarouselRestaurant";
import { mapActions, mapGetters } from "vuex";
export default {
  title: "Multiple slides per view",
  name: "RestaurantIndex",
  components: {
    CarouselRestaurant,
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters({
      restaurants: "restaurants/getRestaurants",
    }),
  },

  created() {

    Promise.all([ this.fetchRestaurants(),
                ])
      .then(() =>
        // this.pageLoader_resolveData()
        {console.log("this.posts home")
        console.log(this.posts)}

      )
      .catch((err) => {
        console.error(err);
        // this.pageLoader_resolveData()
      });

  },

  methods: {
    ...mapActions("restaurants", ["fetchRestaurants"]),

  },

};
</script>

<style scoped lang="scss">

.main-wrapper {
  margin-top: 32px;
   width: 100%;
   height: 100%;
}




</style>