// components/LoadingLayout.vue
// use this layout for loading pages 

<template>
     <v-container class="loading d-flex justify-center align-center">
         <GifLoading></GifLoading>
         <router-view />
       </v-container>
</template>

<script>
import GifLoading from '../components/spinner/GifLoading';


export default {
    name: "LoadingLayout",
    components: {
      GifLoading

  },

  data: () => ({
    loading:true,
    layout: null,
  }),
 
    
}
</script>

<style lang="scss">

  .loading{
    width: 100vw;
    height: 100vh;
  }

</style>
