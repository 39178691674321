<template>
  <div >
    <Banner :title="$t('settings_page.title')"></Banner>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="m-auto">
        <div class="config-wrapper wrapper-1 ml-md-3 mt-sm-6 mt-md-0 py-6">
          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="showModal(item.modal)"
              class="list-item"
            >
              <v-list-item-icon>
                <v-icon color="primary" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  v-t="$t(`profile_page.${item.text}`)"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row> 

    <v-overlay :value="overlay"  dark="isDarkTheme"  class="test-78">
      <div class="card-header d-flex align-center justify-space-between m-0">
        <div class="block-left"></div>
        <h3 v-if="modal == 'editUser'">{{ $t(`profile_page.edit_user`) }}</h3>
        <h3 v-if="modal == 'changePassword'">
          {{ $t(`profile_page.change_password_text`) }}
        </h3>
        <h3 v-if="modal == 'changeAddress'">
          {{ $t(`profile_page.change_address_text`) }}
        </h3>
        <h3 v-if="modal == 'changeLanguage'">
          {{ $t(`profile_page.select_language`) }}
        </h3>
        <h3 v-if="modal == 'changeTheme'">
          {{ $t(`profile_page.change_theme_text`) }}
        </h3>
        <v-btn color="white" icon text @click="overlay = false">
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <EditUser v-if="modal == 'editUser'"></EditUser>
      <EditPassword v-if="modal == 'changePassword'"></EditPassword>
      <EditAddress v-if="modal == 'changeAddress'"></EditAddress>
      <EditLanguage v-if="modal == 'changeLanguage'"></EditLanguage>
      <EditTheme v-if="modal == 'changeTheme'"></EditTheme>
    </v-overlay>
  </div>
</template>

<script>
import Banner from "@/components/banner/Banner";
import EditUser from "@/components/form/EditUser";
import EditTheme from "@/components/form/EditTheme";
import EditPassword from "@/components/form/EditPassword";
import EditAddress from "@/components/form/EditAddress";
import EditLanguage from "@/components/form/EditLanguage";
import authorMixins from "@/mixins/author";
import resizeMixins from "@/mixins/resize";
import themeMixins from "@/mixins/theme";

export default {
  title: "Settings",
  name: "Settings",
  components: {
    Banner,
    EditUser,
    EditTheme,
    EditPassword,
    EditAddress,
    EditLanguage,
  },


  data: () => ({
    overlay: false,
    modal: "editUser",
    items: [
      {
        text: "edit_user",
        icon: "manage_accounts",
        modal: "editUser",
      },
      { text: "change_password_text", icon: "lock", modal: "changePassword" },
      {
        text: "change_language_text",
        icon: "language",
        modal: "changeLanguage",
      },
      {
        text: "change_theme_text",
        icon: "dark_mode",
        modal: "changeTheme",
      },
      {
        text: "change_address_text",
        icon: "location_on",
        modal: "changeAddress",
      },
    ],
  }),


  mixins: [authorMixins, resizeMixins, themeMixins],

  methods: {
    showModal(value) {
      this.modal = value;
      this.overlay = !this.overlay;
    },
  },
};
</script>

<style scoped lang="scss">
.card-header {
  background-color: var(--v-primary-base);
  width: 100%;
  height: 60px;
  .block-left {
    width: 0px;
  }
}
.list-item {
  border-bottom: 1px solid gray;
  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wrapper-1 {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 20px 20px 26px 20px;

  margin: auto;
  border-radius: 8px;
  position: relative;
  /* max-width: 304px; */
}

.config-wrapper {
   background-color: var(--v-background-base);
  padding: 8px;
}

@media (max-width: 960px) {
  .wrapper-1 {
    max-width: 100%;
  }
}
</style> 