<template>
  <div class="pa-3" v-resize="onResize">
    <v-row no-gutters class="" v-if="author_isCurrentUserAuthenticated">
      <v-col cols="12" sm="12" md="6">
        <div class="user-info-wrapper wrapper-1 mr-md-3">
          <div class="container-1">
            <div class="block-left">
              <!-- <v-img class="user-img" src="../../assets/profile.jpg"></v-img> -->
              <v-avatar color="orange" :size="windowSize.x > 960 ? 90 : 200">
                <span
                  v-if="!author_isAuthorPhotoExist"
                  class="white--text avatar-text"
                  >{{ author_LettersName }}</span
                >
                <img
                  v-if="author_isAuthorPhotoExist"
                  :src="author_authorPhoto"
                  :alt="author_fullName"
                />
              </v-avatar>
            </div>

            <div class="block-right">
              <h4 class>{{ author_fullName }}</h4>
              <p class="username">@{{ author_username }}</p>
              <!-- <v-btn class="caption" text small color="primary">edit</v-btn> -->
              <a class="body-2" @click.prevent="showModal('editUser')">
                <v-icon class="mr-1" size="16" color="primary"
                  >mdi-pencil</v-icon
                >
                <span>Edit</span>
              </a>
            </div>
          </div>

          <div class="container-2">
            <a
              :class="contentToShow == 2 ? 'wrap-value active' : 'wrap-value'"
              @click.prevent="setContent(2)"
            >
              <div class="value primary--text">{{ total.favorites }}</div>
              <div class="text">{{ $t(`profile_page.favorites`) }}</div>
            </a>

            <a
              :class="contentToShow == 1 ? 'wrap-value active' : 'wrap-value'"
              @click.prevent="setContent(1)"
            >
              <div class="value primary--text">{{ total.recipes }}</div>
              <div class="text">{{ $t(`profile_page.my_recipes`) }}</div>
            </a>

            <a
              :class="contentToShow == 3 ? 'wrap-value active' : 'wrap-value'"
              @click.prevent="setContent(3)"
            >
              <div class="value primary--text">{{ total.posts }}</div>
              <div class="text">{{ $t(`profile_page.posts`) }}</div>
            </a>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <div class="config-wrapper wrapper-1 ml-md-3 mt-sm-6 mt-md-0">
          <v-list>
            <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              @click="goToPage(item.href)"
              class="list-item"
            >
              <v-list-item-icon>
                <v-icon color="primary" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-t="$t(`${item.text}`)"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="" v-if="!author_isCurrentUserAuthenticated">
      <v-col cols="12">
        <div class="user-info-wrapper any-user-info-wrapper wrapper-1 mr-md-3">
          <div class="container-1">
            <div class="block-left">
              <v-avatar color="orange" :size="windowSize.x > 960 ? 90 : 200">
                <span
                  v-if="!author_isAuthorPhotoExist"
                  class="white--text body-2"
                  >{{ author_LettersName }}</span
                >
                <img
                  v-if="author_isAuthorPhotoExist"
                  :src="author_authorPhoto"
                  :alt="author_fullName"
                />
              </v-avatar>
            </div>

            <div class="block-right">
              <h4 class>{{ author_fullName }}</h4>
              <p class="username">@{{ author_username }}</p>
              <!-- <v-btn class="caption" text small color="primary">edit</v-btn> -->
              <a class="body-2" @click.prevent="showModal('editUser')">
                <v-icon class="mr-1" size="16" color="primary"
                  >mdi-pencil</v-icon
                >
                <span>Edit</span>
              </a>
            </div>
          </div>

          <div class="container-2 card-any-user">
            <a
              :class="contentToShow == 1 ? 'wrap-value active' : 'wrap-value'"
              @click.prevent="setContent(1)"
            >
              <div class="value">{{ total.recipes }}</div>
              <div class="text">{{ $t(`profile_page.my_recipes`) }}</div>
            </a>

            <a
              :class="contentToShow == 3 ? 'wrap-value active' : 'wrap-value'"
              @click.prevent="setContent(3)"
            >
              <div class="value">{{ total.posts }}</div>
              <div class="text">{{ $t(`profile_page.posts`) }}</div>
            </a>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-overlay :value="overlay" dark="isDarkTheme">
      <div class="card-header d-flex align-center justify-space-between m-0">
        <div class="block-left"></div>
        <h3 v-if="modal == 'editUser'">{{ $t(`profile_page.edit_user`) }}</h3>

        <v-btn color="white" icon text @click="overlay = false">
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <EditUser v-if="modal == 'editUser'"></EditUser>
    </v-overlay>
  </div>
</template>

<script>
import EditUser from "../form/EditUser";
import authorMixins from "../../mixins/author";
import resizeMixins from "../../mixins/resize";
import themeMixins from "@/mixins/theme";

export default {
  title: "Multiple slides per view",
  name: "ProfileSidebar",
  components: {
    EditUser,
  },

  props: {
    contentToShow: {
      type: Number,
      require: true,
    },
    total: {
      type: Object,
      require: true,
    },
    authorProp: {
      type: Object,
      require: true,
    },
  },

  data: () => ({
    overlay: false,
    modal: "editUser",

    menuItems: [
      {
        text: "drawer.my_reservations",
        icon: "event_note",
        href: "reservations",
      },
      // {
      //   text: "drawer.planning_menus",
      //   icon: "fastfood",
      //   href: "mealPlanner.mode",
      // },
      { text: "drawer.add_recipe", icon: "add_box", href: "createRecipe" },
    ],
  }),

  created() {
    this.author = this.authorProp;
  },

  mixins: [authorMixins, resizeMixins, themeMixins],
  

  methods: {
    showModal(value) {
      this.modal = value;
      this.overlay = !this.overlay;
    },

    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" }, () => {});
    },

    setContent(value) {
      this.$emit("setContentToShow", value);
    },

    goToPage(path) {
      this.$router.push({ name: path }, () => {});
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-text {
  font-size: 32px;
}

.card-header {
  background-color: $primary;
  width: 100%;
  height: 60px;
  .block-left {
    width: 0px;
  }
}
.list-item {
  border-bottom: 1px solid gray;
  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wrapper-1 {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 20px 20px 26px 20px;
  background-color: var(--v-background-base);

  margin: auto;
  border-radius: 8px;
  position: relative;
  /* max-width: 304px; */
}

.user-info-wrapper {
  background-color: var(--v-background-base);

  .container-1 {
    display: flex;

    .block-right {
      padding-left: 16px;
      p {
        padding: 0;
        margin: 0;
      }
      .username {
        color: #707070;
      }
    }
  }

  .container-2 {
    
    border-top: 1px solid gray;
    margin-top: 16px;
    padding-top: 8px;
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
    &.card-any-user {
      max-width: 400px;
    }

    .wrap-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      .value {
        color: #030f09;
        font-size: 20px;
        font-weight: bold;
      }
      .text {
        color: #707070;
        cursor: pointer;
      }
      &.active {
        .text {
          border-bottom: 2px solid #f7b538;
        }
      }
    }
  }
}
.config-wrapper {
  background-color: var(--v-background-base);
  padding: 8px;
}

.any-user-info-wrapper {
  align-items: center;
  .container-2 {
    width: 100%;
    justify-content: space-around;
  }
}
.user-img {
  border-radius: 50%;
  max-width: 70px;
  max-height: 70px;
}


@media (max-width: 960px) {
  .avatar-text {
    font-size: 60px;
  }

  .wrapper-1 {
    max-width: 100%;
  }

  .user-info-wrapper {
    .container-1 {
      flex-direction: column;
      align-items: center;
      .block-right {
        text-align: center;
      }
    }

    .container-2 {
      justify-content: space-around;
    }
  }

  .user-img {
    max-width: 100px;
    max-height: 100px;
  }
}
</style> 