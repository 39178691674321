<template>
  <v-autocomplete
    v-model="filters.categoriesSelected"
    :disabled="isUpdating"
    :items="items"
    chips
    color="blue-grey lighten-2"
    :label="$t(`filter_page.category_text`)"
    item-text="name"
    item-value="id"
    multiple
    dense
    @change="updateFilter()"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="filters.categoriesSelected"
        close
        @click="data.select"
        @click:close="removeItem(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.image.url"></v-img>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="isObject(data)" >
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="getImageURL(data)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <!-- <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle> -->
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapActions, mapGetters} from "vuex";

export default {
  name: "AutocompleteFilter",
  
  props:{

  isUpdating: {
        type: Boolean,
        default: () => false,
      },
    },

  data() {
    return {  
    };
  },

  created(){
    this.items = this.categories;
  },

  watch: { 
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },

  computed: {
    
     ...mapGetters({
        filters: "search/getFilters",
        recipe: "recipes/getNewRecipe",
        categories: "categories/getCategories",
      }),

  },

  methods: {
      ...mapActions("search", ["removeCategory", "setSearchText"]),
      ...mapActions("recipes", ["removeCategoryNewRecipe"]),

      getImageURL(data){
              return data?.item?.image && data.item.image.url ? data.item.image.url : require(process.env.VUE_APP_IMAGE_PLACEHOLDER);
            },
      isObject(data){
              return typeof data.item !== 'object'
            },

      updateFilter(){
        this.setSearchText()
      },

         removeItem(item) {
          this.removeCategory(item)
          this.setSearchText()

      },
  }, 



};
</script>

<style scoped>

@media (max-width: 400px) {
}
</style>