<template>
  <div
    class="mx-auto"
    max-width="400"
  >
    <div class="slider-wrapper">
     <div class="text-center dist-wrapper">
          <span
            class="text-h3 font-weight-light py-0 my-0 white--text dist-value"
            v-text="value"
          ></span>
          <span class="subheading font-weight-light mr-1 white--text">km</span>
          
        </div>

      <v-slider
        v-model="value"
        :color="color"
        track-color="grey"
        always-dirty
        min="1"
        max="150"
        hide-details 
      >
        <template v-slot:prepend>
          <v-icon
            :color="color"
            @click="decrement"
          >
            mdi-minus
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon
            :color="color"
            @click="increment"
          >
            mdi-plus
          </v-icon>
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

  export default {

     name: "RestaurantSearchSlider",

    data: () => ({
      // value: 10,
      interval: null,
      isPlaying: false,
    }),

    computed: {
      color () {
        
        return 'white'
      },

         ...mapGetters({
            mapSearchDistance: "restaurantSearch/getMapSearchDistance",
        }),

        value: {
      // getter
          get: function () {
            return this.mapSearchDistance;
          },
          // setter
            set: function (newValue) {
              this.setMapSearchDistance(newValue);
              this.fetchMapSearch();
            },
    },
    },

    methods: {
      decrement () {
        this.value--
        this.fetchMapSearch()
      },
      increment () {
        this.value++
        this.fetchMapSearch();
      },
      toggle () {
        this.isPlaying = !this.isPlaying
      },

      ...mapActions("restaurantSearch", ["setMapSearchDistance", "fetchMapSearch"]),
    },
  } 
</script>
<style scoped lang="scss" >
@keyframes metronome-example {
    from {
      transform: scale(.5);
    }

    to {
      transform: scale(1);
    }
  }

  .v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }



  @media (max-width: 960px) {  
    .slider-wrapper{
      display: flex;
      align-items: center;
    }

    .dist-wrapper{
      order: 2;
      padding-left: 1rem;
      .dist-value{
          font-size: 1rem!important;
      };
    }


}
</style>