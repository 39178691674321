<template>
<div>
   
     <div class="pb-8">
      
      <!-- <div>
        <CarouselMenu :menus="menuGroup.menus" />
      </div> -->
      <v-row>
        <v-col  md="4" sm="6" xs="12" v-for="item in otherProducts" :key="item.id">
          <div class="card-menu" >
            <CardProduct :product="item" /> 
            <!-- <h1>{{item.name}}</h1> -->
          </div>
        </v-col>
      </v-row>
    </div>
  
  </div>
</template>

<script>
import CardProduct from "../../components/card/CardProduct";

export default {
  title: "Grid Restaurant Prod",
  name: "GridRestaurantProducts",
  components: {
    CardProduct,
  },

  props: {
    otherProducts: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({

  }),

  methods: {
 
    getImageUrl(assets) {
      return assets && assets.length > 0
        ? assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl(assets) {
      return assets && assets.length > 0
        ? assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

 
  },

};
</script>

<style scoped lang="scss">

</style>
