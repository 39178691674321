import { render, staticRenderFns } from "./TabAdditionalInfo.vue?vue&type=template&id=cb03276e&scoped=true&"
import script from "./TabAdditionalInfo.vue?vue&type=script&lang=js&"
export * from "./TabAdditionalInfo.vue?vue&type=script&lang=js&"
import style0 from "./TabAdditionalInfo.vue?vue&type=style&index=0&id=cb03276e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb03276e",
  null
  
)

export default component.exports