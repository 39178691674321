<template>

<v-container
    
    style="height: 38px; width:38px; padding:0;"
    class="pa-a ma-0"
  >
    <v-row justify="center" class="row-container">
      <v-menu
        bottom
        min-width="220px"
        rounded
        offset-y
        
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            style="height: 38px; width:38px;"
            v-on="on"
          >
            <v-avatar
              color="warning" 
              :size="size"
            >
              <span v-if="!author_isAuthorPhotoExist" class="white--text  avatar-text">{{ author_LettersName }}</span>
               <img
                v-if="author_isAuthorPhotoExist"
                :src="author_authorPhoto"
                :alt="author_fullName" 
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="orange" 
              >
                <span v-if="!author_isAuthorPhotoExist"  class="white--text text-h6">{{ author_LettersName }}</span>
                 <img
                    v-if="author_isAuthorPhotoExist"
                    :src="author_authorPhoto"
                    :alt="author_fullName" 
                  />
              </v-avatar>
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1 mb-0">
                {{ user.email }}
              </p>
                <!-- <p class="text-caption mt-0">
                @{{ author_username }}
              </p> -->
              
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                :to="{ name: 'profile' }"
              >
              <v-icon left>
                person
              </v-icon>
                 {{$t('dropdown_menu.profile')}}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                :to="{ name: 'settings' }"
              >
               <v-icon left>
                settings
              </v-icon>
                 {{$t('dropdown_menu.settings')}}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="logout()"

              >
               <v-icon left>
                logout
              </v-icon>
                {{$t('dropdown_menu.logout')}}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>

</template>

<script>
import authorMixins from "../../mixins/author";
import { mapGetters } from "vuex";

export default {
  name: "AuthorAvatarMenu",
  components: {},

  data: () => ({
     
    }),

  props: {
    user: {
      type: Object,
      require: true,
    },

    size: {
      type: Number,
      default: 32,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authorMixins],

  mounted() {
    this.author = this.user;
  },

  computed: {
    ...mapGetters({
      userAuthenticated: "users/getAccount",
    }),
  },
   methods: {
   

    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" }, () => {});
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-text{
  letter-spacing: 0;
}
.v-btn{
  text-transform: unset !important;
}

.row-container{
  margin: 0!important;
}
 


</style>