<template>
  <div class="locale-changer">
    
        <span v-if="isDarkTheme"  role="button"  @click="setTheme(false)">
          <v-icon size="20" color="primary" >
            light_mode
          </v-icon>
         </span>
        <span v-if="isLightTheme" role="button"  @click="setTheme(true)">
          <v-icon size="20" color="primary">
            dark_mode
          </v-icon>
        </span>
  
  </div>
</template>

<script>
import themeMixins from "@/mixins/theme";

export default {
  name: "ThemeChanger",

  mixins: [themeMixins],


};
</script>

<style scoped lang="scss" >
select {
  color: white;
  outline: none;
}
</style>