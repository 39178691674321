<template>
  <v-bottom-navigation
    :value="activeBtn"
    grow
    color="white"
    class="custom-bottom-nav"
    background-color="secondary"
    dark
  >

    <v-btn 
      v-for="item in itemsList"
      :key="item.id"
      @click="goToPage(item.href)"
      
      >
      <span class="nav-btn-text">{{$t(item.title)}}</span>
      <v-icon >{{item.icon}}</v-icon>
    </v-btn>

  </v-bottom-navigation>
</template>

<script>

import { mapActions } from "vuex";

export default {
  name: "BottomNav",
  components: {},

  data: () => ({
    show1: false,
    activeBtn:0,
    itemsList: [
      { title: "drawer.home", icon: "home", href: "home" },
      // {
      //   title: "drawer.plan_menus",
      //   icon: "fastfood",
      //   href: "mealPlanner.mode",
      // },

      {
        title: "drawer.find_restaurants",
        icon: "location_on",
        href: "findMyRestaurant",
      },

      {
        title: "drawer.reservations",
        icon: "event_note",
        href: "reservations",
      },

      // { title: "drawer.add_recipe", icon: "add_box", href: "createRecipe" },

      { title: "drawer.blog", icon: "pages", href: "blog" },
      // { title: "drawer.settings", icon: "account_circle", href: "profile" },
    ],
  }),


  computed: {
    widthValue() {
      return this.$vuetify.breakpoint.width;
    },
  },

  methods: {
    goToPage(path) {
      this.setSearchActive(false);
      this.$router.push({ name: path }, () => {});
    },
    ...mapActions("search", ["setSearchActive"]),

  },
};
</script>

<style scoped lang="scss" >

  .custom-bottom-nav {
    position: fixed;
    z-index: 99999;
  }

@media (max-width: 768px) {
  .nav-btn-text {
    display: none;
  }
}
</style>
