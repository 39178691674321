<template>
  <v-container
    fluid
    class="d-flex pa-0 flex-column container-wrapper-step content-wrapper"
  >
    <div class="container-body pt-0">
      <!-- <h3 class="pb-5">How to cook</h3> -->

      <div class="steps-wrap d-flex flex-column">
        <div class="d-flex pb-4" v-for="(item, index) in steps" :key="item.id">
          <div class="pr-4 d-flex flex-column">
            <div class="text-index" v-if="!item.hint"> 
              {{ (index) &lt; 10 ? '0':'' }}{{ index + 1 }}
            </div>
            <div class="text-index" v-else>
              <v-icon color="white">wb_incandescent</v-icon>
            </div>
          </div>
          <div class="container-body">
            <h4>{{ item.hint ? $t("recipe_page.hint")  : $t("recipe_page.step") }}</h4>
            <span>{{ item.value }}</span>

            <div
              v-if="item.assets && item.assets.length > 0"
              class="d-flex mt-1"
              :key="'cool-' + item.id"
            >
              <GalleryRow :assets="item.assets"></GalleryRow>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import GalleryRow from "../gallery/GalleryRow";

export default {
  name: "TabStep",
  components: {
    GalleryRow,
  },

  data: () => ({
    index: null,

    items: [
      {
        title: "Image 1",
        description: "Description 1",
        src: "https://i.wifegeek.com/200426/f9459c52.jpg",
      },
      {
        title: "a beautiful mountain view",
        description: "Description 2",
        src:
          "https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg",
      },
      {
        title: "Youtube Video",
        description: "Youtube Video",
        thumb: "thumb.jpg",
        src: "https://www.youtube.com/watch?v=d0tU18Ybcvk",
      },
    ],
  }),

  computed: {
    ...mapGetters({
      steps: "recipes/getSteps",
    }),
  },

  methods: {
    select(index) {
      this.index = index;
      // alert(this.index)
    },

    getGallery(assets) {
      //  let list = [];

      // if(assets && assets.length>0){

      //   assets.forEach(el => {
      //     console.log(el);
      //     list.push(
      //        {
      //         title: 'Image 1',
      //         description: "Description 1",
      //         src: 'https://i.wifegeek.com/200426/f9459c52.jpg',
      //       },
      //     )
      //   });

      // }
      console.log(assets);
      console.log("list");
      console.log(this.items);
      return this.items;
      // return list;
    },
  },
};
</script>

<style scoped lang="scss">
.container-body {
  width: 100%;
}

.container-wrapper-step {
  width: 100%;
  min-height: 200px;
  border-radius: 8px;

  .container-body {
    width: 100%;

    .text-index {
      font-size: 24px;
      line-height: 1.2;
      background-color: var(--v-primary-base);
      color: white;
      padding: 4px;
      border-radius: 4px;
    }
  }
}

.row-img {
  display: flex;
  /* height: 100px; */
}

.element-img {
  width: 100px;
  cursor: pointer;
  margin-right: 1%;
}

@media (max-width: 600px) {
}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }
}
</style>