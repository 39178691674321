import { render, staticRenderFns } from "./GridRestaurantProducts.vue?vue&type=template&id=788935a3&scoped=true&"
import script from "./GridRestaurantProducts.vue?vue&type=script&lang=js&"
export * from "./GridRestaurantProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788935a3",
  null
  
)

export default component.exports