<template>
  <div  class="page-wrapper">
    <Banner :title="$t('about_us_page.title')"></Banner>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="m-auto">
        <div class="pa-4">
          <p>{{$t('about_us_page.text1')}}</p>
          <p>{{$t('about_us_page.text2')}}</p>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Banner from "@/components/banner/Banner";

export default {
  title: "About Us",
  name: "About",
  components: {
    Banner,
  },
};
</script>

<style scoped lang="scss">
.page-wrapper{
    min-height: calc(100vh - 280px);
}

</style> 