<template>
  <div>
    <div class="block-top ma-4 pa-3">
      <div>
        <!-- <v-img
          class="restaurant-img"
          :src="restaurant.logo.url"
          aspect-ratio="1"
        ></v-img> -->
      </div>

      <div class="d-flex justify-space-between name-container">
        <h2 class="full-width mt-2 mb-1"> <span>{{ restaurant.name }}</span> 
         <v-btn class="mx-2" text small color="primary" @click="setShowSlotsModal(true)">
         <span class="primary--text">({{slot_isOpen(slots)?  $t(`restaurant_page.open`) : $t(`restaurant_page.close`) }})</span>
        </v-btn>
         </h2>
        <div class="d-flex">
        <v-btn class="mx-2" fab dark small color="primary" @click="setShowBookModal({restaurantId: restaurant.id})">
          <v-icon dark>event</v-icon>
        </v-btn>
         <v-btn class="mx-2" fab dark small color="primary" @click="setShowMessageModal(true)">
          <v-icon dark>chat</v-icon>
        </v-btn>
        <!-- <v-btn class="mx-2" fab dark small color="primary">
          <v-icon dark>favorite_border</v-icon>
        </v-btn> -->
        </div>
        <!-- <BtnFavorite :recipe="recipe"></BtnFavorite> -->
      </div>

     
    </div>

    <!-- <div class="map-wrapper wrapper-1"></div> -->
    <BookModal></BookModal>
    <MessageModal :restaurantId="restaurant.id"></MessageModal>
    <SlotsModal :slots="slots"></SlotsModal>
  </div>
</template>
<script>
import BookModal from '@/components/modal/BookModal'
import MessageModal from '@/components/modal/MessageModal'
import SlotsModal from '@/components/modal/SlotsModal'
import { mapActions } from "vuex";
import slotMixins from "@/mixins/slot";

export default {
  name: "RestaurantHeader",
  components: {
    BookModal,
    MessageModal,
    SlotsModal,
  },
  props: {
    restaurant: {
      type: Object,
      require: true,
    },
  },
  

  mixins: [slotMixins],

  data: () => ({
    rating: 4,

    reviews: [
      {
        id: 1,
        name: "Food",
        rating: 4,
        iconName: "restaurant_food",
      },

      {
        id: 2,
        name: "Service",
        rating: 4,
        iconName: "room_service",
      },

      {
        id: 3,
        name: "Cost",
        rating: 4,
        iconName: "account_balance_wallet",
      },
    ],
  }),

  computed: {
      getAddress(){
        const { address } = this.restaurant
        return address.address;
      },
      amenities() {
        return this.restaurant ? this.restaurant.amenities : [];
      },
      description() {
        return this.restaurant ? this.restaurant.description : '';
      },
      slots() {
        return this.restaurant ? this.restaurant.slots : [];
      },
      
  },

  methods: {
    getDay(day) {
        return 'days.'+day;
      },
      ...mapActions("settings", ["setShowMessageModal", "setShowSlotsModal"]),
      ...mapActions("reservations", ["setShowBookModal"]),
  }
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

  .time {
    color: var(--v-primary-base);
  }
.block-top {
  background-color:  var(--v-background-darken1);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99999999;
  padding: 10px 0;
  .time {
    margin-bottom: 0;
    padding-bottom: 5px;
    color: var(--v-primary-base);
  }
  .restaurant-img {
    border-radius: 50%;
    width: 80%;
    margin: auto;
  }

  .icon-wrap {
    background-color: #f7b538;
  }
}

button {
  outline: none;
}

.contacts-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .contact-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    &.item-location {
      display: none;
    }
    .contact-value {
      margin: 0;
      padding: 0 8px;
    }
  }
}



@media (max-width: 960px) {
  .block-top {
    .restaurant-img {
      border-radius: 50%;
      width: 30%;
      margin: auto;
    }

    .icon-wrap {
      background-color: #f7b538;
    }
  }

  .contacts-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contact-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 5px 5px;
      &.item-location {
        display: flex;
      }
      .contact-value {
        display: none;
      }
    }
  }

 
}

@media (max-width: 500px) {
  .block-top {
    .restaurant-img {
      border-radius: 50%;
      width: 40%;
    }
  }
}
</style>