import Vue from 'vue'
import moment from 'moment'

const filters = () => {
  Vue.filter('capitalize', function (value) {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }

    return ''
  })

  Vue.filter('formatDate', function (value, formatType = 'LL') {
    if (!value) return ''

    return moment(value).format(formatType)
  })


  Vue.filter('formatDateTime', function (value, formatType = 'LLL') {
    if (!value) return ''

    return moment(value).format(formatType)
  })


  Vue.filter('fromNow', function (value, lang = "en") {
    if (!value) return ''

    return moment(value).locale(lang).fromNow()
  })

  Vue.filter('round1Decimal', function (value, ) {
    if (!value) return ''

    return value.toFixed(1);
  })

  Vue.filter('truncString', function (value, ) {
    if (!value) return ''
    if(value.length <50)
      return value
    return value.substring(0, 50)+'...';
  })


  Vue.filter('formatDifficulty', function (value) {
    if (!value) return ''

    switch (value) {
      case 'BEGINNER':
        return 'fácil';
      case 'BASIC':
        return 'básico';
      case 'INTERMEDIANTE':
        return 'médio';
      case 'ADVANCED':
        return 'avanç';
      case 'EXPERT':
        return 'exper';
    }
    return ""; 
  
  })


  Vue.filter('formatBlogCategory', function (value) {
    if (!value) return ''

    switch (value) {
      case 'food':
        return 'Comida';
      case 'hygiene':
        return 'Higiene';
      case 'health':
        return 'Saúde';
      case 'trick':
        return 'Dicas';
      case 'diet':
        return 'Dieta';
    }
    return "";

  })


  Vue.filter('formatDuration', function (value) {
    if (!value) return ''

    // console.log("$i18n");
    // console.log(this.$i18n.locale);
    try {
      const val = value;
      const hour = val / 60;
      const min = val % 60;

      if (hour > 0)
        return `${hour.toFixed(0)}h${min}`;
      else
        return `${min}min`; 
    } catch (error) {
      console.log(error);
    }
    return '';
    
  })


  Vue.filter('formatMinutes', function (value) {
    if (!value) return ''


    try {
      const val = 300 * (value / 100) ;
      const hour = val / 60;
      const min = val % 60;

      if (hour > 0)
        return `0${hour.toFixed(0)}h${min===0?'00':min.toFixed(0)}`;
      else
        return `${min===0?'00':min.toFixed(0)}min`; 
    } catch (error) {
      console.log(error);
    }
    return '0min';
    
  })

  Vue.filter('formatTimeSlot', function (value) {
    if (!value) return ''

    return value.substring(0, value.length-3);
    
  })


  Vue.filter('length', function (value) {
    if (!value) return 0;
    return value.length;
  })

  Vue.filter('formatNumberViews', function (num) {
    if(!num) return '1';
   
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
     }
     return num;
  })

  Vue.filter('formatNumberReviews', function (num) {
    if(!num) return '0';
   
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
     }
     return num;
  })


  Vue.filter('removeLastCharacter', function (value) {
    if (!value) 
      return "";
  
    return value.slice(0, -1);
  })

  Vue.filter('authorFullName', function (value) {
    if (!value) return ''
      return (value.firstName || "") +" "+ (value.lastName || "")
  })

  Vue.filter('authorTwoLettersName', function (value) {
    if (!value) return ''
      return (value.firstName.substring(0, 1) || "") +" "+ (value.lastName.substring(0, 1) || "")
  })
  
  Vue.filter('authorPhoto', function (value) {
    if (!value || !value.avatar) return ''
      return (value.avatar.url)
  })
}

export default filters