<template>
  <v-container class="pa-0" fluid>
    <div role="button" class="close-wrapper"  v-if="showFilter" @click="setShowFilter()"></div>
   
    <v-row no-gutters class="main-wrapper mt-0">
      <v-col
        cols="12"
        sm="12"
        md="0"
        :class="showFilter ? 'block-left' : 'hide block-left'"
   
      >
        <Filter2></Filter2>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-btn
          class="ma-2 filter-btn"
          fab
          x-small
          color="primary"
          outlined
          @click="setShowFilter()"
        >
          <v-icon dark>
            {{ showFilter ? "close" : "filter_alt" }}
          </v-icon>
        </v-btn> 

        <div :class="showFilter?'container-result filter-on':'container-result'">
        <div v-if="!onFocus">
          <GridRecipe :recipes="recipes"></GridRecipe>
        </div>

        <div v-else>
          <GridCategory
            :categories="categories"
            :categorySelected="categorySelected"
            @changeDisplay="changeDisplay"
          ></GridCategory>
        </div>
        </div>
      </v-col>
      <!--  -->
    </v-row>
  </v-container>
</template>

<script>
import GridRecipe from "../components/grid/GridRecipe";
import GridCategory from "../components/grid/GridCategorySearch";
import Filter2 from "../components/filter/Filter2";
import { mapActions, mapGetters } from "vuex";

export default {
  //  name: 'swiper-example-multiple-slides-per-biew',
  title: "Search page",
  name: "Search",
  components: {
    Filter2,
    GridRecipe,
    GridCategory,
  },

  data: () => ({
    categories: [],
    categorySelected: null,
  }),

  mounted() {
    if (this.isAuthenticated) {
      this.fetchCategories()
        .then((res) => {
          this.setCategories(res);
        })
        .catch((error) => {
          console.log(error);
        });

      this.fetchListRecipesSearch("")
        .then(() => console.log())
        .catch((error) => console.log(error));
    }
  },

  computed: {
    ...mapGetters({
      recipes: "recipes/listRecipesSearch",
      showFilter: "search/getShowFilter",
      onFocus: "search/getOnFocus",
      isAuthenticated: "users/getAccount",
      searchActive: "search/getActive",

    }),
  },


   watch: {
    searchActive: function (val) {
  
       if (val==true && this.isAuthenticated && this.categories?.length==0) {
      this.fetchCategories()
        .then((res) => {
          this.setCategories(res);
        })
        .catch((error) => {
          console.log(error);
        });

      this.fetchListRecipesSearch("")
        .then(() => console.log())
        .catch((error) => console.log(error));
    }
    },
   
  },

  methods: {
    ...mapActions("recipes", ["fetchListRecipesSearch"]),
    ...mapActions("search", ["setShowFilter"]),
    ...mapActions("categories", ["fetchCategories"]),

    setCategories(categories) {

      this.categories = categories;

      // }
    },

    selectCategory(categoryId) {
      if (this.recipes.length > 0) {
        this.recipesSelected = this.recipes.filter((rec) => {
          return (
            rec.categories.filter((el) => {
              const _categoryId = typeof el === "string" ? el : el.id;
              return _categoryId == categoryId;
            }).length > 0
          );
        });
        this.categorySelected = categoryId;
      }
    },

    changeDisplay() {},
  },
};
</script>

<style scoped lang="scss">


.close-wrapper{
 top: 70px;
 left: 0;
 right: 0;
 bottom: 0;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  margin-top: -8px; 
  transition: all 0.3s ease-out;

}

.filter-btn {
  outline: none;
}
.main-wrapper {
  margin-top: 32px;

 
}

.container-result{
  transition: all 0.3s ease-out;
 &.filter-on{
      margin-left: 320px;
  }
}

.card-search {
  width: 19%;
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;

  .block-left {
    position: absolute;
    width: 100%;
    z-index: 2;
    max-width: 320px;
    opacity: 1;
    transition: all 0.3s ease-out;
    &.hide {
      /* display: none; */
      width: 0;
      z-index: -1;
      opacity: 0;
    }
    
  }
}

.container-wrapper {
  padding: 16px 0;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 820px) {
  .card-search {
    width: 24%;
  }
}
@media (max-width: 960px) {
  .text-info {
    margin-top: 15%;
    text-align: center;
  }


}

@media (max-width: 650px) {
  .card-search {
    width: 33%;
  }
}

@media (max-width: 520px) {
  .card-search {
    width: 49%;
  }
  .container {
    .block-left {
      max-width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
}

@media (max-width: 400px) {
}
</style>