<template>
  <div >
    <Banner :title="$t('terms_and_conditions_page.title')"></Banner>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="m-auto">
        <div class="my-8 px-4">
          <p>
            Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Nullam vel sem. Sed libero. Sed lectus. Pellentesque auctor neque nec urna.
          </p>
          <p>
            Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Proin magna. Fusce pharetra convallis urna. Etiam ultricies nisi vel augue. Curabitur vestibulum aliquam leo.
          </p>
          <p>
            Nullam cursus lacinia erat. Sed hendrerit. Pellentesque commodo eros a enim. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Vestibulum fringilla pede sit amet augue.
          </p>
          <p>
            Praesent porttitor, nulla vitae posuere iaculis, arcu nisl dignissim dolor, a pretium mi sem ut ipsum. Nullam cursus lacinia erat. Cras non dolor. Donec vitae sapien ut libero venenatis faucibus. Etiam imperdiet imperdiet orci.
        </p>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Banner from "@/components/banner/Banner";

export default {
  title: "Terms and Conditions",
  name: "TermsAndConditions",
  components: {
    Banner,
  },
};
</script>

<style scoped lang="scss">

</style> 