import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    sending: false,
    deleting: null,
    review: null,
    reviewCount: 0,
    imagesToUpload: [],
  },

  getters: {
    getReviews(state) {
      return state.items
    },
    getSending(state) {
      return state.sending
    },
    getDeleting(state) {
      return state.deleting
    },

    getReview(state) {
      return state.review;
    },

    getReviewCount(state) {
      return state.reviewCount;
    },

    getImagesToUploadUrl(state) {
      let list = [];
        state.imagesToUpload.forEach(file => {
          list.push(URL.createObjectURL(file))
        });
      
      return list;
    },

  },

  actions: {
    setReviews({ commit }, reviews) {
      commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
    },

    setImagesToUpload({ commit }, files) {
      commit('set', { resource: 'imagesToUpload', value: files })
    },

    removeImageSelected({ commit }, index) {
      commit('deleteImageSelected', { index: index })
    },

    fetchReviews({ state, commit }, recipeId) {

      return axiosInstance.get(`reviews?_sort=created_at:DESC&recipe=${recipeId}`)
        .then(res => {
          const reviews = res.data
          commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
          return state.items
        })

    },

    fetchReviewsCount({ state, commit }, recipeId) {

      return axiosInstance.get(`reviews/count?recipe=${recipeId}`)
        .then(res => {
          const count = res.data
          // commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
          commit('set', { resource: 'reviewCount', value: count })

          return state.items
        })
        
    },

    
    resetReview({ commit }) {
      commit('set', { resource: 'review', value: null })
      commit('set', { resource: 'imagesToUpload', value: [] })

    },

    createReview({  commit }, review) {

      const _review = {
        "rating": review.rating,
        "comment": review.comment,
        "recipe": review.recipeId,
        "id": review.id,
      }
      commit('set', { resource: 'review', value: _review })

    },




    fetchSendReview({ state, commit }) {

      const review = state.review;
      commit('set', { resource: 'sending', value: true })
      const formData = new FormData();
      const formJson = {
        "rating": review.rating,
        "comment": review.comment,
        "recipe": review.recipeId
      }

      formData.append("data", JSON.stringify(formJson));

      if(state.imagesToUpload && state.imagesToUpload.length>0){
        state.imagesToUpload.forEach(img => {
          formData.append('files.assets', img, img.name)
        });
      }

      return axiosInstance({
        method: review.id ? 'put' : 'post',
        url: review.id ? `reviews/${review.id}` : 'reviews',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          const _review = res.data
          commit('recipes/updateRecipeReview', { type: review.id ? "edit" : "add", recipeId: review.recipeId, review: _review }, { root: true })
          commit('set', { resource: 'sending', value: false })
          commit('set', { resource: 'review', value: null })

          return state.items
        })
        .catch(
          err => {
            console.log(err)
            commit('set', { resource: 'sending', value: false })
            commit('set', { resource: 'review', value: null })
          }
        )
    },


    fetchRemoveReview({ state, commit }, { recipeId, reviewId }) {

      commit('set', { resource: 'deleting', value: reviewId })

      return axiosInstance.delete(`reviews/${reviewId}`)
        .then(res => {
          const review = res.data
          commit('recipes/updateRecipeReview', { type: "delete", recipeId: recipeId, review: review }, { root: true })
          commit('set', { resource: 'deleting', value: null })

          return state.items
        })
        .catch(err => {
          console.log(err);
          commit('set', { resource: 'deleting', value: null })
        })
    }
  },




  mutations: {

    set(state, { resource, value }) {
      state[resource] = value;
    },
    deleteImageSelected(state, { index }) {

      const files = state.imagesToUpload;
      let list = [];
      for (let i = 0; i < files.length; i++) {
        if (i != index) {
          list.push(files[i]);
        }
      }

      state.imagesToUpload = [...list];
    },

  }
}