<template>
  <div>
    <v-card-text>
      <h4 class="text--primary text-h6" v-t="'login_page.signUp_title'"></h4>
      <p
        class="body-2 subtitle-continue"
        v-t="'login_page.signUp_subtitle'"
      ></p>
      <v-form ref="registerForm">
        <v-text-field
          prepend-icon="badge"
          :label="$t('login_page.signUp_firs_name_label_text')"
          v-model="form.firstName"
          :rules="[(v) => !!v || $t(`login_page.signUp_firs_name_error`)]"
        ></v-text-field>
        <v-text-field
          prepend-icon="badge"
          :label="$t('login_page.signUp_last_name_label_text')"
          v-model="form.lastName"
          :rules="[(v) => !!v || $t(`login_page.signUp_last_name_error`)]"
        ></v-text-field>
        <v-text-field
          prepend-icon="account_circle"
          name="username"
          type="text"
          :rules="[(v) => !!v || $t(`login_page.signUp_username_error`)]"
          v-model="form.username"
          :label="$t('login_page.signUp_username_label_text')"
        ></v-text-field>
        <v-text-field
          prepend-icon="email"
          name="email"
          type="email"
          :rules="[
            (v) => !!v || $t(`login_page.signUp_email_error`),
            (v) =>
              (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
              $t(`login_page.invalid_email`),
          ]"
          v-model="form.email"
          :label="$t('login_page.signUp_email_label_text')"
        ></v-text-field>
        <v-text-field
          id="password"
          name="password"
          prepend-icon="lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            (v) => !!v || $t(`login_page.signUp_password_error`),
            (v) =>
              (!!v && v.length >= 6) ||
              $t(`login_page.signUp_password_length_error`),
          ]"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('login_page.signUp_password_label_text')"
          :hint="$t('login_page.signUp_password_length_error')"
          @click:append="showPassword = !showPassword"
          v-model="form.password"
          autocomplete="on"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-flex>
        <v-spacer></v-spacer>
        <v-btn
          block
          depressed
          color="primary"
          @click.prevent="authenticate"
          :loading="isLoading"
        >
          {{ $t("login_page.signUp_btn") }}
        </v-btn>
        <div class="d-flex align-center justify-center flex-wrap my-4">
          <span>{{ $t("login_page.signUp_subtitle_bottom") }}</span>
          <a class="mx-2" @click.prevent="$router.push('/login')">{{
            $t("login_page.signUp_here")
          }}</a>
        </div>
        <div class="d-flex align-center my-4">
          <v-divider></v-divider>
          <span class="mx-2" v-t="'login_page.login_sign_with'"></span>
          <v-divider></v-divider>
        </div>
        <div class="d-flex align-center justify-center mb-4">
          <BtnFacebookLogin class="mr-2"></BtnFacebookLogin>
          <BtnGoogleLogin class="ml-2"></BtnGoogleLogin>
        </div>
      </v-flex>
    </v-card-actions>
  </div>
</template>

<script>
import BtnFacebookLogin from "../components/btn/BtnFacebookLogin";
import BtnGoogleLogin from "../components/btn/BtnGoogleLogin";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",

  components: {
    BtnFacebookLogin,
    BtnGoogleLogin,
  },

  data: () => ({
    showPassword: false,
    form: {
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      password: null,
    },
  }),

  methods: {
    ...mapActions("auth", ["sendForgotPassword"]),

    authenticate() {
      if (this.$refs.registerForm.validate()) {
        this.$store
          .dispatch("auth/registerUser", this.form)
          .then((res) => {
           
            if(res.status === 200){
              this.$toasted.show(this.$t("login_page.signUp_success_message"), {
                theme: "toasted-primary",
                position: "top-center",
                duration: 5000,
              });

              this.$router.push("/login");
            }
            else if(res.statusCode === 400){
               this.$toasted.show(this.$t(`${res.message[0].messages[0].id}`), {
                theme: "toasted-primary",
                position: "top-center",
                duration: 5000,
              });
            }
          })
          .catch((errorMessage) => {
           
            if (errorMessage) {
             
              this.$toasted.show(
                errorMessage.message === "Network Error"
                  ? this.$t("network_error")
                  : this.$t("login_page.signUp_error_message"),
                {
                  theme: "toasted-primary",
                  position: "top-center",
                  duration: 5000,
                }
              );
            }
          });
      }
    },
  },

  computed: {
    ...mapGetters({
      isLoading: "auth/getIsLoading",
    }),
  },
};
</script>

<style scoped lang="scss">
button {
  /* text-transform: none !important; */
  outline: none;
}
</style>
