import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    getCategories(state) {
      return state.items
    },
  },

  actions: {

    async fetchCategories({ state, commit }) {
      if (state.items > 0)
        return state.items;

      return axiosInstance.get('categories?_sort=name:ASC')
        .then(res => {
          const categories = res.data

          const _categories = categories.map( category =>{
           
            return axiosInstance.get(`recipes?status=published&categories_in=${category.id}`)
            .then(result => {
                return {...category, "numberRecipes": result?.data?.length || 0};
            })
            .catch(err => { console.log(err); return {...category, "numberRecipes": 0} })

          })

         return Promise.all(_categories)
          .then((listCategories) => {
            const _listCategories = listCategories.filter( category => category.numberRecipes > 0)  
            commit('setItems', { resource: 'categories', items: _listCategories }, { root: true })
          return state.items
          })
          .catch(err => { console.log(err) })

          
        })
        .catch(err => { console.log(err) })
    },

    fetchCategory({ state }, id) {
      if (state.items > 0)
        return state.items.filter(category => category.id === id)[0] || {};

      return axiosInstance.get(`categories/${id}`)
        .then(res => {
           const category = res.data;

          return axiosInstance.get(`recipes?status=published&categories_in=${category.id}`)
          .then(result => {
              return {...category, "numberRecipes": result?.data?.length || 0};
          })
          .catch(err => { console.log(err); return {...category, "numberRecipes": 0};})
        })
        .catch(err => { console.log(err) })
    }
  }
}