<template>
  <v-container class="pa-0" fluid>
    <div class="container-img"></div>
    <v-row no-gutters class="main-wrapper">
      <v-col cols="12" class="block-left">
        <ProfileSideBar
          @setContentToShow="setContentToShow"
          :contentToShow="contentToShow"
          :total="{
            recipes: listRecipesByAuthor.length,
            favorites: listFavorites.length,
            posts: listPostsByAuthor.length,
          }"
          :authorProp="author"
        ></ProfileSideBar>
      </v-col>

      <v-col cols="12">
        <div class="container-wrapper px-3 pb-8">
          <div class="d-flex justify-space-between">
            <h2>{{ getHeaderText }}</h2>
          </div>

          <div v-if="contentToShow === 2" key="contentToShow_1">
            <GridRecipeContent
              :recipes="listFavorites"
              key="contentToShow_2"
              v-if="!isListFavoritesEmpty"
            ></GridRecipeContent>
            <p v-else>{{$t('empty_list')}}</p>
          </div>

          <div v-if="contentToShow === 1">
            <GridRecipeContent
              :recipes="listRecipesByAuthor"
               v-if="!isListRecipesByAuthorEmpty"
               :showStatus="true"
            ></GridRecipeContent>
            <p v-else>{{$t('empty_list')}}</p>
          </div>

          <div v-if="contentToShow === 3">
            <GridPostContent 
              :posts="listPostsByAuthor"
               v-if="!isListPostsByAuthorEmpty"
              ></GridPostContent>
              <p v-else>{{$t('empty_list')}}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileSideBar from "../components/sidebar/ProfileSidebar";
import GridRecipeContent from "../components/grid/GridRecipeContent";
import GridPostContent from "../components/grid/GridPostContent";
import authorMixins from "../mixins/author";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "Profile",
  name: "Profile",
  components: {
    ProfileSideBar,
    GridRecipeContent,
    GridPostContent,
  },

  data: () => ({
    contentToShow: 2,
  }),
  mixins: [authorMixins],

  created() {
    let _author = this.$route.params.author;
    console.log('_author', _author)
    this.initUser(_author);
    this.author = this.user;
    if (this.account && _author && this.account.id == _author.id) {
      this.author = this.account;
    } else this.author = this.user;

    this.fetchListRecipesByAuthor(this.author.id)
      // .then(res =>console.log(res))
      .catch((res) => console.log(res));

    this.fetchPostsAuthor(this.author.id)
      // .then(res =>console.log(res))
      .catch((res) => console.log(res));

      

    if (this.account && this.author && this.account.id != this.author.id) {
      this.contentToShow = 1;
    }
  },
  methods: {
    ...mapActions("recipes", [
      "fetchListRecipesByAuthor",
    ]),
    ...mapActions("users", ["initUser"]),
    ...mapActions("posts", ["fetchPostsAuthor"]),

    setContentToShow(value) {
      this.contentToShow = value;
    },
  },

  computed: {
    ...mapGetters({
      listFavorites: "recipes/listFavorites",
      listRecipesByAuthor: "recipes/listRecipesByAuthor",
      listPostsByAuthor: "posts/listPostsByAuthor",
      user: "users/getUser",
      account: "users/getAccount",
    }),

    getHeaderText() {
      if (this.contentToShow == 1) return this.$t("profile_page.my_recipes");
      if (this.contentToShow == 2) return this.$t("profile_page.favorites");
      return this.$t("profile_page.my_posts");
    },
    
    isListFavoritesEmpty(){
      return this.listFavorites.length === 0;
    },

    isListRecipesByAuthorEmpty(){
      return this.listRecipesByAuthor.length === 0;
    },

    isListPostsByAuthorEmpty(){
      return this.listPostsByAuthor.length === 0;
    }

  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  margin-top: 32px;
}

.container-img {
  width: 100%;
  height: 60%;
  position: absolute;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    /* url("../assets/intro/kitchen.jpg"); */ url("../assets/profile_bg.jpg");
  background-size: cover;
  opacity: 0.4;
  z-index: 0;
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 960px) {
  .wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.1)
      ),
      url("../assets/intro/img-intro.jpeg");
  }
}
</style>