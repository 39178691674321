<template>
  <form>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px">
        <v-card>
          <div class="card-header d-flex align-center justify-space-between">
            <div class="block-left"></div>
            <h3>{{ $t('restaurant_page.slots') }}</h3>
            <v-btn color="white" icon text @click="setShowSlotsModal(false)">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
         
          <div class="pa-3">
            <RestaurantSlots :slots="slots"></RestaurantSlots>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RestaurantSlots from "@/components/tab/restaurant/RestaurantSlots";


export default {
  name: "SlotsModal",

  components: {
   RestaurantSlots
  },

  props: {
     slots: {
      type: Array,
      require: true,
      default: ()=> []
    },
  },

  data: () => ({
    loadind: false,
    subject:'',
    message:'',
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),



  methods: {
    ...mapActions("reviews", [
      "fetchReviews",
    ]),
    emit() {
      // this.$emit('closeEvent')
      // this.resetReview();
    },


    ...mapActions("settings", ["setShowSlotsModal"]),
  
  },

  computed: {
    ...mapGetters({
      showShowSlotsModal: "settings/getShowSlotsModal",
    }),

    show: {
      // getter
      get: function () {
        return this.showShowSlotsModal;
      },
      // setter
      set: function (newValue) {
        this.setShowSlotsModal(newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">


.card-header {
  background-color: #f7b538;
  width: 100%;
  height: 60px;
  .block-left {
    width: 0px;
  }
}


@media (max-width: 600px) {
  .card-header {
    .block-left {
      width: 0%;
    }
  }
}

</style>