<template>
  <div>
    <v-card-text>
      <h4
        class="text--primary text-h6 mb-4"
        v-t="'login_page.login_title'"
      ></h4>
      <p class="body-2 subtitle-continue" v-t="'login_page.login_subtitle'"></p>
      <v-form ref="loginForm">
        <v-text-field
          prepend-icon="account_circle"
          name="login"
          type="text"
          v-model="form.username"
          :rules="[(v) => !!v || $t(`login_page.login_username_error`)]"
          :label="$t('login_page.login_username_label_text')"
        ></v-text-field>
        <v-text-field
          id="password"
          name="password"
          prepend-icon="lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            (v) => !!v || $t(`login_page.login_password_error`),
            (v) =>
              (!!v && v.length >= 6) ||
              $t(`login_page.signUp_password_length_error`),
          ]"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('login_page.login_password_label_text')"
          @click:append="showPassword = !showPassword"
          v-model="form.password"
          autocomplete="on"
        ></v-text-field>
        <div class="d-flex align-center justify-end my-2">
          <a @click.prevent="$router.push('/forgot-password')">{{
            $t("login_page.login_forgot_text")
          }}</a>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-flex>
        <v-spacer></v-spacer>

        <v-btn
          block
          depressed
          color="primary"
          @click.prevent="authenticate"
          :loading="isLoading"
        >
          {{ $t("login_page.login_login_text") }}
        </v-btn>
        <div class="d-flex align-center justify-center flex-wrap my-4">
          <span>{{ $t("login_page.login_subtitle_bottom") }}</span>
          <a class="mx-2" @click.prevent="$router.push('/register')">{{
            $t("login_page.login_register_btn")
          }}</a>
        </div>
        <div class="d-flex align-center my-4">
          <v-divider></v-divider>
          <span class="mx-6" v-t="'login_page.login_sign_with'"></span>
          <v-divider></v-divider>
        </div>
        <div class="d-flex align-center justify-center mb-4">
          <BtnFacebookLogin class="mr-2"></BtnFacebookLogin>
          <BtnGoogleLogin class="ml-2"></BtnGoogleLogin>
        </div>
      </v-flex>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BtnFacebookLogin from "../components/btn/BtnFacebookLogin";
import BtnGoogleLogin from "../components/btn/BtnGoogleLogin";

export default {
  name: "Login",

  components: {
    BtnFacebookLogin,
    BtnGoogleLogin,
  },

  data: () => ({
    showPassword: false,
    form: {
      username: null,
      password: null,
    },
  }),

  methods: {
    ...mapActions("auth", ["sendForgotPassword"]),

    goToHome() {
      this.$router.push({ name: "home" }, () => {});
      this.$store.state.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true");
    },

    authenticate() {
      if (this.$refs.loginForm.validate()) {
        this.$store
          .dispatch("auth/loginWithEmailAndPassword", this.form)
          .then(() => {
              
            const redirectPath = localStorage.getItem("redirectPath");
            if(this.$route.query.from || redirectPath){
              this.$router.replace(this.$route.query.from || redirectPath).catch(()=>{});
            }
            else{
              // this.$router.go()
              this.$router.push('/register');
              this.$router.replace('/');

            }
          
            localStorage.removeItem("redirectPath");
            
          })
          .catch((errorMessage) => {
            this.$toasted.show(
              errorMessage.message === "Network Error"
                ? this.$t("network_error")
                : this.$t("login_page.login_error_message"),
              {
                theme: "toasted-primary",
                position: "top-center",
                duration: 5000,
              }
            );
            // this.setShow({value:true, text:'Username/E-mail ou Palavra-Passe incorrectos.'});
          });
      }
    },
  },

  computed: {
    ...mapGetters({
      isLoading: "auth/getIsLoading",
    }),
  },
};
</script>

<style scoped lang="scss">
button {
  /* text-transform: none !important; */
  outline: none;
}
</style>
