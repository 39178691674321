<template>
<div class="main-wrapper pa-4">
  <p class="mb-2">{{$t(`suggest_page.instruction.instructions`)}} ({{ numberSteps }})</p>

        
        <div v-for="(item,i) in steps" :key="i+'step'">
          <Step :index="i"></Step>
        </div>

       <div class="mt-2">
          <v-btn block color="primary" dark @click="addStep()">
            <v-icon>add</v-icon> {{$t(`suggest_page.instruction.add_new`)}}
          </v-btn>
      </div> 
  </div>
</template>
<script>
import Step from "./Step";
import {mapActions, mapGetters } from "vuex";

export default {
  name: "RecipeSteps",

  components: {
    Step,
  },

  data: () => ({
    valid: false,
  }),

  methods:{
    
    ...mapActions("recipes", ["addStep"]),

  },

  computed: {
    numberSteps(){
        return this.steps.length
    },
    ...mapGetters({
      steps: "recipes/getStepsNewRecipe",
    }),
  },
  
};
</script>

<style scoped lang="scss">
.main-wrapper {
  width: 100%;
  min-height: 50vh;
  max-width: 960px;
  /* background-color: white !important; // fix when add dark theme  */
  border-radius: 4px;
}

</style>