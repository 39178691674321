<template>
  <v-autocomplete
    v-model="recipe.categories"
    :rules="[v => !!(v && v.length) || this.$t(`suggest_page.categories_error`)]"
    :items="items"
    chips
    color="blue-grey lighten-2"
    :label="this.$t(`suggest_page.categories`)"
    item-text="name"
    item-value="id"
    multiple
    dense
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="recipe.categories"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.image.url"></v-img>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="isObject(data)" >
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="getImageURL(data)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <!-- <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle> -->
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>

import { mapActions, mapGetters} from "vuex";

export default {
  name: "AutocompleteCategoryNewRecipe",
  
  data() {
    return {  
      items:[]
    };
  },

  created(){
    this.fetchCategories()
          .then(res => {
            this.items = res; 
          })
          .catch(
            err => {
              console.log(err)
            }
          )
  },

  watch: { 
    // isUpdating(val) {
    //   if (val) {
    //     setTimeout(() => (this.isUpdating = false), 3000);
    //   }
    // },
  },

  methods: {

    getImageURL(data){
        return data?.item?.image && data.item.image.url ? data.item.image.url : require(process.env.VUE_APP_IMAGE_PLACEHOLDER);
      },

      isObject(data){
        return typeof data.item !== 'object'
      },

      ...mapActions("categories", ["fetchCategories"]),
      ...mapActions("search", ["removeCategory"]),
      ...mapActions("recipes", ["removeCategoryNewRecipe"]),
      remove(item) {
          this.removeCategoryNewRecipe(item)
      },
  }, 


  computed: {
    
     ...mapGetters({
        filters: "search/getFilters",
        recipe: "recipes/getNewRecipe",
        categories: "categories/getCategories",
      }),

  },
};
</script>

<style scoped>

</style>