<template>
    <v-btn
      text
      icon
      x-large
      color="#4267B2"
      @click="submit()"
      >
      <v-icon>facebook</v-icon>
    </v-btn>
</template>


<script>

export default {
  name: "BtnFacebookLogin",
  props: {
    recipe: {
      type: Object,
      require: true,
    },
  },
  


  methods: { 
    submit() {
            window.location = `${process.env.VUE_APP_PUBLIC_API_URL}connect/facebook`
    }
  }
};
</script>

<style lang="scss" scoped>


</style>
