<template>
  <div class="d-flex flex-wrap justify-space-between px-2  grid-recipe">
          
          <div :class="`card-search mb-4 ${loading?'hidden':''}`" v-for="(item) in recipes" :key="item.id">
            <Card3 type="ratingShort" :recipe="item" v-if="!loading"></Card3>
          </div>

          <div class="card-search mb-4" v-for="(item) in 8" :key="item+'card'" >
            <Card3 type="ratingShort"   :loading="loading" v-if="loading"></Card3>
          </div>

   </div>

</template>

<script>

import Card3 from "../../components/card/Card3";

export default {
  title: "Grid Recipe",
  name: "GridRecipe",
  components: {
    Card3,
  },

  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },


  data: () => ({
  }),

  computed: {},
};
</script>

<style scoped lang="scss">
.hidden{
  display: none;
}
.grid-recipe::after {
  content: "";
  flex: auto;
   /* width: 14.5%; */
   /* padding-right: 0.5%;  */
}
.card-search{
  width: 25%;
  padding-right: 0.5%;
}

@media (min-width: 1200px) {
  .card-search{
    width: 20%;
  }
}

@media (max-width: 960px) {
  .card-search{
    width: 33.33%;
  }
}


@media (max-width: 720px) {
  .card-search{
    width: 50%;
  }
}


@media (max-width: 420px) {
  .card-search{
    width: 100%;
  }
}

/* 
@media (max-width: 650px) {
  .card-search{
    width: 33%;
  }
}


@media (max-width: 520px) {
  .card-search{
    width: 49%;
  }
}

@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
} */

</style>