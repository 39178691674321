<template>
  <v-card
    class="mx-auto my-card"
    max-width="100%"
   
    @click="goTodetail()"
  >
    <div class="mb-10 my-card-wrapper">
      <v-img
        v-if="!loading"
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.7"
      ></v-img>

      <v-skeleton-loader
        v-if="loading"
        type="image"
        class="image-skeleton"
        max-height="100%"
        max-width="700"
        width="100%"
        transition="fade-transition"
        elevation="4"
        :dark="true"
      ></v-skeleton-loader>
    </div>

    <div class="card-body" v-if="!loading">
      <div class="name px-2 accent--text">
        {{ name }}
      </div>

      <div class="text-center">
        <v-icon color="accent">location_on</v-icon>
        <span class="accent--text">{{ address }}</span>
      </div>

      <div>
        <div class="d-flex justify-center">
          <div>
            <v-rating
              small
              half-increments
              dense
              v-model="rating"
              background-color="accent"
              readonly
              color="accent"
            ></v-rating>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "CardSpotlightRestaurant",
  components: {},

  props: {
    restaurant: {
      type: Object,
      require: true,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    rating() {
      return 4;
    },
    getImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    name(){
      return this.restaurant && this.restaurant.name || ''
    },

    address(){
      return this.restaurant && this.restaurant.address && this.restaurant.address.address || ''
    }
  },

  methods: {
    ...mapActions("recipes", ["fetchSetFavorite"]),

    goTodetail() {
      this.$router.push(
         { name: "restaurantItem", params: { restaurantId: this.restaurant.id,  restaurant: this.restaurant } },
        () => {}
      );
    },

    // setFavorite() {
    //   this.fetchSetFavorite(this.recipe);
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}

.card-body {
  position: absolute;
  background-color: var(--v-background-darken2);
  bottom: -50px;
  left: 10%;
  width: 80%;
  border-radius: 16px !important;
  z-index: 9999;
  overflow: visible;
  padding: 2% 0%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}

.my-card {
  position: relative;
  background-color: transparent;
  box-shadow: none !important;
  width: 700px;
  padding: 0 16px;

  .name {
    font-weight: bold;
    text-align: center;
  }

  .v-image {
    border-radius: 32px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.caption {
  color: gray;
}

.btn-favorite {
  position: absolute;
  z-index: 999;
  top: 5%;
  right: 2%;
}

.image-skeleton {
  height: 390px;
  border-radius: 32px;
}

@media (max-width: 600px) {
  .image-skeleton {
    height: 340px;
  }
}
@media (max-width: 600px) and (min-width: 500px) {
  .card-body {
    overflow: visible;
    padding: 2%;
    font-size: 12px;

    .body-2 {
      font-size: 11px !important;
    }
  }
}
</style>
