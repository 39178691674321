<template>
  <v-container fluid class="d-flex pa-0 pt-0 flex-wrap container-wrapper content-wrapper">
    <div class="container-body pt-0">

      <div class="ingredients-wrap d-flex flex-wrap">
        <div
          class="ingredient-item d-flex align-center"
          v-for="(item) in ingredients"
          :key="item.id"
        >
          <div class="ingredient-img pr-4">
            <v-img class="element-img" 
                :src="item.image"
                :lazy-src="item.image"
                :alt="item.value"
                ></v-img>
          </div>
          <div class="ingredient-text">
            <span>{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "TabIngredient",
  components: {},

  props:{
    steps:{
      type: Object,
      require:true,
    }
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters({
        ingredients: "recipes/getIngredients",
      }),
  },

  // methods: {
  //      ...mapActions("recipes", ["setRecipe"]),
  // }
};
</script>

<style scoped lang="scss">
.container-wrapper {
  border-radius: 8px;

  .container-body {
    width: 100%;
    .ingredients-wrap {
      .ingredient-item {
        width: 25%;
        @media (max-width: 1200px) {
          width: 33.3%;
         }

          @media (max-width: 960px) {
              width: 50%;
          }
          padding-bottom: 16px;

        .ingredient-img {
          .element-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .container-wrapper {
    .container-body {
      .ingredients-wrap {
        .ingredient-item {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .h2 {
    font-size: 26px !important;
  }
}
</style>