<template>
  <v-flex>

    <v-container class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('locationPreferences.islands.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="justify-center align-center text-center font-weight-bold">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <checkbox-svg-map
                :map="CapeVerde"
                v-model="locals"
                class="map"
                location-class="region"
                :is-location-selected="l => locals.includes(l)"
                @mouseover="pointLocation"
                @mouseout="unpointLocation"
                @mousemove="moveOnLocation"
                v-on="on"
                v-bind="attrs"
              />
            </template>
            <span>{{ pointedLocation }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="actions my-8">
      <v-row>
        <v-col lg="6" md="6" cols="12">
          <v-btn class="pa-8 font-weight-bold" rounded block @click="$emit('back')">
            {{ $t('formActions.back') }}
          </v-btn>
        </v-col>
        <v-col lg="6" md="6" cols="12">
          <v-btn class="pa-8 font-weight-bold" color="secondary" rounded block @click="finish">
            {{ $t('formActions.finish') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script>
import { CheckboxSvgMap } from 'vue-svg-map';
import CapeVerde from '@jcpaiva/cape-verde';

export default {
  name: "LocationPreferences",
  components: {
    CheckboxSvgMap
  },
  data() {
    return {
      CapeVerde,
      locals: ['pr', 'sd', 'rs'],
      islandOptions: [
        'boaVista',
        'brava',
        'sal',
        'saoNicolau',
        'fogo',
        'santoAntao',
        'santiago',
        'saoVicente'
      ],
      pointedLocation: null
    }
  },
  methods: {

    pointLocation(event) {
      this.pointedLocation = event.target.getAttribute('name');
    },
    unpointLocation() {
      this.pointedLocation = null
    },
    moveOnLocation() {
    },

    finish() {
      this.$emit('finish', {
        locals: this.locals
      });
    }
  }
}
</script>

<style lang="scss">
.question-wrapper {
  .option {
    display: block;
    font-size: 1.6em;
    border: 4px dashed map-get($grey, 'darken-2');
    width: 100%;
    cursor: pointer;

    &--selected {
      border: 4px solid map-get($orange, 'darken-1');
    }
  }

  .checkbox-option {
    display: block;
    width: 100%;
    cursor: pointer;

    .v-input__control {
      .v-icon {
        font-size: 2em;
      }
      .v-label {
        font-size: 1.5em;
      }
    }

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }

  }
}

.map {
  max-width: 600px;
}

.region {
  fill: $grey-light;
  stroke: map-get($grey, 'darken-2');
  stroke-width: 2;
  stroke-dasharray: 10;
  outline: none !important;
  cursor: pointer;

  &[aria-checked='true'] {
    fill: $secondary;
    stroke-dasharray: none;
    stroke: $primary;
    stroke-width: 2;
  }
}
</style>
