// components/AuthLayout.vue
// use this layout for sign in, sign up, forgot password pages

<template>
  <div class="main-wrapper">
    <div class="language-wrapper pa-1 mr-2 mt-1">
       <div class="mr-4"> <ThemeChanger /></div>
      <LocaleChanger />
    </div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md8>
          <v-card class="elevation-12 pa-4">
            <v-layout row align-center justify-center>
              <v-flex xs12 md6>
                <v-layout align-center column>
                  <v-img
                    class="logo"
                    src="../assets/logo-with-text.svg"
                  ></v-img>
                </v-layout>
              </v-flex>
              <v-flex xs12 md6 class="px-4">
                <router-view />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LocaleChanger from "@/components/form/LocaleChanger";
import ThemeChanger from "@/components/form/ThemeChanger";


export default {
  name: "Login",

  components: {
    LocaleChanger,
    ThemeChanger
  },
  
};
</script>

<style scoped lang="scss">
.logo {
  width: 192px;
  @media (max-width: 959px) {
    width: 120px;
  }
}

.main-wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  /* text-transform: none !important; */
  outline: none;
}
.language-wrapper {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}
</style>
