<template>
  <div id="app">
    <div class="gallery-row">
      <div class="gallery-container">

        <div class="d-none d-sm-block">
          <lightbox   :assets="assets"></lightbox>
        </div>
        
          <GallerySlide class="d-flex d-sm-none" :assets="assets"></GallerySlide>
      
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlide from "./GallerySlide.vue";
import lightbox from "./GalleryGrid.vue";
import "./GalleryGrid.css";

export default {
  name: "GalleryRecipe2",
  components: {
    lightbox,
    GallerySlide
  },

   props:{
    assets:{
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
    };
  }
};
</script>

<style>
.gallery-container {
  height: 404.8px;
  width: 100%;
}

.gallery-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
