import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import categories from './modules/categories'
import recipes from './modules/recipes'
import restaurants from './modules/restaurants'
import posts from './modules/posts'
import reviews from './modules/reviews'
import search from './modules/search'
import tags from './modules/tags'
import pantries from './modules/pantries'
import users from './modules/users'
import units from './modules/units'
import products from './modules/products'
import settings from './modules/settings'
import comments from './modules/comments'
import menus from './modules/menus'
import reservations from './modules/reservations'
import restaurantReviews from './modules/restaurantReviews'
import restaurantSearch from './modules/restaurantSearch'
import filterCategory from './modules/filterCategory'
import language from './modules/language'
import partners from './modules/partners'
import contacts from './modules/contacts'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    categories,
    recipes,
    restaurants,
    posts,
    reviews,
    search,
    tags,
    pantries,
    users,
    products,
    units,
    settings,
    comments,
    menus,
    reservations,
    restaurantReviews,
    restaurantSearch,
    filterCategory,
    language,
    partners,
    contacts,
  },
  state: {
    isAuthenticated: localStorage.getItem('isAuthenticated') ? true : false,
  },
  mutations: {
    setItems(state, { resource, items }) {
      state[resource].items = items
    },
    setItem(state, { resource, item }) {
      state[resource].item = item
    },
    addItemToArray(state, { item, index, resource }) {
      Vue.set(state[resource].items, index, item)
    },
  },
  actions: {}
}) 