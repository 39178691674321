<template>
  <v-container class="banner-wrapper" fluid>
  
    <v-row class="header py-16 text-center">
      <v-col lg="8" offset-lg="2" sm="12" offset-sm="0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1>{{ title }}</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 'Banner',
  components: {},

  props: {
    title: {
      type: String,
       default: '',
    },
  }
}
</script>

<style lang="scss" scoped>

.banner-wrapper {
  .header {
    margin-top: -12px;
    background-image: linear-gradient(to right, rgba($secondary, 0.6), rgba($secondary, 0.9)),
    url("../../assets/coffee_machine.jpg");
    background-size: cover;
    background-position: center;

    h1 {
      color: $grey-light;
    }
  }
}

</style>
