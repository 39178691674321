<template>
  <form>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px">
        <v-card>
          <div class="card-header d-flex align-center justify-space-between">
            <div class="block-left"></div>
            <h3>{{$t(`contact_us_page.title`)}}</h3>
            <v-btn color="white" icon text @click="setShowMessageModal(false)">
              <v-icon>close</v-icon>
            </v-btn>
          </div>

          <div class="pa-3">
             <v-form 
                ref="form"
                v-model="valid"
                lazy-validation
                >
              <div>
                  <v-text-field
                    type="email"
                    dense
                    v-model="email"
                    :rules="[
                          (v) => !!v || $t(`login_page.signUp_email_error`),
                          (v) =>
                            (!!v &&
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                              )) ||
                            $t(`login_page.invalid_email`),
                        ]"
                    :label="$t(`contact_us_page.email`)"
                ></v-text-field>


                <v-text-field
                  dense
                  v-model="subject"
                  :rules="[ (v) => !!v || $t(`contact_us_page.missing_subject`)]"
                  :label="$t(`contact_us_page.subject`)"
                ></v-text-field>

                <v-textarea
                  name="input-7-1"
                  filled
                  rows="3"
                  auto-grow
                  v-model="message"
                  :rules="[ (v) => !!v || $t(`contact_us_page.missing_message`)]"
                  :label="$t(`contact_us_page.message`)"
                ></v-textarea>
              </div>

              <div class="mb-6 d-flex justify-end">
                <v-btn :loading="loading"  depressed small color="primary" @click="submitForm()"
                  >{{$t(`contact_us_page.send`)}}</v-btn
                >
              </div>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import toastMixins from "@/mixins/toast";

export default {
  name: "MessageModal",

  components: {
  },

  props: {
     restaurantId: {
      type: Number,
       require: true,
    },
  },

  data: () => ({
    valid: false,
    email: "",
    subject: "",
    message: "",
  }),

  mounted(){
      this.email = this.user.email
  },

  computed: {
    ...mapGetters({
      ShowMessageModal: "settings/getShowMessageModal",
      user: "users/getAccount",
      loading: "contacts/getSending",
    }),

    show: {
      // getter
      get: function () {
        return this.ShowMessageModal;
      },
      // setter
      set: function (newValue) {
        this.setShowMessageModal(newValue);
      },
    },
  },

    methods: {
    ...mapActions("settings", ["setShowMessageModal"]),
    ...mapActions("contacts", ["fetchSendContact"]),

    submitForm(){

      this.$refs.form.validate()

      if(this.valid){
        this.fetchSendContact({
          from: this.email,
          subject: this.subject,
          message: this.message,
          restaurant: this.restaurantId
        })
        .then(() => {
    
            this.email = ''
            this.subject = ''
            this.message = ''
            this.$refs.form.reset()
            this.toast_show(this.$t(`contact_us_page.submit_message.success`))
            
        })
        .catch(() =>  this.toast_show(this.$t(`contact_us_page.submit_message.error`)));
      }
    }
  },

   mixins: [toastMixins],

};
</script>
 
<style scoped lang="scss">
.card-header {
  background-color: #f7b538;
  width: 100%;
  height: 60px;
  .block-left {
    width: 0px;
  }
}

@media (max-width: 600px) {
  .card-header {
    .block-left {
      width: 0%;
    }
  }
}
</style>