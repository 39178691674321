var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"form-wrapper"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[ 
                (v) => !!v || _vm.$t(`profile_page.form_rules.required`),
                (v) => !!v && v.length <6 || _vm.$t(`profile_page.form_rules.min_8_characters`),
                ],"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","label":_vm.$t(`profile_page.edit_password_form.current_password`),"hint":_vm.$t(`profile_page.form_rules.at_least_min_8_characters`),"counter":"","autocomplete":"on"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.passwordsData.oldPassword),callback:function ($$v) {_vm.$set(_vm.passwordsData, "oldPassword", $$v)},expression:"passwordsData.oldPassword"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"on","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"rules":[ 
                (v) => !!v || _vm.$t(`profile_page.form_rules.required`),
                (v) => !!v && v.length <6|| _vm.$t(`profile_page.form_rules.min_8_characters`),
                ],"type":_vm.show2 ? 'text' : 'password',"name":"input-10-1","label":_vm.$t(`profile_page.edit_password_form.new_password`),"hint":_vm.$t(`profile_page.form_rules.at_least_min_8_characters`),"counter":"","required":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.passwordsData.password),callback:function ($$v) {_vm.$set(_vm.passwordsData, "password", $$v)},expression:"passwordsData.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"on","append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"rules":[ 
                (v) => !!v || _vm.$t(`profile_page.form_rules.required`),
                (v) => !!v && v.length <6|| _vm.$t(`profile_page.form_rules.min_8_characters`),
                ],"type":_vm.show3 ? 'text' : 'password',"name":"input-10-1","label":_vm.$t(`profile_page.edit_password_form.confirm_password`),"hint":_vm.$t(`profile_page.form_rules.at_least_min_8_characters`),"counter":"","required":""},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.passwordsData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.passwordsData, "passwordConfirmation", $$v)},expression:"passwordsData.passwordConfirmation"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"row-center"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading == 'updatedUser'},on:{"click":function($event){return _vm.updateAccount('password')}}},[_vm._v(_vm._s(_vm.$t(`profile_page.btn_update_label`)))])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }