<template>
  <div class="container-wrapper ">
    <swiper class="swiper pb-6" :options="swiperOption">
      <swiper-slide v-for="(item) in restaurants" :key="item.id">
        <CardRestaurant :boxShadow="true" :restaurant="item"></CardRestaurant>
      </swiper-slide>
     
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
 
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

import CardRestaurant from "../card/CardRestaurant";

export default {
  title: "Multiple slides per view",
  name: "CarouselRestaurant",
  props: {
      type: {
      type: String,
      default: 'rating'
    },
    restaurants:{
      type: Array,
      default: () => []
    }
  },

  components: {
    CardRestaurant,
    Swiper,
    SwiperSlide,
  },

  data: () => ({
    ecosystem: [],

    swiperOption: {
      // slidesPerView: 2,
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
};
</script>

<style scoped lang="scss">
.swiper {
  height: 100%;
  width: 100%;
}

.swiper-slide {
  width: 25%;
}

.container-wrapper {
  padding: 16px 0;
}
@media (max-width: 1124px) {
  .swiper-slide {
    width: 29%;

}
}

@media (max-width: 960px) {
  .swiper-slide {
  width: 25%;
}
}

@media (max-width: 800px) {
  .swiper-slide {
  width: 30%;
}
}

@media (max-width: 635px) {
  .swiper-slide {
  width: 36%;
}
}

@media (max-width: 480px) {
  .swiper-slide {
  width: 45%;
}
}

@media (max-width: 400px) {
  .swiper-slide {
  width: 55%;
}
}

@media (max-width: 350px) {
  .swiper-slide {
  width: 55%;
}
}

@media (max-width: 290px) {
  .swiper-slide {
  width: 65%;
}
}

</style>