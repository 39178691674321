<template>
  <v-container class="pa-0" fluid >
    <v-row no-gutters class="main-wrapper mt-0">
      <v-col cols="12" sm="12">
        <h1 v-if="!loadingTag" class="text-center display-3 mt-4">
          #{{ tag.tag || "No name" }}
        </h1>
        <v-skeleton-loader
          v-else
          class="mx-auto mt-6"
          max-width="300"
          height="30"
          type="text"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="0"
        :class="showFilter ? 'block-left' : 'hide block-left'"
        v-if="showFilter"
      >
        <!-- <FilterCategory :categoryId="categorySelected"></FilterCategory> -->
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <!-- <v-btn
          class="ma-2 filter-btn"
          fab
          x-small
          color="primary"
          outlined
          @click="setShowFilter()"
        >
          <v-icon dark>
            {{ showFilter ? "close" : "filter_alt" }}
          </v-icon>
        </v-btn> -->
        <div>
          <GridRecipe :recipes="recipes" :loading="loading"></GridRecipe>
          <div class="pa-2 text-center" v-if="recipes.length === 0">{{$t('category_empty')}}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GridRecipe from "../components/grid/GridRecipe";
// import FilterCategory from "../components/filter/FilterCategory";
import { mapActions, mapGetters } from "vuex";

export default {
  //  name: 'swiper-example-multiple-slides-per-biew',
  title: "Tag page",
  name: "Tag",
  components: {
    // FilterCategory,
    GridRecipe,
  },

  data: () => ({
    categories: [],
    tagSelected: 24,
    category: {},
    loading: true,
    loadingTag: true,
    tag:{}
  }),

  mounted() {
    if (this.isAuthenticated) {
      this.category = this.$route.params.category || {};
      this.tagSelected = this.$route.params.id;
      const tagId = this.tagSelected || 24;

      this.fetchListRecipesByTag(`tags=${tagId}`)
        .finally(() => (this.loading = false));

      this.fetchTag(tagId)
        .then((tag) => {
          console.log('tag', tag)
          this.tag = tag;
        })
        .finally(() => (this.loadingTag = false));
    }
  },

  computed: {
    tagId() {
      return this.tagSelected || 15;
    },
    ...mapGetters({
      recipes: "recipes/listRecipesTag",
      showFilter: "filterCategory/getShowFilter",
      onFocus: "search/getOnFocus",
      isAuthenticated: "users/getAccount",
      searchActive: "search/getActive",
    }),
  },

  watch: {
    searchActive: function (val) {
      if (val == true && this.isAuthenticated && this.categories.length == 0) {
        this.fetchListRecipesByTag(`tags=${this.tagId}`)
          .then(() => console.log())
          .catch((error) => console.log(error));
      }
    },
  },

  methods: {
    ...mapActions("recipes", ["fetchListRecipesByTag"]),
    ...mapActions("filterCategory", ["setFilter", "setShowFilter"]),
    ...mapActions("tags", ["fetchTag"]),


    changeDisplay() {},
  },
};
</script>

<style scoped lang="scss">
.filter-btn {
  outline: none;
}
.main-wrapper {
  margin-top: 32px;
}

.card-search {
  width: 19%;
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: calc(100vh - 280px);

  .block-left {
    position: absolute;
    width: 100%;
    z-index: 2;
    max-width: 320px;
    &.hide {
      display: none;
    }
  }
}

.container-wrapper {
  padding: 16px 0;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 820px) {
  .card-search {
    width: 24%;
  }
}
@media (max-width: 960px) {
  .text-info {
    margin-top: 15%;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .card-search {
    width: 33%;
  }
}

@media (max-width: 520px) {
  .card-search {
    width: 49%;
  }
  .container {
    .block-left {
      max-width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
}

@media (max-width: 400px) {
}
</style>