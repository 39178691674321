<template>
  <div>
    
    <h2 v-if="recipes.length > 0 && !loading">
      {{ title }}
    </h2>
    <div v-if="!loading">
      <div v-for="item in recipes" :key="item.id + 'cardListRecipe'">
        <CardListRecipe :recipe="item" :loading="false"></CardListRecipe>
      </div>
    </div>

    <div v-if="loading">
      <div v-for="item in 3" :key="item + 'cardListRecipe'">
        <CardListRecipe :loading="true"></CardListRecipe>
      </div>
    </div>
  </div>
</template>
<script>
import CardListRecipe from "../card/CardListRecipe";

export default {
  name: "RecipeSidebar",
  components: {
    CardListRecipe,
  },
  props: {
    recipes: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      require: true,
    },

    loading: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.block-top {
  background-color: rgb(248, 248, 248);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99999999;
  padding: 10px 0;
  .time {
    margin-bottom: 0;
    padding-bottom: 5px;
    color: var(--v-primary-base);
  }
  .restaurant-img {
    border-radius: 50%;
    width: 80%;
    margin: auto;
  }

  .icon-wrap {
    background-color: var(--v-primary-base);
  }
}

button {
  outline: none;
}

.contacts-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .contact-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    &.item-location {
      display: none;
    }
    .contact-value {
      margin: 0;
      padding: 0 8px;
    }
  }
}

.map-wrapper {
  width: 100%;

  height: 150px;
  background-image: url("../../assets/maps.png");
  background-size: cover;
  border-radius: 8px;
  z-index: 0;
}

@media (max-width: 960px) {
  .block-top {
    .restaurant-img {
      border-radius: 50%;
      width: 30%;
      margin: auto;
    }

    .icon-wrap {
      background-color: #f7b538;
    }
  }

  .contacts-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contact-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 5px 5px;
      &.item-location {
        display: flex;
      }
      .contact-value {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .block-top {
    .restaurant-img {
      border-radius: 50%;
      width: 40%;
    }
  }
}
</style>