<template>

<div class="pa-4 main-wrapper ">
    <div class="display-1 pb-4"> {{ $t('reservation_page.my_reservations') }}</div>

    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      max-width="100%"
      type="table-tbody"
    ></v-skeleton-loader>

  <v-simple-table v-if="!loading">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            #ID
          </th>
          <th class="text-left">
            {{ $t('reservation_page.status') }}
          </th>
          <th class="text-left">
             {{ $t('reservation_page.date') }}
          </th>
          <th class="text-left">
             {{ $t('reservation_page.restaurant') }}
          </th>
          <th class="text-left">
             {{ $t('reservation_page.seats') }}
          </th>
          <th class="text-left">
             {{ $t('reservation_page.actions') }} 
          </th>
         
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in reservations"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>{{ $t(`reservation_page.${item.status}`)  }}</td>
          <td>{{ item.date | formatDateTime }}</td>
          <td>{{ item.restaurant.name }}</td>
          <td>{{ item.seats }}</td>
          <td>
            <v-btn v-if="!item.review && item.status =='confirmed' " small depressed @click.prevent="setShowReview({restaurantId: item.restaurant.id, reservationId : item.id})" :key="item.id + '-button'" color="primary">
               {{ $t('reservation_page.add_review') }} 
            </v-btn>
          </td>
        </tr>
      </tbody>

      
         
        <ReviewModalAddEditRestaurant ></ReviewModalAddEditRestaurant>

    </template>
  </v-simple-table>
  <div class="py-4 " v-if="isListReservationsEmpty">{{$t('empty_list')}}</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import GalleryRow from '../gallery/GalleryRow'
// import AuthorAvatar from "../shared/AuthorAvatar";
import ReviewModalAddEditRestaurant from "../components/review/ReviewModalAddEditRestaurant";

export default {
  name: "Reservations",
  components:{
  ReviewModalAddEditRestaurant
  },

  props: {
    review: {
      type: Object,
      require: true,
    },
  },

   data () {
      return {
        loading: true,
        desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
          },
          {
            name: 'Eclair',
            calories: 262,
          },
          {
            name: 'Cupcake',
            calories: 305,
          },
          {
            name: 'Gingerbread',
            calories: 356,
          },
          {
            name: 'Jelly bean',
            calories: 375,
          },
         
        ],
      }
    },


  created(){
     
    //  this.author = this.review.author;
     this.fetchMyReservations().finally(() => {
            this.loading = false;
          })
  },

  methods: {
    ...mapActions("reviews", ["fetchRemoveReview", "createReview"]),
    ...mapActions("reservations", ["fetchMyReservations", ]),
    ...mapActions("restaurantReviews", ["setShowReview"]),


    removeReview() {
      const recipeId =
        typeof this.review.recipe === "string"
          ? this.review.recipe
          : this.review.recipe.id;
      this.fetchRemoveReview({ recipeId: recipeId, reviewId: this.review.id })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log(error));
    },

    editReview() {
      this.createReview({
        rating: this.review.rating,
        comment: this.review.comment,
        recipe:
          typeof this.review.recipe === "string"
            ? this.review.recipe
            : this.review.recipe.id,
        id: this.review.id,
      });
    },
  },

  computed: {
    ...mapGetters({
      deleting: "reviews/getDeleting",
      reservations: "reservations/getReservations",
    }),
    
    ...mapGetters({
      authUser: "users/getAccount",
    }),

    isAuthor(){
      return this.review.author.id == this.authUser.id
    },

     authorPhoto() {
      return this.review && this.review.author && this.review.author.avatar
        ? this.review.author.avatar.formats.thumbnail.url
        : null;
    },

    isListReservationsEmpty(){
      return this.reservations.length === 0
    },

  },
};
</script>

<style scoped lang="scss">

  .user-info-review,
  .user-info-wrapper {
    width: 100%;
  }

  .main-wrapper{
     min-height: calc(100vh - 280px);
  }

</style>