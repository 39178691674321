<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="3" v-if="amenities.airConditioning">
        <v-icon color="primary">air</v-icon>
        <span> {{ $t("amenities.airConditioning") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.creditCard">
        <v-icon color="primary">payment</v-icon>
        <span> {{ $t("amenities.creditCard") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.driveThru">
        <v-icon color="primary">time_to_leave</v-icon>
        <span> {{ $t("amenities.driveThru") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.freeWifi">
        <v-icon color="primary">wifi</v-icon>
        <span> {{ $t("amenities.freeWifi") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.outdoorSeating">
        <v-icon color="primary">deck</v-icon>
        <span> {{ $t("amenities.outdoorSeating") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.parking">
        <v-icon color="primary">local_parking</v-icon>
        <span> {{ $t("amenities.parking") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.smokingArea">
        <v-icon color="primary">smoking_rooms</v-icon>
        <span> {{ $t("amenities.smokingArea") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.takeAway">
        <v-icon color="primary">delivery_dining</v-icon>
        <span> {{ $t("amenities.takeAway") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.waiterService">
        <v-icon color="primary">room_service</v-icon>
        <span> {{ $t("amenities.waiterService") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.tv">
        <v-icon color="primary">tv</v-icon>
        <span> {{ $t("amenities.tv") }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="amenities.wheelchairAccess">
        <v-icon color="primary">accessible</v-icon>
        <span> {{ $t("amenities.wheelchairAccess") }}</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "RestaurantAmenities",
  components: {},
  props: {
    amenities: {
      type: Object,
      require: true,
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
</style>