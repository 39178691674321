import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    item: {},

    categories: [],
    recipes: [],
    recipesSelected: [],
    categorySelected: null,
    isMenu: true,
    restaurantOfTheWeek:{},
    loading: false
  },

  getters: {
    getRestaurants(state) {
      return state.items
    },

    getRestaurant(state) {
      return state.item
    },
    getRestaurantOfTheWeek(state) {
      return state.restaurantOfTheWeek
    },

    getCategories(state) {
      return state.categories
    },

    getRecipes(state) {
      return state.recipesSelected;
    },

    getCategorySelected(state) {
      return state.categorySelected;
    },
    getIsMenu(state) {
      return state.isMenu;
    },
    getLoading(state) {
      return state.isMenu;
    },
    
    

  },

  actions: {

    fetchRestaurants({ state, commit, rootGetters }) {
      const isHomeInfoLoaded = rootGetters['settings/getIsHomeInfoLoaded']

      if( (state.items && state.items.length>0) && isHomeInfoLoaded)
        return state.items
        
      return axiosInstance.get('restaurants?_limit=10')
        .then(res => {
          const restaurants = res.data
          commit('setItems', { resource: 'restaurants', items: restaurants }, { root: true })
          return state.items
        })
        .catch(err => { console.log(err) })
    },

    fetchRestaurant({ state, commit }, id) {
      commit('set', { resource: 'loading', item: true })
      return axiosInstance.get('restaurants/'+id)
        .then(res => {
          const restaurant = res.data
          commit('set', { resource: 'item', item: restaurant })
          return state.items
        })
        .catch(err => { console.log(err) })
        .finally(()=>  commit('set', { resource: 'loading', item: false }))
    },

    fetchRestaurantOfTheWeek({ state, commit, rootGetters }) {
      const isHomeInfoLoaded = rootGetters['settings/getIsHomeInfoLoaded']

      if( (state.items && state.items.length>0) && isHomeInfoLoaded)
      // if(state.items && state.items.length>0)
        return state.items
        
      return axiosInstance.get('restaurant-of-the-week')
        .then(res => {
          const restaurant = res.data.restaurant
          commit('set', { resource: 'restaurantOfTheWeek', item: restaurant })
          return state.restaurantOfTheWeek
        })
        .catch(err => { console.log(err) })
    },

   
    setRestaurant({ commit }, restaurant) {
      commit('setItem', { resource: 'restaurants', item: restaurant }, { root: true })
    },


    setCategories({ commit }, { categories, recipes }) {

      let list = [];
      if (recipes.length > 0) {
        categories.forEach(cat => {
          const res = recipes.filter(rec => rec.categories.includes(cat.id));
          if (res.length > 0) {
            cat.numberRecipes = res.length;
            list.push(cat)
          }

        });

        commit('set', { resource: 'categories', item: list },)
        commit('set', { resource: 'recipes', item: recipes },)

        if (recipes.length > 0 && list.length > 0) {
          const recipesSelected = recipes.filter(rec => rec.categories.includes(list[0].id))
          commit('set', { resource: 'recipesSelected', item: recipesSelected },)
          commit('set', { resource: 'categorySelected', item: list[0].id },)
        }
      }
    },

    selectCategory({ state, commit }, categoryId) {
      if (state.recipes.length > 0) {
        const recipesSelected = state.recipes.filter(rec => rec.categories.includes(categoryId))
        commit('set', { resource: 'recipesSelected', item: recipesSelected },)
        commit('set', { resource: 'categorySelected', item: categoryId })
      }
    },

    changeDisplay({ state, commit }) {
      commit('set', { resource: 'isMenu', item: !state.isMenu },)
    },
  },

  mutations: {

    set(state, { resource, item }) {
      state[resource] = item;
    },
    

  }
}