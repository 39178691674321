<template>
  <div >
    <Banner :title="$t('contact_us_page.title')"></Banner>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="m-auto">
         <div class="py-3 px-6">
           <p class="text-center">
             <span>{{$t('contact_us_page.intro_title')}}</span><br/>
             <span>{{$t('contact_us_page.intro_text')}}</span>
           </p>
            <div class="pt-4">
              <div>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation
                >
                <v-text-field
                  type="email"
                  dense
                  v-model="email"
                  :rules="[
                        (v) => !!v || $t(`login_page.signUp_email_error`),
                        (v) =>
                          (!!v &&
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            )) ||
                          $t(`login_page.invalid_email`),
                      ]"
                  :label="$t(`contact_us_page.email`)"
                ></v-text-field>

                <v-text-field
                  dense
                  v-model="subject"
                   :rules="[ (v) => (!!v && v.trim()!=='') || $t(`contact_us_page.missing_subject`)]"
                  :label="$t(`contact_us_page.subject`)"
                ></v-text-field>

                <v-textarea
                  name="input-7-1"
                  filled
                  rows="3"
                  auto-grow
                  v-model="message"
                  :rules="[ (v) => (!!v && v.trim()!=='') || $t(`contact_us_page.missing_message`)]"
                  :label="$t(`contact_us_page.message`)"
                ></v-textarea>

                </v-form>

              </div>

              <div class="mb-6 d-flex justify-end">
                <v-btn :loading="loading" depressed small color="primary" @click="submitForm()"
                  >{{$t(`contact_us_page.send`)}}</v-btn
                >
              </div>
            </div>
          </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import toastMixins from "@/mixins/toast";

import Banner from "@/components/banner/Banner";

export default {
  title: "Contact Us",
  name: "Contact",
  components: {
    Banner,
  },
  data: () => ({
    valid: false,
    email: "",
    subject: "",
    message: ""
  }),

  mounted(){
      this.email = this.user.email
  },

  computed: {
    ...mapGetters({
      user: "users/getAccount",
      loading: "contacts/getSending",
 
    }),

  },

    methods: {
    ...mapActions("contacts", ["fetchSendContact"]),

    submitForm(){

      this.$refs.form.validate()

      if(this.valid){
        this.fetchSendContact({
          from: this.email,
          subject: this.subject,
          message: this.message,
        })
        .then(() => {
    
            this.email = ''
            this.subject = ''
            this.message = ''
            this.$refs.form.reset()
            this.toast_show(this.$t(`contact_us_page.submit_message.success`))
            
        })
        .catch(() =>  this.toast_show(this.$t(`contact_us_page.submit_message.error`)));
      }
    }
  
  },

   mixins: [toastMixins],

};
</script>

<style scoped lang="scss">

</style> 