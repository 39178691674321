<template>
  <form id="app" action="#">
    <label :data-state="searchActive+''" for="search">
      <input
        type="text"
        placeholder="Search"
        :value="searchText"
        @input="setSearchText"
        @focus="setOnFocus(true)"
        @blur="setOnFocus(false)"
      />
      <!-- @click="changeState(true)" @blur="changeState(false)" -->
      <v-icon class="search-icon" color="primary" @click="changeState()">{{
        searchActive ? "close" : "mdi-magnify"
      }}</v-icon>
    </label>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Search",
  components: {},

  data: () => ({
    state: "false",
  }),

  methods: {
    ...mapActions("search", ["setSearchActive", "setSearchText", "setOnFocus"]),

    changeState() {
      if (this.searchActive) {
        this.setSearchActive(false);
        this.state = "false";
      } else {
        this.state = "true";
        this.setSearchActive(true);
      }
      // alert(34)
    },

    // changeOnFocus(value) {
    //   console.log("PRINT FOCUS "+value);
    // },

    // searchTextHandler(){
    //   console.log("searchText")
    //   console.log(this.searchText)
    // }
  },

  computed: {
    ...mapGetters({
      searchText: "search/getSearchText",
      searchActive: "search/getActive",
    }),
  },
  
};
</script>

<style scoped lang="scss" >
@media (max-width: 400px) {
}

label {
  position: relative;
  display: inline-block;
  background-color:  var(--v-background-base);;
  padding: 5px 12px;
  transition: all 1s ease;
  border-radius: 0;
  margin: auto;
  &::after {
    content: "";
    display: block;
    height: 2px;
    width: 80%;
    background-color:  var(--v-primary-base);
    transition: all 1s ease 0.5s;
  }
  input {
    transition: width 1s ease, opacity 0.5s ease 0.5s;
    opacity: 1;
    width: 200px;
    height: 25px;
    border: 0;
    outline: none;
    color: var(--v-primary-base);
    padding-right: 25px;
  }
  .search-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    color: #333;
    cursor: pointer;
  }
  &[data-state="false"] {
    border-radius: 30px;
    padding: 5px 5px;
    transition: all 1s ease;
    &::after {
      width: 0%;
      transition: all 0.3s ease;
    }
    i {
      pointer-events: none;
    }
    input {
      width: 28px;
      height: 25px;
      opacity: 0;
      cursor: pointer;
      transition: opacity 0.5s ease, width 1s ease;
      -webkit-appearance: none;
    }
  }
}


</style>