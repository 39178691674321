<template>
  <v-container class="pa-0 pt-6 test" fluid>
    <v-row no-gutters class="main-wrapper">
      <v-col cols="12" sm="12" md="8" class="block-left">
        <v-card class="pa-4 mx-2"  min-height="100%">
          <h3>This is your Shopping List View</h3>
          <IngredientItem></IngredientItem>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" md="4">
        <v-container>
          <v-img
            src="https://res.cloudinary.com/kriolmidia/image/upload/v1596855234/Publicidade1_d6b3c34412.jpg"
            aspect-ratio="2"
            class="adimg"
          ></v-img>
        </v-container>

        <v-container class="flex-column">
          <v-divider class="my-6" color="#f7b538" :inset="inset"></v-divider>
          <h2>Top 3 Recipe Weekly</h2>
          <div v-for="(item) in 3" :key="item">
            <CardListRecipe></CardListRecipe>
          </div>
        </v-container>

        <v-container class="flex-column">
          <v-divider class="my-6" color="#f7b538" :inset="inset"></v-divider>
          <h2>Restaurants</h2>
          <div v-for="(item) in 3" :key="item">
            <CardListRestaurant></CardListRestaurant>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardListRecipe from "../components/card/CardListRecipe";
import CardListRestaurant from "../components/card/CardListRestaurant";
import IngredientItem from "../components/listItem/IngredientItem";

export default {
  title: "List Ingredients",
  name: "ListIngredients",
  components: {
    CardListRecipe,
    CardListRestaurant,
    IngredientItem,
  },

  data: () => ({
    ecosystem: [],
  }),
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
 
}

.test{
   background-color: #f7f8fa;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}
</style>