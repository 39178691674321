import axiosInstance from "@/services/axios";
import { getCategoryByTimeQuery, findIdsList } from "@/helpers/index";

const initialState = () => ({
    items: [],
    _listRecipeFeed: [],
    _listRecipeByTime: [],
    _listRecipeByMostViews: [],
    _listWeeklyTop: [],
    _listMonthlyTop: [],
    _listFavorites: [],
    _listRecipesByAuthor: [],
    _listRecipesSearch: [],
    _listRecipesCategory: [],
    _listRecipesTag: [],
    item: {},
    dailyRecipe: null,
    sendingFavorite: null,
    sendingRecipe: null,
    newRecipe: {
      status: "awaiting_approval",
      difficulty: null,
      cost: null,
      name: null,
      servings: null,
      prepTime: null,
      cookTime: null,
      source: null,
      categories: [],
      assets: [],

      ingredients: [
        {
          product: "",
          unit: "",
          quantity: null,
        },
      ],

      step: [
        {
          description: "",
          hint: false,
          assets: [],
        },
      ],

      tag: [],
    },
    refFormInfos: null,
    refFormIngredients: null,
    refFormInstructions: null,
    recipeRating: 0,
    _listBestReviewsRecipeByCategory: [],
    _listRecentlyRecipeByCategory: [],
  })

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getRecipe(state) {
      
      const result = state.items.filter( (_recipe) => _recipe.id === state.item.id)
      
      if(result.length > 0){
        return result[0];
      }

      return state.item;
    },

    getSendingFavorite(state) {
      return state.sendingFavorite;
    },
    getSendingRecipe(state) {
      return state.sendingRecipe;
    },

    listRecipeFeed(state) {
      return findIdsList(state.items, state._listRecipeFeed);
    },
    listRecipeMostViews(state) {
      return findIdsList(state.items, state._listRecipeByMostViews);
    },

    listRecipeByTime(state) {
      return findIdsList(state.items, state._listRecipeByTime);
    },

    listWeeklyTop(state) {
      return findIdsList(state.items, state._listWeeklyTop);
    },

    listMonthlyTop(state) {
      return findIdsList(state.items, state._listMonthlyTop);
    },

    listRecipesSearch(state) {
      // console.log("state._listRecipesSearch")
      // console.log(state._listRecipesSearch)
      // console.log(state.items)
      return findIdsList(state.items, state._listRecipesSearch);
    },

    listRecipesCategory(state) {
      const list = findIdsList(state.items, state._listRecipesCategory)
      return list;
    },

    listRecipesTag(state) {
      const list = findIdsList(state.items, state._listRecipesTag)
      return list;
    },

    listRecipesByAuthor(state) {
      return findIdsList(state.items, state._listRecipesByAuthor);
    },

    listFavorites(state) {
      return findIdsList(state.items, state._listFavorites);
    },

    listBestReviewsRecipeByCategory(state) {
      return findIdsList(state.items, state._listBestReviewsRecipeByCategory);
    },

    listRecentlyRecipeByCategory(state) {
      return findIdsList(state.items, state._listRecentlyRecipeByCategory);
    },

    getDailyRecipe(state) {
      const result = findIdsList(state.items, state.dailyRecipe);
      return result != null ? result[0] : null;
    },

    getIngredients(state) {
      let list = [];
      if (!state.item || !state.item.ingredients) return list;

      let ingredients = state.item.ingredients;
      for (let j = 0; j < ingredients.length; j++) {
        const ingredient = ingredients[j];
        const product = ingredient["product"];
        const unit = ingredient["unit"];
        const quantity = ingredient["quantity"];
        let image = require("../../assets/placeholder.jpg");

        if (product.image && product["image"][0] && product["image"][0].url){
          image = product["image"][0]["formats"]["thumbnail"]["url"];
        }

        const translations = [];
        const value =
          quantity.toString() + " " + unit["name"] + " " + product["name"];

        list.push({
          id: ingredients[j]["id"],
          value: value,
          translations: translations,
          unit: unit["name"],
          quantity: quantity.toString(),
          product: product["name"],
          image: image,
        });
      }
      // }

      return list;
    },

    getSteps(state) {
      let list = [];

      if (!state.item || !state.item.instructions) return list;

      const steps = state.item.instructions;

      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        list.push({
          id: i + 1,
          value: step["description"],
          hint: step["hint"],
          translation: step["translation"],
          assets: step.image ? [step["image"]] : [],
          // 'assets': step["assets"],
        });
      }

      return list;
    },

    getNewRecipe(state) {
      return state.newRecipe;
    },

    getStepsNewRecipe(state) {
      return state.newRecipe.step;
    },

    getIngredientsNewRecipe(state) {
      return state.newRecipe.ingredients;
    },
    getAssetsNewRecipe(state) {
      return state.newRecipe.assets;
    },
    getRefFormInfos(state) {
      return state.refFormInfos;
    },
    getRefFormIngredients(state) {
      return state.refFormIngredients;
    },
    getRefFormInstructions(state) {
      return state.refFormInstructions;
    },
    
    isValidSteps(state) {
      const steps = state.newRecipe.step;
      for(let i = 0; i<steps.length; i++){
        if(!steps[i].description || steps[i].description.trim().length===0)
          return false;
      }
      return true;
    },

    isValidIngredients(state) {
      const ingredients = state.newRecipe.ingredients;
      for(let i = 0; i<ingredients.length; i++){
        if(!ingredients[0].product || !ingredients[0].unit || !ingredients[0].quantity || ingredients[0].quantity <1)
        return false;
      }
     
      return true;
    },

    getRecipeRating(state) {
      return state.recipeRating;
    },
  },

  actions: {
    fetchRecipe({ state, commit }, id) {
      return axiosInstance
        .get(`recipes/${id}`)
        .then((res) => {
          const _recipe = res.data;
          commit("addRecipe", _recipe);
          commit("set", { resource: "item", item: _recipe });
          return state.item;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchRecipes({ state, commit }, query) {
      return axiosInstance
        .get(`recipes${query}&`)
        .then((res) => {
          const list = res.data;
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchRecipeRating({ commit }, recipeId) {
      return axiosInstance.get(`/recipes/${recipeId}/rating`).then((res) => {
        const rating = res.data;
        // commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
        commit("set", { resource: "recipeRating", item: rating });

        return rating;
      });
    },
   
    fetchListRecipeFeed({ state, commit }) {
      return axiosInstance
        .get("recipes?_sort=updated_at:DESC&_limit=10&status=published")
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", {
            resource: "_listRecipeFeed",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListRecipesByAuthor({ state, commit }, id) {
      // if(state._listRecipesByAuthor.length>0)
      //   return findIdsList(state.items, state._listRecipesByAuthor);

      // return axiosInstance.get('recipes?_limit=10&status=PUBLISHED')
      return axiosInstance
        .get(`recipes?author=${id}`)
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", {
            resource: "_listRecipesByAuthor",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListRecipesSearch({ state, commit }, query) {
      // return axiosInstance.get(`recipes?status=PUBLISHED${query == '_q=' ? '' : '&' + query}`)
      return (
        axiosInstance
          // .get(`recipes?status=approved${query == "_q=" ? "" : "&" + query}`)
          // .get(`recipes?status=approved${query == "_q=" ? "" : "&" + query}`)
          // .get(`recipes${query == "_q=" ? "" : "&" + query}`)
          .get(`recipes?${query}&status=published`)
          .then((res) => {
            const list = res.data;
          
            commit("addListRecipe", {
              resource: "_listRecipesSearch",
              list: list,
            });
            commit("addRecipes", list);
            return state.items;
          })
          .catch((err) => {
            console.log(err);
          })
      );
    },


    fetchListRecipesByCategory({ state, commit }, query) {
   
      return (
        axiosInstance
          // .get(`recipes?status=approved${query == "_q=" ? "" : "&" + query}`)
          // .get(`recipes${query == "_q=" ? "" : "?" + query}`)
          .get(`recipes${query}&status=published`)
          // return axiosInstance.get('recipes?_limit=10&status=PUBLISHED')
          .then((res) => {
            const list = res.data;
          
            commit("addListRecipe", {
              resource: "_listRecipesCategory",
              list: list,
            });
            commit("addRecipes", list);
            return state.items;
          })
          .catch((err) => {
            console.log(err);
          })
      );
    },

    fetchListRecipesByTag({ state, commit }, query) {
   
      return (
        axiosInstance
          // .get(`recipes?status=approved${query == "_q=" ? "" : "&" + query}`)
          // .get(`recipes${query == "_q=" ? "" : "?" + query}`)
          //recipes?tags=24
          .get(`recipes?${query}&status=published`)
          .then((res) => {
            const list = res.data;
          
            commit("addListRecipe", {
              resource: "_listRecipesTag",
              list: list,
            });
            commit("addRecipes", list);
            return state.items;
          })
          .catch((err) => {
            console.log(err);
          })
      );
    },

    fetchListRecipeByTime({ state, commit, rootGetters }) {
      
      const language = rootGetters['language/getLanguage'];
      return axiosInstance
        .get(`recipes${getCategoryByTimeQuery(language)}&status=published`)
        .then((res) => {
          const list = res.data;

          commit("addListRecipe", {
            resource: "_listRecipeByTime",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListRecipeByMostViews({ state, commit }) {
      // return axiosInstance.get('recipes?_limit=10')
      return axiosInstance
        .get("recipes?_sort=views:DESC&_limit=10&status=published")
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", {
            resource: "_listRecipeByMostViews",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //List recipes by Categories
    fetchListBestReviewsRecipeByCategory({ state, commit }, categoryId) {
      commit("addListRecipe", {
        resource: "_listRecentlyRecipeByCategory",
        list: [],
      });

      return axiosInstance
        .get(`recipes?_sort=views:DESC&categories_in=${categoryId}&_limit=10&status=published`)
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", {
            resource: "_listBestReviewsRecipeByCategory",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListRecentlyRecipeByCategory({ state, commit }, categoryId) {
      commit("addListRecipe", {
        resource: "_listRecentlyRecipeByCategory",
        list: [],
      });

      return axiosInstance
        .get(
          `recipes?_sort=created_at:DESC&categories_in=${categoryId}&_limit=10&status=published`
        )
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", {
            resource: "_listRecentlyRecipeByCategory",
            list: list,
          });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchFavoriteRecipes({ state, commit }) {
      return axiosInstance
        .get(`favorites?_sort=created_at:DESC&_limit=10`)
        .then((res) => {
          const list = res.data;

          commit("addListRecipe", { resource: "_listFavorites", list });
          commit("addRecipes", list);

          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListWeeklyTop({ state, commit }, limit) {

      return axiosInstance
        .get(`weekly-top?_limit=${limit?limit:'10'}`)
        .then((res) => {
          const list = res.data.recipes;
          
          commit("addListRecipe", { resource: "_listWeeklyTop", list: list });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchListMonthlyTop({ state, commit }) {
      return axiosInstance
        .get("monthly-top?_limit=10")
        .then((res) => {
          const list = res.data;
          commit("addListRecipe", { resource: "_listMonthlyTop", list: list });
          commit("addRecipes", list);
          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchDailyRecipes({ state, commit }) {
      return axiosInstance
        .get("daily-recipes?day=2020-10-01")
        .then((res) => {
          const list = res.data;
          if (list && list.length > 0) {
            commit("set", {
              resource: "dailyRecipe",
              item: [list[0].recipe.id],
            });
            commit("addRecipes", [list[0].recipe]);
          }

          return state.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchSetFavorite({ state, commit }, recipe) {
      commit("set", { resource: "sendingFavorite", item: recipe.id });
      return axiosInstance({
        method: recipe.isFavorite ? "delete" : "post",
        url: `recipes/${recipe.id}/favorite`,
      })
        .then((res) => {
          const _recipe = res.data;

          recipe.isFavorite = !recipe.isFavorite;
          commit("addFavorite", recipe);
          commit("set", { resource: "sendingFavorite", item: null });
          if (!_recipe) return state.items;
        })
        .catch((err) => {
          console.log(err);
          commit("set", { resource: "sendingFavorite", item: null });
        });
    },

    sendRecipe({ commit, state }) {
      const newRecipe = state.newRecipe;
      commit("set", { resource: "sendingRecipe", item: true });
      const formData = new FormData();

      let recipeData = {
        name: newRecipe.name,
        status: "awaiting_approval",
        prepTime: newRecipe.prepTime,
        servings: newRecipe.servings,
        cookTime: newRecipe.cookTime,
        difficulty: newRecipe.difficulty,
        cost: newRecipe.cost,
        tags: newRecipe.tag,
        categories: newRecipe.categories,
        ingredients: [],
        instructions: [],
      };

      let ingredients = [];
      newRecipe.ingredients.forEach((ingredient) => {
        const obj = Object.assign({}, ingredient);
        obj.product = obj.product.id;
        obj.unit = obj.unit.id;
        ingredients.push(obj);
      });

      recipeData.ingredients = [...ingredients];

      let steps = [];
      newRecipe.step.forEach((item) => {
        const objStep = Object.assign({}, item);

        objStep.type = item.hint ? "tip" : "step";

        objStep.assets = undefined;
        steps.push(objStep);

        for (let i = 0; i < item.assets.length; i++) {
          const img = item.assets[i];
          formData.append(`files.step[${i}].assets`, img, img.name);
        }
      });

      recipeData.instructions = [...steps];

      formData.append("data", JSON.stringify(recipeData));

      newRecipe.assets.forEach((img) => {
        formData.append("files.assets", img, img.name);
      });

      return axiosInstance({
        method: "post",
        url: `recipes`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          // commit('recipes/updateRecipeReview', { type: review.id ? "edit" : "add", recipeId: review.recipeId, review: _review }, { root: true })
          commit("set", { resource: "sendingRecipe", item: false });
          commit("resetNewRecipe");
          // commit('set', { resource: 'review', value: null })

          return state.items;
        })
        .catch((err) => {
          console.log(err);
          commit("set", { resource: "sendingRecipe", item: false });
          // commit('set', { resource: 'review', value: null })
        });
    },

    setRecipe({ commit }, recipe) {
      commit("set", { resource: "item", item: recipe });
    },

    setNewRecipe({ commit }, recipe) {
      commit("set", { resource: "newRecipe", item: recipe });
    },
    setRefFormInfos({ commit }, ref) {
      commit("set", { resource: "refFormInfos", item: ref });
    },
    setRefFormIngredients({ commit }, ref) {
      commit("set", { resource: "refFormIngredients", item: ref });
    },
    setRefFormInstructions({ commit }, ref) {
      commit("set", { resource: "refFormInstructions", item: ref });
    },

    addStep({ commit }) {
      commit("addStepNewRecipe");
    },

    addIngredient({ commit }, stepIndex) {
      commit("addIngredientNewRecipe", { stepIndex: stepIndex });
    },

    removeCategoryNewRecipe({ commit }, item) {
      commit("deleteCategoryNewRecipe", { item: item });
    },

    removeStepNewRecipe({ commit }, index) {
      commit("deleteStepNewRecipe", { index: index });
    },

    removeIngredientNewRecipe({ commit }, indexes) {
      commit("deleteIngredientNewRecipe", { indexes: indexes });
    },

    removeAssetsNewRecipe({ commit }, indexes) {
      commit("deleteAssetsNewRecipe", { indexes: indexes });
    },

    addAssets({ commit }, assets) {
      commit("add_assets", { assets: assets });
    },
  },

  mutations: {
    addListRecipe(state, { resource, list }) {
     
      let listRecipesIndex = [];
      if (list && list.length > 0) 
        listRecipesIndex = list.map((a) => a.id);
      state[resource] = [...listRecipesIndex];
  
    },

    addRecipes(state, list) {
      const items = state.items;
      let result = [];
      if (list && list.length > 0)
        list.forEach((el) => {
          const res = items.filter((el2) => el2.id == el.id);
          if (res.length == 0) {
            const listFav = state._listFavorites;
            const resFav = listFav.filter((elFav) => elFav == el.id);
            el.isFavorite = resFav.length != 0;
            result.push(el);
          }
        });
      state.items = [...items, ...result];
    },

    addRecipe(state, recipe) {
      const items = state.items;
      let result = [];
      if (!recipe) {
        const res = items.filter((el2) => el2.id == recipe.id);

        const listFav = state._listFavorites;
        const resFav = listFav.filter((elFav) => elFav == recipe.id);
        recipe.isFavorite = resFav.length != 0;

        if (res.length == 0) {
          result.push(recipe);
        } else {
          items.forEach((el) => {
            if (el.id == recipe.id) el = recipe;
          });
        }
        state.item = recipe;
        state.items = [...items];
      }
    },

    //Todo - update this function later, just replace the current recipe with the review.recipe, for now is missing the ranting and reviews on the review.recipe
    updateRecipeReview(state, { type, recipeId, review }) {
      const items = state.items;
      let recipe = null;

      let i;
      for (i = 0; i < items.length; i++) {
        if (items[i].id == recipeId) {
          recipe = items[i];
          if (type == "edit") items[i] = review;
        }
      }

      if (recipe) {
        const reviews = recipe.reviews;
        if (type == "add") {
          recipe.reviews = [review, ...reviews];

          // ((Overall Rating * Total Rating) + new Rating) / (Total Rating + 1)
          recipe.rating =
            (recipe.rating * recipe.reviewsCount + review.rating) /
            (recipe.reviewsCount + 1);
          recipe.reviewsCount = recipe.reviewsCount + 1;
        }
        if (type == "edit") {
          let previousReview;
          for (i = 0; i < reviews.length; i++) {
            if (reviews[i].id == review.id) {
              previousReview = reviews[i];
              reviews[i] = review;
            }
          }
          const reviewsCount = recipe.reviewsCount;
          const newReviewsCount =
            recipe.reviewsCount === 0 ? 0 : recipe.reviewsCount - 1;

          recipe.rating =
            newReviewsCount == 0
              ? 0
              : (recipe.rating * reviewsCount - previousReview.rating) /
                newReviewsCount;
          recipe.rating =
            (recipe.rating * newReviewsCount + review.rating) / reviewsCount;
          recipe.reviews = [...reviews];
        } else if (type == "delete") {
          const newReviewsCount =
            recipe.reviewsCount === 0 ? 0 : recipe.reviewsCount - 1;
          recipe.reviews = recipe.reviews.filter(
            (_review) => _review.id != review.id
          );
          recipe.rating =
            newReviewsCount == 0
              ? 0
              : (recipe.rating * recipe.reviewsCount - review.rating) /
                newReviewsCount;
          recipe.reviewsCount = newReviewsCount;
        }

        state.items[i] = recipe;
        state.item = recipe;
      }
    },

    addFavorite(state, recipe) {
      const items = state.items;
      if (items && items.length > 0) {
        items.forEach((el) => {
          if (el.id == recipe.id) el.isFavorite = recipe.isFavorite;
        });
        state.items = [...items];

        if (recipe.isFavorite) {
          state._listFavorites = [recipe.id, ...state._listFavorites];
        } else {

          state._listFavorites = [...state._listFavorites.filter(
            (_id) => _id !== recipe.id
          )];
        }
      }
    },

    deleteCategoryNewRecipe(state, { item }) {
      const index = state.newRecipe.categories.indexOf(item.id);
      if (index >= 0) state.newRecipe.categories.splice(index, 1);
    },

    deleteStepNewRecipe(state, { index }) {
      if (state.newRecipe.step.length > 1)
        state.newRecipe.step.splice(index, 1);
    },

    deleteIngredientNewRecipe(state, { indexes }) {
      if (state.newRecipe.ingredients.length > 1) {
        state.newRecipe.ingredients.splice(indexes.ingredientIndex, 1);
      }
    },

    deleteAssetsNewRecipe(state, { indexes }) {
      if (indexes.type == "step") {
        state.newRecipe.step[indexes.stepIndex].assets.splice(indexes.index, 1);
      } else if (indexes.type == "recipe")
        state.newRecipe.assets.splice(indexes.index, 1);
    },

    addStepNewRecipe(state) {
      state.newRecipe.step.push({
        description: "",
        hint: false,
        recipe: null,
        ingredients: [
          {
            product: "",
            unit: "",
            quantity: null,
          },
        ],
        assets: [],
      });
    },

    add_assets(state, { assets }) {
      const index = assets.index;
      const type = assets.type;
      const files = assets.files;

      if (type == "step") state.newRecipe.step[index].assets = files;
      else if (type == "recipe") state.newRecipe.assets = files;
    },

    addIngredientNewRecipe(state) {
      state.newRecipe.ingredients.push({
        product: "",
        unit: "",
        quantity: null,
      });
    },

    resetNewRecipe(state) {
      state.newRecipe = {
        status: "awaiting_approval",
        difficulty: null,
        cost: null,
        name: null,
        servings: null,
        prepTime: null,
        cookTime: null,
        categories: [],
        assets: [],
        step: [
          {
            description: "",
            hint: false,
            assets: [],
          },
        ],
        ingredients: [
          {
            product: "",
            unit: "",
            quantity: null,
          },
        ],
        tag: [],
      };
    },

    set(state, { resource, item }) {
      state[resource] = item;
    },

    setRecipe(state, recipe) {
      
      state.item = recipe;
    },

    resetState (state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
  },
};
