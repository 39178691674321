<template>

        <div class="d-flex flex-wrap">
        
          <div class="d-flex justify-space-between name-container">
            <h2>{{ recipe.name }}</h2>
            <BtnFavorite :recipe="recipe"></BtnFavorite>
          </div>

          <div class="info-wrapper d-flex justify-space-between pt-2 pb-0">
            <div class="d-flex align-center">
              <span class="mx-2">
                <AuthorAvatar :user="author" :size="38"></AuthorAvatar>
              </span>

               <h4 class>{{ author_fullName }}</h4>

            </div>

            <div class="d-flex align-center">
              <span>{{ recipeRating | round1Decimal }}</span>
              <v-icon size="20" color="primary">star</v-icon>
              <span class="caption ml-1"
                >({{ reviewCount | formatNumberReviews }} reviews)</span
              >
            </div>
          </div>

          <div
            class="info-wrapper pt-6 pb-8 d-flex justify-xs-start justify-sm-space-between justify-lg-space-around"
          >
            <v-card :elevation="2" class="mr-sm-0 mr-4">
              <div
                class="d-flex flex-column align-center justify-center px-5 py-2 "
              >
                <div class="value-top" v-t="'recipe_page.difficulty'"></div>
                <div class="value-bottom body-2">
                  {{ $t(`difficulty.${recipe.difficulty}`) }}
                </div>
              </div>
            </v-card>

            <v-card :elevation="2"  class="mr-sm-0 mr-4">
              <div
                class="d-flex flex-column align-center justify-center px-5 py-2"
              >
                <div class="value-top">
                  {{
                    ((recipe.prepTime || 0) + (recipe.cookTime || 0))
                      | formatDuration
                  }}
                </div>
                <div class="value-bottom body-2"  v-t="'recipe_page.duration'"></div>
              </div>
            </v-card>

            <v-card :elevation="2" class="mr-sm-0 mr-4">
              <div
                class="d-flex flex-column align-center justify-center px-5 py-2"
              >
                <div class="value-top">{{ $t(`costs.${recipe.cost}`) }}</div>
                <div class="value-bottom body-2" v-t="'recipe_page.cost'"></div>
              </div>
            </v-card>

            <v-card :elevation="2" class="mr-sm-0 mr-4">
              <div
                class="d-flex flex-column align-center justify-center px-5 py-2"
              >
                <div class="value-top">{{ recipe.servings }}</div>
                <div class="value-bottom body-2"  v-t="'recipe_page.serving'"></div>
              </div>
            </v-card>

            <v-card :elevation="2">
              <div
                class="d-flex flex-column align-center justify-center px-5 py-2"
              >
                <div class="value-top">
                  {{ recipe.views | formatNumberViews }}
                </div>
                <div class="value-bottom body-2" v-t="'recipe_page.views'"></div>
              </div>
            </v-card>
          </div>

 
        </div>

 

</template>

<script>

import BtnFavorite from "@/components/btn/BtnFavorite";
import AuthorAvatar from "@/components/shared/AuthorAvatar";
import authorMixins from "@/mixins/author";
import { mapActions, mapGetters } from "vuex";


export default {
  title: "Sabores CV Receita",
  name: "RecipeHeader",
  components: {

    BtnFavorite,
    AuthorAvatar,
  },

   props: {
   
    recipe: {
      type: Object,
      require: true,
    },

    user: {
      type: Object,
      require: true,
    },
   },


  data: () => ({}),

    created() {
      this.author = this.user;
    },
  
  computed: {

    ...mapGetters({
          recipeRating: "recipes/getRecipeRating",
          sendingFavorite: "recipes/getSendingFavorite",
          reviewCount: "reviews/getReviewCount",

    }),

  },

  methods: {
    ...mapActions("recipes", [ "fetchSetFavorite"]),

    setFavorite() {
      this.fetchSetFavorite(this.recipe);
    },
  },

  mixins: [authorMixins],
};
</script>

<style scoped lang="scss">

.name-container {
  width: 100%;
}

.info-wrapper {
  width: 100%;
  flex-wrap: wrap;
  .v-card {
    border-radius: 8px;
  }
  .value-top {
    font-weight: bold;
    color: #f7b538;
    line-height: 1.3;
  }

  .value-bottom {
    color: gray;
    line-height: 1.3;
    opacity: 0.7;
  }

  .caption {
    color: grey;
  }
}
@media (max-width: 600px) {

  .v-card {
    margin-bottom: 10px;
  }

}


</style>