import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    getUnits(state) {
      return state.items
    },
  },

  actions: {
    fetchUnits({ state, commit }) {
      if (state.items > 0)
        return state.items;

      return axiosInstance.get('units?_sort=name:ASC')
        .then(res => {
          const units = res.data
          commit('setItems', { resource: 'units', items: units }, { root: true })
          return state.items
        })
        .catch(err => { console.log(err) })
    }
  }
}