
const initialState = () => ({
  showDrawer: false,
  showMessageModal: false,
  showSlotsModal: false,
  isHomeInfoLoaded: false,

})

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getShowDrawer(state) {
      return state.showDrawer
    },
    getShowMessageModal(state) {
      return state.showMessageModal
    },
   
    getShowSlotsModal(state) {
      return state.showSlotsModal
    },
    getIsHomeInfoLoaded(state) {
      return state.isHomeInfoLoaded
    },
  },

  actions: {
    setShowDrawer({ commit }, value) {
      commit('set', { resource: 'showDrawer', item: value });
    },
    setShowMessageModal({ commit }, value) {
      commit('set', { resource: 'showMessageModal', item: value });
    },
    setShowSlotsModal({ commit }, value) {
      commit('set', { resource: 'showSlotsModal', item: value });
    },
    setIsHomeInfoLoaded({ commit }, value) {
      commit('set', { resource: 'isHomeInfoLoaded', item: value });
    }
  },

  mutations: {
    set(state, { resource, item }) {
      state[resource] = item;
    },
    resetState (state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },

  }
}