<template>
        <v-btn
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click.stop="setFavorite()"
        :loading="sendingFavorite == recipe.id"
      >
        <v-icon dark>{{
          recipe.isFavorite ? "mdi-heart" : "favorite_border"
        }}</v-icon>
      </v-btn>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BtnFavorite",

  props: {
    recipe: {
      type: Object,
      require: true,
    },
  },

  computed: {
    ...mapGetters({
      sendingFavorite: "recipes/getSendingFavorite",
    }),
  },

  methods: {
    ...mapActions("recipes", ["fetchSetFavorite"]),

    setFavorite() {
      this.fetchSetFavorite(this.recipe);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
