<template>
  <!-- :center="{ lat: 10, lng: 10 }" -->
  <div>
    <!-- <label>
      AutoComplete
      <GmapAutocomplete @place_changed="setPlace">
      </GmapAutocomplete>
      <button @click="usePlace">Add</button>
    </label> -->

    <GmapMap
      ref="mapRef"
      :zoom="13"
      :style="'width: 100%; height:' + getHeight"
      :center="centerPoint"
    >
    
      <GmapInfoWindow
        :key="index + 'T'"
        v-for="(m, index) in markers"
        :position="google && new google.maps.LatLng(m.lat, m.lng)"
        :options="getInfoOptions(m.label)"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
      </GmapInfoWindow>

      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="google && new google.maps.LatLng(m.lat, m.lng)"
        :clickable="true"
        :draggable="true"
        @click="center = google && new google.maps.LatLng(m.lat, m.lng)"
        icon="https://res.cloudinary.com/kriolmidia/image/upload/v1677103991/red_circle_536672d443.png"
      />
      <!-- icon="https://res.cloudinary.com/kriolmidia/image/upload/v1631847869/restaurant_marker_be830c0b1a.png" -->
    </GmapMap>
    <!-- @click="center=google && new google.maps.LatLng(m.lat, m.lng)" -->
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";

export default {
  name: "MapSection",
  components: {},

  props: {
    mapCenter: {
      type: Object,
      require: true,
    },
    height: {
      type: String,
    },
    markers: {
      type: Array,
      require: true,
    },
  },

  data() {
    const lat = 14.919576;
    const lng = -23.508067;
    return {
      center: { lat, lng },
      place: null,
      infoWindowPos: { lat, lng },
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: " vamos testar",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
    };
  },

  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)

    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   map.panTo( this.mapCenter? this.mapCenter : {lat:14.919576, lng: -23.508067 } );
    // });
    if (this.mapCenter) this.center = this.mapCenter;
  },

  computed: {
    getHeight() {
      return this.height || "300px";
    },

    centerPoint() {
      return this.centerMapSearch &&
        this.centerMapSearch.lat &&
        this.centerMapSearch.lng
        ? this.centerMapSearch
        : this.center;
    },

    ...mapGetters({
      centerMapSearch: "restaurantSearch/getCenterMapSearch",
    }),

    google: gmapApi,

    // labelcontent: "<b>TEST</b>",

    // labelObj: {
    //   text: "label",
    //   color: "black",
    //   fontWeight: "bold",
    //   fontSize: "12px",
    // },
  },

  methods: {
    btnClick(link) {
      window.location.href = link;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace(place) {
      console.log(place);
    },

    getInfoOptions(text) {
      return {
        content: text,
        pixelOffset: {
          width: 0,
          height: -35,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
