<template>
  <v-container
    fluid
    class="d-flex pa-0 flex-column container-wrapper-reviews"
  >
    <div class="mt-4">
      


      
   
    <p v-if="isEmpty">
            {{$t(`reviews_component.add_the_first`)}} <a>{{$t(`reviews_component.here`)}}</a>.
          </p>

    <ReviewItem
      v-for="item in reviews"
      :key="item.id"
      :review="item"
    ></ReviewItem>
     </div>

    <!-- <div class="flex text-center pb-5" v-if="!isEmpty && showViewAll"> -->

    <ReviewModalAddEditRestaurant :restaurantId="restaurantId" ></ReviewModalAddEditRestaurant>

    <!-- <div v-if="showAllReview">
              <ReviewModal :recipeId="recipe.id" :dialog="showAllReview"  @closeEvent="hideShowAllReview()"></ReviewModal>
          </div>
          <div v-if="review!=null">
              <ReviewModalAddEdit :recipeId="recipe.id"  ></ReviewModalAddEdit>
          </div> -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReviewItem from "./ReviewItemRestaurant";
import ReviewModalAddEditRestaurant from "./ReviewModalAddEditRestaurant";
// import { restaurantRating } from "../../mockup";
// import ReviewModal from "./ReviewModal";
// import ReviewModalAddEdit from "./ReviewModalAddEdit";

export default {
  name: "RestaurantReviewCard",
  components: {
    ReviewItem,
    ReviewModalAddEditRestaurant,
    // ReviewModal,
    // ReviewModalAddEdit,
  },

  props: {
    showLoadMore: {
      type: Boolean,
      default: false,
    },

    showViewAll: {
      type: Boolean,
      default: true,
    },
 
      restaurantId: {
        type: Number,
        require: true,
      },
  

  },

  data: () => ({
    showAllReview: false,
    showAddEditReview: false,
  }),

  created(){
    this.fetchRestaurantReviews({restaurantId: this.restaurantId});
   
  },

  computed: {
    ...mapGetters({
      recipe: "recipes/getRecipe",
      // reviews: "reviews/getReviews",
      review: "restaurantReviews/getReview",
      reviews: "restaurantReviews/getReviews",
    }),

    isEmpty() {
      return !this.reviews || this.reviews.length == 0;
    },
  },

  methods: {
    ...mapActions("restaurantReviews", ["createReview", "fetchRestaurantReviews"]),

    hideShowAllReview() {
      this.showAllReview = false;
    },

    hideShowAddEditReview() {
      this.showAddEditReview = false;
    },

    // addReview(){

    //   this.createReview({
    //     "rating": 0,
    //     "comment": null,
    //     "recipeId": "this.recipe.id",
    //   })
    // }
  },
};
</script>

<style scoped lang="scss">
.container-wrapper-reviews {
  /* background-color: rgb(248, 248, 248); */
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
}
</style>