<template>
  <v-flex>
    <v-container class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('dietaryNeeds.any.question') }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="justify-center align-center text-center font-weight-bold">
          <label :class="`option rounded-lg px-16 py-8 ${dietaryNeeds && 'option--selected'}`"
                 @click="dietaryNeeds = true">
            {{ $t('dietaryNeeds.any.options.yes') }}
          </label>
        </v-col>
        <v-col class="justify-center align-center">
          <label
              :class="`option rounded-lg px-16 py-8 text-center font-weight-bold ${dietaryNeeds || 'option--selected'}`"
              @click="dietaryNeeds = false">
            {{ $t('dietaryNeeds.any.options.no') }}
          </label>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="dietaryNeeds" class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('dietaryNeeds.preferences.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="justify-center align-center">
          <label
              :class="`option rounded-lg px-16 py-8 text-center font-weight-bold ${preferences === 'pescetarian' && 'option--selected'}`"
              @click="preferences = 'pescetarian'"
          >
            {{ $t('dietaryNeeds.preferences.options.pescetarian') }}
          </label>
        </v-col>
        <v-col class="justify-center align-center">
          <label
              :class="`option rounded-lg px-16 py-8 text-center font-weight-bold ${preferences === 'vegan' && 'option--selected'}`"
              @click="preferences = 'vegan'"
          >
            {{ $t('dietaryNeeds.preferences.options.vegan') }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="justify-center align-center">
          <label
              :class="`option rounded-lg px-16 py-8 text-center font-weight-bold ${preferences === 'vegetarian' && 'option--selected'}`"
              @click="preferences = 'vegetarian'"
          >
            {{ $t('dietaryNeeds.preferences.options.vegetarian') }}
          </label>
        </v-col>
        <v-col class="justify-center align-center">
          <label
              :class="`option rounded-lg px-16 py-8 text-center font-weight-bold ${preferences === 'paleo' && 'option--selected'}`"
              @click="preferences = 'paleo'"
          >
            {{ $t('dietaryNeeds.preferences.options.paleo') }}
          </label>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="dietaryNeeds" class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('locationPreferences.islands.question') }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="justify-center align-center font-weight-bold">
          <v-checkbox
              v-for="option in allergyOptions.slice(0, allergyOptions.length/2)"
              v-bind:key="option"
              v-model="allergies"
              :label="$t('dietaryNeeds.allergies.options.' + option)"
              :value="option"
              hide-details
              class="checkbox-option ma-0 mb-8"
          ></v-checkbox>
        </v-col>
        <v-col class="justify-center align-center font-weight-bold">
          <v-checkbox
              v-for="option in allergyOptions.slice(allergyOptions.length/2)"
              v-bind:key="option"
              v-model="allergies"
              :label="$t('dietaryNeeds.allergies.options.' + option)"
              :value="option"
              hide-details
              class="checkbox-option ma-0 mb-8"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="dietaryNeeds" class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('dietaryNeeds.religious.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-for="option in religiousOptions"
            v-bind:key="option"
            class="justify-center align-center font-weight-bold"
        >
          <v-checkbox
              v-model="religious"
              :label="$t('dietaryNeeds.religious.options.' + option)"
              :value="option"
              hide-details
              class="checkbox-option ma-0 mb-8"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="actions my-8">
      <v-row>
        <v-col lg="6" md="6" cols="12">
          <v-btn class="pa-8 font-weight-bold" rounded block @click="$emit('back')">
            {{ $t('formActions.back') }}
          </v-btn>
        </v-col>
        <v-col lg="6" md="6" cols="12">
          <v-btn v-if="hasNext" class="pa-8 font-weight-bold" color="secondary" rounded block @click="next">
            {{ $t('formActions.next') }}
          </v-btn>
          <v-btn v-else class="pa-8 font-weight-bold" color="secondary" rounded block @click="finish">
            {{ $t('formActions.finish') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: "DietaryNeeds",
  props: {
    hasNext: {
      type: Boolean
    }
  },
  data() {
    return {
      dietaryNeeds: true,
      preferences: 'pescetarian',
      allergies: [],
      allergyOptions: [
        'beef',
        'dairy',
        'egg',
        'fish',
        'gluten',
        'nuts',
        'shellfish',
        'soy'
      ],
      religious: [],
      religiousOptions: [
        'halal',
        'kosher'
      ]
    }
  },
  methods: {
    next() {
      this.$emit('next', {
        dietaryNeeds: this.dietaryNeeds,
        preferences: this.preferences,
        allergy: this.allergies
      });
    },
    finish() {
      this.$emit('finish', {
        dietaryNeeds: this.dietaryNeeds,
        preferences: this.preferences,
        allergy: this.allergies
      });
    }
  }
}
</script>

<style lang="scss">
.question-wrapper {
  .option {
    display: block;
    font-size: 1.6em;
    border: 4px dashed map-get($grey, 'darken-2');
    width: 100%;
    cursor: pointer;

    &--selected {
      border: 4px solid map-get($orange, 'darken-1');
    }
  }

  .checkbox-option {
    display: block;
    width: 100%;
    cursor: pointer;

    .v-input__control {
      .v-icon {
        font-size: 2em;
      }

      .v-label {
        font-size: 1.5em;
      }
    }

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }

  }
}
</style>
