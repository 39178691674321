<template>
  <v-container class="pa-0" fluid>
     <LoadingContainer :loading="loading"></LoadingContainer>
    <v-row no-gutters class="main-wrapper" v-if="!loading">
      <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
        <BtnBack></BtnBack>
        <GalleryRecipe2 :assets="assest" />
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <RestaurantHeader :restaurant="restaurant" />
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <div class="pa-3 pb-8">
          <v-expansion-panels accordion multiple v-model="panel">
            <v-expansion-panel key="ingredient">
              <v-expansion-panel-header
                ><h4 class="accent--text font-weight-bold">
                  {{ $t("restaurant_page.details") }}
                </h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div v-html="description"></div>
                <div class="py-2">
                  <b>{{ $t("restaurant_page.features") }}</b>
                </div>
                <RestaurantAmenities :amenities="amenities" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="menus">
              <v-expansion-panel-header><h4 class="accent--text font-weight-bold">Menu</h4></v-expansion-panel-header>
              <v-expansion-panel-content>
                <GridRestaurantMenu
                  :listMenusBycategories="listMenusBycategories"
                ></GridRestaurantMenu>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="other-products">
              <v-expansion-panel-header><h4 class="accent--text font-weight-bold">{{ $t("restaurant_page.other_products") }}</h4></v-expansion-panel-header>
              <v-expansion-panel-content>
                <GridRestaurantProducts
                  :otherProducts="restaurant.otherProducts"
                ></GridRestaurantProducts>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="review-content">
              <v-expansion-panel-header>
                <h4 class="d-flex accent--text font-weight-bold">
                  <span>{{$t('restaurant_page.reviews')}}</span>
                  <span class="grey--text">
                    <span class="d-flex pl-4">
                      <span>{{reviewsValue}}</span>
                      <v-icon color="primary" small>star</v-icon>
                      <!-- <span>({{reviews.length}})</span> -->
                      <span>({{numberReviews}})</span>
                    </span>
                  </span>
                </h4> 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RestaurantReviewCard :restaurantId="restaurant.id"></RestaurantReviewCard>
              </v-expansion-panel-content>
            </v-expansion-panel>

              <v-expansion-panel key="restaurant-contact-location">
              <v-expansion-panel-header
                ><h4 class="accent--text font-weight-bold">
                  {{ $t("restaurant_page.contacts_licenses") }}
                </h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <RestaurantContactLocation :restaurant="restaurant" />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GridRestaurantMenu from "../components/grid/GridRestaurantMenu";
import GridRestaurantProducts from "../components/grid/GridRestaurantProducts";
import RestaurantReviewCard from "../components/review/RestaurantReviewCard";
import GalleryRecipe2 from "../components/gallery/GalleryRecipe2";
import BtnBack from "../components/btn/BtnBack";
// import RestaurantSlots from "../components/tab/restaurant/RestaurantSlots";
import RestaurantAmenities from "../components/tab/restaurant/RestaurantAmenities";
import RestaurantContactLocation from "../components/tab/restaurant/RestaurantContactLocation";
import RestaurantHeader from "../components/tab/restaurant/RestaurantHeader";
import LoadingContainer from '@/components/shared/LoadingContainer';

// import { galleryRestaurant } from "../mockup";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "Multiple slides per view",
  name: "Restaurant",
  components: {
    GridRestaurantMenu,
    GridRestaurantProducts,
    RestaurantReviewCard,
    GalleryRecipe2,
    BtnBack,
    // RestaurantSlots,
    RestaurantAmenities,
    RestaurantContactLocation,
    RestaurantHeader,
    LoadingContainer
  },

  data: () => ({
    assets: [],
    restaurnt: {},
    panel: [0, 1, 2, 3, 4, 5],
    loading: true,
    swiperOption: {
      // slidesPerView: 2,
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),


  created() {

    let _restaurant = this.$route.params.restaurant;
    let restaurantId = this.$route.params.restaurantId;
  
      if(_restaurant){
        this.setRestaurant(_restaurant);
        this.fetchMenus(_restaurant.id);
        this.loading = false
      }
      else{
        Promise.all([
          this.fetchRestaurant(restaurantId),
          this.fetchMenus(restaurantId),
        ])
        .then( () => this.loading = false )
        .catch( () => this.loading = true )
      }
      
  },

  methods: {
    ...mapActions("restaurants", [
      "fetchRestaurant",
      "setRestaurant",
      "setCategories",
      "changeDisplay",
    ]),
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("menus", ["fetchMenus"]),
  },

  computed: {
    assest() {
      return this.restaurant ? this.restaurant.assets : [];
    },

    amenities() {
      return this.restaurant ? this.restaurant.amenities : [];
    },

    slots() {
      return this.restaurant ? this.restaurant.slots : [];
    },

    description() {
      return this.restaurant ? this.restaurant.description : "";
    },

    reviews() {
      return this.restaurant ? this.restaurant.reviews : [];
    },


  
    ...mapGetters({
      restaurant: "restaurants/getRestaurant",
      categories: "restaurants/getCategories",
      recipes: "restaurants/getRecipes",
      loadingRestaurant: "restaurants/geLoading",
      isMenu: "restaurants/getIsMenu",
      menus: "menus/getMenus",
      listMenusBycategories: "menus/getMenuByCategories",
      numberReviews: "restaurantReviews/getNumberReviews",
      reviewsValue: "restaurantReviews/getReviewsValue",
    }),
  
  },
};
</script>

<style scoped lang="scss">
.swiper-slide {
  width: 18%;
}

/* h4{
  color: ;
} */

.container-img {
  width: 100%;
  height: 60%;
  position: absolute;
  /* background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    url("../assets/profile_bg.jpg"); */
  background-size: cover;
  opacity: 0.4;
  z-index: 0;
}

.container {
  display: flex;
}

.wrapper-1 {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 16px;
  margin: 16px;

  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  max-width: 304px;
}

.container-wrapper {
  padding-bottom: 16px;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

.btn-container {
  display: flex;
  flex-direction: row;
}

.logo-wrap {
  position: absolute;
}

.user-img {
  border-radius: 50%;
  max-width: 70px;
  max-height: 70px;
}

@media (max-width: 960px) {
  .btn-container {
    justify-content: center;
  }

  .wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.1)
      ),
      url("../assets/intro/img-intro.jpeg");
  }
}

@media (max-width: 600px) {
  .swiper-slide {
    width: 22%;
  }

  .block-left {
    height: 5%;
  }
}

@media (max-width: 400px) {
  .swiper-slide {
    width: 24%;
  }
}
</style>
