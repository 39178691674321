<template>
  <form>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px">
        <v-card>
          <div class="card-header d-flex align-center justify-space-between">
            <div class="block-left"></div>
            <h3>{{$t('book_modal.title')}}</h3>
            <v-btn color="white" icon text @click="setShowBookModal(null)">
              <v-icon>close</v-icon>
            </v-btn>
          </div>

          <Spinner v-if="loadind"></Spinner>
          <v-form 
            v-model="valid"
            ref="bookForm"
            class="pa-3">
            <div>
              <div>
                <v-menu
                  v-model="showMenuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateVisit"
                      :label="$t('book_modal.subtitle')"
                       :rules="[v => !!(v && v.length) || $t(`book_modal.form_validation.date`)]"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateVisit"
                    @input="showMenuDate = false"
                  ></v-date-picker>
                </v-menu>

                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      :label="$t('book_modal.time')"
                       :rules="[v => !!(v && v.length) || $t(`book_modal.form_validation.time`)]"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal2" v-model="time" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      {{$t(`book_modal.cancel`)}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(time)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>

                <v-text-field
                  name="input-7-1"
                  filled
                   :rules="[ 
                      (v) => !!v || $t(`book_modal.form_validation.seat`),
                      (v) => !!v && v>0|| $t(`book_modal.form_validation.seat_length`),
                    ]"
                  :label="$t('book_modal.seats')"
                   v-model="seats"
                   type="number"
                  
                ></v-text-field>
              </div>

              <div class="mb-6 d-flex justify-end">
                <v-btn depressed small color="primary" @click="submit()"
                 :loading="sending"
                  >{{$t('book')}}</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Spinner from "../spinner/AppSpinner";
import toastMixins from "@/mixins/toast";

export default {
  name: "BookModal",

  components: {
    Spinner,
  },

  props: {
    restaurantId: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    time: null,
    modal2: false,
    loadind: false,
    showMenuDate: false,
    dateVisit: new Date().toISOString().substr(0, 10),
    seats:null,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  created() {
    this.loadind = false;
  },

  computed: {
    ...mapGetters({
      showBookModal: "reservations/getShowBookModal",
      sending: "reservations/getSending",
    }),

    show: {
      // getter
      get: function () {
        return this.showBookModal;
      },
      // setter
      set: function () {
        // this.setShowBookModal(newValue);
      },
    },
  },

  methods: {

   resetForm() {
     this.time = null,
     this.dateVisit = new Date().toISOString().substr(0, 10),
     this.seats = null
   },
     submit() {
      
      if(this.$refs.bookForm.validate()){

        this.fetchSendReservation({reservation: {date: this.dateVisit, time: this.time, seats:this.seats}})
          .then(() => {
            this.resetForm(); 
            this.toast_show(this.$t(`book_modal.success_message`))
            this.setShowBookModal(null)
          })
          .catch(() => this.toast_show(this.$t(`book_modal.error_message`)));
      }


    },

    ...mapActions("reservations", ["setShowBookModal", "fetchSendReservation"]),
  },


  mixins: [toastMixins],

};
</script>

<style scoped lang="scss">
.card-header {
  background-color: #f7b538;
  width: 100%;
  height: 60px;
  .block-left {
    width: 70px;
  }
}

@media (max-width: 600px) {
  .card-header {
    .block-left {
      width: 0%;
    }
  }
}
</style>