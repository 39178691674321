export default {
  
    methods: {
      isValidDate(slots){

        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        let isValid = false;

        if(slots && slots.length > 0){
          slots.forEach(element => {
        
            const {close, open} = element;
            const openArray = open.split(':')
            const closeArray = close.split(':')
            
            const openHours = parseInt(openArray[0])
            const openMinutes = parseInt(openArray[1])
            const closeHours = parseInt(closeArray[0])
            const closeMinutes = parseInt(closeArray[1])
            
            if( (hours > openHours || (hours === openHours && minutes > openMinutes )) && 
                  hours < closeHours  || (hours === closeHours && minutes < closeMinutes )) {
                isValid = true;
            }          
          

          });
        }
       
        return isValid;
      },
      
     slot_isOpen(slots){
      
      const d = new Date();
      const indexDay = d.getDay();

      for(let i = 0; i < slots.length; i++){
      
        const slot = slots[i];
        const day = slot.day;
        const isValidTime = this.isValidDate(slot.slots);
         
        if( indexDay === 0 && (day === 'sunday' || day === 'weekend') )
          return  isValidTime;
        if( indexDay === 1 && (day === 'monday' || day === 'working_days') )
          return isValidTime;
        if( indexDay === 2 && (day === 'tuesday' || day === 'working_days') )
          return  isValidTime;
        if( indexDay === 3 && (day === 'wednesday' || day === 'working_days') )
          return  isValidTime;
        if( indexDay === 4 && (day === 'thursday' || day === 'working_days') )
          return  isValidTime;
        if( indexDay === 5 && (day === 'friday' || day === 'working_days') )
          return isValidTime;
        if( indexDay === 6 && (day === 'saturday' || day === 'weekend') )
          return  isValidTime;
      
     }
      return false;
    },

    slot_isCurrentDay(day){
      const d = new Date();
      const indexDay = d.getDay();
      if( indexDay === 0 && (day === 'sunday' || day === 'weekend') )
        return true;
      if( indexDay === 1 && (day === 'monday' || day === 'working_days') )
        return true;
      if( indexDay === 2 && (day === 'tuesday' || day === 'working_days') )
        return true;
      if( indexDay === 3 && (day === 'wednesday' || day === 'working_days') )
        return true;
      if( indexDay === 4 && (day === 'thursday' || day === 'working_days') )
        return true;
      if( indexDay === 5 && (day === 'friday' || day === 'working_days') )
        return true;
      if( indexDay === 6 && (day === 'saturday' || day === 'weekend') )
        return true;
      
      return false;
    },
    },

    isMenuDishOfTheDay(startDate, endDate){
      if(!startDate || !endDate)
        return false
      
      const _startDate = new Date(startDate);
      const _endDate = new Date(endDate);
      const currentDate = new Date()

      if (currentDate >= _startDate && currentDate <= _endDate)   
        return true;
 
      
      return false;
    },
    
  }