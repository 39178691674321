var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{staticClass:"py-1",attrs:{"accordion":"","flat":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$expand")])]},proxy:true}])},[_c('div',[(!_vm.isValid)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")]):_vm._e(),(_vm.isValid)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "+_vm._s(_vm.productName)+" ")],1)]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"items":_vm.products,"label":_vm.$t(`suggest_page.ingredient.product`),"item-text":"name","item-value":"id","return-object":"","rules":[
                (v) => !!v || _vm.$t(`suggest_page.ingredient.product_error`),
              ]},model:{value:(_vm.ingredients[_vm.index].product),callback:function ($$v) {_vm.$set(_vm.ingredients[_vm.index], "product", $$v)},expression:"ingredients[index].product"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"items":_vm.units,"label":_vm.$t(`suggest_page.ingredient.unit`),"item-text":"name","rules":[
                (v) => !!v || _vm.$t(`suggest_page.ingredient.unit_error`),
              ]},model:{value:(_vm.ingredients[_vm.index].unit),callback:function ($$v) {_vm.$set(_vm.ingredients[_vm.index], "unit", $$v)},expression:"ingredients[index].unit"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"rules":[
                (v) => !!v || _vm.$t(`suggest_page.ingredient.quantity_error`),
                (v) => !!v && v>0 || _vm.$t(`suggest_page.ingredient.quantity_error_length`),
              ],"label":_vm.$t(`suggest_page.ingredient.quantity`),"type":"number","required":""},model:{value:(_vm.ingredients[_vm.index].quantity),callback:function ($$v) {_vm.$set(_vm.ingredients[_vm.index], "quantity", $$v)},expression:"ingredients[index].quantity"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.removeIngredientNewRecipe({
                  ingredientIndex: _vm.index,
                })}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }