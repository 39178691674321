<template>
  <div class="full-width time" >
        <div  v-for="item in slots" :key="item.id + 'days'" 
             :class="slot_isCurrentDay(item.day)? 'font-weight-bold': ''">
          <span>{{ $t(getDay(item.day)) }} </span> 
          <span  v-for="slot in item.slots" :key="slot.id + 'slot'">
            {{slot.open | formatTimeSlot}} - {{slot.close | formatTimeSlot}} 
          </span>
        </div>
          
        <!-- {{ restaurant.city + " " + restaurant.country }} -->
      </div>

          
</template>
<script>

import slotMixins from "@/mixins/slot";
export default {
  name: "RestaurantSlots",
  components: {

  },
  props: {
    slots: {
      type: Array,
      require: true,
      default: ()=> []
    },
  },

  mixins: [slotMixins],

  computed: {
  
  },

  methods: {

    getDay(day) {
        return 'days.'+day;
      },

  
  },



};
</script>

<style lang="scss" scoped>

  .full-width {
    width: 100%;
  }

  .time {
    margin-bottom: 0;
    padding-bottom: 5px;
  }

</style>