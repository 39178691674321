<template>
  <v-container class="main-wrapper" fluid>
    <v-form 
      v-model="valid"
      ref="formInfo" 
      >
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="recipe.name"
              :rules="[ 
                  (v) => !!v || $t(`suggest_page.name_error`)
                  ]"
              :label="$t(`suggest_page.recipe_name`)"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recipe.servings"
              :rules="[
              (v) => !!v || $t(`suggest_page.servings_error`),
              (v) => !!v && v>0|| $t(`suggest_page.servings_error_length`)]"
              :label="$t(`suggest_page.servings`)"
              required
              type="number"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recipe.prepTime"
              :rules="[(v) => !!v || $t(`suggest_page.preparationTime_error`)]"
              :label="$t(`suggest_page.preparationTime`)"
              required
              type="number"
              :hint="'('+$t(`suggest_page.minutes`)+')'"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recipe.cookTime"
              :rules="[(v) => !!v || $t(`suggest_page.cookTime_error`)]"
              :label="$t(`suggest_page.cookTime`)"
              required
              type="number"
              :hint="'('+$t(`suggest_page.minutes`)+')'"
              dense
              
            ></v-text-field>
          </v-col>


          <v-col cols="12" md="6">
            <v-select
              v-model="recipe.difficulty"
              :items="recipe_difficulties"
              :item-text="recipe_difficulties_text"
              item-value ="id" 
              :rules="[(v) => !!v || $t(`suggest_page.difficulty_error`)]"
              :label="$t(`suggest_page.difficulty`)"
              required
              dense
            >
            <!-- <template slot="item" slot-scope='{ item }' >
                 {{$t(`difficulty.${item.id}`)}}
            </template> -->
            </v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="recipe.cost"
              :items="recipe_costs"
              :item-text="recipe_costs_text"
              item-value ="id" 
              :rules="[(v) => !!v || $t(`suggest_page.cost_error`)]"
              :label="$t(`suggest_page.cost`)"
              required
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" md="12">
            <Autocomplete></Autocomplete>
          </v-col>

          <v-col cols="12" md="12">
            <v-combobox
              v-model="recipe.tag"
              :items="tags"
               :rules="[v => !!(v && v.length) || $t(`suggest_page.tags_error`)]"
              label="Tags"
              item-text="tag"
              multiple
              chips
              return-object
              dense
            >
            </v-combobox>
          </v-col>

            <v-col cols="12" md="12">
            <v-text-field
              v-model="recipe.source"
              :label="$t(`suggest_page.source`)"
              type="text"
              :hint="'('+$t(`suggest_page.source_hint`)+')'"
              dense   
            ></v-text-field>
          </v-col>

        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import Autocomplete from "./AutocompleteCategoryNewRecipe";
import { mapActions, mapGetters } from "vuex";
import recipeMixins from "../../mixins/recipe";


export default {
  name: "RecipeInfo",
  components: {
    Autocomplete,
  },

  data: () => ({
    valid: false,
   
  }),

  mixins: [recipeMixins],

  created(){
   
    this.fetchTags()
       .catch((error) => {
        console.log("error fetchCategories");
        console.log(error);
      });
  },

    mounted(){
      this.setRefFormInfos(this.$refs.formInfo)
    },

  methods:{
   
    ...mapActions("tags", ["fetchTags"]), 
    ...mapActions("recipes", ["setRefFormInfos"]), 
    
  },

  computed: {
    ...mapGetters({
      recipe: "recipes/getNewRecipe",
      tags: "tags/getTags",
    }),

  },

  

};
</script>

<style scoped lang="scss">
.main-wrapper {
  width: 100%;
  height: 100%;
  max-width: 960px;
  background-color: transparent !important; // fix when add dark theme 

  /* background-color: rgb(240, 240, 240); */
  border-radius: 4px;
}

</style>