<template>
  <div>
    <MapSection
      v-if="isValidaddress"
      :mapCenter="getMapCenter"
      :markers="getMarkers"
    />

    <div>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="my-1 py-6 white--text full-width"
            block
            @click.stop.prevent="
              btnClick('https://www.facebook.com/' + restaurant.social.facebook)
            "
          >
            <v-icon left dark>
              facebook
            </v-icon>
            <span>Facebook</span>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            color="primary"
            class="my-1 py-6 white--text full-width"
            block
            @click.stop.prevent="
              btnClick(
                'https://www.instagram.com/' + restaurant.social.instagram
              )
            "
          >
            <v-icon left dark>
              alternate_email
            </v-icon>
            <span>Instagram</span>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            color="primary"
            class="my-1 py-6 white--text full-width"
            block
            @click.stop.prevent="btnClick('mailto:' + restaurant.email)"
          >
            <v-icon left dark>
              mail
            </v-icon>
            <span>Email</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-btn
       
        class="my-1 py-6 full-width"
        block
        @click.stop.prevent="btnClick('tel:' + restaurant.phone)"
      >
        <v-icon left dark color="green">
          phone
        </v-icon>
        <span>{{ restaurant.phone }}</span>
      </v-btn>
    </div>

      <!-- todo in the second phase  -->
    <!-- <div class="pt-4">
      <h5>Licenças e certificados</h5>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="green darken-2" dark v-bind="attrs" v-on="on">
            verified
          </v-icon>
        </template>
        <span>Verified</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="green darken-2" dark v-bind="attrs" v-on="on">
            verified
          </v-icon>
        </template>
        <span>Verified</span>
      </v-tooltip>
    </div> -->
  </div>
</template>
<script>
import MapSection from "@/components/maps/MapSection";

export default {
  name: "RestaurantContactLocation",
  components: {
    MapSection,
  },

  props: {
    restaurant: {
      type: Object,
      require: true,
    },
  },

  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    if (this.$refs.mapRef)
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({ lat: 1.38, lng: 103.8 });
      });
  },

  computed: {
    address() {
      return this.restaurant.address || {};
    },

    getMapCenter() {
      return {
        lat: this.address.lat,
        lng: this.address.lng,
      };
    },

    getMarkers() {
      return [
        {
          label: this.address?.address || "",
          lat: this.address.lat,
          lng: this.address.lng,
        },
      ];
    },

    isValidaddress() {
      return (
        this.restaurant &&
        this.restaurant.address &&
        this.restaurant.address.lat &&
        this.restaurant.address.lng
      );
    },
  },

  methods: {
    btnClick(link) {
      window.location.href = link;
    },
  },
};
</script>

<style lang="scss" scoped></style>
