<template>
  <v-container class="px-4" fluid>  
    <div class="blog-menu">
      <a
        :class="menuSelected == 'all' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('all')"
        :key="'home'"
        >{{ $t("blog_categories.all") }}</a
      >
      <a
        :class="menuSelected == 'food' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('food')"
        :key="'food'"
        >{{ $t("blog_categories.food") }}</a
      >
      <a
        :class="menuSelected == 'hygiene' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('hygiene')"
        :key="'hygiene'"
        >{{ $t("blog_categories.hygiene") }}</a
      >
      <a
        :class="menuSelected == 'health' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('health')"
        :key="'health'"
        >{{ $t("blog_categories.health") }}</a
      >
      <a
        :class="menuSelected == 'trick' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('trick')"
        :key="'trick'"
        >{{ $t("blog_categories.trick") }}</a
      >
      <a
        :class="menuSelected == 'diet' ? 'menu-item active' : 'menu-item'"
        @click.prevent="setCategory('diet')"
        :key="'diet'"
        >{{ $t("blog_categories.diet") }}</a
      >
      <div class="blog-wrap">
    </div>
   </div>
    <v-row no-gutters class="main-wrapper">
      <v-col cols="12" xs="12" md="8">
        <div class="slide-wrapper">
          <div class="rounded-16">
            <v-skeleton-loader
              type="image"
              min-width="100%"
              height="400px"
              max-height="100%"
              transition="fade-transition"
              v-if="loading"
            ></v-skeleton-loader>

            <v-img
              v-else
              :src="mainPost.featured.url"
              :lazy-src="mainPost.featured.url"
              :aspect-ratio="$vuetify.breakpoint.mobile == true ?1.5:2"
              class="main-img"
            ></v-img>
          </div>

          <div class="slide-title-wrapper" v-if="!loading && mainPost">
            <h1 class="py-0 slide-title " @click="goTodetail(mainPost)">
              {{ mainPost.title }}
            </h1>

            <div class="d-flex align-center flex-wrap pb-1">
              <AuthorAvatarImg
                :user="mainPost.author"
                :size="32"
              ></AuthorAvatarImg>

              <p class="pl-2 ma-0">{{ mainPost.author | authorFullName }}</p>

              <div class="d-flex">
                <v-icon class="ml-6 hidden-xs"  color="primary">timer</v-icon>
                <p class="pl-2 ma-0 hidden-xs" >
                  {{ mainPost.created_at | fromNow(this.$i18n.locale) }}
                </p>

              </div>
              <div class="d-flex hidden-xs-and-down">
                <v-icon class="ml-6 " color="primary">local_library</v-icon>
                <p class="pl-2 ma-0 " >
                  {{ mainPost.readingTime }} {{ $t("min_read") }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="blog-container pt-10">
          <div class="card-post-item" v-for="item in listPosts" :key="item.id">
            <CardPost :post="item" v-if="!loading"></CardPost>
          </div>

          <div   :class="!loading?'mb-0':'card-post-item'" v-for="item in 6" :key="item.id">
            <CardPost :loading="true" v-if="loading"></CardPost>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="4" class="block-left d-none d-md-block">
        <v-divider class="ml-6 mb-6 mt-0 " color="#f7b538" :inset="inset"></v-divider>
        <div class="ml-6">
          <!-- <p class="title ">Advertisement</p> -->
          <div>
            <a :href="partnerUrl" target="_blank">
               <v-skeleton-loader
                type="image"
                min-width="100%"
                height="160"
                transition="fade-transition"
                v-if="loading"
              ></v-skeleton-loader>
              <v-img
                :src="partnerImg"
                aspect-ratio="2"
                class="adimg"
                 v-if="!loading"
              ></v-img>
            </a>
          </div>

          <RestaurantSidebar
            :restaurants="listRestaurants"
            :title="$t('blog_page.restaurants')"
            :loading="loading"
          />

          <RecipeSidebar
            :recipes="listWeeklyTop3"
            :title="$t('blog_page.top_3_recipe')"
            :loading="loading"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import CardListRecipe from "../components/card/CardListRecipe";
import RestaurantSidebar from "../components/sidebar/RestaurantSidebar";
import RecipeSidebar from "../components/sidebar/RecipeSidebar";
import CardPost from "../components/card/CardPost";
import AuthorAvatarImg from "../components/shared/AuthorAvatarImg";
import { mapActions, mapGetters } from "vuex";
export default {
  title: "Blog Index",
  name: "Blog",
  components: {
    RestaurantSidebar,
    CardPost,
    AuthorAvatarImg,
    RecipeSidebar,
  },

  data: () => ({
    menuSelected: "all",
    loading: true,
    inset: false,
  }),

  created() {
    // Promise.all([this.fetchListPosts(), this.fetchRestaurants(),])
    //   .then((items) =>{
    //     this.loading = false;
    //     console.log(items)
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    Promise.all([
      this.fetchRestaurants(),
      this.fetchListPosts(),
      this.fetchListWeeklyTop(),
      this.fetchPartners(),
    ])
      .then(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
      });

    // this.fetchListWeeklyTop()
    //     .then((items) =>{
    //       this.loading = false;
    //       console.log("this.fetchListWeeklyTop()")
    //       console.log(items)
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
  },

  methods: {
    goTodetail(post) {
      // this.$router.push({ name: "post", params: { post: post } }, () => {});
      this.$router.push({ name: "postItem", params: {postId: post.id, post: post } }, () => {});
    },

    setCategory(value) {
      this.menuSelected = value;
      this.setCategorySelected(value);
      this.fetchListPosts()
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    },
    ...mapActions("posts", ["fetchListPosts", "setCategorySelected"]),
    ...mapActions("restaurants", ["fetchRestaurants"]),
    ...mapActions("recipes", ["fetchListWeeklyTop"]),
    ...mapActions("partners", ["fetchPartners"]),
  },

  computed: {
    ...mapGetters({
      posts: "posts/getListPosts",
      restaurants: "restaurants/getRestaurants",
      listWeeklyTop: "recipes/listWeeklyTop",
      partner: "partners/getPartner",
    }),

    listWeeklyTop3() {
      if (this.listWeeklyTop.length > 0) return this.listWeeklyTop.slice(-3);
      return [];
    },

    partnerUrl() {
      if (this.partner) 
        return this.partner.url;
      return "";
    },

    partnerImg() {
      if (this.partner) 
        return this.partner.logo.url;
      return "";
    },

    mainPost() {
      return this.posts[0] || null;
    },

    listPosts() {
      if (this.posts.length > 1) {
        let listPost = this.posts.slice(1);
        return listPost;
      }
      return [];
    },

    listRestaurants() {
      if (!this.restaurants) return [];
      return this.restaurants.slice(-3);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
.container {
  /* background-color: #f7f8fa; */
  background-color: var(--v-background-base);
   min-height: calc(100vh - 280px);
}
.main-wrapper {
  width: 100%;
  height: 100%;
}

.blog-menu {
  padding: 24px 16px;
  /* background-color: white; */
  background-color: var(--v-background-darken1);
  border-radius: 16px;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  
  .menu-item {
    font-size: 18px;
    color: gray;
    z-index: 1;
    @media (max-width: 420px) {
      padding: 0 12px;
      margin-bottom: 8px;
       flex: 1 1 33%;
    }
   

    &.active {
      color: var(--v-primary-base);
      border-bottom: 1px solid var(--v-primary-base);
    }
  }
  @media (max-width: 420px) {
    justify-content: flex-start;
  }
}



.slide-wrapper {
  position: relative;
  .v-image {
    border-radius: 16px;
  }

  .slide-title-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
    padding: 30px 8px 0 8px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .slide-title {
      color: white;
      line-height: 1.2;
      &:hover {
        color: var(--v-primary-base);
        cursor: pointer;
      }
    }

    p {
      color: rgb(173, 173, 173);
    }
  }
}

.blog-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card-post-item {
    width: 32%;
    margin-bottom: 1.5%;
  }
}

.adimg {
  .v-image {
    border-radius: 8px;
  }
}
@media (max-width: 1300px) {
  .blog-container {
    .card-post-item {
      width: 49%;
      margin-bottom: 2%;
    }
  }
}

@media (max-width: 500px) {
  .blog-container {
    .card-post-item {
      width: 100%;
      margin-bottom: 5%;
    }
  }

  .slide-title {
    font-size: 1.2rem;
  }

  .blog-menu {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .blog-wrap{
    background-color: var(--v-background-darken1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 77%;
    z-index: 0;
    }

    .hidden-xs-and-down{
      display: none !important;

    }
}

@media (max-width: 500px) {
    .hidden-xs{
      display: none !important;

    }
  }
</style>