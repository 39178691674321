import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    sending: false,  
  },

  getters: {

    getSending(state) {
      return state.sending
    },


  },

  actions: {


    fetchSendContact({ state, commit }, contactObject) {
      commit('set', { resource: 'sending', value: true })
      return axiosInstance({
        method: 'post',
        url: `contacts`,
        data: {
          ...contactObject
        },
      })
        .then(() => {
          commit('set', { resource: 'sending', value: false })
          return state.items;
        })
        .catch(
          err => {
            console.log(err)
            commit('set', { resource: 'sending', value: false })
          }
        )


    },

  },

  mutations: {
    set(state, { resource, value }) {
      state[resource] = value;
    },
  }
}