<template>
  <meal-plan-step>
    <template v-slot:header>
      <h2></h2>
    </template>
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col
              v-for="diet of diets"
              :key="diet.id"
              class="col-md-4"
              cols="12"
          >
            <diet-card
                :title="diet.title"
                :subtitle="diet.subtitle"
                :description="diet.description"
                :link="'/meal-planner/mode/' + diet.id"
            ></diet-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </meal-plan-step>
</template>

<script>
import MealPlanStep from '@/components/meal-plan/meal-plan-step/MealPlanStep';
import DietCard from '@/components/meal-plan/diet-card/DietCard';

export default {
  name: 'DietPicker',
  components: {
    DietCard,
    MealPlanStep
  },
  data() {
    return {
      diets: [
        {
          id: 1,
          title: 'Explorador',
          subtitle: 'Come sempre fora',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat eros vitae porta vestibulum. Sed ipsum turpis, rhoncus quis elit sit amet, efficitur condimentum mi. Nam eget pellentesque odio. Phasellus aliquam feugiat leo. Vivamus egestas nisl non lectus vulputate, ut malesuada lorem vehicula. Interdum et malesuada fames ac ante ipsum.'
        },
        {
          id: 2,
          title: 'Observador',
          subtitle: 'Tanto fora como em casa',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat eros vitae porta vestibulum. Sed ipsum turpis, rhoncus quis elit sit amet, efficitur condimentum mi. Nam eget pellentesque odio. Phasellus aliquam feugiat leo. Vivamus egestas nisl non lectus vulputate, ut malesuada lorem vehicula. Interdum et malesuada fames ac ante ipsum.'
        },
        {
          id: 3,
          title: 'Caseiro',
          subtitle: 'Só cozinha em casa',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat eros vitae porta vestibulum. Sed ipsum turpis, rhoncus quis elit sit amet, efficitur condimentum mi. Nam eget pellentesque odio. Phasellus aliquam feugiat leo. Vivamus egestas nisl non lectus vulputate, ut malesuada lorem vehicula. Interdum et malesuada fames ac ante ipsum.'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
