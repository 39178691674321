import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    items: [],
    loading:null,
    selected:[],
  },

  getters: {
    getPantries (state) {
      return state.items 
    },
    getLoading (state) {
      return state.loading 
    },

    getListIngredients (state) {
      
      let list = [];
      if (!state.items || !state.items.productLines)
        return list;

      const productLines = state.items.productLines;
      for (let i = 0; i < productLines.length; i++) {


          const ingredient = productLines[i];
          const product = ingredient["product"];
          const unit = ingredient["unit"];
          const quantity = ingredient["quantity"];
          let image = require('../../assets/placeholder.jpg');

          if (product.image)
            image = product['image']['formats']['thumbnail']['url'];

          const translations = [];
          const value = quantity.toString() + " " + unit["name"] + " " + product["name"];
          const _translations = ingredient['product']['translation'];

          _translations.forEach(el => {
            el.lang = (quantity == 1 ? el['singular'] : el['plural']);
            translations.add(el);
          });

          const id = ingredient["id"];
          list.push({
            'id': id,
            'value': value,
            'translations': translations,
            'unit': unit['name'],
            'quantity': quantity.toString(),
            'product': product['name'],
            'image': image,
            'selected': (state.selected.filter(_id => _id == id)).length>0,

          });
        
      }

      return list;


    },
    },

  actions: {
   
    fetchPantries ({state, commit}) {
      commit('set', {resource:'loading', item:'getAll'})
      return axiosInstance.get('pantry')
        .then(res => {
          const pantries = res.data
          commit('setItems', {resource: 'pantries', items: pantries}, {root: true})
          commit('set', {resource:'loading', item:null})
          return state.items
        })
        .catch(
          err => { 
            console.log(err)
            commit('set', {resource:'loading', item:null})
           }
        )
    },

    selectIngredient ({ commit}, id) {
      console.log(id)
      if(!id)
        return true;
      commit('changeIngredient', {id:id})
    },

   

    fetchAdd ({state, commit}, recipeId) {
      console.log(recipeId)
      if(!recipeId)
        return state.items
      commit('set', {resource:'loading', item:'add'})
      return axiosInstance.put('pantry/undo-recipe',{ "recipe": recipeId })
        .then(res => {
          const pantries = res.data
          commit('setItems', {resource: 'pantries', items: pantries}, {root: true})
          commit('set', {resource:'loading', item:null})
          return state.items
        })
        .catch(
          err => { 
            console.log(err)
            commit('set', {resource:'loading', item:null})
           }
        )
    },


    fetchDoRecipe ({state, commit}, recipeId) {
     
      if(!recipeId)
        return state.items
      commit('set', {resource:'loading', item:'doRecipe'})
      return axiosInstance.put('pantry/do-recipe',{ "recipe": recipeId })
        .then(res => {
          const pantries = res.data
          commit('setItems', {resource: 'pantries', items: pantries}, {root: true})
          commit('set', {resource:'loading', item:null})
          return state.items
        })
        .catch(
          err => { 
            console.log(err)
            commit('set', {resource:'loading', item:null})
           }
        )
    }
  },

  mutations: {
    set(state, { resource, item }) {
      state[resource] = item;
    },
    
    changeIngredient(state, { id }) {
      const list = state.selected.filter(_id => _id == id);

      if(list.length == 0)
        state.selected = [id, ...state.selected]
      else{
          const _list = state.selected.filter(_id => _id != id);
          state.selected = [..._list]
        }
        
    },
  }

}