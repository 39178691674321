<template>
  <v-container class="pa-0" fluid >
    <v-row no-gutters class="main-wrapper mt-0">
      <v-col cols="12" sm="12">
        <h1 v-if="!loadingCategory" class="text-center display-3 mt-4">
          {{ category.name || "No name" }}  <span class="display-1 grey--text" v-if="recipes.length > 0">({{recipes.length | formatNumberViews}})</span>
        </h1>
        <v-skeleton-loader
          v-else
          class="mx-auto mt-6"
          max-width="300"
          height="30"
          type="text"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="0"
        :class="showFilter ? 'block-left' : 'hide block-left'"
        v-if="showFilter"
      >
        <FilterCategory :categoryId="categorySelected"></FilterCategory>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-btn
          class="ma-2 filter-btn"
          fab
          x-small
          color="primary"
          outlined
          @click="setShowFilter()"
        >
          <v-icon dark>
            {{ showFilter ? "close" : "filter_alt" }}
          </v-icon>
        </v-btn>
        <div>
          <GridRecipe :recipes="recipes" :loading="loading"></GridRecipe>
          <div class="pa-2 text-center" v-if="recipes.length === 0">{{$t('category_empty')}}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GridRecipe from "../components/grid/GridRecipe";
import FilterCategory from "../components/filter/FilterCategory";
import { mapActions, mapGetters } from "vuex";

export default {
  //  name: 'swiper-example-multiple-slides-per-biew',
  title: "Category page",
  name: "Category",
  components: {
    FilterCategory,
    GridRecipe,
  },

  data: () => ({
    categories: [],
    categorySelected: null,
    category: {},
    loading: true,
    loadingCategory: true,
  }),

  mounted() {
    if (this.isAuthenticated) {
      // const category = this.$route.params.category;
      this.category = this.$route.params.category || {};
      // alert(this.$route.name)
      // alert(this.$route.params.id)
      this.categorySelected = this.$route.params.id;
      const categoryId = this.categorySelected || 15;

      this.fetchListRecipesByCategory(`?categories_in=${categoryId}`)
        // .then((list) => console.log(list))
        // .catch((error) => console.log(error));
        .finally(() => (this.loading = false));

      this.fetchCategory(categoryId)
        .then((category) => {
          this.category = category;
        })
        // .catch((error) => console.log(error))
        .finally(() => (this.loadingCategory = false));
    }
  },

  computed: {
    categoryId() {
      return this.categorySelected || 15;
    },
    ...mapGetters({
      recipes: "recipes/listRecipesCategory",
      showFilter: "filterCategory/getShowFilter",
      onFocus: "search/getOnFocus",
      isAuthenticated: "users/getAccount",
      searchActive: "search/getActive",
    }),
  },

  watch: {
    searchActive: function (val) {
      if (val == true && this.isAuthenticated && this.categories.length == 0) {
        this.fetchListRecipesByCategory(`?categories_in=${this.categoryId}`)
          .then(() => console.log())
          .catch((error) => console.log(error));
      }
    },
  },

  methods: {
    ...mapActions("recipes", ["fetchListRecipesByCategory"]),
    ...mapActions("filterCategory", ["setFilter", "setShowFilter"]),
    ...mapActions("categories", ["fetchCategory"]),

    setCategories(categories) {
      // alert(categories)
      // let list = [];
      // // if (this.categories.length > 0) {
      //   categories.forEach((cat) => {
      //     const res = this.recipes.filter((rec) =>{
      //       return rec.categories.filter(el => {
      //         const categoryId = typeof el === "string" ? el: el.id;
      //         return  categoryId == cat.id;
      //       }).length>0
      //     }
      //     );

      //     if (res.length > 0) {
      //       cat.numberRecipes = res.length;
      //       list.push(cat);
      //     }
      //   });
      //   this.categories = list;
      this.categories = categories;
      console.log("this.categories");
      console.log(this.categories);
      // }
    },

    selectCategory(categoryId) {
      if (this.recipes.length > 0) {
        this.recipesSelected = this.recipes.filter((rec) => {
          return (
            rec.categories.filter((el) => {
              const _categoryId = typeof el === "string" ? el : el.id;
              return _categoryId == categoryId;
            }).length > 0
          );
        });
        this.categorySelected = categoryId;
      }
    },

    changeDisplay() {},
  },
};
</script>

<style scoped lang="scss">
.filter-btn {
  outline: none;
}
.main-wrapper {
  margin-top: 32px;
}

.card-search {
  width: 19%;
}

.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: calc(100vh - 280px);

  .block-left {
    position: absolute;
    width: 100%;
    z-index: 2;
    max-width: 320px;
    &.hide {
      display: none;
    }
  }
}

.container-wrapper {
  padding: 16px 0;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 820px) {
  .card-search {
    width: 24%;
  }
}
@media (max-width: 960px) {
  .text-info {
    margin-top: 15%;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .card-search {
    width: 33%;
  }
}

@media (max-width: 520px) {
  .card-search {
    width: 49%;
  }
  .container {
    .block-left {
      max-width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
}

@media (max-width: 400px) {
}
</style>