<template>
  <v-container class="pa-0 main-wrapper-main" fluid>
   
   
    <LoadingContainer :loading="loading"></LoadingContainer>
   
    <v-row no-gutters class="main-wrapper" v-if="!loading">
      <v-col cols="12" sm="12" md="12" class="block-left">
        <BtnBack></BtnBack>
        <GalleryRecipe2 :assets="recipe.assets" />
      </v-col>

      <v-col cols="12" xs="12" md="12">
        <v-container fluid class="d-flex pa-4 flex-wrap container-wrapper">
          <RecipeHeader :recipe="recipe" :user="author"></RecipeHeader>
        
          <v-expansion-panels accordion multiple v-model="panel">
            <v-expansion-panel key="ingredient">
              <v-expansion-panel-header
                ><h4 v-t="'recipe_page.ingredients'"></h4></v-expansion-panel-header
              >

              <v-expansion-panel-content>
                <TabIngredients />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="how-to-cook" v-if="!isPrivate">
              <v-expansion-panel-header
                ><h4 v-t="'recipe_page.steps'"></h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <TabStep />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="additional-info">
              <v-expansion-panel-header
                ><h4 v-t="'recipe_page.additional_information'"></h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <TabAdditionalInfo :recipe="recipe" /> 
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="review-content">
              <v-expansion-panel-header 
                ><h4>{{$t(`recipe_page.reviews`)}} ({{reviewCount | formatNumberReviews}})</h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <RecipeReviewCard :recipeId="recipe.id" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
 
        </v-container>

  
        <div v-if="!isListRestaurantsEmpty" class="px-4 restaurants-wrapper" >

          <h3  v-t="'recipe_page.find_here'" ></h3>
          <CarouselRestaurant boxShadow type="view" :restaurants="restaurants"></CarouselRestaurant>

        </div>
       
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import TabIngredients from "../components/tab/TabIngredient";
import TabStep from "../components/tab/TabStep";
import TabAdditionalInfo from "../components/tab/TabAdditionalInfo";
import RecipeReviewCard from "../components/review/RecipeReviewCard";
import GalleryRecipe2 from "../components/gallery/GalleryRecipe2";
import CarouselRestaurant from "../components/carousel/CarouselRestaurant";
import BtnBack from "../components/btn/BtnBack";
import LoadingContainer from '@/components/shared/LoadingContainer';
import RecipeHeader from '@/components/headers/RecipeHeader';
import authorMixins from "../mixins/author";
import { mapActions, mapGetters } from "vuex";


export default {
  title: "Sabores CV Receita",
  name: "Recipe",
  components: {
    TabIngredients,
    TabStep,
    TabAdditionalInfo,
    RecipeReviewCard,
    GalleryRecipe2,
    CarouselRestaurant,
    RecipeHeader,
    BtnBack,
    LoadingContainer
  },

  data: () => ({
    panel: [0, 1, 2, 3],
    tabToShow: 1,
    loadingRecipe: true,
    loadingReview: true,
    loadingRating: true,
    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
  mixins: [authorMixins],
  created() {
   
    let recipeId = this.$route.params.recipeId;


    this.fetchRecipe(recipeId)
      .then((recipeItem) => {
        
        if (recipeItem){
              // this.setRecipe(recipeItem);
              this.author = recipeItem.author;
              this.loadingRecipe = false;
        }
     
         })
      .catch((err) => {
        console.error(err);
        this.loadingRecipe = false;
      });
          
    
    this.fetchReviewsCount(recipeId)
    .finally(() => { this.loadingReview = false; });

    this.fetchRecipeRating(recipeId)
    .finally(() => {this.loadingRating = false; });

 

  },
  computed: {
    ...mapGetters({
      recipe: "recipes/getRecipe",
      recipeRating: "recipes/getRecipeRating",
      sendingFavorite: "recipes/getSendingFavorite",
      pantryLoading: "pantries/getLoading",
      reviewCount: "reviews/getReviewCount",

    }),
  
    loading(){
      return this.loadingRecipe || this.loadingReview || this.loadingRating;
    },
    isPrivate(){
        return this.recipe.private === true;
    },
    restaurants() {
        return  this.recipe.restaurants;
    },
    isListRestaurantsEmpty(){
      return !this.recipe || !this.recipe.restaurants || this.recipe.restaurants.length === 0;
    }

  },

  methods: {
    ...mapActions("recipes", ["setRecipe", "fetchSetFavorite", "fetchRecipeRating", "fetchRecipe"]),
    ...mapActions("pantries", ["fetchAdd", "fetchDoRecipe"]),
    ...mapActions("reviews", ["fetchReviewsCount"]),

    setFavorite() {
      this.fetchSetFavorite(this.recipe);
    },
  },
};
</script>

<style scoped lang="scss">



.swiper-slide {
  width: 18%;
}

.main-wrapper-main {
  min-height: calc(100% - 54px);
}


.container {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  .block-left {
    margin-right: 16px;
  }
}


.container-wrapper {
  /* background-color: white; */
  border-radius: 8px;
  .container-body {
    width: 100%;
    border-top: 1px solid gray;
  }
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

.name-container {
  width: 100%;
}

.info-wrapper {
  width: 100%;
  flex-wrap: wrap;
  .v-card {
    border-radius: 8px;
  }
  .value-top {
    font-weight: bold;
    color: #f7b538;
    line-height: 1.3;
  }

  .value-bottom {
    color: gray;
    line-height: 1.3;
    opacity: 0.7;
  }

  .caption {
    color: grey;
  }
}
@media (max-width: 600px) {
  .block-left {
    height: 5%;
  }
  .main-wrapper {
    margin-top: 0;
  }

  .v-card {
    margin-bottom: 10px;
  }

}


</style>
