<template>
  <div class="lb" v-if="items.length > 0">
    <div
      class="lb-grid"
      :class="[
        css,
        items.length > cells ? 'lb-grid-' + cells : 'lb-grid-' + items.length,
      ]"
    >
      <div
        class="lb-item"
        v-for="(item, i) in items"
        v-bind:key="item.src+i"
        @click="show(i)"
        :style="bg(item.src)"
      >
        <span class="lb-more" v-if="i == cells - 1 && items.length - cells > 0"
          >{{ items.length - cells }}+</span
        >
      </div>
    </div>

    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
    ></CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import galleryMixins from "../../mixins/gallery";
export default {
  components: {
    CoolLightBox,
  },

  props: {
    css: {
      type: String,
      default: () => "h-250 h-md-400 h-lg-600",
    },

    cells: {
      type: Number,
      default: () => 5,
    },
  },

  mixins: [galleryMixins],

  data() {
    return {
      src: "",
      index: null,
      loading: false,
      events: [],
    };
  },

  methods: {
    bind() {
      if (this.events.length > 0) return;

      this.events.push([
        "keydown",
        (e) => {
          if (this.index < 0) return;
          if (e.keyCode === 37) {
            this.prev();
          } else if (e.keyCode === 39) {
            this.next();
          } else if (e.keyCode === 27) {
            this.close();
          }
        },
      ]);
      this.events.forEach((e) => {
        window.addEventListener(e[0], e[1]);
      });
    },

    show(i) {
      this.index = i;
    },
    next() {
      this.show(this.index - 1);
    },

    prev() {
      this.show(this.index + 1);
    },
    close() {
      this.index = -1;
      this.events.forEach((e) => {
        window.removeEventListener(e[0], e[1]);
      });
      this.events = [];
    },
  
  },
};
</script>

<style scoped lang="scss">
.h-250{
  height: 410px!important;
}
</style>
