<template>
  <div class="apppp" ref="galleryRef">
    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
    ></CoolLightBox>
    <div class="images-wrapper d-flex mt-1" v-resize="onResize">
      <!-- {{windowSize.x}} -->
      <div v-for="(image, imageIndex) in items" :key="image.src">
        <a
          class="element-img more d-flex align-center justify-center display-1"
          @click="index = imageIndex"
          :style="{ backgroundImage: 'url(' + image.src + ')' }"
          :key="image.src + 'cd'"
          v-if="imageIndex <= maxIndexToShow"
        >
          <span v-if="imageIndex == maxIndexToShow" class="lb-more">
            {{ numberItemsHiden + "+" }}
          </span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import galleryMixins from "../../mixins/gallery";
import resizeMixins from "../../mixins/resize";

export default {
  name: "GalleryRow",
  components: {
    CoolLightBox,
  },

  mixins: [galleryMixins, resizeMixins],

  computed: {
    showMore() {
      return this.maxIndexToShow < this.itemsLength;
    },
    maxIndexToShow() {
      return Math.round(
        this.width / ( 140) || 0
      );
    },
    numberItemsHiden() {
      const val = this.itemsLength - this.maxIndexToShow;
      return val < 0 ? 0 : val - 1;
    },

    itemsLength() {
      return this.items.length;
    },
  },
};
</script>

<style scoped lang="scss">
.apppp {
  width: 100%;
}
.images-wrapper {
  .element-img {
    /* width: 100px;
    */
    position: relative;
    width: 100px;
    height: 100px;
    background-size: cover;
    cursor: pointer;
    margin-right: 3px;

    .lb-more {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      color: #fff;
      font-size: 3rem;
      background-color: rgba(0, 0, 0, 0.4);

      &:before {
        display: inline-block;
        content: "";
        vertical-align: middle;
        height: 100%;
      }
    }
  }
}
@media (max-width: 600px) {
  .images-wrapper {
    .element-img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>