<template>
  <v-flex>
    <v-container class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('schedulePlan.timeInterval.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="justify-center align-center text-center font-weight-bold">
          <v-date-picker
              v-model="timeInterval"
              :min="nowDate"
              :landscape="!$vuetify.breakpoint.mobile"
              range
              flat
              full-width
          ></v-date-picker>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('schedulePlan.meals.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="i in [2,3,4,5]" v-bind:key="i" class="justify-center align-center">
          <label
              :class="`option rounded-pill px-16 py-8 text-center font-weight-bold ${meals === i && 'option--selected'}`"
              @click="meals = i"
          >
            {{ i }}
          </label>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="question-wrapper">
      <v-row>
        <v-col>
          <p class="title">{{ $t('schedulePlan.snacks.question') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="i in [0,1,2]" v-bind:key="i" class="justify-center align-center">
          <label
              :class="`option rounded-pill px-16 py-8 text-center font-weight-bold ${snacks === i && 'option--selected'}`"
              @click="snacks = i"
          >
            {{ i }}
          </label>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="actions my-8">
      <v-row>
        <v-col lg="6" md="6" cols="12">
          <!--<v-btn class="pa-8 font-weight-bold" rounded block @click="$emit('back')">
            {{ $t('formActions.back') }}
          </v-btn>-->
        </v-col>
        <v-col lg="6" md="6" cols="12">
          <v-btn class="pa-8 font-weight-bold" color="secondary" rounded block @click="next">
            {{ $t('formActions.next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: 'SchedulePlan',
  data() {
    return {
      timeInterval: [],
      meals: 3,
      snacks: 2
    }
  },
  computed: {
    nowDate: () => new Date().toISOString().slice(0, 10)
  },
  methods: {
    next() {
      this.$emit('next', {
        timeInterval: this.timeInterval,
        meals: this.meals,
        snacks: this.snacks
      });
    }
  }
}
</script>

<style lang="scss">
.question-wrapper {
  .option {
    display: block;
    font-size: 1.6em;
    border: 4px dashed map-get($grey, 'darken-2');
    width: 100%;
    cursor: pointer;

    &--selected {
      border: 4px solid map-get($orange, 'darken-1');
    }
  }

  .checkbox-option {
    display: block;
    width: 100%;
    cursor: pointer;

    .v-input__control {
      .v-icon {
        font-size: 2em;
      }

      .v-label {
        font-size: 1.5em;
      }
    }

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }

  }
}
</style>
