export default {
  data: () => ({
  
  }),

  mounted() {
  },

  methods: {
    
    toast_show(text, theme, position, duration) {
      
      this.$toasted.show(
        text,
        {
          theme: theme?theme:"toasted-primary",
          position: position?position:"top-center",
          duration: duration?duration:5000,
        }
      );
    },

    toast_success(text, theme, position, duration) {
      
      this.$toasted.success(
        text,
        {
          theme: theme?theme:"toasted-primary",
          position: position?position:"top-center",
          duration: duration?duration:5000,
        }
      );
    },

    toast_info(text, theme, position, duration) {
      
      this.$toasted.info(
        text,
        {
          theme: theme?theme:"toasted-primary",
          position: position?position:"top-center",
          duration: duration?duration:5000,
        }
      );
    },

    toast_error(text, theme, position, duration) {
      
      this.$toasted.error(
        text,
        {
          theme: theme?theme:"toasted-primary",
          position: position?position:"top-center",
          duration: duration?duration:5000,
        }
      );
    },

    toast_clear() {
      
      this.$toasted.clear();
    },
  },

}