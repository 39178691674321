 
<template>
       <div></div>
</template>

<script>

export default {
  title: "Google Callback",
  name: "GoogleCallback",
  components: {

  },

  created() {

    const token = this.$route.query.access_token;

            this.$store
              .dispatch("auth/loginWithProvider", {"provider":'google', token})
              .then(() => {
                this.$router.replace(localStorage.getItem("redirectPath") || '/');
                localStorage.removeItem("redirectPath");
              })
              .catch((errorMessage) => {
                this.$router.push("/login");
                this.$toasted.show(
                  errorMessage.message === "Network Error"
                    ? this.$t("network_error")
                    : this.$t("login_page.login_error_message"),
                  {
                    theme: "toasted-primary",
                    position: "top-center",
                    duration: 5000,
                  }
                );
              });
      


  },

};
</script>

<style lang="scss">

  .loading{
    width: 100vw;
    height: 100vh;
  }

</style>

