// export const applyFilters = (url, filter) => {}

const getTagByTime = () => {
  const date = new Date();
  //DateTime date = new DateTime(date.year, date.month, date.day, date.hour, date.minute, date.millisecond);
  if (date.hour >= 3 && date.hour < 10) return "Pequeno almoço";
  else if (date.hour >= 10 && date.hour <= 11) return "Lanche da manhã";
  else if (date.hour > 11 && date.hour < 15) return "Almoço";
  else if (date.hour >= 15 && date.hour < 18) return "Lanche da tarde";
  else if (date.hour >= 18 && date.hour < 22) return "Jantar";
  // else if (date.hour >= 22 && date.hour < 3)
  return "Ceia";

  // return "Sugestões da refeição";
};
/**
 * 9 - 107 - breakfast
 * 10 - 111 - Brunch 
 * 39 - 109 - Baking
 * 11 - 105 - Lunch 
 * 18 - 114 - Pizza
 * 15 - 119 - Soups
 * 16 - 115 - Salads 
 * 37 - 120 - Vegetarian
 * 17 - 117 - Sides
 * 14 - 108 - Appetsiers
 * 40 - 113 - Drinks
 * 12 - 112 Dinner
 * 



 */
const getCategoryByTime = (language) => {
  const date = new Date();
  //DateTime date = new DateTime(date.year, date.month, date.day, date.hour, date.minute, date.millisecond);
  if (date.hour >= 3 && date.hour < 10) return language === 'pt'? [107] : [9]; //"Pequeno almoço"; 
  else if (date.hour >= 10 && date.hour <= 11) return language === 'pt'? [111, 109] : [10, 39];
  else if (date.hour > 11 && date.hour < 15) return language === 'pt'? [105, 114, 115, 119 , 120 ] : [11,18,16,37,15];
  else if (date.hour >= 15 && date.hour < 18) return language === 'pt'? [117, 108, 113, 111, 109] : [17,14,40, 10, 39];
  else if (date.hour >= 18 && date.hour < 22) return language === 'pt'? [112, 115, 114, 120, 119] : [12,18,16,37,15];
  // else if (date.hour >= 22 && date.hour < 3)
  return  language === 'pt'? [117, 108, 113, 109] : [17,14,40, 39];

  // return "Sugestões da refeição";
};

const getCategoryByTimeQuery = (language) => {

    const categories = getCategoryByTime(language);
    let query = "";

    for(let i = 0; i < categories.length; i++){
      const item = categories[i];
      query+= `${i==0?'?':'&'}categories_in=` +item;
    }
    
    return query;
    
}

const findIdsList = (items, listIds) => {
  if (!items || items.length == 0 || !listIds || listIds.length == 0) return [];

  let list = []
  listIds.forEach(id => {
    const recipes = items.filter((item) => item.id == id)
    list.push(recipes[0]);
  })

  // return items.filter((item) => listIds.includes(item.id));
  return list;
};

const findIdInList = (items, id) => {
  if (!items || items.length == 0) return false;

  return items.filter((item) => item.id == id).length>0;
};

const findRecipeByCategory = (recipe, category) => {
  // const result = recipes.filter((rec) =>{
  //   return rec.categories.filter(el => {
  //     const categoryId = typeof el === "string" ? el: el.id;
  //      return  categoryId == category;
  //    }).length>0;
  // });

  return (
    recipe.categories.filter((el) => {
      const categoryId = typeof el === "string" ? el : el.id;
      return categoryId == category.id;
    }).length > 0
  );
};

const debounce = (fn, delay) => {
  var timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
};

const getOrderBy = (state, _operation) => {
  let operation = _operation?'&':_operation;
  let url = "";
  if (state.filters.orderBy == "name") url += operation +"_sort=name:ASC";
  else if (state.filters.orderBy == "time") url += operation +"_sort=created_at:DESC";
  else if (state.filters.orderBy == "cookTime") url += operation +"_sort=cookTime:ASC";
  return url;
};

const getCategoriesFromMenu = (menu) => {
  if (
    !menu ||
    !menu.entries ||
    menu.entries.length === 0 ||
    !menu.entries[0].recipe ||
    !menu.entries[0].recipe.categories ||
    menu.entries[0].recipe.categories === 0
  )
    return [];
  return menu.entries[0].recipe.categories.map(function(item) {
    return item.id;
  });
};

const containsArray = (list1, list2) => {
  if (!list1 || !list2) return false;

  let isContain = false;
  for (let i = 0; i < list1.length; i++) {
    const result = list2.filter(item => item === list1[i] );
    if (result.length > 0) {
      isContain = true;
      break;
    }
  }
  return isContain;
};

export {
  getTagByTime,
  getCategoryByTime,
  getCategoryByTimeQuery,
  findIdsList,
  findRecipeByCategory,
  debounce,
  getOrderBy,
  getCategoriesFromMenu,
  containsArray,
  findIdInList
};
