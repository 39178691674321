<template>
  <swiper class="swiper" :options="swiperOption">

    <swiper-slide  
    v-for="(image, imageIndex) in items" 
    :style="bg(image.src)" 
    :key="imageIndex"
    class="slide-1">
    </swiper-slide>

    <!-- <swiper-slide class="slide-2"></swiper-slide>
    <swiper-slide class="slide-3"></swiper-slide>
    <swiper-slide class="slide-4"></swiper-slide>
    <swiper-slide class="slide-5"></swiper-slide> -->


    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'
import galleryMixins from "../../mixins/gallery";

  export default {
    name: 'GallerySlide',
    title: 'Fade effect',
    components: { 
      Swiper,
      SwiperSlide
    },
    mixins: [galleryMixins],

    data() {
      return {
        swiperOption: {
          spaceBetween: 30,
          effect: 'fade',
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* @import './base.scss'; */

  .swiper {
    height: 100%;

    .swiper-slide {
      background-position: center;
      background-size: cover;

      &.slide-1 {
        background-image:url('https://i.wifegeek.com/200426/f9459c52.jpg')
      }
      &.slide-2 {
        background-image:url('https://i.wifegeek.com/200426/5ce1e1c7.jpg')
      }
      &.slide-3 {
        background-image:url('https://i.wifegeek.com/200426/5fa51df3.jpg')
      }
      &.slide-4 {
        background-image:url('https://i.wifegeek.com/200426/663181fe.jpg')
      }
      &.slide-5 {
        background-image:url('https://i.wifegeek.com/200426/2d110780.jpg')
      }
    }
  }


  .swiper {
  /* height: 300px;
  width: 100%; */

  /* .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 12 * 2;
    background-color: red;
  } */
}
</style>