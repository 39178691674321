import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import './registerServiceWorker'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import store from './store/store'
import i18n from './i18n'
import filters from './filters'
import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import VueEditor from 'vue-md-editor'
import Toasted from 'vue-toasted'
import GoogleAuth from 'vue-google-oauth'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueTippy, { TippyComponent } from "vue-tippy";
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
// import "tippy.js/themes/google.css";
// import "tippy.js/themes/translucent.css";

Vue.config.productionTip = false
require('vue-cool-lightbox/dist/vue-cool-lightbox.min.css')

filters()
Vue.use(VueEditor)
Vue.use(Toasted)
Vue.use(GoogleAuth, {
  // TODO - change the client_id to our client_id
  client_id:  process.env.VUE_APP_GOOGLE_AUTH
})
// Vue.googleAuth().load()

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_TOKEN,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },})

Vue.component('GmapCluster', GmapCluster)


// Vue.use(VueTippy);
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy 
  theme: 'light', 
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
});
Vue.component("tippy", TippyComponent);

new Vue({
  vuetify,
  VueAwesomeSwiper,
  VueSimpleMarkdown,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
