import { render, staticRenderFns } from "./RecipeIngredients.vue?vue&type=template&id=79f2f127&scoped=true&"
import script from "./RecipeIngredients.vue?vue&type=script&lang=js&"
export * from "./RecipeIngredients.vue?vue&type=script&lang=js&"
import style0 from "./RecipeIngredients.vue?vue&type=style&index=0&id=79f2f127&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f2f127",
  null
  
)

export default component.exports