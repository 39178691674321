<template>
  <div class="d-flex flex-wrap justify-space-between px-2 grid-recipe">
    <div
      class="card-feed mb-4"
      v-for="(item, index) in restaurants"
      :key="item.id + '-card-restaurant'"
    >
      <CardRestaurant
        v-if="index < limit && !loading"
        :restaurant="item"
        :loading="false"
      ></CardRestaurant>
    </div>

    <div
      :class="!loading?'mb-0':'card-feed mb-4'"
      v-for="(item, index) in 6"
      :key="item + index + '-card'"
    >
      <CardRestaurant v-if="loading" :loading="true"></CardRestaurant>
    </div>
  </div>
</template> 

<script>
import CardRestaurant from "../../components/card/CardRestaurant";

export default {
  title: "Grid Restaurant Home",
  name: "GridRestaurantHome",
  components: {
    CardRestaurant,
  },

  props: {
    restaurants: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    limit_sm: 4,
    limit_md: 6,
    limit_lg: 8,
  }),

  computed: {
    getImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.recipe.assets && this.recipe.assets.length > 0
        ? this.recipe.assets[0].formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    limit() {
      if (this.width >= 900 && this.width < 1300) return this.limit_md;
      else if (this.width < 900) return this.limit_sm;

      return this.limit_lg;
    },

    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped lang="scss">
.grid-recipe::after {
  content: "";
  flex: auto;
}
.card-feed {
  width: 25%;
  padding-right: 2%;
}

@media (max-width: 1300px) {
  .card-feed {
    width: 33%;
    padding-right: 2%;
  }
}

@media (max-width: 900px) {
  .card-feed {
    width: 48%;
  }
}

@media (max-width: 400px) {
  .card-feed {
    width: 100%;
  }
}
</style>