<template>
  <div class="step-wrapper">
    <slot name="header"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'MealPlanStep'
}
</script>

<style scoped>

</style>
