<template>
  <div class="main-container d-flex align-center" @click="goToDetail()">
    <div class="block-left">
       <v-skeleton-loader
          v-bind="attrs"
          type="image"
          min-width="100%"
          width="100%"
          height="60px"
          transition="fade-transition"
          v-if="loading"
      ></v-skeleton-loader>

      <v-img
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.8"
         v-if="!loading"
      ></v-img>
    </div>
    <div class="block-right">

       <v-skeleton-loader
        v-bind="attrs"
        type="paragraph"
        min-width="100%"
        min-height="60px"
        height="100%"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <p class="bold " v-if="!loading">{{ restaurant.name }}</p>
      <div v-if="!loading" >
        <div class>
          <div class="d-flex align-center mt-1">
              <div class="d-flex align-center mr-2">
                <v-icon size="16" color="accent">location_on</v-icon>
              </div>
           
            <div>
              <div class="value mr-1 body-2 " > {{
                address
              }}</div>
              <!-- <span class="value body-2 secondary--text">Portugal</span> -->
            </div>
          </div>

          <div class="d-flex align-center mt-1" >
            <div>
              <div class="d-flex align-center mr-2">
                <v-icon size="16" color="accent">schedule</v-icon>
              </div>
            </div>

            <div
              class="
                d-flex
                align-center
                flex-wrap
                justify-space-between
                view-button-time-wrapper
              "
            >
              <span class="value body-2 mr-1 ">{{
                slot_isOpen(slots)
                  ? $t(`restaurant_page.open`)
                  : $t(`restaurant_page.close`)
              }}</span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import slotMixins from "@/mixins/slot";

export default {
  name: "CardListRestaurantSidebar",
  components: {},
  props: {
    restaurant: {
      type: Object,
      require: true,
    },

     loading: {
      type: Boolean,
      default: () =>  true,
    },
  },

  data: () => ({
    attrs: {
     
    },
  }),

  computed: {
    lang() {
      return this.$i18n.locale;
    },

    getImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl() {
      return this.restaurant && this.restaurant.logo
        ? this.restaurant.logo.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },

    address() {
      return this.restaurant?.address.address;
    },
    slots() {
      return this.restaurant && this.restaurant.slots
        ? this.restaurant.slots
        : [];
    },
  },

  methods: {
     ...mapActions("restaurantSearch", ["setCenterMapSearch"]),
     ...mapActions("search", ["setSearchActive"]),

     goToDetail() {
        this.$router.push(
          { name: "restaurantItem", params: { restaurantId: this.restaurant.id,  restaurant: this.restaurant } },
          () => {}
        );
        this.setSearchActive(false);
      },


  },

  mixins: [slotMixins],
};
</script>

<style lang="scss" scoped>
.view-button-time-wrapper {
  width: 100%;
}
.view-button {
  color: white;
  border-radius: 4px;
  padding: 0 10px;
  background-color: #6d191e;
  font-size: 12px;
  margin-right: 10px;
  outline: none;
}
.main-container {
  /* display: flex; */
  cursor: pointer;

  .block-left {
    width: 35%;
    padding: 8px 8px 8px 0;
    .v-image {
      border-radius: 8px;
    }
  }

  .block-right {
    width: 65%;
    padding: 8px 0;
  }
}

.bold {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  &:hover {
    /* text-decoration: underline; */
    color: #f7b538;
  }
}

.value{
  line-height: 1;
}

.wrap-value {
  span {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
}
</style>