// https://medium.com/@MRWwebDesign/responsive-images-the-sizes-attribute-and-unexpected-image-sizes-882a2eadb6db
<template>
  <v-card class="mx-auto my-card" max-width="100%" @click="goTodetail()">
    <div class="mb-10 my-card-wrapper main-image">
       <v-skeleton-loader 
            v-if="loading"
            type="image" 
            class="image-skeleton"
            max-height="100%"
            max-width="100%"
            transition = "fade-transition"
            elevation = "4"
          ></v-skeleton-loader>
      <v-img
        v-if="!loading"
        :src="getImageUrl"
        :lazy-src="getLazyImageUrl"
        aspect-ratio="1.6"
        sizes="(min-width: 400px) 400px, 100vw"
        width="500"
        class="main-image"
      ></v-img>
    </div>


    <div class="card-body" v-if="!loading">

      <div class="name px-2 accent--text">
        {{ recipe.name + ""}}
      </div>

      <div>
        <div class="d-flex justify-space-around px-2 pt-1">
          <div class="pa-0 wrap-value">
            <div style="width: 20px; height: 20px" class="img-logo">
              <v-img src="../../assets/icons/timer-red.svg"></v-img>
            </div>
            <span class="pl-1 prep-time text body-2 text-lg-body-1 accent--text">
              {{
                ((recipe.prepTime || 0) + (recipe.cookTime || 0))
                  | formatDuration
              }}
            </span>
          </div>

          <div class="pa-0 wrap-value">
            <div style="width: 20px; height: 20px">
              <v-img src="../../assets/icons/difficulty-red.svg"></v-img>
            </div>
            <span class="pl-1 body-2 text-lg-body-1 accent--text">{{
              $t(`difficulty.${recipe.difficulty}`)
            }}</span>
          </div>

          <div class="pa-0 wrap-value">
            <div style="width: 20px; height: 20px">
              <v-img src="../../assets/icons/cost-red.svg"></v-img>
            </div>
            <span class="pl-1 body-2 text-lg-body-1 accent--text">{{
              $t(`costs.${recipe.cost}`)
            }}</span>
          </div>
        </div>

        <div class="d-flex justify-center pt-1 pb-2">
          <div>
            <v-rating
              small
              half-increments
              dense
              v-model="rating"
              background-color="accent"
              readonly
              color="accent"
            ></v-rating>
          </div>
          
        </div>
      </div>
      
    </div>

    <div class="btn-favorite" v-if="!loading">
      <BtnFavorite :recipe="recipe"></BtnFavorite>
    </div>
    
    <!-- <v-card-actions>
      <v-btn text>Share</v-btn>

      <v-btn
        color="purple"
        text
      >
        Explore
      </v-btn>


    </v-card-actions> -->
  </v-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import BtnFavorite from "../btn/BtnFavorite";

export default {
  name: "cardSpotlight",

  components: {
    BtnFavorite,
  },

  props: {
   
   recipe: {
      type: Object,
      default: () => {},
      // require: true,
    },

   loading: {
      type: Boolean,
      default: () => true,
    },
  },

  // created(){
  //   console.log("recipe.assets[0].url")
  //   console.log(this.recipe.assets[0].url)
  // },

  data: () => ({
    show: false,
  
  }),

  computed: {
    rating() {
      return this.recipe.rating || 0;
    },
    getImageUrl(){
        return (this.recipe.assets && this.recipe.assets.length>0) ? this.recipe.assets[0].url : require('@/assets/placeholder.jpg');
    },

    getLazyImageUrl(){
        return (this.recipe.assets && this.recipe.assets.length>0) ? this.recipe.assets[0].formats.thumbnail.url: require('@/assets/placeholder.jpg');
      },
    ...mapGetters({
      sendingFavorite: "recipes/getSendingFavorite",
    }),
  },

  methods: {
    ...mapActions("recipes", ["fetchSetFavorite"]),

    goTodetail() {
      if(!this.loading)
      this.$router.push(
        { name: "recipeItem", params: { recipeId: this.recipe.id } },
        () => {}
      );
    },

    setFavorite() {
      this.fetchSetFavorite(this.recipe);
    },
  },
};
</script>

<style lang="scss" scoped>

.image-skeleton{
 width: 380px;
 border-radius: 32px;
}

.card-body {
  position: absolute;
  background-color:  var(--v-background-darken2);
  bottom: 0px;
  left: 10%;
  width: 80%;
  border-radius: 16px !important;
  z-index: 9999;
  overflow: visible;
  padding: 1.5% 0%;
  /* font-size: 11px; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}



.my-card-wrapper {
  background-color: transparent !important;
}
.my-card {
  position: relative;
  background-color: transparent;
  box-shadow: none !important;

  .name {
    font-weight: bold;
    text-align: center;
  }

  .main-image .v-image {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}


.caption {
  color: gray;
}

.btn-favorite {
  position: absolute;
  z-index: 999;
  top: 5%;
  right: 2%;
}

.wrap-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
  /* display: inline;
  .img-logo{
     display: inline;
  } */
}


@media (max-width: 600px) and (min-width: 500px) {
  .card-body {
    overflow: visible;
    padding: 2%;
    font-size: 12px;

    .body-2 {
      font-size: 11px !important;
    }
  }
}
</style>
