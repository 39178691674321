<template>
  <div>
    <!-- <div class="d-none d-md-block">
      <CarouselCategorySquare 
        :categories="categories"
        :categorySelected="categorySelected"
        @selectCategory="selectCategory"
      ></CarouselCategorySquare>
    </div> -->

     <!-- <div class="d-flex flex-wrap px-1 grid-recipe">
    <a
      class="card-category pa-1"
      v-for="item in categories"
      :key="item.id"
      @click.prevent="select(item)"
      
    >
      <v-img
        :src="getImageUrl(item)"
        :lazy-src="getImageUrl(item)"
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        aspect-ratio="1.4"
      ></v-img>
      <div
        class="title d-flex flex-wrap align-center justify-center flex-column"
      >
        <h4>{{ $t('blog_categories.'+item.id) }} </h4>
        <div class="body-2">{{ item.numberRecipes }}</div>
      </div>
    </a>
  </div> -->

    <!-- <div class="d-none d-md-block mt-4">
      <GridItemBig :item="postsSelected" :type="'post'"></GridItemBig>
    </div> -->

    <div class="grid-cat">
      <div v-if="isMenu">
        <GridCategory
          :categories="categories"
          :categorySelected="categorySelected"
          @changeDisplay="changeDisplay"
          @selectCategory="selectCategory"
          type="post"
        ></GridCategory>
      </div>
      <div v-if="!isMenu">
        <v-btn
          class="my-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeDisplay()"
        >
          <v-icon dark>{{ "arrow_back" }}</v-icon>
        </v-btn>
        <GridItemBig :item="postsSelected" :type="'post'"></GridItemBig>
      </div>
    </div>
  </div>
</template>

<script>
import GridItemBig from "./GridItemBig";
// import CarouselCategorySquare from "../carousel/CarouselCategorySquare";
import GridCategory from "./GridCategory";

import { mapActions, mapGetters } from "vuex";
// import { findRecipeByCategory } from '@/helpers/index'

export default {
  //  name: 'swiper-example-multiple-slides-per-biew',
  title: "Grid Post Content",
  name: "GridPostContent",
  components: {
    GridItemBig,
    // CarouselCategorySquare,
    GridCategory,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      require: true,
    },
  },

  data: () => ({
    categories: [
      {
        name: "Hygiene",
        id: "hygiene",
        image: {
          url: "https://res.cloudinary.com/kriolmidia/image/upload/v1596109013/SCV_1f166dd199.png",
        },
      },
      {
        name: "Food",
        id: "food",
        image: {
          url: "https://res.cloudinary.com/kriolmidia/image/upload/v1596109013/SCV_1f166dd199.png",
        },
      },
      {
        name: "Health",
        id: "health",
        image: {
          url: "https://res.cloudinary.com/kriolmidia/image/upload/v1596109013/SCV_1f166dd199.png",
        },
      },
      {
        name: "Trick",
        id: "trick",
        image: {
          url: "https://res.cloudinary.com/kriolmidia/image/upload/v1596109013/SCV_1f166dd199.png",
        },
      },
      {
        name: "Diet",
        id: "diet",
        image: {
          url: "https://res.cloudinary.com/kriolmidia/image/upload/v1596109013/SCV_1f166dd199.png",
        },
      },
    ],
    postsSelected: [],
    categorySelected: null,
    isMenu: true,
    postCategories: [],
  }),

  created() {
    // this.fetchCategories()
    //   .then((res) => {
    //     this.setCategories(res);
    //   })
    //   .catch((error) => console.log(error));
    this.setCategories();
  },

  methods: {
    ...mapActions("restaurants", ["setCategories", "changeDisplay"]),
    // ...mapActions("categories", ["fetchCategories"]),

    setCategories() {
      let list = [];

      if (this.posts.length > 0) {
        this.categories.forEach((cat) => {
          const res = this.posts.filter((post) => post.category == cat.id);
          cat.numberRecipes = res.length;
          if (res.length > 0) list.push(cat);
        });

        this.categories = [...list];
        if (this.posts.length > 0 && list.length > 0) {
          this.selectCategory(list[0].id);
        }
      }
    },

    selectCategory(categoryId) {
      if (this.posts.length > 0) {
        const res = this.posts.filter((post) => post.category == categoryId);
        if (res && res.length > 0) this.categorySelected = categoryId;
        this.postsSelected = res;
      }
    },

    changeDisplay() {
      this.isMenu = !this.isMenu;
    },

    getImageUrl(item) {
      return item.image && item.image.url
        ? item.image.url
        : require("@/assets/placeholder.jpg");
    },

    getLazyImageUrl(item) {
      return item.image && item.image.url
        ? item.image.formats.thumbnail.url
        : require("@/assets/placeholder.jpg");
    },
  },

  computed: {
    ...mapGetters({
      // categories: "restaurants/getCategories",
      // isMenu: "restaurants/getIsMenu",
      // postsSelected: "restaurants/getRecipes",
    }),
  },
};
</script>

<style scoped lang="scss">

</style>