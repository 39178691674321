<template>
  <v-container class="px-4" fluid>
      <LoadingContainer :loading="loading"></LoadingContainer>
   
    <v-row no-gutters class="main-wrapper" v-if="!loading">
      <v-col cols="12" xs="12" md="8">
        <BtnBack></BtnBack>
        <v-img :src="post.featured.url" aspect-ratio="2"></v-img>
        <h1 class="display-1 py-4">{{ post.title }}</h1>
        <div class="d-flex align-center mb-5 flex-wrap">
          
          <AuthorAvatarImg :size="32" :user="post.author"></AuthorAvatarImg>
          <p class="ma-0">{{ post.author | authorFullName }}</p>
          
          <div class="d-flex timer-wrapper pl-2">
            <v-icon class="ml-6" color="primary">timer</v-icon>
            <p class="pl-2 ma-0">
              {{ post.created_at | fromNow(this.$i18n.locale) }}
           </p>
        </div>

         

          <div class="d-flex read-wrapper ml-6">
            <v-icon class="" color="primary">local_library</v-icon>
            <p class="pl-2 ma-0">{{ post.readingTime }} {{ $t("min_read") }}</p>
        </div>

        </div>
        <div class="post-container">
          <div class="post-sharing">
            <p class="text-center">{{$t('sharing')}}</p>
            <div class="d-flex flex-column align-center">
              <v-btn class="ma-2" fab dark small color="primary">
                <v-icon dark>facebook</v-icon>
              </v-btn>
              <v-btn class="ma-2" fab dark small color="primary">
                <v-icon dark>instagram</v-icon>
              </v-btn>

              <v-btn class="ma-2" fab dark small color="primary">
                <v-icon dark>mail</v-icon>
              </v-btn>
              <v-btn class="ma-2" fab dark small color="primary">
                <v-icon dark>print</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="post-body">
            <div v-html="compiledMarkdown"></div>
            <div class="comment-wrapper mt-6">
              <CommentForm
                :postId="post.id"
                :commentCount="commentCount"
              ></CommentForm>

              <CommentItem
                v-for="item in comments"
                :key="item.id"
                :comment="item"
              ></CommentItem>

              <div class="full-width text-center" v-if="showLoadMore">
                <v-btn
                  class="text-none"
                  text
                  color="primary"
                  :loading="loadingComments"
                  @click.prevent="loadMore()"
                >
                  {{ $t("load_more") }} ({{ numberCommentToView }})
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="4" class="block-left d-none d-md-block">
        <v-divider class="mx-6 mt-0" color="#f7b538" ></v-divider>
        <div class="mx-6">
          <!-- <p class="title ">Advertisement</p> -->
          <div class="">
            <a href="http://www.kriolmidia.com/" target="_blank">
              <v-img
                src="https://res.cloudinary.com/kriolmidia/image/upload/v1596855234/Publicidade1_d6b3c34412.jpg"
                aspect-ratio="2"
              ></v-img>
            </a>
          </div>

          <v-divider class="my-6" color="#f7b538" ></v-divider>
          <h2 v-t="'suggested_for_you'"></h2>
          <div v-for="item in listPosts" :key="item.id">
            <CardListPost :post="item"></CardListPost>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardListPost from "@/components/card/CardListPost";
import CommentForm from "@/components/comment/CommentForm";
import CommentItem from "@/components/comment/CommentItem";
import BtnBack from "@/components/btn/BtnBack";
import AuthorAvatarImg from "@/components/shared/AuthorAvatarImg";
import LoadingContainer from '@/components/shared/LoadingContainer';

import { mapActions, mapGetters } from "vuex";
import marked from "marked";

export default {
  title: "Post detail",
  name: "Post",
  components: {
    CardListPost,
    CommentForm,
    CommentItem,
    BtnBack,
    AuthorAvatarImg,
    LoadingContainer
  },

  data: () => ({
    input: "# hello",
    limitComments: 2,
    loading: true,
  }),

  created() {
    let _post = this.$route.params.post;
    let _postId = this.$route.params.postId;

    if(_post){
      this.setPost(_post);
      this.setCommentCount(_post.commentCount ? _post.commentCount : 0);
      this.fetchComments({ postId: _post.id, limit: this.limitComments })
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
      this.loading = false;
    
    }
    else{

        this.fetchPost(_postId)
          .then((postObj) => {
            console.log("postObj", postObj)
               this.setPost(postObj);
                this.setCommentCount(postObj.commentCount ? postObj.commentCount : 0);
                this.fetchComments({ postId: _postId, limit: this.limitComments })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                  });
                   this.loading = false;

          })
          .catch((err) => {
            console.error(err);
          });
    }

     if (this.posts.length === 0) {
        this.fetchListPosts()
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
  },

  methods: {
    ...mapActions("posts", ["setPost", "fetchListPosts", "fetchPost"]),
    ...mapActions("comments", [
      "fetchComments",
      "setCommentCount",
      "clearComments",
    ]),

    loadMore() {
      if (this.post)
        this.fetchComments({ postId: this.post.id, limit: null })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
    },
  },

  computed: {
    ...mapGetters({
      post: "posts/getPost",
      posts: "posts/getListPosts",
      comments: "comments/getComments",
      totalComments: "comments/getTotalComments",
      loadingComments: "comments/getLoading",
      commentCount: "comments/getCommentCount",
    }),
    //  ...mapGetters({
    //   comments: "comments/getComments",
    //   commentotalCommentsts: "comments/getTotalComments",
    // }),

    compiledMarkdown: function () {
      return marked(this.post.content, { sanitize: true });
    },
    numberCommentToView() {
      return this.commentCount - this.limitComments;
    },

    showLoadMore() {
      if (this.commentCount === 0) return false;
      const _limit = this.commentCount - this.limitComments;
      return _limit > 0 && this.totalComments < this.commentCount;
    },

    listPosts() {
      if (this.posts.length > 0) {
        return this.posts.slice(-5);
      }
      return [];
    },
  },

  beforeDestroy() {
    // temporary solution
    this.clearComments();
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  width: 100%;
  height: 100%;
}

.post-container {
  max-width: 100%;
  display: flex;
  .post-sharing {
    width: 20%;
    /* display: flex;
    flex-wrap: wrap; */
  }
  .post-body {
    width: 80%;

    p img {
      width: 100% !important;
    }
  }
}

.comment-wrapper,
.full-width {
  width: 100%;
}

@media (max-width: 452px) {
  .read-wrapper{
    margin-left: 0!important;
    padding-top: 6px;
  }
}
</style>