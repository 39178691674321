import { debounce, getOrderBy } from '../../helpers';
export default {
  namespaced: true,

  state: {
    items: [],
    searchText: '',
    showFilter: false,
    filters: {
      orderBy: 'name',
      categoryId: 15,
      costsSelected: [],
      difficultiesSelected: [],
      tagsSelected: [],
      prepTime: 100,
      cookTime: [1, 100],
      classification: [
        { id: "1", value: [1], selected: true },
        { id: "2", value: [1, 2], selected: true },
        { id: "3", value: [1, 2, 3], selected: true },
        { id: "4", value: [1, 2, 3, 4], selected: true },
        { id: "5", value: [1, 2, 3, 4, 5], selected: true },
      ],
    }
  },

  getters: {
    getItems(state) {
      return state.items
    },

    getSearchText(state) {
      return state.searchText;
    },
    getFilters(state) {
      return state.filters;
    },
    getShowFilter(state) {
      return state.showFilter;
    },


  },

  actions: {

    setClassification({ commit }, id) {
      commit('changeClassification', { id: id });
    },

    setCategoryId({ commit }, id) {
      commit('setCategoryID', { id: id });
    },

    setShowFilter({ state, commit },) {
      commit('set', { resource: 'showFilter', item: !state.showFilter });
    },

    setFilter: debounce(function ({  state, dispatch }) {
    

        let url = getOrderBy(state, '?');
   

        url += `&categories_in=${state.filters.categoryId}`;
        

        state.filters.costsSelected.forEach((element) => {
          url += `&cost=${element.id}`;
        });

        state.filters.difficultiesSelected.forEach((element) => {
          url += `&difficulty=${element.id}`;
        });

        state.filters.tagsSelected.forEach((element) => {
          // url += `&tag=${element.tag}`;
          url += `&tags_in=${element.id}`;
        });

        const _prepTime = 300 * (state.filters.prepTime / 100);
        url += `&prepTime_lte=${_prepTime}`;

        const _cookTime = 300 * (state.filters.cookTime[1] / 100);
        url += `&cookTime_lte=${_cookTime}`;

        // const classification = state.filters.classification;
        // let maxRating = 0;
        // for(let i = 0; i < classification.length; i++){
        //   if(classification[i].selected){
        //     maxRating = i;
        //   }
        // }
        // url += `&rating_lte=${maxRating+1}`;
        

      // dispatch('recipes/fetchListRecipesSearch', url, { root: true });
      dispatch('recipes/fetchListRecipesByCategory', url, { root: true });
    }, 300)

  },

  mutations: {
    set(state, { resource, item }) {
      state[resource] = item;
    },

    deleteCategory(state, { item }) {
      const index = state.filters.categoriesSelected.indexOf(item.id);

      if (index >= 0)
        state.filters.categoriesSelected.splice(index, 1);
    },

    changeClassification(state, { id }) {
      const items = state.filters.classification;
      items.forEach(el => {
        if (el.id == id)
          el.selected = !el.selected
      });
      state.filters.classification = [...items]
    },
    setCategoryID(state, { id }) {
      state.filters.setCategoryId = id
    },
  }
}