<template>

   <v-img
        src="../../assets/loading.gif"
        lazy-src="../../assets/loading.gif"
        aspect-ratio="1"
        max-width="300px"
      ></v-img>
</template>

<script>
  export default {
  }
</script>

<style scoped>

</style>