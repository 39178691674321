import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import RestaurantIcon from '../components/icons/RestaurantIcon'

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/scss/variables.scss'],
  treeShake: true,
    theme: {
     
        themes: {
            options: {
                customProperties: true,
            },
            light: {
                primary: '#e0a440',
                secondary: '#6d191e',
                accent: '#6d191e',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                mainBackground: '#FFFFFF',
                background: {
                  base: '#FFFFFF',
                  darken1: '#ececec',
                  darken2: '#FFFFFF', //card
              },
              inputText: {
                base: '#202020',
              }
            },
            dark: {
                primary: '#AA7508',
                secondary: '#3E0000',
                accent: '#AA7508',
                mainBackground: '#222222',
                footer: '#8c9eff',
                background: {
                  // base: '#00ff00',
                  base: '#222222',
                  darken1: '#303030',
                  // darken2: '#323232', //card
                  darken2: '#1E1E1E', //card
                  // 121212
              },
              inputText: {
                base: '#FFFFFF'
              }
                
            },
          },
          dark: false,
          options: { customProperties: true },
      },
      icons: {
        values: {
          custom: { // name of our custom icon
            component: RestaurantIcon, // our custom component
          },
        },
    }
});
